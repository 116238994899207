import { Pinia } from 'pinia'
import { NQuote } from '~/types/api'
import ApiFactory from '~/api/factory'
import { EVehicleType } from '~/types/vehicle'

export type QuotePayload = {
  Criteria: any
  Vin: string
  GssnId: string
}

function transformResponse(
  res: NQuote.get.Response
): NQuote.get.TransformedResponse {
  const message = 'Unable to get finance quote'

  // Deal with no reponse from apiis
  if (!res || !res.FinanceCalculate) {
    throw new OsFetchError(message)
  } else if (isQuoteError(res.FinanceCalculate)) {
    // todo: is this possible?
    throw new OsFetchError(res.FinanceCalculate.Message)
  } else {
    // hande FinanceCalculate errors
    const product =
      res.FinanceCalculate.VehicleResults[0].FinanceProductResults[0]

    if (!res.FinanceCalculate.HasError) {
      return product
    } else if (isQuoteError(product.QuoteDto)) {
      return { ...product, HasError: true }
    }

    throw new OsFetchError(message)
  }
}

class QuoteModule extends ApiFactory {
  quoteApiVersion = ''

  constructor(
    pinia: Pinia,
    props: { apiVersion: string; quoteApiVersion: string }
  ) {
    super(pinia, { apiVersion: props.apiVersion })

    this.quoteApiVersion = props.quoteApiVersion
  }

  async get(
    config: QuotePayload,
    vehicleType: EVehicleType = EVehicleType.New,
    signal?: AbortSignal
  ) {
    const { Criteria, Vin, GssnId } = config

    const apiUrl = `${
      this.quoteApiVersion
    }/finance/calculate/${vehicleType.toLowerCase()}`

    return await $osPost<NQuote.get.Response, NQuote.get.TransformedResponse>(
      apiUrl,
      {
        body: {
          Criteria,
          Vehicles: [{ Vin, GssnId }]
        },
        transform: transformResponse,
        signal
      }
    )
  }

  getTerms(TermsUrl: string) {
    const apiUrl = `${this.apiVersion}/finance/terms`

    return $osPost(apiUrl, { body: { TermsUrl } })
  }
}

export default QuoteModule
