type ErrorMessage = string | object

export class OsFetchError {
  response: { status: number } = { status: 500 }
  data: ErrorMessage = { message: 'Something went wrong' }

  constructor(error: ErrorMessage, code?: number) {
    // Check the type of the error argument and handle accordingly
    if (typeof error === 'string') {
      this.data = { message: error }
    } else {
      this.data = error
    }

    if (code) {
      this.response.status = code
    }
  }

  toString() {
    return JSON.stringify({
      ...this.response,
      data: this.data
    })
  }
}
