<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" class="moonIcon">
    <path
      id="Moon"
      fill="currentColor"
      d="M15.8051713,8.56415371 C15.6739573,8.50919863 15.52036,8.54799277 15.4307486,8.66113906 C14.2723233,10.1159786 12.553885,10.9500921 10.7138423,10.9500921 C7.3761672,10.9500921 4.66251052,8.20853417 4.66251052,4.83652216 C4.66251052,3.22326998 5.28012251,1.70055059 6.40335167,0.549611711 C6.5025532,0.446154098 6.52495606,0.290957802 6.45454425,0.164886714 C6.38415199,0.0387958722 6.23694154,-0.025867605 6.09934068,0.00969038564 C2.50886677,0.905231254 0,4.14468016 0,7.8852365 C0,12.3596651 3.60328672,16 8.03217851,16 C12.0706706,16 15.4979572,12.9480693 15.9971679,8.90361231 C16.0163676,8.75812442 15.9363657,8.62234493 15.8051713,8.56415371 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Moon'
}
</script>
