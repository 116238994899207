<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 22"
    class="heart__icon"
  >
    <path
      class="iconShape"
      fill="currentColor"
      fill-rule="evenodd"
      stroke-width=".8"
      d="M17,0.4c3.6,0,6.5,2.9,6.5,6.5c0,1.8-0.8,3.5-2,4.7l-8.3,9.9h-2.4l-8.3-10l0,0c-1.2-1.2-2-2.8-2-4.7c0-3.6,2.9-6.5,6.5-6.5c2,0,3.9,0.9,5,2.4C13.2,1.4,15,0.4,17,0.4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Heart'
}
</script>

<style lang="scss">
.iconShape {
  transition: 0.2s linear;
}
</style>
