import { UseFetchOptions } from 'nuxt/app'
import { ECondition, EConditionSlug } from '~/types/vehicle'
import { NVehiclesSearch } from '~/types/api'
import { useFiltersStore } from '~/stores/filtersStore'
import isValidResponse from '~/guards/vehicle'
import ApiFactory from '~/api/factory'

const processSearchResponse = (response: NVehiclesSearch.Response) => {
  const vehicles = response?.SearchResults.Vehicles
  const vehicleValidation = vehicles.map((vehicle) => isValidResponse(vehicle))
  const allValid = vehicleValidation.every(([isValid]) => isValid)

  if (allValid) {
    return {
      searchResults: response?.SearchResults,
      agencyEnabled: response?.FeatureStatus?.AgencyEnabled || false
    }
  } else {
    const failedVehicle = vehicleValidation.find(([isValid]) => !isValid)
    const [, reason] = failedVehicle!

    // eslint-disable-next-line no-console
    console.warn(reason || 'Invalid response')
    throw new OsFetchError(reason || 'Invalid response')
  }
}

class VehicleSearchModule extends ApiFactory {
  fetch(payload: NVehiclesSearch.Payload, isNew = true) {
    const conditionSlug = isNew ? EConditionSlug.New : EConditionSlug.Used

    return $osPost<
      NVehiclesSearch.Response,
      NVehiclesSearch.TransformedResponse
    >(`${this.apiVersion}/vehicles/search/${conditionSlug}`, {
      body: {
        ...payload,
        IncludeOffers: true,
        IncludeReservations: false,
        IncludeQuotes: false
      },
      transform: processSearchResponse
    })
  }

  get(options: UseFetchOptions<any, any>) {
    const filtersStore = useFiltersStore(this.pinia)

    const conditionSlug = filtersStore.isNew
      ? EConditionSlug.New
      : EConditionSlug.Used

    const payload: NVehiclesSearch.Payload =
      getVehicleSearchPayloadFromFilters()

    return $osPost<
      NVehiclesSearch.Response,
      NVehiclesSearch.TransformedResponse
    >(`${this.apiVersion}/vehicles/search/${conditionSlug}`, {
      ...options,
      body: {
        ...payload,
        IncludeOffers: true,
        IncludeReservations: false,
        IncludeQuotes: false
      },
      transform: processSearchResponse
    })
  }

  // todo -> move to offers if it's using its apiVersion
  getPromoOffers() {
    // const config = useRuntimeConfig()
    // The version -> config.public.apiVersions.offers

    return $osPost(`v3/offers/promotion`, {
      body: getPromoOffersPayload('Results')
    })
  }

  getCount(payload?: NVehiclesSearch.CountPayload) {
    if (!payload) {
      payload = getVehicleSearchPayloadFromFilters()
    }

    const isNew = payload.Criteria.VehicleType === ECondition.New
    const conditionSlug = isNew ? EConditionSlug.New : EConditionSlug.Used

    return $osPost<NVehiclesSearch.CountResponse>(
      `${this.apiVersion}/vehicles/search/${conditionSlug}/count`,
      {
        body: payload
      }
    )
  }
}

export default VehicleSearchModule
