<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 3">
    <path fill="currentColor" fill-rule="evenodd" d="M0 0h16v2.526H0z" />
  </svg>
</template>
<script>
export default {
  name: 'Minus'
}
</script>
