/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const humanMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

/**
 * Process `date` so that it can be used by `new Date()`
 *
 * @param {string} date
 */
function getCleanDate(date: string | Date) {
  const dateString = typeof date !== 'string' ? String(date) : date
  if (dateString?.includes('/Date(')) {
    return parseInt(dateString.replace('/Date(', '').replace(')/', ''))
  }

  return dateString
}

const formatDate = (
  date: string | Date | undefined,
  formatTo = 'DD/MM/YYYY',
  formatFrom?: string
): string => {
  if (!date) return ''

  return dayjs(getCleanDate(date), formatFrom).format(formatTo)
}

const formatOfferDate = (date) => {
  if (!date) return

  const cleanDate = new Date(getCleanDate(date))

  return dayjs(cleanDate).format('DD/MM/YYYY')
}

const isRegisteredAfterApril2020 = (date) => {
  if (date) {
    const vehicleTimestamp = new Date(getCleanDate(date)).getTime() / 1000
    const aprilTimestamp = 1585699200
    return vehicleTimestamp > aprilTimestamp
  }
}

const isRegisteredBetweenJan19March2020 = (date) => {
  if (date) {
    const vehicleTimestamp = new Date(getCleanDate(date)).getTime() / 1000
    const aprilTimestamp = 1585699200
    const janTimestamp = 1546300800
    return vehicleTimestamp < aprilTimestamp && vehicleTimestamp > janTimestamp
  }
}

const isRegisteredBeforeJan19 = (date) => {
  if (date) {
    const vehicleTimestamp = new Date(getCleanDate(date)).getTime() / 1000
    const janTimestamp = 1546300800
    return vehicleTimestamp < janTimestamp
  }
}

const getMonthName = (date) => {
  if (date) {
    const monthName = humanMonths[date.getMonth()]
    return monthName
  }
}

const formatCountdown = (value: Number, hideHours: boolean = false): string => {
  if (!value) return ''

  let seconds: number | string = Math.floor((value / 1000) % 60)
  let minutes: number | string = Math.floor((value / (1000 * 60)) % 60)
  let hours: number | string = Math.floor((value / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  if (hideHours) return minutes + ':' + seconds
  return hours + ':' + minutes + ':' + seconds
}

const timeFromNow = (date) => {
  if (!date) return

  return date.fromNow()
}

const diffFromNow = (date) => {
  if (!date) return

  return date.diff(dayjs())
}

export const useDate = () => {
  return {
    getCleanDate,
    formatDate,
    formatOfferDate,
    isRegisteredAfterApril2020,
    isRegisteredBetweenJan19March2020,
    isRegisteredBeforeJan19,
    getMonthName,
    formatCountdown,
    timeFromNow,
    diffFromNow
  }
}
