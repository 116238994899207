<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22">
    <defs>
      <clipPath id="drive-time-clip-path">
        <path
          d="M7.2645,0.48225 C11.28225,0.48225 14.56125,3.7605 14.56125,7.779 C14.56125,11.12175 12.279,13.95 9.19275,14.81775 L8.0355,21.375 L6.5895,21.375 L5.43225,14.81775 C2.3145,14.0145 0,11.154 0,7.779 C0,3.76125 3.24675,0.48225 7.2645,0.48225 Z M17.3895,16.81125 L11.66775,20.18625 L9.19275,20.18625 L15.68625,16.81125 L17.3895,16.81125 Z M18.5355,0 C21.55725,0 24,2.44275 24,5.4645 C24,8.004 22.296,10.125 19.98225,10.76775 L19.05,15.62175 L17.9895,15.62175 L17.12175,10.704 C16.5585,10.57875 16.02675,10.3305 15.5535,10.02075 C15.76875,9.29925 15.891,8.53875 15.891,7.749 C15.891,5.80125 15.18825,4.0125 14.025,2.61975 C13.99575,2.583 13.968,2.54325 13.9395,2.50725 C14.90325,0.99675 16.60725,0 18.5355,0 Z M8.10675,2.93775 L6.44175,2.93775 L3.51675,11.6235 L5.22675,11.6235 L5.76675,9.8235 L8.82675,9.8235 L9.36675,11.6235 L11.07675,11.6235 L8.10675,2.93775 Z M7.25175,4.82775 L8.37675,8.38275 L6.12675,8.38275 L7.25175,4.82775 Z M18.8385,2.79675 L17.42025,2.79675 L17.42025,8.145 L18.6765,8.145 C19.608,8.145 19.85175,8.145 20.21625,8.0235 C20.82375,7.82025 21.22875,7.25325 21.22875,6.60525 C21.22875,5.87625 20.82375,5.43 20.0535,5.268 C20.661,5.0655 20.94525,4.7415 20.94525,4.134 C20.94525,3.567 20.62125,3.08025 20.09475,2.91825 C19.81125,2.83725 19.48725,2.79675 18.8385,2.79675 Z M19.1995,5.75488889 C19.4795,5.758 19.6125,5.772 19.77,5.835 C20.013,5.9565 20.175,6.1995 20.175,6.52425 C20.175,6.84825 20.0535,7.13175 19.81125,7.21275 C19.6485,7.29375 19.446,7.33425 18.879,7.33425 L18.4335,7.33425 L18.4335,5.754 Z M18.9540674,3.6886582 C19.2934922,3.68976562 19.46625,3.698625 19.608,3.7695 C19.8105,3.8505 19.932,4.0935 19.932,4.3365 C19.932,4.5795 19.80975,4.821 19.608,4.9035 C19.44375,4.97025 19.2435,5.025 18.879,5.025 L18.393,5.025 L18.393,3.6885 Z"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#drive-time-clip-path)">
      <polygon fill="currentColor" points="0 0 24 0 24 21.375 0 21.375 0 0" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DriveTime'
}
</script>
