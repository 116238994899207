<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="cursor-help"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M16 20H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4Zm-5.257-5.49V8.333H7.156v1.324h1.63v4.853H6.993v1.323h5.217V14.51h-1.467ZM9.167 6.667c.724 0 1.304-.527 1.304-1.185 0-.789-.58-1.315-1.304-1.315-.725 0-1.305.526-1.305 1.315 0 .658.58 1.185 1.305 1.185Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Info'
}
</script>
