import ApiFactory from '~/api/factory'
import { CmsAgencyWorkflowContentBlock } from '~/types/cms/contentModel'

class ContentBlockModule extends ApiFactory {
  get() {
    return $osGet<CmsAgencyWorkflowContentBlock[]>(
      '/v3/cms/agencyworkflowcontentblock'
    )
  }
}

export default ContentBlockModule
