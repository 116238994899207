import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/build/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import slideovers_X7whXyrIWR from "/build/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/build/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/build/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/build/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/build/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/build/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _00_browserCheck_pL7xToxAUq from "/build/plugins/00.browserCheck.ts";
import _01_api_GIiciWaEWX from "/build/plugins/01.api.ts";
import _02_piniaApp_YBbAqJGZ4l from "/build/plugins/02.piniaApp.ts";
import _03_navigation_j04bDiXwF6 from "/build/plugins/03.navigation.ts";
import _05_kiosk_E5aCSSaSmf from "/build/plugins/05.kiosk.ts";
import _07_eventBus_DcFRfP59IU from "/build/plugins/07.eventBus.ts";
import consent_client_xTMARhDQ7q from "/build/plugins/consent.client.ts";
import deepLink_client_elyfM6oOpf from "/build/plugins/deepLink.client.ts";
import errors_5qvZOSbheL from "/build/plugins/errors.ts";
import gtm_client_OzmBuHLRIb from "/build/plugins/gtm.client.ts";
import preferredRetailer_client_5lfgfDrzpU from "/build/plugins/preferredRetailer.client.ts";
import restoreCookie_client_slN9wblZtf from "/build/plugins/restoreCookie.client.ts";
import rules_Lpq0z0sPAd from "/build/plugins/rules.ts";
import survey_client_gSxBExQo8t from "/build/plugins/survey.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_AUP22rrBAc,
  _00_browserCheck_pL7xToxAUq,
  _01_api_GIiciWaEWX,
  _02_piniaApp_YBbAqJGZ4l,
  _03_navigation_j04bDiXwF6,
  _05_kiosk_E5aCSSaSmf,
  _07_eventBus_DcFRfP59IU,
  consent_client_xTMARhDQ7q,
  deepLink_client_elyfM6oOpf,
  errors_5qvZOSbheL,
  gtm_client_OzmBuHLRIb,
  preferredRetailer_client_5lfgfDrzpU,
  restoreCookie_client_slN9wblZtf,
  rules_Lpq0z0sPAd,
  survey_client_gSxBExQo8t
]