import { defineStore } from 'pinia'
import { useFinanceCriteriaStore } from './criteriaStore'
import { useFinanceQuotesStore } from './quotesStore'
import { ECondition } from '~/types/vehicle'
import {
  EFinanceProductsType,
  IFinanceProducts,
  IFinanceProductsSingle
} from '~/types/finance/products'
import { EFinanceProductKeys, IFinanceProduct } from '~/types/finance/product'
import { IFiltersRange } from '~/types/filters'
import { ERoutes } from '~/types/routes'
import { financeFactory } from '~/factories/financeFactory'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { NonNullableInterface } from '~/types'

export interface IFinanceProductsState {
  [EFinanceProductsType.all]: {
    [ECondition.New]: IFinanceProduct[]
    [ECondition.Used]: IFinanceProduct[]
  }
  [EFinanceProductsType.vehicle]: IFinanceProduct[]
}

export const useFinanceProductsStore = defineStore('finance/products', {
  state: (): IFinanceProductsState => {
    return financeFactory.products.create()
  },

  actions: {
    async fetchAllProducts(type?: EFinanceProductsType, nuxtApp?) {
      const [New, Used] = await this.api.finance.products.getProducts()

      if (New?.error || Used?.error) {
        throw createError({
          statusMessage:
            'We could no fetch the finance defaults at this moment. Please try again later.'
        })
      }

      if (Array.isArray(New.data) && Array.isArray(Used.data)) {
        this[EFinanceProductsType.all] = {
          [ECondition.New]: New.data,
          [ECondition.Used]: Used.data
        }

        if (nuxtApp) {
          nuxtApp.runWithContext(() => this.setDefaultProduct(type))
        } else {
          this.setDefaultProduct(type)
        }
      }
    },

    async fetchProductsForVehicle({
      vin,
      vehicleType,
      price
    }: {
      price: number
      vehicleType: string
      vin: string
    }) {
      const { data } = await this.api.finance.products.getProductsForVehicle(
        vehicleType.toLowerCase(),
        vin,
        price
      )

      if (Array.isArray(data)) {
        this[EFinanceProductsType.vehicle] = data
      }

      this.setDefaultProduct(EFinanceProductsType.vehicle)
    },

    setDefaultProduct(type?: EFinanceProductsType) {
      if (!type) {
        const route = useRoute()
        type =
          route.name === ERoutes.Vehicle || route.name === ERoutes.Offer
            ? EFinanceProductsType.vehicle
            : EFinanceProductsType.all
      }

      const financeCriteriaStore = useFinanceCriteriaStore()
      const customerTypeStore = useCustomerTypeStore()

      // Do not set default product if the user has already selected.
      if (financeCriteriaStore.isPersonalised) return

      const defaultProducts = this.getDefaultProduct
      const defaultKey = defaultProducts[type]?.Key

      if (customerTypeStore.isBusiness) {
        financeCriteriaStore.defaultBusinessKey =
          defaultKey || EFinanceProductKeys.CH
      } else {
        financeCriteriaStore.defaultKey = defaultKey || EFinanceProductKeys.PCP
      }
    }
  },

  getters: {
    getAllProducts(state): IFinanceProduct[] {
      const { active } = useFiltersStore()

      return state[EFinanceProductsType.all][active.condition]
    },

    getVehicleProducts(state): IFinanceProduct[] {
      const { from } = useNuxtApp()
      if (!from) {
        return state[EFinanceProductsType.vehicle]
      } else {
        return getUniqueFinanceProducts(
          this.getAllProducts,
          state[EFinanceProductsType.vehicle]
        )
      }
    },

    getPersonalProducts(state): IFinanceProducts {
      return {
        [EFinanceProductsType.all]: getProducts(this.getAllProducts).personal,
        [EFinanceProductsType.vehicle]: getProducts(
          state[EFinanceProductsType.vehicle]
        ).personal
      }
    },

    getBusinessProducts(): IFinanceProducts {
      return {
        [EFinanceProductsType.all]: getProducts(this.getAllProducts).business,
        [EFinanceProductsType.vehicle]: getProducts(this.getVehicleProducts)
          .business
      }
    },

    getProductByKey(): (key: EFinanceProductKeys) => IFinanceProductsSingle {
      return (key) => ({
        [EFinanceProductsType.all]: this.getAllProducts.find(
          (p: IFinanceProduct) => p.Key === key
        )!,
        [EFinanceProductsType.vehicle]: this.getVehicleProducts.find(
          (p: IFinanceProduct) => p.Key === key
        )!
      })
    },

    getDefaultProduct(): IFinanceProductsSingle {
      const { isBusiness } = useCustomerTypeStore()
      const products: any = isBusiness
        ? this.getBusinessProducts
        : this.getPersonalProducts

      return {
        [EFinanceProductsType.all]: getProducts(
          products[EFinanceProductsType.all]
        ).default,
        [EFinanceProductsType.vehicle]: getProducts(
          products[EFinanceProductsType.vehicle]
        ).default
      }
    },

    getDefaultMonthlyPrice(): NonNullableInterface<IFiltersRange> {
      const { currentQuote } = useFinanceQuotesStore()

      if (!isQuote(currentQuote?.QuoteDto)) {
        return {
          Min: 50,
          Max: 4000
        }
      }

      const min: number[] = []
      const max: number[] = []

      for (const p of this.getAllProducts) {
        if (p.CustomerType === currentQuote?.QuoteDto?.CustomerType) {
          if (p.MonthlyPrice.Min) min.push(p.MonthlyPrice.Min)
          if (p.MonthlyPrice.Max) max.push(p.MonthlyPrice.Max)
        }
      }

      return {
        Min: min.length ? Math.min(...min) : 50,
        Max: max.length ? Math.max(...max) : 4000
      }
    }
  }
})
