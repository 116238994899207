import {
  ICustomerCriteriaFilters,
  IModelCriteria
} from '~/types/forms/stockNotification'
import {
  EFilterCriteriaMap,
  EFilterDataMap,
  IFilterCollection
} from '~/types/filters'
import { IFilterDataBrand, IOsFilterDataNew } from '~/types/filterData'

export function getModelCriteria(
  models: number[],
  brands: IFilterDataBrand[]
): IModelCriteria[] {
  const allModels = getAllModelsFromBrands(brands)

  return allModels.filter(({ Id }) => models.includes(Id))
}

export function getCustomerCritera(
  collection: IFilterCollection,
  data: IOsFilterDataNew
): ICustomerCriteriaFilters {
  const criteria: ICustomerCriteriaFilters = {
    Model: [],
    Transmission: [],
    Fuel: [],
    Upholstery: [],
    Line: [],
    ColourGroup: [],
    Packages: [],
    Engine: []
  }

  for (const [key, value] of Object.entries(collection)) {
    const criteriaKey = EFilterCriteriaMap[key]
    const dataKey = EFilterDataMap[key]

    if (!Object.keys(criteria).includes(criteriaKey)) continue

    if (key === 'models') {
      criteria.Model = getModelCriteria(collection.models, data.Brands)

      continue
    }
    if (!data[dataKey]) {
      continue
    }
    criteria[criteriaKey] = data[dataKey]
      .filter(({ Id }) => value.includes(Id))
      .map(({ Description }) => Description)
  }

  return criteria
}
