import { defineStore } from 'pinia'
import { ICustomerTypeStore, ECustomerTypes } from '~/types/customerType'
import { ERouteCustomerTypes } from '~/types/routes'

export const useCustomerTypeStore = defineStore('customerType', {
  state: (): ICustomerTypeStore => {
    return {
      isMotability: false,
      isBusiness: false
    }
  },

  actions: {
    enableMotability() {
      this.isMotability = true
      this.isBusiness = false
    },

    enableBusiness() {
      this.isBusiness = true
      this.isMotability = false
    },

    enablePrivate() {
      this.isBusiness = false
      this.isMotability = false
    }
  },

  getters: {
    isDefault(): boolean {
      return !this.isBusiness && !this.isMotability
    },

    getCustomerTypeName: (state): ECustomerTypes => {
      if (state.isBusiness) return ECustomerTypes.Business
      if (state.isMotability) return ECustomerTypes.Motability
      return ECustomerTypes.Private
    },

    /**
     * @returns Returns the Customer Type route parameter based on the
     * Customer Type name
     */
    getCustomerTypeRouteParam(): ERouteCustomerTypes | null {
      if (this.getCustomerTypeName === ECustomerTypes.Business)
        return ERouteCustomerTypes.Business
      if (this.getCustomerTypeName === ECustomerTypes.Motability)
        return ERouteCustomerTypes.Motability

      return null
    }
  }
})
