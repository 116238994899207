import { IFilterCollection } from '~/types/filters'
import { NVehiclesSearch } from '~/types/api'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'

export const RESULTS_PER_PAGE = 20
export const BEST_MATCH_CRITERIA_ID = '6A9BCA02-BD6C-4854-ABAB-3560FC2CDB70'

export interface ISearchPayloadOptions {
  sortId: number
  pageIndex: number
  isRetailerMode: boolean
  isNew: boolean
  isMotability: boolean
}

/*
 * Helper function to generate V3 vehicle search payload.
 */
export function getVehicleSearchPayload(
  collection: IFilterCollection,
  options?: Partial<ISearchPayloadOptions>
): NVehiclesSearch.Payload {
  const { $pinia } = useNuxtApp()
  const searchOptions: ISearchPayloadOptions = {
    sortId: 1,
    pageIndex: 0,
    isRetailerMode: false,
    isNew: true,
    isMotability: false,
    ...options
  }

  const financeCriteriaStore = useFinanceCriteriaStore($pinia)
  const filterDataStore = useFilterDataStore($pinia)

  const New = filterDataStore.getFilterDataNew
  const Used = filterDataStore.getFilterDataUsed

  const payload: NVehiclesSearch.Payload = {
    Criteria: filterCollection.getCriteria(
      collection,
      { New, Used },
      searchOptions.isRetailerMode,
      searchOptions.isMotability
    ),
    Sort: {
      Id: searchOptions.sortId
    },
    Finance: {
      Criteria: financeCriteriaStore.quoteCriteria(
        financeCriteriaStore.currentKey
      )
    },
    Paging: {
      ResultsPerPage: RESULTS_PER_PAGE,
      PageIndex: searchOptions.pageIndex
    },
    BestMatchCriteriaId: BEST_MATCH_CRITERIA_ID
  }

  if (searchOptions.isNew) {
    payload.AgentId = collection.retailers[0]
  }

  return payload
}

/*
 * Helper function to generate V3 vehicle search payload.
 */
export function getVehicleSearchPayloadFromFilters(): NVehiclesSearch.Payload {
  const { $pinia } = useNuxtApp()

  const filtersStore = useFiltersStore($pinia)
  const customerTypeStore = useCustomerTypeStore($pinia)
  const vehiclesSearchStore = useVehiclesSearchStore($pinia)
  const filters = { ...filtersStore.active }

  return getVehicleSearchPayload(filters, {
    sortId: filtersStore.getSortId,
    pageIndex: vehiclesSearchStore.meta.pages.current,
    isRetailerMode: filtersStore.isRetailerMode,
    isNew: filtersStore.isNew,
    isMotability: customerTypeStore.isMotability
  })
}
