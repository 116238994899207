import { NTestDrives } from '~/types/api'
import isValidResponse from '~/guards/vehicle'
import ApiFactory from '~/api/factory'

const processResponse = (response: NTestDrives.get.Response) => {
  const vehicles = response?.Response.map(({ Vehicle }) => Vehicle)
  const vehicleValidation = vehicles.map((vehicle) => isValidResponse(vehicle))
  const allValid = vehicleValidation.every(([isValid]) => isValid)

  if (allValid) {
    return {
      testDrives: response?.Response
    }
  }

  const failedVehicle = vehicleValidation.find(([isValid]) => !isValid)
  const [, reason] = failedVehicle!

  // eslint-disable-next-line no-console
  console.warn(reason || 'Invalid response')
  throw new OsFetchError(reason || 'Invalid response')
}

class TestDrivesModule extends ApiFactory {
  get(ciamId: string) {
    return $osGet<
      NTestDrives.get.Response,
      NTestDrives.get.TransformedResponse
    >(`${this.apiVersion}/myaccount/${ciamId}/testdriveenquiry`, {
      transform: processResponse
    })
  }
}

export default TestDrivesModule
