<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M22.8 4c.66 0 1.2.54 1.2 1.2v13.2c0 .66-.54 1.2-1.2 1.2H1.2A1.2 1.2 0 0 1 0 18.4V5.08A1.2 1.2 0 0 1 1.2 4Zm-10.84 8.13c-1.9 0-3.46 1-3.46 2.19v1.88h6.92v-1.88c0-1.19-1.58-2.19-3.46-2.19ZM12 8c-.46 0-.86.16-1.2.5a1.6 1.6 0 0 0-.49 1.18 1.68 1.68 0 0 0 2.89 1.2c.34-.33.5-.72.5-1.2 0-.44-.18-.84-.5-1.18a1.6 1.6 0 0 0-1.2-.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IdLicense'
}
</script>
