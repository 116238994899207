<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C24,8.81740212 22.7357179,5.76515517 20.4852814,3.51471863 C18.2348448,1.26428208 15.1825979,0 12,0 Z M15.4314,16.7082 C15.0621,17.3307 14.49,17.5824 13.4814,17.5824 C13.179,17.5824 13.179,17.5824 11.8356,17.499 C11.3814,17.4648 10.9449,17.4483 10.5756,17.4483 C9.68780515,17.4504661 8.80096526,17.5066701 7.92,17.6166 C8.559,16.86 9.1293,15.0117 9.5331,12.3576 L8.0721,12.3576 L8.0721,11.0976 L9.7686,11.0976 L9.8028,10.962 C10.1559,8.9961 10.2735,8.5089 10.6266,7.7031 C11.0628,6.6606 11.9028,6.1407 13.1124,6.1407 C14.07,6.1407 14.8431,6.4938 15.2466,7.0983 C15.5336621,7.6116417 15.6839703,8.19014729 15.6831,8.7783 L14.2041,8.7783 C14.1207,7.9383 13.8183,7.5852 13.1466,7.5852 C12.4407,7.5852 12.1383,7.9383 11.8686,9.03 C11.7852,9.3324 11.6676,10.0218 11.4993,11.0973 L14.0535,11.0973 L14.0535,12.3393 L11.2635,12.3393 C11.028,14.0208 10.6422,15.2631 10.0881,16.188 C10.6606102,16.0203271 11.2553578,15.9410139 11.8518,15.9528 C12.087,15.9528 12.3729,15.969 12.9108,15.9855 L13.4808,16.0032 C13.9008,16.0032 14.1198,15.9363 14.2374,15.75 C14.4219036,15.4044617 14.5142723,15.017216 14.5056,14.6256 L15.8856,14.6256 C15.8856,15.6165 15.768,16.1541 15.4314,16.7082 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Pound'
}
</script>
