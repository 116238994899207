import { defineStore } from 'pinia'
import {
  EProfileVehicleGroup,
  SavedVehicle,
  SavedVehicleCard
} from '~/types/profile'
import { ECustomerTypes } from '~/types/customerType'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useCompareStore } from '~/stores/compareStore'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { IRetailer } from '~/types/retailer'
import { isNewVehicle } from '~/guards/vehicle'

interface IShortlistStore {
  shortlist: (NewVehicle | UsedVehicle)[]
  savedVehicles: SavedVehicle[]
  isLoading: boolean
  isLoadingDetails: boolean
  fetchError: boolean
  fetchDetailsError: boolean
}

export const useShortlistStore = defineStore('shortlist', {
  state: (): IShortlistStore => {
    return {
      shortlist: [],
      savedVehicles: [],
      isLoading: false,
      isLoadingDetails: false,
      fetchError: false,
      fetchDetailsError: false
    }
  },

  actions: {
    async fetch() {
      this.isLoading = true
      this.fetchError = false
      const response = await this.api.profile.shortlist.get()

      if (response.error) {
        this.fetchError = response.error.code !== 404
      } else {
        this.savedVehicles = response.data.Vehicles || []
      }

      this.isLoading = false
    },

    async fetchDetails(force = false) {
      const serviceStatusStore = useServiceStatusStore()
      // Avoid unnecessary API calls
      if (!this.fetchDetailsError) {
        if (this.savedVehicles.length === 0) return
        if (this.isLoading || this.isLoadingDetails) return
        if (!force && this.savedVehicles.length === this.shortlist.length)
          return
      }

      this.fetchDetailsError = false

      let vins = this.savedVehicles.map((savedVehicle) => ({
        Vin: savedVehicle.vin,
        RetailerId: savedVehicle.retailerId
      }))

      // filter already fetched vehicle details
      if (!force) {
        vins = vins.filter(
          (e) => !this.shortlist.find((item) => item.Vin === e.Vin)
        )
      }

      if (!vins.length) return

      this.isLoadingDetails = true

      await this.api.vehicles.details
        .get({
          Vins: vins,
          IncludeOfflineVehicles: true,
          IncludeEquipment: false
        })
        .then((response) => {
          serviceStatusStore.updateAgency(!!response.data?.agencyEnabled)

          if (response.error) {
            this.fetchDetailsError = true
          }

          if (force) {
            this.shortlist = response.data?.vehicles || []
          } else {
            this.shortlist = this.shortlist.concat(response.data?.vehicles)
          }
        })

      this.isLoadingDetails = false
    },

    async add(vehicle: NewVehicle | UsedVehicle, retailer: IRetailer) {
      this.isLoading = true

      const isNew = isNewVehicle(vehicle)

      const customerTypeStore = useCustomerTypeStore()
      const retailerData = retailer || vehicle.Retailer
      const ClientApplicationUserName = isNew ? 'ncl' : 'ucl'
      const CustomerMode = customerTypeStore.getCustomerTypeName

      // Legacy for PnR object store, look to only send
      // Vin & CustomerMode in the future
      const { error } = await this.api.profile.shortlist.add({
        ClientApplicationUserName,
        VIN: vehicle.Vin,
        IsNew: isNew,
        ExternalRetailerId: String(retailerData.Id),
        RetailerDescription: String(retailerData.Description),
        GssnId: String(retailerData.GssnId),
        VehicleGroup: EProfileVehicleGroup.Favourite,
        Description: vehicle.Description || '',
        BodyStyle: vehicle.BodyStyle || '',
        Model: vehicle.Model || '',
        FuelType: vehicle.FuelType || '',
        VehicleClass: vehicle.VehicleClass || '',
        TransmissionType: vehicle.TransmissionType || '',
        P11D: isNew ? String(vehicle.P11D) : null,
        ImageURL: vehicle.Media.MainImageUrl,
        CustomerMode
      })

      if (!error) {
        // Add to shortlist on success
        this.shortlist.unshift({
          ...vehicle,
          Retailer: retailerData
        })
        this.savedVehicles.unshift({
          vin: vehicle.Vin,
          customerMode: CustomerMode,
          retailerId: retailerData.Id!
        })
      }

      this.isLoading = false
    },

    async remove(vin: string, customerMode: ECustomerTypes) {
      const compareStore = useCompareStore()

      const { data } = await this.api.profile.shortlist.delete(
        vin,
        customerMode
      )

      if (!data.statusCode || !data.code) {
        let index = this.savedVehicles.findIndex((savedVehicle) => {
          return (
            savedVehicle.vin === vin &&
            savedVehicle.customerMode === customerMode
          )
        })

        this.savedVehicles.splice(index, 1)

        index = this.shortlist.findIndex((shortlistVehicle) => {
          return shortlistVehicle.Vin === vin
        })

        this.shortlist.splice(index, 1)

        compareStore.removeVehicle(vin)
      }
    },

    resetAll() {
      this.shortlist = []
      this.savedVehicles = []
      const compareStore = useCompareStore()
      compareStore.removeAllVehicles()
    },
    async removeAll() {
      const compareStore = useCompareStore()
      const { data } = await this.api.profile.shortlist.deleteAll()

      if (!data.statusCode || !data.code) {
        this.shortlist = []
        this.savedVehicles = []
        compareStore.removeAllVehicles()
      }
    }
  },

  getters: {
    getCount(): number {
      return this.getShortlist.length
    },

    getAvailableVinsCount(): number {
      return this.getShortlist
        .filter(({ vehicle }) => vehicle?.IsAvailableOnline)
        .map(({ vehicle }) => vehicle?.Vin).length
    },

    getShortlist(state): SavedVehicleCard[] {
      return state.savedVehicles
        .map((savedVehicle) => {
          const details = state.shortlist.find(
            ({ Vin }) => Vin === savedVehicle.vin
          )!

          return {
            ...savedVehicle,
            vehicle: details
          }
        })
        .filter((item) => !!item.vehicle)
    },

    isShortlisted(): (vehicleVin?: string) => boolean {
      return (vehicleVin?: string): boolean => {
        if (!vehicleVin) return false

        const customerTypeStore = useCustomerTypeStore()

        return this.savedVehicles.some(
          ({ vin, customerMode }) =>
            vin === vehicleVin &&
            customerMode === customerTypeStore.getCustomerTypeName
        )
      }
    }
  }
})
