import { Pinia } from 'pinia'

class ApiFactory {
  apiVersion = 'v3'
  pinia: Pinia | null = null

  constructor(pinia: Pinia, props?: { apiVersion: string }) {
    this.pinia = pinia
    this.apiVersion = props?.apiVersion || 'v3'
  }

  // Add extra shared fields as required.
}

export default ApiFactory
