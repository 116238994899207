export enum EFormActions {
  RemoveSection = 'DeleteSection',
  AddSection = 'AddSection'
}

export interface IOnlineCreditDecisionTextDetail {
  details: string
  list: string[]
  citation?: {
    enabled: string
    disabled: string
  }
}

export interface IOnlineCreditDecisionText {
  [key: string]: IOnlineCreditDecisionTextDetail
}

interface IOptions {
  Text: string
  Value: string
}

interface IValidationRules {
  Type: string
  Value: string
  ErrorMessage: string
}

export interface IField {
  Error: string | null
  Identifier: string
  IsReadOnly: boolean
  IsRequired: boolean
  Key: string
  Label: string
  Options: [] | IOptions[]
  ToolTip: string
  ToolTipPrompt: string
  Type: string
  ValidationRules: [] | IValidationRules
  Value: string
}

export interface IInputData {
  FieldIdentifier: string
  Value: string
  error: [] | string[]
}

interface IActions {
  Header: any[]
  Footer: any[]
}

interface IDetails {
  field?: []
  subStep?: string
  id?: string
  hasOneField?: boolean
  actions: IActions
}

export interface ISubStep {
  step: string
  id?: string
  isFormFactory: boolean
  isActive: boolean
  IsValidToSubmit?: boolean
  details: IDetails[]
  index?: number
  actions: IActions
}

export interface ITier {
  heading: string
  text: string[]
}

export enum ICreditRatingTier {
  SuperRed = 'superred',
  Red = 'red',
  Amber = 'amber',
  Green = 'green',
  SuperGreen = 'supergreen',
  White = 'white'
}

export interface ICreditRatingTiers {
  [ICreditRatingTier.SuperRed]: ITier
  [ICreditRatingTier.Red]: ITier
  [ICreditRatingTier.Amber]: ITier
  [ICreditRatingTier.Green]: ITier
  [ICreditRatingTier.SuperGreen]: ITier
  [ICreditRatingTier.White]: ITier
}

export interface IFinanceApplicationDecisions {
  approved: ITier
  other: ITier
}

export interface IOCDSessionExpire {
  ExpiresAt: Date
  ExpiresIn: number
}
