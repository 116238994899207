import {
  IFilterDataBrand,
  IFilterDataBrandModel,
  IFilterDataFilter,
  IOsBodyStyleIds
} from '~/types/filterData'

export * from './filter'
export * from './helpers'
export * from './filterCollection'

export function getAllModelsFromBrands(brands: IFilterDataBrand[]) {
  return brands.reduce<IFilterDataBrandModel[]>(
    (prev, current) => [...prev, ...current.Models],
    []
  )
}

export function getAllBodystylesFromBrands(brands: IFilterDataBrand[]) {
  let bodyStyles: IFilterDataFilter[] = []
  const bodyStyleByDescription: { [key: string]: IOsBodyStyleIds } = {}

  // Merge all the bodystyles of all brands into one array
  brands.forEach((brand) => {
    bodyStyles = bodyStyles.concat(brand.BodyStyles)
  })

  bodyStyles.forEach((bodyStyle) => {
    // Special case: Merge Cabriolet and Cabrio (smart) BodyStyle
    bodyStyle = {
      ...bodyStyle,
      Description:
        bodyStyle.Description === 'Cabrio' ? 'Cabriolet' : bodyStyle.Description
    }
    bodyStyleByDescription[bodyStyle.Description] = {
      Description: bodyStyle.Description,
      Ids: [
        ...(bodyStyleByDescription[bodyStyle.Description]?.Ids || []),
        bodyStyle.Id
      ]
    }
  })

  return Object.values(bodyStyleByDescription)
}
