<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 23">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.92940625,2.228125 L3.45,4.6 L13.2674062,4.6 L12.788,2.228125 L16.71525,2.228125 L16.71525,23 L-1.13686838e-13,23 L-1.13686838e-13,2.228125 L3.92940625,2.228125 Z M3.91359375,18.2584063 C3.43169079,18.2584063 3.04103125,18.6490658 3.04103125,19.1309688 C3.04103125,19.6128717 3.43169079,20.0035313 3.91359375,20.0035313 C4.39549671,20.0035313 4.78615625,19.6128717 4.78615625,19.1309688 C4.78615625,18.6490658 4.39549671,18.2584063 3.91359375,18.2584063 Z M14.303125,18.63575 L7.661875,18.63575 L7.661875,19.621875 L14.303125,19.621875 L14.303125,18.63575 Z M4.29135271,14.2624511 C3.96529845,14.127395 3.58999377,14.2020478 3.34044264,14.4515989 C3.09089151,14.70115 3.01623877,15.0764547 3.15129487,15.402509 C3.28635096,15.7285632 3.60451891,15.9411565 3.9574375,15.9411565 C4.18826122,15.941347 4.4103856,15.8495008 4.5741783,15.6858429 C4.73797099,15.522185 4.83,15.3001363 4.83,15.0685938 C4.83,14.7156752 4.61740697,14.3975072 4.29135271,14.2624511 Z M14.303125,14.5740938 L7.661875,14.5740938 L7.661875,15.5595 L14.303125,15.5595 L14.303125,14.5740938 Z M6.5190625,8.56175 L3.91646875,11.1363125 L2.95621875,10.4542188 L2.5774375,10.8854688 L3.99265625,12.2510938 L6.8726875,8.915375 L6.5190625,8.56175 Z M14.303125,10.4750625 L7.661875,10.4750625 L7.661875,11.462625 L14.303125,11.462625 L14.303125,10.4750625 Z M11.3030625,0 L12.0016875,3.5808125 L4.7135625,3.5808125 L5.41146875,0 L11.3030625,0 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Checklist'
}
</script>
