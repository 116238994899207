<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 Z M5.51115789,10.8454737 C6.20336842,8.88126316 8.05010526,8.52126316 10.5246316,8.52126316 L12.8336842,8.52126316 L12.8336842,11.3684211 L18.8362105,6.85010526 L12.8324211,2.33557895 L12.8324211,5.02105263 L10.9149474,5.02105263 C9.37930065,4.99758513 7.90239535,5.61060739 6.8347397,6.7146332 C5.76708405,7.81865901 5.20386055,9.31526344 5.27873684,10.8492632 L5.51115789,10.8454737 Z M5.28,17.3507368 L11.2825263,21.8690526 L11.2825263,19.1873684 L13.1962105,19.1873684 C14.7327409,19.2119153 16.2108601,18.5991871 17.279393,17.4947572 C18.347926,16.3903273 18.9114966,14.8927759 18.8362105,13.3578947 L18.6050526,13.3578947 C17.9128421,15.3221053 16.0648421,15.6821053 13.5915789,15.6821053 L11.2825263,15.6821053 L11.2825263,12.8349474 L5.28,17.3507368 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoubleArrow'
}
</script>
