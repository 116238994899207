import { defineStore } from 'pinia'
import {
  EOcdFormType,
  FINANCE_ATTEMPTS,
  useOnlineCreditDecisionStore
} from './onlineCreditDecisionStore'
import { EStoreStatus } from '~/types/forms'
import { IStep } from '~/types/steps'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'

export enum EFinanceApplicationSteps {
  FormFactory = 'Form factory'
}

export const steps: IStep[] = [
  {
    label: EFinanceApplicationSteps.FormFactory,
    title: ''
  }
]

interface ICreditCheckState {
  status: EStoreStatus
  isLoading: boolean
  steps: IStep[]
}

export const useFinanceApplicationStore = defineStore('financeApplication', {
  hasNavigation: true,

  state: (): ICreditCheckState => {
    return {
      status: EStoreStatus.OK,
      isLoading: false,
      steps: steps.slice(0, 2)
    }
  },
  getters: {
    getSteps(): IStep[] {
      return steps
    },

    isFinanceApplicationLoading(): boolean {
      const ocdStore = useOnlineCreditDecisionStore()
      return this.isLoading || ocdStore.isLoading
    },

    hasError(): boolean {
      return this.status !== EStoreStatus.OK
    }
  },
  actions: {
    reset() {
      this.$reset()
    },

    setIsLoading(val) {
      this.isLoading = val
    },

    async getForm() {
      if (this.status !== EStoreStatus.OK) return

      this.steps = useCloneDeep(steps)
      const ocdStore = useOnlineCreditDecisionStore()
      await ocdStore.fetchContent('creditAgencyNotice')

      await ocdStore.getForm(EOcdFormType.FINANCE_APPLICATION)
    },

    async submitForm() {
      const ocdStore = useOnlineCreditDecisionStore()
      ocdStore.clearSessionTimeout()
      ocdStore.resetOcdSession()

      this.isLoading = true

      const response = await this.api.onlineCreditDecision.apply(
        ocdStore.proposalReference,
        EOcdFormType.FINANCE_APPLICATION
      )

      this.isLoading = false

      if (response.error || response.data?.Error) {
        this.status = EStoreStatus.ERROR
      }
    },

    async getFinanceResult() {
      const ocdStore = useOnlineCreditDecisionStore()
      const millisecondsMax = 120000
      const millisecondsBetweenCalls = 6000

      const getFinanceRating = async (retry = 0) => {
        const { sidepanelModel } = useSidepanel(ESidepanel.FORMS)
        const response = await this.api.onlineCreditDecision.getFinanceRating(
          ocdStore.proposalReference,
          ocdStore.sessionToken
        )

        const status = response.data?.Status

        if (
          sidepanelModel.value &&
          continuePolling(status) &&
          retry < FINANCE_ATTEMPTS &&
          new Date().getTime() + millisecondsBetweenCalls < timestampLimit
        ) {
          await new Promise((resolve) =>
            setTimeout(resolve, millisecondsBetweenCalls)
          )

          return getFinanceRating(retry + 1)
        } else {
          return response
        }
      }

      this.isLoading = true

      const timestampLimit = new Date().getTime() + millisecondsMax
      const financeResult = await getFinanceRating()

      this.isLoading = false

      const financeApplication = financeResult?.data?.FinanceApplications?.[0]
      ocdStore.setExternalReference(financeApplication.ExternalReference || '')
      const finalDecision = getFinanceDecision(
        financeResult.data?.Status?.Id || ''
      )
      ocdStore.setFinanceResult(finalDecision)

      const financeQuotesStore = useFinanceQuotesStore()
      financeQuotesStore.ocdProposalReference = ocdStore.proposalReference
    }
  }
})
