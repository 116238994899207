import { Buffer } from 'buffer'
import ApiFactory from './factory'

class MapModule extends ApiFactory {
  getImage(queryString: string) {
    // TODO: Create response type for maps
    return $osGet<any, string>(`${this.apiVersion}/gmap?${queryString}`, {
      responseType: 'arrayBuffer',
      transform: (response) => {
        const base64data = Buffer.from(response, 'binary').toString('base64')

        return `data:image/png;base64,${base64data}`
      }
    })
  }
}

export default MapModule
