import { ECustomerTypes } from '~/types/customerType'
import { useProfileStore } from '~/stores/profileStore'
import ApiFactory from '~/api/factory'
import { NProfileVehicle } from '~/types/api'

class ShortlistModule extends ApiFactory {
  profileId() {
    const { getProfileId } = useProfileStore(this.pinia)
    return getProfileId
  }

  get() {
    const getUrl = `${this.apiVersion}/profiles/${this.profileId()}/vehicles`

    return $osGet<
      NProfileVehicle.get.Response,
      NProfileVehicle.get.TransformedResponse
    >(getUrl, {
      transform: transformProfileVehicles
    })
  }

  async add(data: NProfileVehicle.add.Payload) {
    const getUrl = `${this.apiVersion}/profiles/${this.profileId()}/vehicles`

    return await $osPost<NProfileVehicle.add.Response>(getUrl, {
      body: data
    })
  }

  delete(vin: string, customerType: ECustomerTypes) {
    const getUrl = `${
      this.apiVersion
    }/profiles/${this.profileId()}/vehicles/${vin}/${customerType}/Favourite`

    return $osDelete(getUrl)
  }

  deleteAll() {
    const getUrl = `${
      this.apiVersion
    }/profiles/${this.profileId()}/vehicles/Favourite`

    return $osDelete(getUrl)
  }
}

export default ShortlistModule
