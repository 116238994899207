import { defineStore } from 'pinia'
import { useFinanceCriteriaStore } from '../finance/criteriaStore'
import { useOnlineCreditDecisionStore } from './ocd/onlineCreditDecisionStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { EStoreStatus } from '~/types/forms'
import { IStep } from '~/types/steps'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'
import { NOrders } from '~/types/api'

export enum EOfferSteps {
  'Retailer' = 'Showroom',
  'Order' = 'Order',
  'AboutYou' = 'About You',
  'Finance' = 'Finance',
  'OrderSummary' = 'Order Summary',
  'NextSteps' = 'Next Steps'
}

export enum EOfferExceptions {
  IsSecured = 'VehicleIsSecuredException',
  ActiveOrder = 'VehicleHasActiveOrderException',
  NotSellable = 'VehicleNotSellableException'
}

export const steps: IStep[] = [
  {
    label: EOfferSteps.Retailer,
    title: 'Select a showroom',
    intro:
      'Please select your preferred showroom. This is the showroom that will fulfil your order.'
  },
  {
    label: EOfferSteps.Order,
    title: 'Order your car',
    intro:
      'You are about to begin the purchase of your new Mercedes-Benz. By continuing with this order, the following stages will need to be completed and we will keep you updated every step of the way.'
  },
  {
    label: EOfferSteps.AboutYou,
    title: 'Tell us about you',
    intro: ''
  },
  {
    label: EOfferSteps.Finance,
    title: 'Your payment options',
    intro: ''
  },
  {
    label: EOfferSteps.OrderSummary,
    title: 'Your order summary',
    intro: ''
  },
  {
    label: EOfferSteps.NextSteps,
    title: 'Next steps',
    intro:
      'Thank you for your order. Your selected showroom will be in touch with you as soon as possible to confirm and complete the next steps of your order.'
  }
]
interface IOfferFormStoreState {
  isFinance: boolean
  backOrderGuid: string
  orderGuid: string
  status: EStoreStatus
}

export const useOfferFormStore = defineStore('offerForm', {
  state: (): IOfferFormStoreState => {
    return {
      isFinance: false,
      backOrderGuid: '',
      status: EStoreStatus.OK,
      orderGuid: ''
    }
  },
  getters: {
    getSteps(): IStep[] {
      return steps
    },

    isFinanceDisabled(): boolean {
      const financeQuotesStore = useFinanceQuotesStore()
      const serviceStatusStore = useServiceStatusStore()
      const defaultStatus = serviceStatusStore.finance.defaults.isEnabled
      const vinStatus = serviceStatusStore.finance.vinDefaults.isEnabled
      const calculateStatus = serviceStatusStore.finance.calculate.isEnabled
      return (
        !defaultStatus ||
        !vinStatus ||
        !calculateStatus ||
        financeQuotesStore.hasError
      )
    },

    getOCDReference(): string {
      const ocdStore = useOnlineCreditDecisionStore()

      return ocdStore.isOcdActive ? ocdStore.externalReference : ''
    },

    isBackOrder(state: IOfferFormStoreState): boolean {
      return !!state.backOrderGuid
    },

    getPayload(state: IOfferFormStoreState): NOrders.create.Payload {
      const { customerDetails, getVehicle, getCustomerPayload } =
        useFormsStore()
      const retailerStore = useRetailerStore()
      const financeCriteriaStore = useFinanceCriteriaStore()

      return {
        Source: 'OnlineShowroom',
        TagUrl: getTagUrl() || '',
        GssnId: retailerStore.retailer?.GssnId || '',
        AdditionalInformation: customerDetails?.additionalInfo || '',
        Cash: !state.isFinance,
        Finance: state.isFinance,
        Customer: getCustomerPayload,
        Vehicle: {
          Vin: getVehicle?.Vin || ''
        },
        FinanceCriteria: financeCriteriaStore.quoteCriteria,
        FinanceApplicationReference: this.getOCDReference,
        BackOrderGuid: this.backOrderGuid
      }
    }
  },
  actions: {
    setFinance(val: boolean) {
      this.isFinance = val
    },
    setBackOrder(val = '') {
      this.backOrderGuid = val
    },
    setOrderGuid(val = '') {
      this.orderGuid = val
    },
    reset() {
      this.$reset()
    },
    submit() {
      return this.api.myAccount.orders.create(this.getPayload)
    }
  },
  hasNavigation: true
})
