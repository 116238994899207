interface Decision {
  variationId: string
  experimentId: string
  campaignId: string
}
interface OptimizelyEvent {
  data: {
    decision: Decision
  }
}

const experiments = reactive<Record<string, Decision>>({})

export const useOptimizely = () => {
  const initialise = () => {
    if (!import.meta.client) return

    window.optimizely = window.optimizely || []

    window.optimizely.push({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'campaignDecided'
      },
      handler: (e: OptimizelyEvent) => assignVariation(e.data.decision)
    })
  }

  const assignVariation = (decision: Decision) => {
    experiments[decision.experimentId] = decision
  }

  return {
    initialise
  }
}

export const useExperiment = (id: string) => {
  const currentExperiment = computed(() => experiments[id])

  return currentExperiment
}
