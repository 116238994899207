<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M28 0c15.464 0 28 12.536 28 28 0 15.463-12.536 28-28 28S0 43.463 0 28C0 12.536 12.536 0 28 0zm-.046 32.23L5.222 41.64a26.406 26.406 0 0 0 22.83 12.91 26.457 26.457 0 0 0 22.732-13.057l-22.83-9.263zM27.64 1.588a26.51 26.51 0 0 0-22.61 13.25 26.397 26.397 0 0 0-.147 26.21l19.135-15.464 3.622-23.996zm.68.002l3.452 24.313 19.353 14.976a26.41 26.41 0 0 0-.291-26.087A26.492 26.492 0 0 0 28.319 1.59z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
