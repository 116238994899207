import { defineStore } from 'pinia'
import { useVehicleStore } from '../vehicles/detailsStore'
import { EFormType, ICsRef, IVehiclePayload } from '~/types/forms'
import { ICustomer, IOfferCustomerPayload } from '~/types/forms/customer'
import { EVehicleType, NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { useFormContainer } from '~/composables/forms/useFormContainer'
import { useProfileStore } from '~/stores/profileStore'

import { defaultTitle } from '~/constants/forms'

interface IFormMeta {
  utm: string
  leadSource: string
  csref: ICsRef
  gagcmid: string
  gclid: string
}
interface IFormsStore {
  meta: IFormMeta
  customerDetails: ICustomer
  vehicle: NewVehicle | UsedVehicle | null
  completedForms: EFormType[]
}

export const useFormsStore = defineStore('forms', {
  state: (): IFormsStore => {
    return {
      meta: {
        utm: '',
        leadSource: '',
        csref: {
          parsedCsref: ''
        },
        gagcmid: '',
        gclid: ''
      },
      customerDetails: {
        title: defaultTitle,
        firstName: '',
        lastName: '',
        companyName: '',
        additionalInfo: '',
        email: '',
        phone: '',
        postcode: '',
        OptInEmail: false,
        OptInSMS: false,
        OptInTelephone: false,
        OptInPost: false
      },
      vehicle: null, // TODO: Depracate this in favour of vehicleStore
      completedForms: []
    }
  },

  getters: {
    getCustomer(): ICustomer {
      return {
        ...this.customerDetails,
        companyName: this.customerDetails.companyName?.trim()
      }
    },

    getVehicle(): NewVehicle | UsedVehicle | null {
      const vehicleStore = useVehicleStore()

      return this.vehicle || vehicleStore.vehicle
    },

    isVehicleSmart(): boolean {
      if (!this.getVehicle) return false

      return this.getVehicle?.Brand?.Description.toUpperCase() === 'SMART'
    },

    isVehicleMBCUKRetailer(): boolean {
      if (this.isNewVehicle) return true

      return !!(
        this.getVehicle?.Retailer?.IsCentralRetailer ||
        this.getVehicle?.Retailer?.IsMotabilityRetailer
      )
    },

    isNewVehicle(): boolean {
      if (!this.getVehicle) return false

      return this.getVehicle.VehicleType === EVehicleType.New
    },

    getVehiclePayload(): IVehiclePayload | null {
      if (!this.getVehicle) return null

      const { isBusiness, isMotability } = useCustomerTypeStore()

      return getVehiclePayload(this.getVehicle, isBusiness, isMotability)
    },

    getCustomerPayload(): IOfferCustomerPayload {
      const profileStore = useProfileStore()

      const Id = profileStore.getProfileId
      const CiamId = profileStore.getCiamId

      return getOfferCustomerPayload(this.getCustomer, {
        Id,
        CiamId
      })
    },

    getMarketingPreferences(): string {
      const { OptInEmail, OptInSMS, OptInTelephone, OptInMail, OptInPost } =
        this.getCustomer

      return [
        ...(OptInEmail ? ['OptInEmail'] : []),
        ...(OptInSMS ? ['OptInSMS'] : []),
        ...(OptInMail ? ['OptInMail'] : []),
        ...(OptInPost ? ['OptInPost'] : []),
        ...(OptInTelephone ? ['OptInTelephone'] : [])
      ].join(',')
    },

    isSubmittingAsBusiness(): boolean {
      const { isNew } = useFiltersStore()

      if (!isNew) return false

      const { isBusiness } = useCustomerTypeStore()

      return !!this.customerDetails.companyName?.trim() || isBusiness
    }
  },

  actions: {
    updateMeta(payload: Partial<IFormMeta>) {
      this.meta = {
        ...this.meta,
        ...payload
      }
    },

    updateForm(form: EFormType) {
      const { currentForm } = useFormContainer()

      currentForm.value = form
    },

    updateCustomer(form: ICustomer) {
      Object.entries(form).forEach(([key, value]) => {
        this.customerDetails[key] = value
      })
    },

    // Syncs the legacy customer with Pinia one,
    // without causing an infinte loop.
    syncCustomer(form: ICustomer) {
      Object.entries(form).forEach(([key, value]) => {
        this.customerDetails[key] = value
      })
    },

    updateVehicle(vehicleAttr: Partial<NewVehicle | UsedVehicle>) {
      this.vehicle = {
        ...(this.vehicle as NewVehicle | UsedVehicle),
        ...vehicleAttr
      }
    },

    setVehicle(vehicle: NewVehicle | UsedVehicle | null) {
      this.vehicle = vehicle
    },

    /**
     * Marks the currently active form as complete.
     *
     * `form` parameter is only needed for old forms. Newer forms detect the currently active form automatically.
     * @param formName
     */
    markFormComplete(formName?: EFormType): void {
      if (formName && !this.completedForms.includes(formName)) {
        this.completedForms.push(formName)
      }
    }
  }
})
