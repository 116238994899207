import { IKioskModeCookie, IKioskQuery } from '~/types/cookies'

export function kioskQueryFromCookie(): IKioskQuery {
  const kioskModeCookie = useCookie<IKioskModeCookie | null>('kioskmode')
  const kioskQuery: IKioskQuery = {}
  if (kioskModeCookie.value) {
    if (kioskModeCookie.value.Retailer)
      kioskQuery.Retailer = kioskModeCookie.value.Retailer
    if (kioskModeCookie.value.RetailerGroup)
      kioskQuery.RetailerGroup = kioskModeCookie.value.RetailerGroup
    if (kioskModeCookie.value.isKiosk) kioskQuery.kioskmode = 'true'
    if (kioskModeCookie.value.KioskType)
      kioskQuery.kiosktype = kioskModeCookie.value.KioskType
  }

  return kioskQuery
}
