import { ICampaignOffer, IOfferTypes } from '~/types/vehicle'

export const AdditionalOffers: Record<
  string,
  {
    icon: string
    title: string
    note: string
    keys: string[] // OfferType Keys associated
  }
> = {
  Servicing: {
    icon: 'spanner',
    title: 'Servicing',
    note: '*',
    keys: ['Service']
  },
  CustomOffer: {
    icon: 'pound',
    title: 'Accessories',
    note: '†',
    keys: ['Accessory Offer', 'Custom']
  },
  WarrantyOffer: {
    icon: 'warranty',
    title: 'Warranty offer',
    note: '§',
    keys: ['Warranty']
  },
  OtherOffer: {
    icon: 'pound',
    title: 'Other offers',
    note: '*',
    keys: ['Other']
  }
}

// TODO: OFFERS NEED TYPING

export function getAdditionalOffers(offers: IOfferTypes) {
  return [...offers.RetailerOffers, ...offers.CampaignOffers].filter(
    (offer: ICampaignOffer) =>
      offer?.Enabled &&
      offer?.Pending === false &&
      offer?.OfferType !== 'Discount'
  )
}

/**
 * Returns a list of OfferTypes from AdditionalOffers for vehicle offers data
 * @param {object} offers
 */
export function getOfferTypesForVehicle(offers: any): string[] {
  return getAdditionalOffers(offers).map((o: any) => o.OfferType)
}

/**
 * Combinds all non-monetary offers with an Offer
 * description
 * @param {object} offers
 */
function getNonMonetaryOfferList(vehicle) {
  return vehicle?.Offers?.Combined
    ? getAdditionalOffers(vehicle.Offers.Combined).map(
        (offer) => offer?.OfferDescription && offer.OfferDescription
      )
    : []
}

/**
 * Creates a monetary offer list in the
 * format for forms payload
 * @param {object} offers
 */
function getMonetaryOfferList(vehicle) {
  const allOfferDescriptions = []

  vehicle.RetailerOfferContribution &&
    vehicle.RetailerOfferContribution > 0 &&
    allOfferDescriptions.push(
      `Retail offer £${vehicle.RetailerOfferContribution}`
    )
  vehicle.CampaignOfferContribution &&
    vehicle.CampaignOfferContribution > 0 &&
    allOfferDescriptions.push(
      `Campaign offer £${vehicle.CampaignOfferContribution}`
    )
  vehicle.CampaignOLEVOfferContribution &&
    vehicle.CampaignOLEVOfferContribution > 0 &&
    allOfferDescriptions.push(
      `Campaign OZEV offer £${vehicle.CampaignOLEVOfferContribution}`
    )

  return allOfferDescriptions
}

/**
 * Creates all offers list with an offer description
 * in the format for forms payload
 * @param {object} offers
 */
export function getOfferList(vehicle) {
  if (!vehicle) return 'No offer'

  const nonMonetaryOfferList = getNonMonetaryOfferList(vehicle)
  const monetaryOfferList = getMonetaryOfferList(vehicle)
  const { CampaignPromoOfferContribution, PromoCode } = vehicle

  if (
    nonMonetaryOfferList.length < 1 &&
    monetaryOfferList < 1 &&
    CampaignPromoOfferContribution === 0
  ) {
    return 'No offer'
  } else {
    let combineOfferList = 'Active current offer exists:'
    if (PromoCode && CampaignPromoOfferContribution) {
      combineOfferList += ` Monetary offer (PromoCampaign ${PromoCode} offer £${CampaignPromoOfferContribution}),`
    }
    monetaryOfferList.length > 0 &&
      monetaryOfferList.forEach(
        (offer) => (combineOfferList += ` Monetary offer (${offer}),`)
      )
    nonMonetaryOfferList.length > 0 &&
      nonMonetaryOfferList.forEach(
        (offer) => (combineOfferList += ` Non-monetary offer (${offer}),`)
      )
    return combineOfferList.slice(0, -1)
  }
}

/**
 * Checks for non-monetary offer and creates
 * a Additional Offer(s) title based on the
 * amount of offers
 * @param {object} offers
 */
export function getNonMonetaryOffersTitle(vehicle) {
  const { TotalOfferValue } = vehicle

  const enabledOffers = vehicle?.Offers?.Combined
    ? getAdditionalOffers(vehicle.Offers.Combined)
    : []

  // If TotalOfferValue exists then use it (results)
  // If key unavailable then use Combined object (shortlist)
  const hasMonetaryOffer =
    parseTotalOffer(TotalOfferValue) !== 0 ||
    (vehicle.Offers &&
      vehicle.Offers.Combined &&
      vehicle.Offers.Combined.Combined.find((offer) => offer.Current))

  if (enabledOffers.length > 0) {
    return enabledOffers.length === 1
      ? `${enabledOffers.length} ${
          hasMonetaryOffer ? 'Additional Offer' : 'Offer'
        }`
      : `${enabledOffers.length} ${
          hasMonetaryOffer ? 'Additional Offers' : 'Offers'
        }`
  } else {
    return null
  }
}
