<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 14">
    <g transform="translate(0 -5)">
      <path
        fill="currentColor"
        d="M9.2,0.1 L3.6,3.6 L0.5,3.6 C0.2,3.6 0,3.8 0,4.1 L0,9.1 C0,9.4 0.2,9.6 0.5,9.6 L3.6,9.6 L9.2,13.1 C9.4,13.2 9.7,13.2 9.9,12.9 C9.9,12.8 10,12.7 10,12.6 L10,0.5 C10,0.2 9.8,0 9.5,0 C9.4,0 9.3,0 9.2,0.1 Z"
        transform="translate(0 5.4)"
      />
      <polygon
        fill="currentColor"
        points="8.742 7.681 5.561 4.5 8.742 1.319 7.681 .258 4.5 3.439 1.319 .258 .258 1.319 3.439 4.5 .258 7.681 1.319 8.742 4.5 5.561 7.681 8.742"
        transform="translate(11.5 7.5)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MediaVolumeUnMute'
}
</script>
