import { useMessengerStore } from '~/stores/messengerStore'
import { EConsents } from '~/types/usercentrics'

export default defineNuxtPlugin(() => {
  const { initialise: initialiseConsent } = useConsent()
  const { initialise: initialiseOptimizely } = useOptimizely()

  initialiseConsent()
  initialiseOptimizely()

  const messengerStore = useMessengerStore()
  const { hasConsent: hasUserlikeConsent } = useConsent(EConsents.Userlike)

  // Toggle messenger based on consent
  watchEffect(() => {
    hasUserlikeConsent.value
      ? messengerStore.enableMessenger()
      : messengerStore.disableMessenger()
  })
})
