import { PiniaPluginContext } from 'pinia'
import { IFormNavigation } from '~/types/forms/formNavigation'
import { IFormStep } from '~/types/forms'
import { IStep } from '~/types/steps'

export const PiniaNavigationPlugin = ({
  store,
  options
}: PiniaPluginContext) => {
  if (options.hasNavigation) {
    if (!store?.getSteps) {
      // eslint-disable-next-line no-console
      console.error(`Navigation store ${store.$id} is missing getSteps getter`)
      return
    }

    const currentStepIndex = ref<number>(0)
    const steps = ref<IStep[]>(store.getSteps)

    const isBackEnabled = computed(
      () => !isLastStep.value && !isFirstStep.value
    )

    /**
     * Computed Properties for accessing the form steps.
     */
    const currentStep = computed<IStep>(
      () =>
        steps.value[currentStepIndex.value] || {
          label: '',
          title: ''
        }
    )
    const previousStep = computed<IStep | null>(() =>
      isFirstStep.value ? null : steps.value[currentStepIndex.value - 1]
    )
    const nextStep = computed<IStep | null>(() =>
      isLastStep.value ? null : steps.value[currentStepIndex.value + 1]
    )
    const lastStepIndex = computed<number>(() =>
      steps.value.length ? steps.value.length - 1 : 0
    )
    const isLastStep = computed<boolean>(
      () => currentStepIndex.value === lastStepIndex.value
    )
    const isFirstStep = computed<boolean>(() => currentStepIndex.value === 0)

    const resetNavigation = () => {
      currentStepIndex.value = 0
    }

    /**
     * Returns the steps ready to be used the form.
     * @param cb - Callback function to override the step label
     */
    const getMappedSteps = (cb?: (arg: string) => string): IFormStep[] => {
      return steps.value.map((step, index) => ({
        id: index + 1,
        isActive: index === currentStepIndex.value,
        isChecked: index < currentStepIndex.value,
        step: typeof cb === 'function' ? cb(step.label) : step.label,
        label: typeof cb === 'function' ? cb(step.label) : step.label
      }))
    }

    /**
     * Methods for navigating the form steps.
     */
    const next = (skip?: number) => {
      if (currentStepIndex.value + (skip || 0) >= lastStepIndex.value) {
        currentStepIndex.value = lastStepIndex.value
        return
      }

      currentStepIndex.value += 1 + (skip || 0)
    }
    const setStep = (index: number) => (currentStepIndex.value = index)
    const back = () => {
      if (currentStepIndex.value === 0) return

      currentStepIndex.value--
    }

    watch(
      () => store.getSteps,
      (val) => {
        steps.value = val
      }
    )

    const navigation = computed<IFormNavigation>(() => ({
      currentStepIndex,
      isBackEnabled,
      currentStep,
      previousStep,
      nextStep,
      isLastStep,
      isFirstStep,
      steps,
      getMappedSteps,
      resetNavigation,
      next,
      setStep,
      back
    }))

    // todo: check if we need .value or not
    store.navigation = navigation

    store.resetWithNavigation = () => {
      store.reset()
      resetNavigation()
    }

    const config = useRuntimeConfig()

    if (config.public.nodeEnv === 'development') {
      store._customProperties.add('navigation')
      store._customProperties.add('resetWithNavigation')
    }
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(PiniaNavigationPlugin)
})
