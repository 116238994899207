<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 12">
    >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M9.64229,5.20099 L0.75,0.06699 C0.67399,0.02311 0.58777,0 0.5,0 C0.41224,0 0.32602,0.0231 0.25001,0.06699 C0.174,0.11087 0.11088,0.17399 0.06699,0.24999 C0.02311,0.326 0,0.41222 0,0.49999 L0,10.76799 C0,10.85579 0.02311,10.94199 0.06699,11.01799 C0.11088,11.09399 0.174,11.15709 0.25001,11.20099 C0.32602,11.24489 0.41224,11.26799 0.5,11.26799 C0.58777,11.26799 0.67399,11.24489 0.75,11.20099 L9.64229,6.06699 C9.71829,6.02309 9.78139,5.95999 9.82529,5.88399 C9.86919,5.80799 9.89229,5.72179 9.89229,5.63399 C9.89229,5.54619 9.86919,5.45999 9.82529,5.38399 C9.78139,5.30799 9.71829,5.24489 9.64229,5.20099 Z"
      transform="translate(.054 .366)"
    />
  </svg>
</template>

<script>
export default {
  name: 'MediaPlay'
}
</script>
