import { LocationQueryValue } from '#vue-router'

export const parseQueryValue = (
  query: string | LocationQueryValue[] | null
): string => {
  if (!query) return ''

  if (Array.isArray(query)) {
    return query[0] || ''
  }

  return query
}
