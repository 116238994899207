import { IRetailer } from '~/types/retailer'

export const isRetailerAvailable = (
  retailer: IRetailer,
  attribute?: keyof IRetailer
) => {
  if (attribute && !retailer[attribute]) {
    return false
  }

  return !retailer.IsMotabilityRetailer && !retailer.IsCentralRetailer
}

export const filterRetailersBy = (
  retailers: IRetailer[],
  attribute?: keyof IRetailer
) => {
  if (attribute) {
    return retailers.filter((retailer) => !!retailer[attribute])
  }

  return retailers.filter(
    (retailer) => !retailer.IsMotabilityRetailer && !retailer.IsCentralRetailer
  )
}

export const isNotMBUK = (retailer: IRetailer) => {
  return retailer.GssnId !== 'GS0004666'
}

export const isMBUK = (retailer: IRetailer) => {
  return retailer.GssnId === 'GS0004666'
}

export const isValidRetailer = (retailer: IRetailer, isNew: boolean = true) => {
  return (
    (isNew && retailer.IsNewCarRetailer) ||
    (!isNew && retailer.IsUsedCarRetailer)
  )
}
