<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
    <path
      fill="currentColor"
      stroke-width="1"
      d="M5.27 13.324L0 8.396l1.412-1.552 3.576 2.464L14.682 0 16 1.278z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Tick'
}
</script>
