import ApiFactory from '~/api/factory'
import { NOrderStatus } from '~/types/api'

class OrderStatusModule extends ApiFactory {
  get() {
    return $osGet<NOrderStatus.get.Response>('/v3/cms/agencyworkflow')
  }
}

export default OrderStatusModule
