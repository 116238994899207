import { HttpStatusCode, HttpErrorMessage } from '~/types/HttpError'

export type NuxtError = {
  url: string
  statusCode: number
  statusMessage: string
  message: string
  description: string
  data?: any
}

const isNuxtError = (
  error: Error | NuxtError | null | undefined
): error is NuxtError => {
  if (!error) return false

  return 'statusCode' in error && 'statusMessage' in error
}

export const useOsError = (
  error: Ref<Error | NuxtError | null | undefined>,
  isVehicle = false
) => {
  const statusCode = computed(() =>
    isNuxtError(error.value) ? error.value.statusCode : undefined
  )

  const message = computed<HttpErrorMessage>(() => {
    switch (statusCode.value) {
      case HttpStatusCode.BannedRequest:
      case HttpStatusCode.NotFound:
        return {
          problem: isVehicle
            ? 'Unfortunately, the car you are looking for is unavailable.'
            : 'The page you are looking for may not exist.',
          solution: 'Feel free to try again later'
        }
      case HttpStatusCode.InternalServerError:
      case HttpStatusCode.NotImplemented:
      case HttpStatusCode.BadGateway:
      case HttpStatusCode.ServiceUnavailable:
        return {
          problem:
            'There appears to be something wrong with our system, we are looking into it.',
          solution: 'Feel free to try again later'
        }
      case HttpStatusCode.GatewayTimeout:
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.PaymentRequired:
      case HttpStatusCode.Forbidden:
        return {
          problem: 'We are not sure what went wrong',
          solution: 'Feel free to try again, refresh the page '
        }
      default:
        return {
          problem: 'The site is currently undergoing essential maintenance.',
          solution:
            'Please check back soon to see a full list of cars available to buy'
        }
    }
  })

  return {
    statusCode,
    message
  }
}
