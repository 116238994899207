<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24">
    <path
      d="m7.504875 9.21690033h-2.462625v-1.55605531h2.462625v-2.57352027h1.582875v2.57352027h2.462625v1.55605531h-2.462625v2.57352027h-1.582875zm.82125 5.38705197c3.283875 0 5.9805-2.7526675 5.9805-6.10478966 0-3.35212211-2.696625-6.1047897-5.9805-6.1047897s-5.9805 2.75266759-5.9805 6.1047897c0 1.67548686.586125 3.11211066 1.758375 4.30872286 1.173375 1.1966123 2.579625 1.7960668 4.222125 1.7960668zm9.673875 7.3611178c0 1.0174649-.82125 2.0349299-2.052 2.0349299-.87975 0-1.641375-.5385904-2.2275-1.5560553l-3.283875-5.7453467c-.76275.1791473-1.465875.2997273-2.1105.2997273-4.397625-.0011484-8.326125-3.8309967-8.326125-8.49916266 0-4.66816594 3.753-8.49916264 8.326125-8.49916264s8.326125 3.82984832 8.326125 8.49916264c0 2.21407726-.82125 4.24900716-2.404125 5.98535816l3.22425 5.7453467c.352125.5983061.527625 1.1966122.527625 1.7352026z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchPlus'
}
</script>
