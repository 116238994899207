import ApiFactory from '~/api/factory'
import { NValuations } from '~/types/api'

const processResponse = (response: NValuations.get.Response) => {
  return {
    valuations: response.map((valuation) => {
      return valuation.TradeInValuation
    })
  }

  throw new OsFetchError('Invalid response')
}

class ValuationsModule extends ApiFactory {
  get(ciamId: string) {
    return $osGet<
      NValuations.get.Response,
      NValuations.get.TransformedResponse
    >(`${this.apiVersion}/myaccount/${ciamId}/tradeindetails`, {
      transform: processResponse
    })
  }
}

export default ValuationsModule
