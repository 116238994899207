import { defineStore } from 'pinia'

export enum EFinanceServices {
  Defaults = 'defaults',
  VinDefaults = 'vinDefaults',
  Calculate = 'calculate'
}

interface IFinanceService {
  isEnabled: boolean
  errorMessage: string
}

interface IServiceStatusStore {
  agency: boolean
  filters: boolean
  finance: {
    defaults: IFinanceService
    vinDefaults: IFinanceService
    calculate: IFinanceService
  }
}

export const useServiceStatusStore = defineStore('serviceStatus', {
  state: (): IServiceStatusStore => {
    return {
      agency: false,
      filters: true,
      finance: {
        defaults: { isEnabled: true, errorMessage: '' },
        vinDefaults: { isEnabled: true, errorMessage: '' },
        calculate: { isEnabled: true, errorMessage: '' }
      }
    }
  },

  actions: {
    updateAgency(value: boolean) {
      this.agency = value
    },

    updateFilters(value: boolean) {
      this.filters = value
    },

    updateFinanceService(
      service: EFinanceServices,
      value: boolean,
      error = ''
    ) {
      this.finance[service].isEnabled = value
      this.finance[service].errorMessage = error
    }
  }
})
