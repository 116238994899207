import { ERoutePageSlugs, ERoutes } from './routes'
import { EEventType, EFormNames } from '~/types/dataLayer'
import { IRetailer } from '~/types/retailer'
import { IOrder } from '~/types/myaccount/orders'
import { IFinanceProduct } from '~/types/finance/product'
import { FinanceAnalyticsProduct } from '~/utils/analytics'

export enum EUserType {
  Private = 'private buyer',
  Motability = 'motability',
  Business = 'business'
}

export enum ETrafficType {
  Internal = 'internal',
  Showroom = 'showroom',
  Publicis = 'publicis',
  User = 'user'
}

export enum EDisplayMode {
  TWA = 'androidApp',
  Standalone = 'standalone',
  Browser = 'browser'
}

export enum EPageName {
  VehicleDetails = 'product',
  Homepage = 'homepage',
  Results = 'results',
  Models = 'all model directory',
  MyAccount = 'my account',
  Compare = 'compare',
  FAQ = 'faq',
  CookiePolicy = 'cookie policy',
  PrivacyPolicy = 'privacy policy',
  LegalNotice = 'legal notice',
  MSA = 'modern slavery act',
  MBUK = 'mbuk ltd',
  Default = 'generic',
  Offer = 'offer'
}

interface IPage {
  name: EPageName
  category?: string
  language: string
  market: string
  application: string
  displayMode: string
  page_number?: number
  homepage_type?: string
}

interface IEvent {
  type: EEventType
  timestamp: Date
  category?: string
  action?: string
}

interface IFormInput {
  name: string
  field?: string
  event: string
  value: any
}

interface IFilter {
  sort?: string
  model?: string
  fuel?: string
  colour?: string
  line?: string
  package?: string
  transmission?: string
  engine?: string
  finance?: string
  upholstery?: string
  location?: string
}

export interface IEcommerceVehicle {
  name: string
  id: string
  price: number
  brand: string
  category: string
  variant: string
  position: string
  dimension28: string
  dimension29: string
  dimension30: string
  dimension31: string
  dimension32: string
  dimension33: string
  dimension34: string
  dimension173: string
  dimension172: string
  dimension171: string
  dimension170: number
  dimension169: string
  dimension168: string
  dimension167: string
  dimension166: string
  dimension165: string
}

interface IEcommerce {
  currencyCode: string
  click?: {
    products: Array<IEcommerceVehicle>
  }
  detail?: {
    actionField: {
      list: string
    }
    products: Array<IEcommerceVehicle>
  }
  addImpression?: any
}

export interface IStockNotificationSelectionDataLayer {
  vehicle_name: string
  fuel_type: string
  colour: string
  equipment: string
}

interface IRetailerDataLayer {
  name?: string
  id?: number
  gssnid?: string
  travel_time?: number
  preferred_showroom_position?: '1' | '2' | '3'
  group_name?: string
}

interface IOrderDataLayer {
  number: string
  date: string
  stage?: string
  payment_type?: string
}

type IFormSubmission = {
  [key in keyof typeof EFormNames]?: 'yes' | ''
}

export interface IGoogleDataLayer {
  label?: string
  page: IPage
  event: IEvent
  user?: {
    type: EUserType
    omnichannel_status: string
    logged_in: 'logged in' | 'logged out'
    id?: string
    ciam?: string
    agent_user: Boolean
    traffic_type: ETrafficType
    geolocation_location_status: string
  }
  kiosk?: object
  vehicle?: object
  forms?: Array<IFormInput>
  filters?: Array<IFilter>
  ecommerce?: Array<IEcommerce>
  results?: object
  selections_made?: IStockNotificationSelectionDataLayer
  marketing_preference?: Array<string>
  retailer?: IRetailerDataLayer
  order?: IOrderDataLayer
  form_submitted?: IFormSubmission
  finance?: IFinanceProduct
  eligibility_results?: string
  ocd_application_results?: string
  user_feedback?: object
}

export interface IGoogleDataLayerOptions {
  route: ERoutes | ERoutePageSlugs
  slug: string
  type: EEventType
  pageCategory?: string
  category?: string
  action?: string
  user?: object
  vehicle?: object
  forms?: Array<IFormInput>
  filters?: Array<IFilter>
  isPageLoad?: Boolean
  ecommerce?: Array<IEcommerce>
  results?: object
  selectionsMade?: IStockNotificationSelectionDataLayer
  marketingPreference?: Array<string>
  retailer?: IRetailer
  order: IOrder
  formSubmitted?: IFormSubmission
  finance?: FinanceAnalyticsProduct
  label?: string
  eligibilityResults?: any
  ocdApplicationResults?: any
  userFeedback?: any
}
