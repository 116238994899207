<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.969 9.797a.532.532 0 0 1 .219-.156.728.728 0 0 1 .515 0 .532.532 0 0 1 .219.156l2.875 2.875a.532.532 0 0 1 .156.219.729.729 0 0 1 0 .515.532.532 0 0 1-.156.219l-.719.719-3.828-3.828.719-.72zm-1.203 1.187l3.843 3.844-.25.235a3.102 3.102 0 0 1-1.93.914c-.755.078-1.575-.04-2.46-.352-.886-.313-1.813-.805-2.781-1.477a21.213 21.213 0 0 1-2.891-2.445 21.213 21.213 0 0 1-2.445-2.89C1.18 7.843.688 6.917.375 6.03c-.313-.885-.43-1.705-.352-2.46a3.102 3.102 0 0 1 .914-1.93l.235-.25 3.844 3.843-.938.922c.177.26.362.524.555.79.193.265.398.528.617.788.219.26.448.524.688.79a21.147 21.147 0 0 0 1.539 1.539c.265.24.528.468.789.687.26.219.523.424.789.617.265.193.528.378.789.555l.922-.938zM2.375.204a.532.532 0 0 1 .219-.157.728.728 0 0 1 .515 0 .532.532 0 0 1 .22.156l2.874 2.875a.532.532 0 0 1 .156.219.729.729 0 0 1 0 .515.532.532 0 0 1-.156.22l-.719.718L1.656.922l.719-.719z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Phone'
}
</script>
