import { default as index9aUBRgreXiMeta } from "/build/pages/[condition]/[[type]]/index.vue?macro=true";
import { default as indexXGGN4CH95QMeta } from "/build/pages/[condition]/[[type]]/model/[slug]/index.vue?macro=true";
import { default as resultsILNd0U0P0NMeta } from "/build/pages/[condition]/[[type]]/results.vue?macro=true";
import { default as stock_45notificationQnPgcDbbaFMeta } from "/build/pages/[condition]/[[type]]/stock-notification.vue?macro=true";
import { default as indexelW1kmC7vmMeta } from "/build/pages/[condition]/[[type]]/vehicle-detail/[model]/[id]/[retailerId]/index.vue?macro=true";
import { default as thankyou3knkfotOhZMeta } from "/build/pages/[condition]/[[type]]/vehicle-detail/[model]/[id]/[retailerId]/offer/thankyou.vue?macro=true";
import { default as offerUZ8Eff1PbeMeta } from "/build/pages/[condition]/[[type]]/vehicle-detail/[model]/[id]/[retailerId]/offer.vue?macro=true";
import { default as models5l1VLb2rzXMeta } from "/build/pages/[condition]/models.vue?macro=true";
import { default as callbackL4LcFgFASKMeta } from "/build/pages/callback.vue?macro=true";
import { default as compareJeBZDfjfRbMeta } from "/build/pages/compare.vue?macro=true";
import { default as healthcheckei31nUzaHjMeta } from "/build/pages/healthcheck.vue?macro=true";
import { default as logoutIARkfhKEN0Meta } from "/build/pages/logout.vue?macro=true";
import { default as indexFkwwUYQj0WMeta } from "/build/pages/myaccount/backorders/index.vue?macro=true";
import { default as enquiries9jOb3egdaOMeta } from "/build/pages/myaccount/enquiries.vue?macro=true";
import { default as _91id_93ZTCdskoMZ5Meta } from "/build/pages/myaccount/orders/[id].vue?macro=true";
import { default as indexY3OeZs238BMeta } from "/build/pages/myaccount/orders/index.vue?macro=true";
import { default as recently_45viewedRVhVqAxXd3Meta } from "/build/pages/myaccount/recently-viewed.vue?macro=true";
import { default as retailer_45sessionKPBAdM8JoBMeta } from "/build/pages/myaccount/retailer-session.vue?macro=true";
import { default as saved_45searchmlG3JzdegqMeta } from "/build/pages/myaccount/saved-search.vue?macro=true";
import { default as settingsqj53AlbmIaMeta } from "/build/pages/myaccount/settings.vue?macro=true";
import { default as shortlistgwP8dSpdgLMeta } from "/build/pages/myaccount/shortlist.vue?macro=true";
import { default as test_45drives8nWe3IAbDdMeta } from "/build/pages/myaccount/test-drives.vue?macro=true";
import { default as valuationsmQNofv4YeSMeta } from "/build/pages/myaccount/valuations.vue?macro=true";
import { default as myaccountD6YnN3IfyNMeta } from "/build/pages/myaccount.vue?macro=true";
import { default as _91slug_93AJdZ912tXcMeta } from "/build/pages/page/[slug].vue?macro=true";
import { default as cookie_45policyjdSI1fMkisMeta } from "/build/pages/page/cookie-policy.vue?macro=true";
import { default as faqRv4AG4xttkMeta } from "/build/pages/page/faq.vue?macro=true";
import { default as component_45stubqzOyA0WOlZMeta } from "/build/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubqzOyA0WOlZ } from "/build/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: index9aUBRgreXiMeta?.name ?? "condition-type",
    path: "/:condition()/:type?",
    meta: index9aUBRgreXiMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXGGN4CH95QMeta?.name ?? "condition-type-model-slug",
    path: "/:condition()/:type?/model/:slug()",
    meta: indexXGGN4CH95QMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/model/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsILNd0U0P0NMeta?.name ?? "condition-type-results",
    path: "/:condition()/:type?/results",
    meta: resultsILNd0U0P0NMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/results.vue").then(m => m.default || m)
  },
  {
    name: stock_45notificationQnPgcDbbaFMeta?.name ?? "condition-type-stock-notification",
    path: "/:condition()/:type?/stock-notification",
    meta: stock_45notificationQnPgcDbbaFMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: indexelW1kmC7vmMeta?.name ?? "condition-type-vehicle-detail-model-id-retailerId",
    path: "/:condition()/:type?/vehicle-detail/:model()/:id()/:retailerId()",
    meta: indexelW1kmC7vmMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/vehicle-detail/[model]/[id]/[retailerId]/index.vue").then(m => m.default || m)
  },
  {
    name: offerUZ8Eff1PbeMeta?.name ?? "condition-type-vehicle-detail-model-id-retailerId-offer",
    path: "/:condition()/:type?/vehicle-detail/:model()/:id()/:retailerId()/offer",
    meta: offerUZ8Eff1PbeMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/vehicle-detail/[model]/[id]/[retailerId]/offer.vue").then(m => m.default || m),
    children: [
  {
    name: thankyou3knkfotOhZMeta?.name ?? "condition-type-vehicle-detail-model-id-retailerId-offer-thankyou",
    path: "thankyou",
    meta: thankyou3knkfotOhZMeta || {},
    component: () => import("/build/pages/[condition]/[[type]]/vehicle-detail/[model]/[id]/[retailerId]/offer/thankyou.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "condition-models",
    path: "/:condition()/models",
    component: () => import("/build/pages/[condition]/models.vue").then(m => m.default || m)
  },
  {
    name: callbackL4LcFgFASKMeta?.name ?? "callback",
    path: callbackL4LcFgFASKMeta?.path ?? "/callback",
    meta: callbackL4LcFgFASKMeta || {},
    component: () => import("/build/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: "compare",
    path: "/compare",
    component: () => import("/build/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: "healthcheck",
    path: "/healthcheck",
    component: () => import("/build/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: logoutIARkfhKEN0Meta?.path ?? "/logout",
    meta: logoutIARkfhKEN0Meta || {},
    component: () => import("/build/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: myaccountD6YnN3IfyNMeta?.name ?? "myaccount",
    path: "/myaccount",
    meta: myaccountD6YnN3IfyNMeta || {},
    component: () => import("/build/pages/myaccount.vue").then(m => m.default || m),
    children: [
  {
    name: indexFkwwUYQj0WMeta?.name ?? "myaccount-backorders",
    path: "backorders",
    meta: indexFkwwUYQj0WMeta || {},
    component: () => import("/build/pages/myaccount/backorders/index.vue").then(m => m.default || m)
  },
  {
    name: enquiries9jOb3egdaOMeta?.name ?? "myaccount-enquiries",
    path: "enquiries",
    meta: enquiries9jOb3egdaOMeta || {},
    component: () => import("/build/pages/myaccount/enquiries.vue").then(m => m.default || m)
  },
  {
    name: "myaccount-orders-id",
    path: "orders/:id()",
    component: () => import("/build/pages/myaccount/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexY3OeZs238BMeta?.name ?? "myaccount-orders",
    path: "orders",
    component: () => import("/build/pages/myaccount/orders/index.vue").then(m => m.default || m)
  },
  {
    name: recently_45viewedRVhVqAxXd3Meta?.name ?? "myaccount-recently-viewed",
    path: "recently-viewed",
    component: () => import("/build/pages/myaccount/recently-viewed.vue").then(m => m.default || m)
  },
  {
    name: retailer_45sessionKPBAdM8JoBMeta?.name ?? "myaccount-retailer-session",
    path: "retailer-session",
    meta: retailer_45sessionKPBAdM8JoBMeta || {},
    component: () => import("/build/pages/myaccount/retailer-session.vue").then(m => m.default || m)
  },
  {
    name: saved_45searchmlG3JzdegqMeta?.name ?? "myaccount-saved-search",
    path: "saved-search",
    component: () => import("/build/pages/myaccount/saved-search.vue").then(m => m.default || m)
  },
  {
    name: settingsqj53AlbmIaMeta?.name ?? "myaccount-settings",
    path: "settings",
    meta: settingsqj53AlbmIaMeta || {},
    component: () => import("/build/pages/myaccount/settings.vue").then(m => m.default || m)
  },
  {
    name: shortlistgwP8dSpdgLMeta?.name ?? "myaccount-shortlist",
    path: "shortlist",
    component: () => import("/build/pages/myaccount/shortlist.vue").then(m => m.default || m)
  },
  {
    name: test_45drives8nWe3IAbDdMeta?.name ?? "myaccount-test-drives",
    path: "test-drives",
    meta: test_45drives8nWe3IAbDdMeta || {},
    component: () => import("/build/pages/myaccount/test-drives.vue").then(m => m.default || m)
  },
  {
    name: valuationsmQNofv4YeSMeta?.name ?? "myaccount-valuations",
    path: "valuations",
    meta: valuationsmQNofv4YeSMeta || {},
    component: () => import("/build/pages/myaccount/valuations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/build/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: "page-cookie-policy",
    path: "/page/cookie-policy",
    component: () => import("/build/pages/page/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-faq",
    path: "/page/faq",
    component: () => import("/build/pages/page/faq.vue").then(m => m.default || m)
  },
  {
    name: component_45stubqzOyA0WOlZMeta?.name,
    path: "/",
    component: component_45stubqzOyA0WOlZ
  }
]