<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M16 0v12.67h-3.33V16H0V3.33h3.33V0H16ZM3.33 5.33H2V14h8.67v-1.33H3.33V5.33ZM14 2H5.33v8.67H14V2Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Copy'
}
</script>
