<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M2.4 16H.8V8h1.6v8zm0-11.2H.8V0h1.6v4.8zm12.8-3.2h-1.6V0h1.6v1.6zm0 14.4h-1.6V4.8h1.6V16zM8.8 8.8H7.2V0h1.6v8.8zm0 7.2H7.2v-4h1.6v4zM0 5.6h3.2v1.6H0V5.6zm6.4 4h3.2v1.6H6.4V9.6zm6.4-7.2H16V4h-3.2V2.4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Filters'
}
</script>
