<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M15.516 4.24L16 6.146v5.187h-2v-1H2v1H0V6.146L.516 4.24l3-3.907h9l3 3.907zM2.984 3.333h10.047l-1.047-2h-7.89l-1.11 2zm.985 3.813c.094-.094.101-.214.023-.36a1.98 1.98 0 0 0-.351-.453 6.994 6.994 0 0 0-.5-.453c-.177-.146-.318-.26-.422-.344a.944.944 0 0 0-.32-.187 1.11 1.11 0 0 0-.368-.063c-.125 0-.25.021-.375.063a.93.93 0 0 0-.328.187.827.827 0 0 0-.281.617.827.827 0 0 0 .281.618c.094.083.268.167.524.25.255.083.523.148.804.195.282.047.547.068.797.063.25-.006.422-.05.516-.133zm6.969.687c.218-.375.367-.69.445-.945.078-.255.122-.46.133-.617.01-.156 0-.268-.032-.336l-.046-.102H4.516l-.047.102c-.032.068-.042.18-.032.336.01.156.055.362.133.617.078.255.227.57.446.945h5.921zm3.812-1.062a.827.827 0 0 0 .281-.617.827.827 0 0 0-.281-.618.93.93 0 0 0-.328-.187 1.18 1.18 0 0 0-.375-.063c-.125 0-.248.021-.367.063a.944.944 0 0 0-.32.187c-.094.084-.232.198-.415.344a5.29 5.29 0 0 0-.5.453 2.326 2.326 0 0 0-.351.453c-.084.146-.078.266.015.36.094.083.269.127.524.133.255.005.523-.016.805-.063.28-.047.546-.112.796-.195s.422-.167.516-.25zM0 2.146h1v1H0v-1zm15 0h1v1h-1v-1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeTestDrive'
}
</script>
