import { EVehicleType } from '~/types/vehicle'
import {
  EFinanceServices,
  useServiceStatusStore
} from '~/stores/serviceStatusStore'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { EFinanceProductKeys } from '~/types/finance/product'

export default async (
  vin: string,
  gssnId: string,
  vehicleType: EVehicleType,
  signal?: AbortSignal,
  key?: EFinanceProductKeys
) => {
  const { $api, runWithContext } = useNuxtApp()
  const serviceStatusStore = useServiceStatusStore()

  if (!serviceStatusStore.finance.calculate.isEnabled) {
    throw new Error('Calculate service is not enabled')
  }

  serviceStatusStore.updateFinanceService(EFinanceServices.Calculate, true)

  const criteriaStore = useFinanceCriteriaStore()

  const keyToUse = key || criteriaStore.currentKey

  const config = {
    Criteria: criteriaStore.quoteCriteria(keyToUse),
    Vin: vin,
    GssnId: gssnId
  }

  return await runWithContext(() =>
    $api.finance.quote.get(config, vehicleType, signal)
  )
}
