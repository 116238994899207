import { storeToRefs } from 'pinia'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { ERouteConditions, ERouteCustomerTypes, ERoutes } from '~/types/routes'

/**
 * This middleware is to disable the manual change of the vehicle
 * condition via URL, as Business and Motability is not available on used cars.
 */
export default defineNuxtRouteMiddleware((to) => {
  // Return early when the customer type param is not set
  if (to.params.type === undefined) return

  const { $pinia } = useNuxtApp()
  const customerTypeStore = useCustomerTypeStore($pinia)

  const { type, condition } = to.params
  const { Business, Motability } = ERouteCustomerTypes

  // Remove motability for now (OS-2220)
  if (type === Motability) {
    return navigateTo({
      name: ERoutes.Homepage,
      params: {
        condition: ERouteConditions.New
      }
    })
  }

  const { enablePrivate, enableBusiness } = customerTypeStore
  const { isBusiness, isMotability } = storeToRefs(customerTypeStore)

  // Update the store if the customer type param is set manually.
  if (type === Business && !isBusiness.value) enableBusiness()
  // if (type === Motability && !isMotability) enableMotability()
  if (type === null && (isBusiness.value || isMotability.value)) enablePrivate()

  // There is no business or motability mode for used cars
  // so we redirect to new
  if (condition === ERouteConditions.Used && !!type) {
    const newRoute = {
      name: to.name!,
      params: {
        ...to.params,
        condition: ERouteConditions.New
      },
      query: to.query
    }

    return navigateTo(newRoute)
  }
})
