<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 13">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M16 3.016v9.05H0v-9.05l8 5.028 8-5.028zM16 0v1.005L8 6.033 0 1.005V0h16z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Email'
}
</script>
