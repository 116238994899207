import { NVehicleDetails } from '~/types/api'
import isValidResponse from '~/guards/vehicle'
import ApiFactory from '~/api/factory'
import { $osGet, $osPost } from '~/composables/useOsFetch'

const processVehicleResponse = (response: NVehicleDetails.get.Response) => {
  const vehicles = response?.Vehicles
  const vehicleValidation = vehicles.map((vehicle) => isValidResponse(vehicle))
  const allValid = vehicleValidation.every(([isValid]) => isValid)

  if (allValid) {
    return {
      vehicles,
      agencyEnabled: response?.FeatureStatus?.AgencyEnabled || false
    }
  } else {
    const failedVehicle = vehicleValidation.find(([isValid]) => !isValid)
    const [, reason] = failedVehicle!

    throw new OsFetchError(reason || 'Invalid response')
  }
}

class VehicleDetailsModule extends ApiFactory {
  get(payload: NVehicleDetails.get.Payload) {
    return $osPost<
      NVehicleDetails.get.Response,
      NVehicleDetails.get.TransformedResponse
    >('v3/vehicles/details', {
      body: {
        IncludeOffers: true,
        IncludeReservations: false,
        IncludeEquipment: true,
        IncludeQuotes: false,
        ...payload
      },
      transform: (data) => processVehicleResponse(data)
    })
  }

  getIsSold(vin: string) {
    return $osGet<NVehicleDetails.getIsSold.Response>(
      `v3/vehicles/exist/${vin}`
    )
  }

  getStock(vin: string) {
    return $osGet<{ StockLevelIndicator: string }>(
      `v3/vehicles/stocklevelindicator/${vin}`
    )
  }
}

export default VehicleDetailsModule
