import { ICreateOrderResponse } from '~/types/myaccount'
import ApiFactory from '~/api/factory'
import { IErrorResponse, NOrders } from '~/types/api'

export interface AddOrderPayload {
  Vin: string
  CustomerCiam: string
  OrderReference: string
}

export const isErrorResponse = (response: any): response is IErrorResponse => {
  if (!response) return false
  return 'ResponseStatus' in response
}

// todo: check the status type
const transformOrderResponse = (response: {
  data: any | IErrorResponse
  status?: string
}) => {
  if (!response.status) {
    return response
  }
  if (response.status !== 'success' || isErrorResponse(response.data)) {
    throw new OsFetchError(response)
  }

  return response
}

class OrdersModule extends ApiFactory {
  async create(offer: NOrders.create.Payload) {
    return await $osPost<
      NOrders.create.Response,
      NOrders.get.Response & { data: ICreateOrderResponse }
    >(`${this.apiVersion}/myaccount/order`, {
      body: offer,
      transform: transformOrderResponse
    })
  }

  async get(ciamId: string) {
    return await $osGet<NOrders.get.Response>(
      `${this.apiVersion}/myaccount/orders/customer/${ciamId}`
    )
  }

  async add(payload: AddOrderPayload) {
    return await $osPost(`${this.apiVersion}/myaccount/orderCiam`, {
      body: payload
    })
  }
}

export default OrdersModule
