import {
  EMarketingPreference,
  IMarketingPreferences,
  IMarketingPreferencesPayload,
  IOfferCustomerPayload,
  ICustomer,
  ICustomerDetailsPayload
} from '~/types/forms/customer'
import { ECustomerTypes } from '~/types/customerType'

/**
 * Return the customer details ready to be sent to Agency API.
 */
export const getOfferCustomerPayload = (
  customer: ICustomer,
  profile: { Id: string; CiamId: string } = { Id: '', CiamId: '' }
): IOfferCustomerPayload => {
  return {
    CustomerId: profile.CiamId,
    IsCiam: true,
    ProfileId: profile.Id,
    Title: customer.title?.text || '',
    Firstname: customer.firstName || '',
    Surname: customer.lastName || '',
    EmailAddress: customer.email || '',
    CompanyName: customer.companyName?.trim() || '',
    Address: {
      Street: customer.street || '',
      Property: customer.property || '',
      Locality: '',
      Town: customer.town || '',
      County: customer.county || '',
      Postcode: customer.postcode || ''
    },
    Telephone: customer.phone || '',
    Preferences: getMarketingPreferences({
      OptInEmail: customer.OptInEmail,
      OptInSMS: customer.OptInSMS,
      OptInTelephone: customer.OptInTelephone,
      OptInPost: customer.OptInPost
    }),
    Mode: customer.companyName?.trim()
      ? ECustomerTypes.Business
      : ECustomerTypes.Private
  }
}

export const getCustomerDetailsPayload = (
  customerDetails: ICustomer,
  isBusiness = false
): ICustomerDetailsPayload => {
  const customerPayload: any = {
    Title: customerDetails.title?.text || '',
    FirstName: customerDetails.firstName || '',
    Surname: customerDetails.lastName || '',
    EmailAddress: customerDetails.email || '',
    HomeTel: customerDetails.phone || '',
    OptInEmail: !!customerDetails.OptInEmail,
    OptInSms: !!customerDetails.OptInSMS,
    OptInMail: !!customerDetails.OptInMail,
    OptInTelephone: !!customerDetails.OptInTelephone
  }

  if (customerDetails.companyName?.trim() && isBusiness) {
    customerPayload.CompanyName = customerDetails.companyName?.trim()
  }

  if (customerDetails.postcode) {
    customerPayload.Street = `${customerDetails.street}${
      customerDetails.streetTwo ? ' ' + customerDetails.streetTwo : ''
    }`
    customerPayload.Property = customerDetails.property
    customerPayload.Locality = ''
    customerPayload.Town = customerDetails.town
    customerPayload.County = customerDetails.county
    customerPayload.Postcode = customerDetails.postcode
  }

  customerPayload.MarketingPreference = getMarketingPreferences({
    OptInEmail: customerDetails.OptInEmail,
    OptInTelephone: customerDetails.OptInTelephone,
    OptInSMS: customerDetails.OptInSMS,
    OptInMail: customerDetails.postcode ? customerDetails.OptInMail : null
  })

  return customerPayload
}

/**
 * Return the customer marketing preferences as strings.
 */
export const getMarketingPreferences = (
  preferences: IMarketingPreferences
): IMarketingPreferencesPayload => {
  const payload: IMarketingPreferencesPayload = {
    OptInEmail: EMarketingPreference.None,
    OptInSMS: EMarketingPreference.None,
    OptInTelephone: EMarketingPreference.None,
    OptInPost: EMarketingPreference.None
  }

  for (const key in preferences) {
    if (preferences[key] !== null) {
      payload[key] = preferences[key]
        ? EMarketingPreference.Yes
        : EMarketingPreference.No
    }
  }

  return payload
}
