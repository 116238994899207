import { defineStore } from 'pinia'
import {
  EProfileVehicleGroup,
  SavedVehicle,
  SavedVehicleCard
} from '~/types/profile'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { ECustomerTypes } from '~/types/customerType'
import { IRetailer } from '~/types/retailer'
import { isNewVehicle } from '~/guards/vehicle'

interface IRecentlyViewedStore {
  vehicles: (NewVehicle | UsedVehicle)[]
  savedVehicles: SavedVehicle[]
  fetchError: boolean
  fetchDetailsError: boolean
  isLoading: boolean
  isLoadingDetails: boolean
}

export const useRecentlyViewedStore = defineStore('recentlyViewed', {
  state: (): IRecentlyViewedStore => {
    return {
      vehicles: [],
      savedVehicles: [],
      fetchError: false,
      fetchDetailsError: false,
      isLoading: true,
      isLoadingDetails: false
    }
  },

  actions: {
    async fetch() {
      this.isLoading = true
      this.fetchError = false
      const { error, data } = await this.api.profile.recentlyViewed.get()

      if (error) {
        this.fetchError = error.code !== 404
      } else {
        this.savedVehicles = data.Vehicles || []
      }

      this.isLoading = false
    },

    async fetchDetails() {
      const serviceStatusStore = useServiceStatusStore()

      if (
        this.savedVehicles.length === this.vehicles.length ||
        this.isLoadingDetails
      )
        return

      this.isLoadingDetails = true
      this.fetchDetailsError = false
      await this.api.vehicles.details
        .get({
          Vins: this.savedVehicles.map((vehicle) => {
            return {
              Vin: vehicle.vin,
              RetailerId: vehicle.retailerId
            }
          }),
          IncludeOfflineVehicles: true,
          IncludeEquipment: false
        })
        .then((response) => {
          serviceStatusStore.updateAgency(!!response.data?.agencyEnabled)
          if (response.error) {
            this.vehicles = []
            this.fetchDetailsError = true
            return
          }

          this.vehicles = response.data.vehicles
        })
      this.isLoadingDetails = false
    },

    async add(vehicle: NewVehicle | UsedVehicle, retailer: IRetailer) {
      if (!vehicle) return

      const customerTypeStore = useCustomerTypeStore()
      const customerMode = customerTypeStore.getCustomerTypeName

      // Do not add mutliple times
      if (this.alreadyViewed(vehicle.Vin, customerMode)) return

      const retailerData = retailer || vehicle.Retailer
      const isNew = isNewVehicle(vehicle)

      // Legacy for PnR object store, look to only send
      // Vin & CustomerMode in the future
      const { error } = await this.api.profile.recentlyViewed.add({
        ClientApplicationUserName: isNew ? 'ncl' : 'ucl',
        VIN: vehicle.Vin,
        IsNew: isNew,
        ExternalRetailerId: String(retailerData.Id),
        RetailerDescription: String(retailerData.Description),
        GssnId: String(retailerData.GssnId),
        VehicleGroup: EProfileVehicleGroup.RecentlyViewed,
        Description: vehicle.Description,
        BodyStyle: vehicle.BodyStyle || '',
        Model: vehicle.Model || '',
        FuelType: vehicle.FuelType,
        VehicleClass: vehicle.VehicleClass || '',
        TransmissionType: vehicle.TransmissionType || '',
        P11D: isNew ? String(vehicle.P11D) : null,
        ImageURL: vehicle.Media?.MainImageUrl,
        CustomerMode: customerMode
      })

      // Manually add to store
      if (!error) {
        this.savedVehicles.unshift({
          vin: vehicle.Vin,
          customerMode,
          IsNew: isNew,
          retailerId: retailerData.Id!
        })
      }
    },
    resetAll() {
      this.vehicles = []
      this.savedVehicles = []
    }
  },

  getters: {
    alreadyViewed: (state) => {
      return (vin: string, customerMode: ECustomerTypes) =>
        state.savedVehicles.some((vehicle) => {
          return vehicle.vin === vin && vehicle.customerMode === customerMode
        })
    },

    getCount(state) {
      return state.savedVehicles.length
    },

    getVehicles(state): SavedVehicleCard[] {
      return state.savedVehicles
        .map((vehicle) => {
          const details = state.vehicles?.find(
            ({ Vin, VehicleType }) =>
              Vin === vehicle.vin &&
              VehicleType === (vehicle.IsNew ? 'NEW' : 'USED')
          )

          return {
            ...vehicle,
            vehicle: details
          }
        })
        .filter((item) => !!item.vehicle)
    }
  }
})
