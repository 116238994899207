import { defineStore } from 'pinia'
import { useCreditCheckStore } from './creditCheckStore'
import { useOnlineCreditDecisionStore } from './onlineCreditDecisionStore'
import { useFinanceApplicationStore } from './financeApplicationStore'
import {
  EStoreStatus,
  IFieldValueById,
  IOcdField,
  IOcdSection,
  TFormFactorySection
} from '~/types/forms'
import { IStep } from '~/types/steps'

type TOcdSectionKey = string

interface IFormFactoryState {
  status: EStoreStatus
  isLoading: boolean
  updatedFieldsById: IFieldValueById
  sectionsByStep: Map<TOcdSectionKey, TOcdSectionKey[]>
  sections: Map<TOcdSectionKey, TFormFactorySection>
}

export const useFormFactoryStore = defineStore('formFactory', {
  hasNavigation: true,

  state: (): IFormFactoryState => {
    return {
      status: EStoreStatus.OK,
      isLoading: false,
      updatedFieldsById: {},
      sectionsByStep: new Map<TOcdSectionKey, TOcdSectionKey[]>(),
      sections: new Map<TOcdSectionKey, TFormFactorySection>()
    }
  },
  getters: {
    getSteps(): IStep[] {
      return [...this.sectionsByStep.keys()].map((key) => {
        const label = this.sections.get(key)?.Label || ''
        return {
          key,
          label,
          title: label
        }
      })
    },

    getCurrentStepSections(): TFormFactorySection[] {
      const sections = this.sectionsByStep.get(
        this.navigation.currentStep.value.key!
      )

      if (!sections) return []

      return sections.map((s) => this.sections.get(s)!)
    },

    hasError(): boolean {
      return this.status !== EStoreStatus.OK
    },

    isFormFactoryLoading(): boolean {
      const ocdStore = useOnlineCreditDecisionStore()
      const creditCheckStore = useCreditCheckStore()
      const financeApplicationStore = useFinanceApplicationStore()

      return (
        this.isLoading ||
        ocdStore.isLoading ||
        creditCheckStore.isLoading ||
        financeApplicationStore.isLoading
      )
    }
  },
  actions: {
    reset() {
      this.$reset()
    },

    resetSections() {
      this.sectionsByStep.clear()
      this.sections.clear()
    },

    updateSections(payload: IOcdSection[]) {
      const stepSections = getSectionsWithCount(payload)
      const sections = new Map()

      stepSections.forEach((section) => sections.set(section.key, section))
      this.sections = sections
    },

    initialiseSteps(payload: IOcdSection[]) {
      const stepSections = getSectionsWithCount(payload)
      this.sectionsByStep.clear()
      this.sections.clear()
      const sectionsByStep = new Map()
      const sections = new Map()

      stepSections.forEach((section) => {
        sections.set(section.key, section)
        const lastSection = [...sectionsByStep][sectionsByStep.size - 1]
        if (
          section.fieldsCount > 1 ||
          !lastSection ||
          section.Identifier === 'OS_NOTICE'
        ) {
          sectionsByStep.set(section.key, [section.key])
        } else {
          sectionsByStep.set(
            lastSection[0],
            lastSection[1].concat([section.key])
          )
        }
      })

      this.sectionsByStep = sectionsByStep
      this.sections = sections
    },

    resetTextFieldsValue() {
      this.updatedFieldsById = {}
    },

    addFormValue({ id, value, error }) {
      this.updatedFieldsById = {
        ...this.updatedFieldsById,
        [id]: {
          value,
          error
        }
      }
    },

    updateFormDateValue(payload: { field: IOcdField; value: string }) {
      this.updateFormValue({
        field: payload.field,
        value: formatDateForApi(payload.value)
      })
    },

    updateFormValuesFromSections() {
      const fields = getUpdatedFields([...this.sections.values()])

      fields.forEach(({ Identifier, Value, Error }) => {
        this.addFormValue({
          id: Identifier,
          value: Value,
          error: Error
        })
      })
    },

    async updateFormValue(payload: { field: IOcdField; value: string }) {
      const { field, value } = payload
      const ocdStore = useOnlineCreditDecisionStore()

      const { ValidationRules, Identifier } = field
      const serverSideRegex = ValidationRules.find(
        (rule) => rule.Type === 'ServerSideRegex'
      )

      const error =
        serverSideRegex &&
        !String(value).match(serverSideRegex.Value.replace(/['"]+/g, ''))
          ? [serverSideRegex.ErrorMessage]
          : []

      this.addFormValue({
        id: Identifier,
        value,
        error
      })

      await ocdStore.updateFormData()
    },

    async removeSection(actionId) {
      const ocdStore = useOnlineCreditDecisionStore()

      const response = await this.api.onlineCreditDecision.removeSection(
        ocdStore.proposalReference,
        actionId
      )

      if (!response?.data?.Sections) {
        this.status = EStoreStatus.ERROR
        return
      }

      this.updateSections(response.data.Sections)
    }
  }
})
