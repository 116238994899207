import { IGoogleDataLayer, IGoogleDataLayerOptions } from './googleDataLayer'
import { EFormType } from '~/types/forms'

export interface IDataLayerService {
  push: (
    payload: Partial<IGoogleDataLayerOptions>
  ) => IGoogleDataLayer | undefined
  pageName: Function
}

export enum EEventType {
  PageView = 'page_view',
  LinkClick = 'link_click',
  Purchase = 'purchase',
  EECClick = 'ee_click',
  EECDetail = 'eec_detail',
  EECImpression = 'eec_impression',
  Scroll = 'scroll',
  SYSTEM_MESSAGE_SHOWN = 'system_message_shown'
}

export enum ERoute {
  VehicleDetails = 'vehicle-detail',
  Condition = 'condition',
  Results = 'results',
  MyAccount = 'myaccount',
  Compare = 'compare'
}

export enum EFormNames {
  'callback' = EFormType.Callback,
  'value_my_car' = EFormType.ValueMyCar,
  'offer' = EFormType.Offer,
  'enquiry' = EFormType.Enquiry,
  'test_drive' = EFormType.TestDrive,
  'ocd' = EFormType.FinanceEligibility,
  'stock_notification' = EFormType.StockNotification
}

export const TrackingCategoryKey: InjectionKey<string> = Symbol.for(
  'TrackingCategoryKey'
)

export const TrackingActionKey: InjectionKey<string> =
  Symbol.for('TrackingActionKey')
