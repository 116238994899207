import type {
  AsyncDataOptions,
  NuxtApp,
  NuxtError,
  UseFetchOptions
} from '#app'
import { defu } from 'defu'
import { RuntimeConfig } from 'nuxt/schema'
import { useProfileStore } from '~/stores/profileStore'
import { OsResponse } from '~/types/api'
import { useAuthStore } from '~/stores/authStore'

export interface INuxtOptions {
  headers: Readonly<Record<string, string>>
  config: RuntimeConfig
}

const RETRY_ATTEMPTS = 2
const AUTH_STATUS = [401, 403]

const getParams = <Input, Output>(
  url: string,
  options: UseFetchOptions<Input, Output>
) => {
  const profileStore = useProfileStore()

  const serverHeaders = useRequestHeaders([
    'user-agent',
    'x-forwarded-for',
    'sec-ch-ua',
    'sec-ch-ua-mobile',
    'cloudfront-viewer-longitude',
    'cloudfront-viewer-latitude'
  ])

  // Bearer token
  const authHeaders = {
    Authorization: `Bearer ${profileStore.getAuthToken}`
  }

  const defaults: UseFetchOptions<Input, Output> = {
    baseURL: useRuntimeConfig()?.public?.apiUrl ?? '/',
    key: url,

    headers: {
      ...(profileStore.getAuthToken ? authHeaders : {}),
      // Server headers only
      ...(import.meta.server
        ? {
            'user-ip': serverHeaders['x-forwarded-for'] || '',
            'x-forwarded-for': serverHeaders['x-forwarded-for'] || '',
            'x-viewer-longitude':
              serverHeaders['cloudfront-viewer-longitude'] || '',
            'x-viewer-latitude':
              serverHeaders['cloudfront-viewer-latitude'] || '',
            'user-agent': serverHeaders['user-agent'] || '',
            'sec-ch-ua': serverHeaders['sec-ch-ua'] || '',
            'sec-ch-ua-mobile': serverHeaders['sec-ch-ua-mobile'] || ''
          }
        : {})
    }
  }

  return defu(options, defaults)
}

export function useCustomLazyAsyncData<Output>(
  key: string,
  handler: (ctx?: NuxtApp) => Promise<OsResponse<Output>>,
  options?: AsyncDataOptions<Output>
) {
  return useCustomAsyncData<Output>(key, handler, { ...options, lazy: true })
}

export function useCustomAsyncData<Output>(
  key: string,
  handler: (ctx?: NuxtApp) => Promise<OsResponse<Output>>,
  options?: AsyncDataOptions<Output>
) {
  return useAsyncData<OsResponse<Output>, NuxtError, Output>(key, handler, {
    ...options,
    transform: (data) => {
      if (data.error) {
        throw createError({
          statusCode: data.error.code,
          data: data.error.data
        })
      }

      return data.data
    }
  })
}

export async function $osFetch<Input, Output = Input>(
  url: string,
  options: UseFetchOptions<Input, Output> = {}
): Promise<OsResponse<Output>> {
  const params = getParams(url, options)
  const retryAttempts = useState('retryAttempts', () => 0)
  const isRefreshing = useState('isRefreshing', () => false)

  if (options.transform) {
    params.onResponse = (ctx) => {
      if (ctx.response?.status === 418) {
        showError({
          statusCode: 418,
          statusMessage: 'Request blocked'
        })
      }
      if (ctx.response.ok) {
        ctx.response._data = options.transform!(ctx.response._data)
      }
    }
  }

  // @ts-ignore
  return await $fetch<Output>(url, params)
    .then((res) => ({
      data: res,
      error: null
    }))
    .catch(async (e) => {
      if (e.response?.status === 418) {
        showError({
          statusCode: 418,
          statusMessage: 'Request blocked'
        })
      }
      if (
        !process.server &&
        options.retry !== false &&
        AUTH_STATUS.includes(e.response?.status)
      ) {
        if (isRefreshing.value) {
          while (isRefreshing.value) {
            await new Promise((resolve) => setTimeout(resolve, 100))
          }

          return await $osFetch(url, options)
        } else if (retryAttempts.value < RETRY_ATTEMPTS) {
          isRefreshing.value = true
          retryAttempts.value++

          const authStore = useAuthStore()
          const profileStore = useProfileStore()

          await authStore
            .refreshToken()
            .catch(async () => await profileStore.createProfile().catch())

          isRefreshing.value = false

          return await $osFetch(url, options)
        } else {
          setTimeout(() => {
            retryAttempts.value = 0
          }, 10000)
        }
      }

      return {
        data: null,
        error: {
          code: e.response?.status,
          data: e.data
        }
      }
    })
}

export function $osGet<T, D = T>(
  url: string,
  options: UseFetchOptions<T, D> = {}
) {
  return $osFetch<T, D>(url, {
    method: 'GET',
    ...options
  })
}

export function $osPost<T, D = T>(
  url: string,
  options: UseFetchOptions<T, D> = {}
) {
  return $osFetch<T, D>(url, {
    method: 'POST',
    ...options
  })
}

export function $osPut<T, D = T>(
  url: string,
  options: UseFetchOptions<T, D> = {}
) {
  return $osFetch<T, D>(url, {
    method: 'PUT',
    ...options
  })
}

export function $osDelete<T, D = T>(
  url: string,
  options: UseFetchOptions<T, D> = {}
) {
  return $osFetch<T, D>(url, {
    method: 'DELETE',
    ...options
  })
}
