import isValidResponse from '~/guards/vehicle'
import ApiFactory from '~/api/factory'
import { NEnquiries } from '~/types/api'

const processEnquiriesResponse = (response: NEnquiries.Response) => {
  const vehicles = response?.Response.map((enquiry) => enquiry.Vehicle)
  const vehicleValidation = vehicles.map((vehicle) => isValidResponse(vehicle))
  const allValid = vehicleValidation.every(([isValid]) => isValid)

  if (allValid) {
    return {
      enquiries: response?.Response
    }
  } else {
    const failedVehicle = vehicleValidation.find(([isValid]) => !isValid)
    const [, reason] = failedVehicle!

    // eslint-disable-next-line no-console
    console.warn(reason || 'Invalid response')
    throw new OsFetchError(reason || 'Invalid response')
  }
}

class EnquiriesModule extends ApiFactory {
  get(ciamId: string) {
    return $osGet<NEnquiries.Response, NEnquiries.TransformedResponse>(
      `${this.apiVersion}/myaccount/${ciamId}/enquiryhistory`,
      {
        transform: processEnquiriesResponse
      }
    )
  }
}

export default EnquiriesModule
