import { googleDataLayer } from '~/services/analytics/googleDataLayer'
import { EEventType } from '~/types/dataLayer'
import { ERoutePageSlugs, ERoutes } from '~/types/routes'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'

const analyticsFactory = () => ({
  slug() {
    const route = useRoute()
    return route.params.slug as string
  },

  route() {
    const route = useRoute()
    return route.name === ERoutes.Page
      ? (route.path as ERoutePageSlugs)
      : (route.name as ERoutes)
  },

  linkClick(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.LinkClick,
      isPageLoad: false,
      slug: this.slug(),
      ...payload
    })
  },

  pageView(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.PageView,
      isPageLoad: true,
      slug: this.slug(),
      ...payload
    })
  },

  purchase(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.Purchase,
      category: 'eec',
      action: 'purchase',
      ...payload
    })
  },

  eecClick(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.EECClick,
      category: 'eec',
      action: 'click',
      ...payload
    })
  },

  eecDetail(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.EECDetail,
      category: 'eec',
      action: 'detail',
      ...payload
    })
  },

  eecImpression(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.EECImpression,
      category: 'eec',
      action: 'impression',
      ...payload
    })
  },
  scroll(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.Scroll,
      category: 'scroll_depth',
      ...payload
    })
  },
  systemMessageEvent(payload: Partial<IGoogleDataLayerOptions>) {
    googleDataLayer.push({
      route: this.route(),
      type: EEventType.SYSTEM_MESSAGE_SHOWN,
      ...payload
    })
  }
  // dispatch(payload: Partial<IGoogleDataLayerOptions>) {
  //   googleDataLayer.push(payload)
  // }
})

export default analyticsFactory
