import { defineStore } from 'pinia'
import { useFormFactoryStore } from './formFactoryStore'
import { useCreditCheckStore } from './creditCheckStore'
import { useFinanceApplicationStore } from './financeApplicationStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { EStoreStatus } from '~/types/forms'
import { IOCDSessionExpire, ITier } from '~/types/onlineCreditDecision'

let sessionTimeout = 0
let sessionTimeoutReminder = 0
let sessionReminderInterval: NodeJS.Timeout = 0
let cancelSource: AbortController | null = null
let cancelAddressSource: AbortController | null = null
const userAbortReason = 'cancel form update'
export const CREDIT_ATTEMPTS = 5
export const FINANCE_ATTEMPTS = 20

export const OcdTitles: Record<number, string> = {
  1: 'Mr',
  2: 'Mrs',
  3: 'Miss',
  4: 'Ms',
  5: 'Dr',
  6: 'Lady',
  7: 'Lord',
  8: 'Prof',
  9: 'Sir'
}

export enum EOcdFormType {
  CREDIT_CHECK = 'CustomerCreditCheck',
  FINANCE_APPLICATION = 'CustomerFinanceApplication'
}

interface IOcdStoreState {
  status: EStoreStatus
  isLoading: boolean
  activeFormType: EOcdFormType

  session: {
    expiresAt: number | null
    hasStarted: boolean
    isExpired: boolean
  }
  sessionEnding: string | null
  content: {
    preCreditText: string
    creditAgencyNotice: string
  }
  proposalReference: string
  sessionToken: string
  consentPolicy: object
  creditRating: ITier
  isEnquirySent: boolean
  externalReference: string
  financeResult: ITier
  isOcdEnabled: boolean
  quoteReference: string
}

export const useOnlineCreditDecisionStore = defineStore(
  'onlineCreditDecision',
  {
    state: (): IOcdStoreState => {
      return {
        status: EStoreStatus.OK,
        isLoading: false,
        activeFormType: EOcdFormType.CREDIT_CHECK,

        session: {
          expiresAt: null,
          hasStarted: false,
          isExpired: false
        },
        sessionEnding: null,
        content: {
          preCreditText: '',
          creditAgencyNotice: ''
        },
        proposalReference: '',
        sessionToken: '',
        consentPolicy: {},
        creditRating: {} as ITier,
        isEnquirySent: false,
        externalReference: '',
        financeResult: {} as ITier,
        isOcdEnabled: false,
        quoteReference: ''
      }
    },
    getters: {
      creditNoticeText(): any {
        return {
          Identifier: 'OS_NOTICE',
          Label: 'Credit Agency Notice',
          Fields: [
            {
              Label: 'Agreement',
              Identifier: 'OS_NOTICE',
              Value: this.content.creditAgencyNotice,
              Error: '---',
              Type: 'DisplayText',
              IsRequired: false,
              ToolTip: '',
              ToolTipPrompt: '',
              IsReadOnly: false,
              Key: 'Agreement',
              Options: [],
              ValidationRules: [],
              InformationMessages: ['Agreement'],
              IsDynamicField: true
            }
          ],
          Sections: [],
          Actions: {},
          IsValidToSubmit: true
        }
      },
      isCreditCheck(): boolean {
        return this.activeFormType === EOcdFormType.CREDIT_CHECK
      },

      hasError(): boolean {
        return this.status !== EStoreStatus.OK
      },

      isOcdActive(): boolean {
        const financeQuotesStore = useFinanceQuotesStore()

        const quoteDto = financeQuotesStore.currentQuote?.QuoteDto

        if (financeQuotesStore.currentQuote?.HasError) return false
        if (!isQuote(quoteDto)) return false

        return !!(
          this.isOcdEnabled &&
          quoteDto.RetailerApply &&
          quoteDto.QuoteReference &&
          (quoteDto.IsPCP ||
            quoteDto.IsPHP ||
            quoteDto.IsPOL ||
            quoteDto.IsPCH) &&
          !this.isEnquirySent
        )
      },

      isSessionEnded(): boolean {
        return this.session.isExpired && this.session.hasStarted
      },

      isSessionActive(): boolean {
        return !this.session.isExpired && this.session.hasStarted
      }
    },
    actions: {
      reset() {
        const creditCheckStore = useCreditCheckStore()
        const finnaceApplicationStore = useFinanceApplicationStore()
        const ocdFormFactoryStore = useFormFactoryStore()

        this.$reset()
        this.clearSessionTimeout()
        creditCheckStore.resetWithNavigation()
        finnaceApplicationStore.resetWithNavigation()
        ocdFormFactoryStore.resetWithNavigation()
      },

      setCreditRating(payload: ITier) {
        this.creditRating = payload
      },

      setExternalReference(payload: string) {
        this.externalReference = payload || ''
      },

      setFinanceResult(payload: ITier) {
        this.financeResult = payload
      },

      setIsEnquirySent(payload = true) {
        this.isEnquirySent = payload
      },

      resetOcdSession() {
        this.session.isExpired = false
        this.session.hasStarted = false
      },

      async getForm(formType: EOcdFormType) {
        this.activeFormType = formType

        if (this.status !== EStoreStatus.OK) return

        const ocdFormFactoryStore = useFormFactoryStore()

        ocdFormFactoryStore.resetSections()
        ocdFormFactoryStore.resetTextFieldsValue()

        this.isLoading = true

        const response =
          await this.api.onlineCreditDecision.getCustomerCreditCheck(
            this.proposalReference,
            formType,
            this.isCreditCheck ? '' : EOcdFormType.CREDIT_CHECK
          )

        this.isLoading = false

        if (response.data?.Sections && response.data.Sections.length >= 1) {
          const data = [...response.data.Sections]

          /// Add Dummy section here
          if (formType === EOcdFormType.FINANCE_APPLICATION) {
            data.push(this.creditNoticeText)
          }

          ocdFormFactoryStore.initialiseSteps(data)
          ocdFormFactoryStore.navigation.setStep(0)
          return
        }

        this.status = EStoreStatus.ERROR
      },

      async fetchContent(type: 'preCreditText' | 'creditAgencyNotice') {
        this.isLoading = true

        const response =
          type === 'preCreditText'
            ? await this.api.onlineCreditDecision.getPreCreditText()
            : await this.api.onlineCreditDecision.getCreditAgencyNotice()

        if (response?.data?.Content) {
          this.content = {
            ...this.content,
            [type]: response.data.Content
          }
        } else {
          this.status = EStoreStatus.ERROR
        }

        this.isLoading = false
      },

      async fetchFinanceApplicationContent() {
        await this.fetchContent('creditAgencyNotice')
      },

      async fetchProposalReference() {
        const retailerStore = useRetailerStore()
        const financeQuotesStore = useFinanceQuotesStore()

        const retailerGssnId = retailerStore.retailer?.GssnId

        if (!retailerGssnId) {
          this.status = EStoreStatus.ERROR
        } else {
          this.isLoading = true

          const quoteDto = financeQuotesStore.currentQuote?.QuoteDto
          const payload = {
            QuoteReference: isQuote(quoteDto) ? quoteDto.QuoteReference : '',
            OrganisationIdentifier: { Type: 'MbGssn', Value: retailerGssnId }
          }
          const proposalResponse =
            await this.api.onlineCreditDecision.generateProposal(payload)

          this.isLoading = false

          if (proposalResponse?.data?.ProposalReference) {
            this.proposalReference = proposalResponse.data.ProposalReference
            this.sessionToken = proposalResponse.data.SessionToken
            this.updateSessionTimes(proposalResponse.data.SessionExpiryDetails)

            if (this.status === EStoreStatus.OK) {
              await this.fetchConsentPolicy()
            }
          } else {
            this.status = EStoreStatus.ERROR
          }
        }
      },

      async fetchConsentPolicy() {
        this.isLoading = true

        const response = await this.api.onlineCreditDecision.getConsent(
          this.proposalReference
        )

        if (response.data?.Overview) {
          this.consentPolicy = response.data
        } else {
          this.status = EStoreStatus.ERROR
        }

        this.isLoading = false
      },

      async extendSession() {
        const response = await this.api.onlineCreditDecision.extendSession(
          this.sessionToken
        )
        if (response.data) {
          this.updateSessionTimes(response.data)
        }
      },

      setOcdSessionExpiryDate(payload) {
        this.session = {
          expiresAt: new Date(payload.ExpiresAt).getTime() - 1000,
          hasStarted: true,
          isExpired: false
        }
      },

      updateSessionTimes(sessionExpiryDetails: IOCDSessionExpire) {
        const delay = sessionExpiryDetails.ExpiresIn - 1000

        this.setOcdSessionExpiryDate(sessionExpiryDetails)
        this.clearSessionTimeout()
        sessionTimeout = setTimeout(() => {
          this.session = { ...this.session, isExpired: true }
          this.status = EStoreStatus.ERROR
        }, delay) as unknown as number

        // Session timeout at 20 mins, show warning after approx. 5 minutes, so.
        sessionTimeoutReminder = setTimeout(
          this.onTimeoutEnding,
          delay * 0.75
        ) as unknown as number
      },

      onTimeoutEnding() {
        const { formatCountdown } = useDate()
        sessionReminderInterval = setInterval(() => {
          const t = (this.session?.expiresAt || 0) - Date.now()
          if (t <= 0) {
            this.sessionEnding = null
            clearInterval(sessionReminderInterval)
          } else {
            this.sessionEnding = formatCountdown(t, true)
          }
        }, 1000)
      },

      clearSessionTimeout() {
        clearTimeout(sessionTimeout)
        clearTimeout(sessionTimeoutReminder)
        if (sessionReminderInterval) clearInterval(sessionReminderInterval)
        this.sessionEnding = null
      },

      async checkConsent() {
        this.isLoading = true

        const consentResponse =
          await this.api.onlineCreditDecision.approveConsent(
            this.proposalReference
          )

        if (consentResponse.error || consentResponse.data?.Error) {
          this.status = EStoreStatus.ERROR
        }

        this.isLoading = false
      },

      async updateFormData() {
        const formFactoryStore = useFormFactoryStore()
        const inputData: { FieldIdentifier: string; Value: string }[] = []
        const data = formFactoryStore.updatedFieldsById

        for (const key in data) {
          inputData.push({
            FieldIdentifier: key,
            Value: data[key].value
          })
        }

        if (cancelSource) {
          cancelSource.abort(userAbortReason)
        }

        cancelSource = new AbortController()
        const signal = cancelSource?.signal

        const response = await this.api.onlineCreditDecision.updateForm(
          this.proposalReference,
          {
            Updates: inputData,
            MinusFormTypes: this.isCreditCheck
              ? []
              : [EOcdFormType.CREDIT_CHECK],
            ReturnAllFieldErrors: this.isCreditCheck
          },
          { signal }
        )

        // user cancel request error
        if (response.error && signal?.reason === userAbortReason) return

        if (
          response.error ||
          response.data?.Error ||
          !response.data?.Sections ||
          response.data?.Sections.length < 1
        ) {
          this.status = EStoreStatus.ERROR
        } else {
          const data = [...response.data.Sections]
          /// Add Dummy section here
          if (!this.isCreditCheck) {
            data.push(this.creditNoticeText)
          }

          formFactoryStore.updateSections(data)
          this.extendSession()
        }
      },

      async getAddressList(postcode: string) {
        if (cancelAddressSource) {
          cancelAddressSource.abort(userAbortReason)
        }

        cancelAddressSource = new AbortController()
        const signal = cancelAddressSource?.signal

        const response = await this.api.onlineCreditDecision.getAddressList(
          { Postcode: postcode },
          { signal }
        )

        return response?.data?.Addresses || []
      },

      async updateFormAddress(payload: { data: any; id: string }) {
        const formFactoryStore = useFormFactoryStore()

        const response = await this.api.onlineCreditDecision.updateFormAddress(
          this.proposalReference,
          {
            FieldIdentifier: payload.id,
            Data: {
              Value: payload.data.Id,
              Type: 'AddressLookupId'
            }
          }
        )

        if (
          response.error ||
          response.data?.Error ||
          !response.data?.Sections ||
          response.data.Sections.length < 1
        ) {
          this.status = EStoreStatus.ERROR
        } else {
          const data = [...response.data.Sections]
          /// Add Dummy section here
          if (!this.isCreditCheck) {
            data.push(this.creditNoticeText)
          }
          formFactoryStore.updateSections(data)
          this.extendSession()
          formFactoryStore.resetTextFieldsValue()
        }
      },

      async sendOcdEnquiry() {
        this.isLoading = true
        const response = await this.api.onlineCreditDecision.sendEnquiry(
          this.proposalReference
        )
        this.isLoading = false

        if (response.error || response.data?.Error) {
          this.status = EStoreStatus.ERROR
          return
        }

        this.isEnquirySent = true
      },

      async fetchOcdSession() {
        const financeQuotesStore = useFinanceQuotesStore()

        const quoteDto = financeQuotesStore.currentQuote?.QuoteDto
        const quoteRef = isQuote(quoteDto)
          ? (quoteDto.QuoteReference ?? '')
          : ''

        const isSessionExpired = this.session.expiresAt
          ? new Date().getTime() - this.session.expiresAt > 0
          : false

        if (quoteRef !== this.quoteReference || isSessionExpired) {
          this.reset()
        }

        const response = await this.api.onlineCreditDecision.getJourneyState()

        this.isOcdEnabled = !!response.data?.enabled

        if (response.data?.enabled) {
          this.quoteReference = quoteRef
        }
      }
    }
  }
)
