import ApiFactory from './factory'
import { OsResponse } from '~/types/api'

type RefreshTokenResponse = {
  refreshToken: string
  authToken: string
}

class AuthModule extends ApiFactory {
  async refreshToken(
    profileID: string,
    refreshToken: string,
    authToken: string
  ): Promise<OsResponse<RefreshTokenResponse>> {
    return await $osPost<RefreshTokenResponse>('v3/token/refresh', {
      retry: false,
      body: {
        profileID,
        refreshToken
      },
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
  }
}

export default AuthModule
