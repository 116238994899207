<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M10.551572,20.4412576 C10.9349145,20.4840044 11.5124104,20.6836735 12.0537783,20.9834528 C12.6853282,21.3334253 13.17016,21.7804744 13.3656922,22.1089355 L10.6781577,24 L10.1362383,23.6853282 L10.551572,20.4412576 Z M15.0375069,12.7032543 C15.4222284,12.7363486 15.972973,12.9321566 16.5336459,13.2429675 C17.1977386,13.610866 17.674021,14.0358522 17.8507998,14.3725869 L13.8077772,21.3463872 C13.630171,21.0099283 13.1618864,20.5962493 12.4988969,20.2289024 C11.9412576,19.9202978 11.3739658,19.7222835 10.9895201,19.6875345 L15.0375069,12.7032543 Z M14.4107891,0 L14.4107891,11.3 L9.7,19.3050193 L7.37188088e-14,19.3050193 L7.37188088e-14,0 L14.4107891,0 Z M7.06944369,12.779379 L2.99091848,12.779379 L2.99091848,13.8669857 L7.06944369,13.8669857 L7.06944369,12.779379 Z M16.574738,11.0672918 C16.9070601,11.0672918 17.2741313,11.1897408 17.6412024,11.3996139 C18.4804192,11.8712079 18.6729178,12.5706012 18.6729178,12.850524 L18.6729178,12.9553227 L18.2691671,13.6511307 C18.0962493,13.3135687 17.653337,12.9040265 16.9837286,12.5330943 C16.4200221,12.2209046 15.8450083,12.0055157 15.4597352,11.9740761 L15.8753447,11.2595146 C15.9453944,11.189465 16.2076669,11.0672918 16.574738,11.0672918 Z M11.6917723,10.6041655 L2.99091848,10.6041655 L2.99091848,11.6917723 L11.6917723,11.6917723 L11.6917723,10.6041655 Z M11.6917723,8.15705041 L2.99091848,8.15705041 L2.99091848,9.24465713 L11.6917723,9.24465713 L11.6917723,8.15705041 Z M11.6917723,5.70993529 L2.99091848,5.70993529 L2.99091848,6.79754201 L11.6917723,6.79754201 L11.6917723,5.70993529 Z M11.6917723,3.26282016 L2.99091848,3.26282016 L2.99091848,4.35042689 L11.6917723,4.35042689 L11.6917723,3.26282016 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SubmitOffer'
}
</script>
