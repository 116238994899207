import { ECustomerTypes } from '~/types/customerType'
import { IFiltersRange } from '~/types/filters'
import { EFinanceProductKeys } from '~/types/finance/product'
import { IQuoteFinanceProduct } from '~/types/finance/quote'
import { IEcommerceVehicle } from '~/types/googleDataLayer'
import { ECondition, EVehicleType, IVehicle } from '~/types/vehicle'

export enum EFinanceEventType {
  FINANCE_ADJUSTED = 'financeAdjustment',
  FINANCE_COMPLETE = 'financeComplete',
  FINANCE_ERROR = 'financeError',
  FINANCE_TRANSPORT = 'financeTransportError' // How do we do
}

export type FinanceAnalyticsProduct =
  | {
      Key: EFinanceProductKeys
      Name: {
        Name: string
      }
      Type?: string
      IsDefault: boolean
      Term: number
      Deposit?: {
        Default: number | string
      }
      Mileage: number
      MonthlyPrice?: {
        Min: number
        Max: number
      }
      IsPersonalised: boolean
      VehicleType: ECondition
      RegularPayment?: number
      InitialPaymentMultiple?: number
      LumpSum?: number
      CustomerType: ECustomerTypes
      IsActive: true
    }
  | {}

export function vehicleAnalytics(vehicle) {
  const getStockLevel = (level: 'G' | 'Y' | 'A' | 'R') => {
    switch (level) {
      case 'G':
        return '5+ in stock'
      case 'Y':
        return 'Hurry. Last few remaining'
      case 'A':
        return 'Hurry. Last 1 in stock'
      case 'R':
        return 'Out of stock'
    }
  }
  return {
    name: vehicle.Description ?? '',
    model: vehicle.Model ?? '',
    class: vehicle.VehicleClass ?? '',
    brand: vehicle?.Brand?.Description ?? '',
    id: vehicle.Id ?? '',
    version: vehicleCondition(vehicle),
    retailer_id: vehicle?.Retailer?.Id ?? '',
    owning_retailer_id: vehicle?.OwningRetailer?.Id ?? '',
    transmission_type: vehicle.TransmissionType ?? '',
    fuel_type: vehicle.FuelType ?? '',
    value: vehicle.OTR ?? '',
    engine: vehicle.Engine ?? '',
    colour: vehicle.Colour ?? '',
    line: vehicle.Line ?? '',
    package: vehicle?.AvailablePackages?.join(';') ?? '',
    offerValue: parseTotalOffer(vehicle?.TotalOfferValue),
    offer:
      parseTotalOffer(vehicle?.TotalOfferValue) > 0
        ? vehicleOfferDescription(vehicle)
        : '',
    offer_status: vehicle.UnderOffer ? 'Under Offer' : 'Available',
    ...(vehicleCondition(vehicle) === 'Used' && {
      regDate: vehicle.RegistrationDate ?? '',
      age: vehicle.Age ?? '',
      usage: vehicle.Mileage ?? ''
    }),
    condition: vehicleCondition(vehicle),
    filters_removed: [...new Set(vehicle.BestMatchDroppedValues)],
    vin: vehicle.Vin ?? '',
    stock_level: getStockLevel(vehicle.StockLevelIndicator)
  }
}

export function vehicleEcommerceAnalytics(
  vehicle,
  includesMetrics = true
): IEcommerceVehicle {
  return {
    name: vehicle.Description ?? '',
    id: vehicle.Id ?? '',
    price: getVehiclePrice(vehicle) ?? '',
    brand: vehicle?.Brand?.Description ?? '',
    category: vehicle.VehicleClass ?? '',
    variant: vehicleCondition(vehicle),
    position: '',
    dimension28: vehicle.VehicleClass ?? '',
    dimension29: vehicle.Model ?? '',
    dimension30: vehicle.TransmissionType ?? '',
    dimension31: vehicle.FuelType ?? '',
    dimension32: vehicle?.Brand?.Description ?? '',
    dimension33: vehicle?.Retailer?.Id ?? '',
    dimension34:
      parseTotalOffer(vehicle?.TotalOfferValue) > 0
        ? vehicleOfferDescription(vehicle)
        : '',
    dimension173: vehicleCondition(vehicle),
    dimension172: vehicle.Colour ?? '',
    dimension171: vehicle?.AvailablePackages?.join(';') ?? '',
    dimension170: getVehiclePrice(vehicle),
    dimension169: parseTotalOffer(vehicle.TotalOfferValue)
      ? parseTotalOffer(vehicle.TotalOfferValue).toString()
      : '',
    dimension168: vehicle?.Engine?.Badge ?? '',
    dimension167: vehicle.Age ?? '',
    dimension166: vehicle.RegistrationDate ?? '',
    dimension165: vehicle.Mileage ?? '',
    ...(includesMetrics && { metric2: getVehiclePrice(vehicle) }),
    ...(includesMetrics && {
      metric3: parseTotalOffer(vehicle.TotalOfferValue) || ''
    })
  }
}

function getVehiclePrice(vehicle: IVehicle) {
  if (vehicle.OTR) return vehicle.OTR
  if (!vehicle.RetailPrice) return 0
  return parseInt(vehicle.RetailPrice.toString().replace(/[^0-9.]+/g, ''))
}

function vehicleCondition({ VehicleType, IsNew }): string {
  if (VehicleType) {
    return VehicleType === EVehicleType.New ? 'New' : 'Used'
  }

  if (typeof IsNew === 'boolean') {
    return IsNew ? 'New' : 'Used'
  }

  return ''
}

function vehicleOfferDescription({ Offers, VehicleType, IsNew }: IVehicle) {
  const offerTypes = Offers?.Combined?.CampaignOffers.map((offer) => {
    return offer.OfferType
  }).join(' | ')
  const isNew = vehicleCondition({ VehicleType, IsNew }) === 'New'
  const priceLabel = isNew ? 'Offer price' : 'Retail offer price'

  return offerTypes ? `${priceLabel}: ${offerTypes}` : ''
}

export const quoteAnalytics = (
  quote: IQuoteFinanceProduct | null,
  monthlyPrice: IFiltersRange,
  isNew: boolean = true,
  isActive: boolean = false,
  error: NuxtError | null = null
): FinanceAnalyticsProduct => {
  if (error) {
    return {
      Results: {
        EventName: EFinanceEventType.FINANCE_ERROR,
        Events: [
          {
            Code: error?.statusCode,
            Message: (error?.data as any).message
          }
        ]
      }
    }
  }
  if (!quote || !quote.QuoteDto) return {}
  if (!isQuote(quote.QuoteDto)) {
    return {
      Results: {
        EventName: EFinanceEventType.FINANCE_ERROR,
        Events: [
          {
            Code: quote.QuoteDto.Code,
            Message: quote.QuoteDto.Message
          }
        ]
      }
    }
  }

  return {
    Key: quote.Key,
    Name: quote.Product,
    Type: '',
    IsDefault: quote.QuoteDto.IsDefault ? 'true' : 'false',
    Term: quote.QuoteDto.DurationOfAgreement ?? '',
    Deposit: {
      Default: quote.QuoteDto.CustomerDeposit ?? ''
    },
    Mileage: quote.QuoteDto.AnnualMileage ?? '',
    MonthlyPrice: {
      Min: monthlyPrice.Min ?? '',
      Max: monthlyPrice.Max ?? ''
    },
    IsPersonalised: quote.QuoteDto.IsPersonalised ? 'true' : 'false',
    VehicleType: isNew ? 'new' : 'used',
    RegularPayment: quote.QuoteDto.MonthlyPayment ?? '',
    InitialPaymentMultiple: quote.QuoteDto.AdvanceRentals ?? '',
    LumpSum: quote.QuoteDto.CustomerDeposit,
    CustomerType: quote.QuoteDto.CustomerType,
    IsActive: isActive ? 'true' : 'false',

    // Send adjustments if we have notification otherwise send 200 complete.
    Results: quote.QuoteDto.Notifications?.length
      ? {
          EventName: EFinanceEventType.FINANCE_ADJUSTED,
          Events: quote.QuoteDto.Notifications.map(({ Message, Code }) => {
            return { Message, Code }
          })
        }
      : {
          EventName: EFinanceEventType.FINANCE_COMPLETE,
          Events: [
            {
              Code: 200,
              Message: 'Finance Complete'
            }
          ]
        }
  }
}
