import ApiFactory from '~/api/factory'
import { CmsCrossSellTile } from '~/types/cms/contentModel'

class CrossSaleModule extends ApiFactory {
  getByModelId(modelId: string) {
    return $osGet<CmsCrossSellTile[]>(`${this.apiVersion}/cms/tiles/${modelId}`)
  }

  async getByModelIds(modelIds: string[]) {
    if (!modelIds.length) throw new OsFetchError('Not found', 404)

    const response = await Promise.allSettled(
      modelIds.map((modelId) => this.getByModelId(modelId))
    )

    const success = getFulfilledPromises(response).filter((res) => !res.error)

    if (success.length) return success[0]

    throw new OsFetchError('Not found', 404)
  }
}

export default CrossSaleModule
