<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0 1.934V0h16v1.934H0zM0 6.15V4.217h14.533V6.15H0zm0 4.244V8.46h12.8v1.934H0zm0 4.216v-1.933h14.587v1.933H0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsMenu'
}
</script>
