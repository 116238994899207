import { v4 as uuidv4 } from 'uuid'
import { NProfileAuth } from '~/types/api'
import ApiFactory from '~/api/factory'
import { INuxtOptions } from '~/composables/useOsFetch'

class ProfileAuthModule extends ApiFactory {
  create(options?: INuxtOptions) {
    return $osPost<NProfileAuth.create.Response>(
      `${this.apiVersion}/profiles`,
      {
        retry: false,
        body: {
          SessionId: uuidv4()
        },
        ...options
      }
    )
  }

  getAuthToken(code: string) {
    const config = useRuntimeConfig()

    return $osPost<NProfileAuth.getAuthToken.Response>(
      `${this.apiVersion}/myaccount/token`,
      {
        body: {
          grant_type: 'authorization_code',
          code,
          redirect_uri: `${config.public.ciamCallbackUrl}`
        }
      }
    )
  }
}

export default ProfileAuthModule
