<template>
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,-5.68434189e-14 8,-5.68434189e-14 C12.418278,-5.68434189e-14 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M11.7073171,8.58536585 L11.7073171,11.7073171 L13.2682927,11.7073171 L13.2682927,3.90243902 L11.7073171,3.90243902 L11.7073171,7.02439024 L10.1463415,7.02439024 L10.1463415,3.90243902 L8.58536585,3.90243902 L8.58536585,7.02439024 L7.02439024,7.02439024 L7.02439024,3.90243902 L5.46341463,3.90243902 L5.46341463,7.02439024 L3.90243902,7.02439024 L3.90243902,3.90243902 L2.34146341,3.90243902 L2.34146341,8.58536585 L3.90243902,8.58536585 L5.46341463,8.58536585 L5.46341463,11.7073171 L7.02439024,11.7073171 L7.02439024,8.58536585 L8.58536585,8.58536585 L8.58536585,11.7073171 L10.1463415,11.7073171 L10.1463415,8.58536585 L11.7073171,8.58536585 Z"
        fill="currentColor"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Transmission'
}
</script>
