import { ITitle } from '~/types/forms'
import { IAddressPayload } from '~/types/forms/address'

export enum EMarketingPreference {
  None = '',
  Yes = 'Y',
  No = 'N'
}

/**
 * Customer's marketing preferences interface.
 */
export interface IMarketingPreferences {
  OptInEmail?: boolean | null
  OptInSMS?: boolean | null
  OptInTelephone?: boolean | null
  OptInPost?: boolean | null
  OptInMail?: boolean | null
}

/**
 * Payload interface for marketing preferences.
 */
export interface IMarketingPreferencesPayload {
  OptInEmail: EMarketingPreference
  OptInSMS: EMarketingPreference
  OptInTelephone: EMarketingPreference
  OptInPost?: EMarketingPreference
  OptInMail?: EMarketingPreference
}

/**
 * Default customer interface.
 */
export interface ICustomer {
  title?: ITitle
  firstName?: string
  lastName?: string
  additionalInfo?: string
  companyName?: string
  email?: string
  phone?: string
  property?: string
  street?: string
  streetTwo?: string
  town?: string
  county?: string
  postcode?: string
  OptInEmail?: boolean | null
  OptInTelephone?: boolean | null
  OptInPost?: boolean | null
  OptInSMS?: boolean | null
  OptInMail?: boolean | null
}

/**
 * Payload interface for a customer (legacy forms).
 */
export interface ICustomerDetailsPayload {
  Title: string
  FirstName: string
  Surname: string
  EmailAddress: string
  CompanyName?: string
  Street?: string
  Property?: string
  Locality?: string
  Town?: string
  County?: string
  Postcode?: string
  Telephone?: string
  OptInEmail?: boolean
  OptInSms?: boolean
  OptInTelephone?: boolean
  OptInMail?: boolean
  HomeTel?: string
  MarketingPreference?: IMarketingPreferencesPayload
}

/**
 * Payload interface for the customer, for the offer form.
 */
export interface IOfferCustomerPayload {
  CustomerId: string
  IsCiam: boolean
  ProfileId: string
  CompanyName?: string
  Title: string
  Firstname: string
  Surname: string
  EmailAddress: string
  Telephone?: string
  Address: IAddressPayload
  Preferences: IMarketingPreferencesPayload
  Mode: string
}
