import {
  ICreditRatingTier,
  ICreditRatingTiers,
  IFinanceApplicationDecisions,
  IOnlineCreditDecisionText,
  ITier
} from '~/types/onlineCreditDecision'
import {
  EVehicleType,
  IVehicle,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'

export const checkFinanceEligibility: IOnlineCreditDecisionText = {
  enquiry: {
    details: 'See your likelihood of being accepted before you apply.',
    list: [
      'This won’t affect your credit rating',
      'Simply enter a few personal details like your name and address',
      "It only takes around 3 minutes to check your eligibility and you'll get a response in 60 seconds"
    ],
    citation: {
      enabled:
        'You may then be able to apply for finance online or ask a Showroom to call you to complete your application together.',
      disabled:
        'You may then be able to apply for finance, simply send an enquiry to your Showroom and they will call you to complete an application together.'
    }
  },
  retailer: {
    details: 'See your likelihood of being accepted before you apply.',
    list: [
      'This won’t affect your credit rating',
      'Get a response in 60 seconds',
      'Option to continue to apply for finance'
    ]
  }
}

export const yourFinanceQuoteText: string[] = [
  'Your selected finance quote is below, if you’re happy to proceed with your finance eligibility check, please click ‘Next’.',
  'You should only request a finance eligibility check for yourself, not on behalf of anyone else.'
]

export const financeWaitingText = (type: string) => {
  const typeText =
    type === 'creditRating'
      ? ' eligibility'
      : type === 'financeApplication'
        ? ' application'
        : ''
  const duration = type === 'creditRating' ? 'a minute' : '2 minutes'

  return {
    waiting: `We’re just getting your finance${typeText} result... <br><br> It’ll take no more than ${duration}. Please do not close this window.`
  }
}

export const checkFinanceEligibilityHeadings: string[] = [
  'Your finance quote',
  'Check finance eligibility'
]

export const financeEligibilityResultHeading: string[] = [
  'Finance eligibility result'
]

export const sessionExpiryError = {
  title: 'Your Session has timed out',
  message:
    'You have been inactive for over 20 minutes so we have closed your application to protect your privacy.'
}

export const creditRatingTiers: ICreditRatingTiers = {
  [ICreditRatingTier.SuperGreen]: {
    heading:
      'Our initial assessment suggests that you are likely to be approved for finance*',
    text: [
      "You have two options if you'd like to continue with a full finance application. Either complete the application form yourself or ask your selected Showroom to call you back to complete it together.",
      "You'll be asked for a bit more information, mainly regarding your employment and bank details, so we can finalise your application in line with the value of the vehicle you want to finance. It only takes around 4 minutes to apply online.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.Green]: {
    heading:
      'Our initial assessment suggests that you are likely to be approved for finance*',
    text: [
      "You have two options if you'd like to continue with a full finance application. Either complete the application form yourself or ask your selected Showroom to call you back to complete it together.",
      "You'll be asked for a bit more information, mainly regarding your employment and bank details, so we can finalise your application in line with the value of the vehicle you want to finance. It only takes around 4 minutes to apply online.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.Amber]: {
    heading:
      'Our initial assessment suggests we need to look a little more closely to determine your eligibility*',
    text: [
      "You have two options if you'd like to continue with a full finance application. Either complete the application form yourself or ask your selected Showroom to call you back to complete it together.",
      "You'll be asked for a bit more information, mainly regarding your employment and bank details, so we can finalise your application in line with the value of the vehicle you want to finance. It only takes around 4 minutes to apply online.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.Red]: {
    heading:
      'Our initial assessment suggests we need to look a little more closely to determine your eligibility*',
    text: [
      "You have two options if you'd like to continue with a full finance application. Either complete the application form yourself or ask your selected Showroom to call you back to complete it together.",
      "You'll be asked for a bit more information, mainly regarding your employment and bank details, so we can finalise your application in line with the value of the vehicle you want to finance. It only takes around 4 minutes to apply online.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.SuperRed]: {
    heading:
      "We're sorry but we're unable to proceed with your online application from Mercedes-Benz Financial Services UK Ltd*",
    text: [
      'If you would like to discuss this or your other options, please submit an enquiry to your chosen Showroom who will call you back.',
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.White]: {
    heading:
      "We've been unable to complete an eligibility check with the information provided*",
    text: [
      "You have two options if you'd like to continue with a full finance application. Either complete the application form yourself or ask your selected Showroom to call you back to complete it together.",
      "You'll be asked for a bit more information, mainly regarding your employment and bank details, so we can finalise your application in line with the value of the vehicle you want to finance. It only takes around 4 minutes to apply online.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  }
}

export const OCDDisabledCreditRatingTiers: ICreditRatingTiers = {
  [ICreditRatingTier.SuperGreen]: {
    heading:
      'Our initial assessment suggests that you are likely to be approved for finance*',
    text: [
      "If you'd like to continue with a full finance application, please submit an enquiry to your chosen Showroom who will call you back to complete it together.",
      "You'll be asked for or a bit more information, mainly regarding your employment and bank details, so we can finalise your application.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.Green]: {
    heading:
      'Our initial assessment suggests that you are likely to be approved for finance*',
    text: [
      "If you'd like to continue with a full finance application, please submit an enquiry to your chosen Showroom who will call you back to complete it together.",
      "You'll be asked for or a bit more information, mainly regarding your employment and bank details, so we can finalise your application.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.Amber]: {
    heading:
      'Our initial assessment suggests we need to look a little more closely to determine your eligibility*',
    text: [
      "If you'd like to continue with a full finance application, please submit an enquiry to your chosen Showroom who will call you back to complete it together.",
      "You'll be asked for or a bit more information, mainly regarding your employment and bank details, so we can finalise your application.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.Red]: {
    heading:
      'Our initial assessment suggests we need to look a little more closely to determine your eligibility*',
    text: [
      "If you'd like to continue with a full finance application, please submit an enquiry to your chosen Showroom who will call you back to complete it together.",
      "You'll be asked for or a bit more information, mainly regarding your employment and bank details, so we can finalise your application.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.SuperRed]: {
    heading:
      "We're sorry but we're unable to proceed with your online application from Mercedes-Benz Financial Services UK Ltd*",
    text: [
      'If you would like to discuss this or your other options, please submit an enquiry to your chosen Showroom who will call you back.',
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  },
  [ICreditRatingTier.White]: {
    heading:
      "We've been unable to complete an eligibility check with the information provided*",
    text: [
      "If you'd like to continue with a full finance application, please submit an enquiry to your chosen Showroom who will call you back to complete it together.",
      "You'll be asked for or a bit more information, mainly regarding your employment and bank details, so we can finalise your application.",
      `Before applying, reading our <a href="https://www.mercedes-benz.co.uk/passengercars/finance/pre-contract.html" target="_blank" class='os-form__link'>Frequently Asked Questions (FAQs)</a> may help you compare and understand the features, benefits and any risks associated with finance products from Mercedes-Benz Financial Services UK Ltd.`,
      `*Our automated eligibility assessment result is only valid at the time taken, to learn more about how it used your credit information, please see our <a href="https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html#privacy-policy" target="_blank" class='os-form__link'>Privacy Policy</a>.`
    ]
  }
}

export const getCreditRatingTier = (score: number): ICreditRatingTier => {
  if (score === 1) {
    return ICreditRatingTier.SuperRed
  } else if (score > 974) {
    return ICreditRatingTier.SuperGreen
  } else if (score < 975 && score > 724) {
    return ICreditRatingTier.Green
  } else if (score < 724 && score > 474) {
    return ICreditRatingTier.Amber
  } else if (score < 475 && score > 224) {
    return ICreditRatingTier.Red
  } else {
    return ICreditRatingTier.White
  }
}

export const getCreditResponse = (
  creditRatingTier: ICreditRatingTier,
  ocdEnabled: Boolean
): ITier => {
  return ocdEnabled
    ? creditRatingTiers[creditRatingTier]
    : OCDDisabledCreditRatingTiers[creditRatingTier]
}

const financeApplicationDecisions: IFinanceApplicationDecisions = {
  approved: {
    heading: "Congratulations, you've been approved for finance*.",
    text: [
      'Your preferred Showroom will be in touch soon to guide you through the next steps.',
      'If your application includes an indicative valuation for your current car, your Showroom can provide a final valuation and if this is different to your indicative valuation, the amount you wish to borrow may change.',
      '*Your approval is valid for 90 days. We have the right to review our decision if new information becomes available that impacts your finance application. If another credit check is required, this won’t appear on your credit file when conducted within 90 days.'
    ]
  },
  other: {
    heading: "We've not been able to confirm your finance approval.",
    text: [
      'We need a bit more information. Your preferred Showroom will be in touch soon to guide you through the next steps.'
    ]
  }
}

export const getFinanceDecision = (decision: string): ITier => {
  return decision === 'Approved'
    ? financeApplicationDecisions.approved
    : financeApplicationDecisions.other
}

export const formatDateForApi = (date: string) => {
  if (!date) return '00/00/0000'

  const parts = date.split('-')

  return `${parts[2]}/${parts[1]}/${parts[0]}`
}

export const continuePolling = (status) => {
  if (!status) return true

  return status.Id === 'Quote' || status.Id === 'Proposed'
}

// Maybe refactor this is isVehicleAvailable for more generic uses
export const isOCDAvailable = (
  vehicle: IVehicle | NewVehicle | UsedVehicle
) => {
  const { VehicleType, UnderOffer } = vehicle

  if (VehicleType === EVehicleType.New) {
    return !UnderOffer
  }

  return true
}
