export enum HttpStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  BannedRequest = 418,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504
}

export interface HttpResponse {
  status: number
  statusCode: HttpStatusCode
  vehicle?: boolean
}
export interface HttpErrorMessage {
  problem: string
  solution: string
}
