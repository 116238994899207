import { ECustomerTypes } from '~/types/customerType'
import { ERouteCustomerTypes } from '~/types/routes'

/**
 * Function that converts the Customer Type
 * to a customer type route parameter
 * @param value Customer Type
 * @returns Returns the Customer Type route parameter based on the
 * Customer Type
 */
export const getTypeParam = (
  value: ECustomerTypes
): ERouteCustomerTypes | null => {
  if (value === ECustomerTypes.Business) return ERouteCustomerTypes.Business
  if (value === ECustomerTypes.Motability) return ERouteCustomerTypes.Motability
  return null
}
