export enum CommCodes {
  NewEnquiry = 'WEB1709080002',
  NewMotabilityEnquiry = 'WEB2103080005',
  NewBusinessEnquiry = 'WEB2109080001',
  NewSmartEnquiry = 'WEB2006104002',
  NewSmartMotabilityEnquiry = 'WEB2103104003',
  NewSmartBusinessEnquiry = 'WEB2109104001',
  UsedEnquiry = 'WEB1807080031',
  UsedSmartEnquiry = 'WEB2010045003',

  Callback = 'WEB2108080001',
  NewSmartCallback = 'WEB2006104003',
  NewBusinessCallback = 'WEB2108080001',
  UsedSmartCallback = 'WEB2010045004',

  NewTestDrive = 'WEB2007080001',
  NewMotabilityTestDrive = 'WEB2103080007',
  NewBusinessTestDrive = 'WEB2109080003',
  NewSmartTestDrive = 'WEB2007045002',
  NewSmartMotabilityTestDrive = 'WEB2103104001',
  NewSmartBusinessTestDrive = 'WEB2109104003',
  UsedTestDrive = 'WEB2007080002',
  UsedSmartTestDrive = 'WEB2010045001',

  NewReservation = 'WEB1709080846',
  NewBusinessReservation = 'WEB2109080002',
  NewSmartReservation = 'WEB2006104001',
  NewSmartBusinessReservation = 'WEB2109104002',
  UsedReservation = 'WEB1904080001',
  UsedSmartReservation = 'WEB2010045002'
}

export enum RequestSources {
  NewEnquiry = '85',
  NewMotabilityEnquiry = '126',
  NewBusinessEnquiry = '148',
  NewSmartEnquiry = '104',
  NewSmartMotabilityEnquiry = '135',
  NewSmartBusinessEnquiry = '151',
  UsedEnquiry = '92',
  UsedSmartEnquiry = '117',

  NewCallback = '145',
  NewBusinessCallback = '154',
  NewSmartCallback = '109',
  UsedCallback = '146',
  UsedSmartCallback = '118',
  MotabilityCallback = '147',

  NewTestDrive = '107',
  NewMotabilityTestDrive = '134',
  NewBusinessTestDrive = '150',
  NewSmartTestDrive = '111',
  NewSmartMotabilityTestDrive = '136',
  NewSmartBusinessTestDrive = '153',
  UsedTestDrive = '112',
  UsedSmartTestDrive = '116'
}

export const CodeType: {
  [key in 'COMM_CODES' | 'REQUEST_SOURCE']:
    | typeof CommCodes
    | typeof RequestSources
} = {
  COMM_CODES: CommCodes,
  REQUEST_SOURCE: RequestSources
}

export enum ECodeFormType {
  ENQUIRY = 'Enquiry',
  TEST_DRIVE = 'TestDrive'
}
