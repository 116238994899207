import validate from "/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45token_45global from "/build/middleware/checkToken.global.ts";
import csref_45global from "/build/middleware/csref.global.ts";
import customer_45type_45global from "/build/middleware/customerType.global.ts";
import os_45route_45global from "/build/middleware/osRoute.global.ts";
import retailer_45mode_45global from "/build/middleware/retailerMode.global.ts";
import manifest_45route_45rule from "/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  check_45token_45global,
  csref_45global,
  customer_45type_45global,
  os_45route_45global,
  retailer_45mode_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  condition: () => import("/build/middleware/condition.ts"),
  "my-account-logged-in": () => import("/build/middleware/myAccountLoggedIn.ts")
}