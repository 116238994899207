import { OsResponse } from '~/types/api'

export * from './profile/vehicles'
export * from './vehicles/search'
export * from './callback'
export * from './filters'
export * from './promoOffers'

export const proxyMethod = <TargetArgs extends any[]>(targetMethod: {
  default: (...args: TargetArgs) => any
  server: (...args: TargetArgs) => any
}) =>
  new Proxy(() => targetMethod, {
    apply: function (target, thisArg, argumentsList: TargetArgs) {
      return target().default!(...argumentsList)
    },
    get(target, prop: 'default' | 'server') {
      if (target()[prop]) {
        return target()[prop]
      }
    }
  })

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled'

export const transformSettlePromises = <T>(
  response: PromiseSettledResult<OsResponse>[]
): OsResponse<T>[] => {
  return response.map((res) => {
    if (isFulfilled(res)) return res.value

    return {
      data: null,
      error: {
        data: res.reason
      }
    }
  })
}

export const getFulfilledPromises = <T>(
  response: PromiseSettledResult<OsResponse<T>>[]
): OsResponse<T>[] => {
  const fulfilledPromises: OsResponse<T>[] = []

  response.forEach((res) => {
    if (isFulfilled(res)) {
      fulfilledPromises.push(res.value)
    }
  })

  return fulfilledPromises
}
