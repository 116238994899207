<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6.08 2.36V.84h5.743v1.52H6.079zM16 11.502h-3.748l-.84 1.113h-5.09l-1.679-2.318H3.04V5.439h1.64l1.4-1.614h5.78l1.958 2.281H16v5.396zM1.548 5.44v4.858H0V5.439h1.548z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Engine'
}
</script>
