<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 8">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M23.674 4.138l-.204-1.73s-.908-1.02-1.786-1.7l.205-.093-.058-.154c-5.502-.464-9.95-.65-11.647-.217C9.92.337 9.16.77 6.848 2.376c0 0-4.01.248-5.473.866l-.614.247-.615.958.059.648L0 5.374S.117 6.61.117 6.579l1.697.309h.059a2.095 2.095 0 0 1-.117-.618c0-1.144.878-2.07 1.96-2.07 1.083 0 1.961.926 1.961 2.07 0 .216-.03.463-.117.649h11.354c-.058-.217-.117-.433-.117-.65 0-1.143.878-2.07 1.961-2.07 1.083 0 1.96.927 1.96 2.07 0 .155 0 .248-.029.402h.088l2.663-.34.44-.772.116-1.082c.03-.03-.117-.154-.322-.34zm-22.21.401l-.879.371s.205-.649.615-.927c.234-.154 1.024-.34 1.024-.34l-.76.896zm17.733-1.792c-5.033.124-8.457.216-11.91.525-1.083.093-3.541.217-3.922.31.234-.093 1.522-.464 3.805-.619C8.633 1.79 10.008.893 10.418.77c.41-.124 1.463-.217 2.721-.217l-.204 2.07c.263 0 .526-.03.79-.03L13.93.584c.877-.062 3.628.124 3.628.124l.644 1.792h.556L18.114.77c.703.061 1.698.185 2.488.463 1.141.433 1.931 1.36 1.931 1.36s-1.083.092-3.336.154zm-.41 1.854c-.878 0-1.61.742-1.61 1.7 0 .927.703 1.699 1.61 1.699.878 0 1.61-.742 1.61-1.7-.03-.957-.732-1.699-1.61-1.699zm0 2.75c-.585 0-1.024-.494-1.024-1.081 0-.618.468-1.082 1.024-1.082.586 0 1.025.495 1.025 1.082 0 .587-.498 1.081-1.025 1.081zm0-1.823c.41 0 .732.34.732.773 0 .432-.322.772-.732.772s-.731-.34-.731-.772c0-.433.322-.773.731-.773zM3.746 4.601c-.878 0-1.61.742-1.61 1.7C2.136 7.228 2.84 8 3.746 8c.878 0 1.61-.742 1.61-1.7-.03-.957-.732-1.699-1.61-1.699zm0 2.75c-.586 0-1.024-.494-1.024-1.081 0-.618.468-1.082 1.024-1.082.585 0 1.024.495 1.024 1.082 0 .587-.497 1.081-1.024 1.081zm0-1.823c.41 0 .731.34.731.773 0 .432-.322.772-.731.772-.41 0-.732-.34-.732-.772 0-.433.322-.773.732-.773z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Estate'
}
</script>
