<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 19">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M16 14.42H8V7.333H4.295V0h11.682L16 14.42zM6.614 2.617v1.24h7.068v-1.24H6.614zm0 2.346v1.241h7.068v-1.24H6.614zm2.772 2.37v1.24h4.296v-1.24H9.386zM6.523 8.755v.18c-1.5.52-1.75 1.896-1.75 3.792v1.737h2.182L3.477 19 0 14.464h2.068V13.02c0-2.775 2.182-4.265 4.455-4.265zm2.863.926v1.24h2.319v-1.24H9.386z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Download'
}
</script>
