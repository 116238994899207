import type { RouteLocationNamedRaw, RouteParamsRaw } from 'vue-router'
import { IKioskQuery } from './cookies'
import type { IBaseCriteria } from '~/types/filters'

export enum ERoutes {
  ModelHomepage = 'ModelHomepage',
  Homepage = 'homepage',
  Results = 'results',
  Vehicle = 'vehicle',
  Models = 'models',
  Offer = 'offer',
  OfferThankYou = 'offer-thank-you',
  StockNotification = 'stock-notification',
  Compare = 'compare',
  CookiePolicy = 'cookie-policy',
  Page = 'page-slug',
  FAQ = 'page-faq',
  Healthcheck = 'healthcheck',
  MyAccount = 'myaccount',
  MyAccountCallback = 'myaccount-callback',
  MyAccountLogout = 'logout',
  MyAccountOrders = 'myaccount-orders',
  MyAccountOrder = 'myaccount-orders-id',
  MyAccountBackOrders = 'myaccount-backorders',
  MyAccountEnquiries = 'myaccount-enquiries',
  MyAccountRecentlyViewed = 'myaccount-recently-viewed',
  MyAccountSavedSearch = 'myaccount-saved-search',
  MyAccountSettings = 'myaccount-settings',
  MyAccountShortlist = 'myaccount-shortlist',
  MyAccountTestDrives = 'myaccount-test-drives',
  MyAccountValuations = 'myaccount-valuations',
  MyAccountRetailerSession = 'myaccount-retailer-session',
  Design = 'design'
}

export enum ERouteConditions {
  New = 'new',
  Used = 'used'
}

export enum ERouteCustomerTypes {
  Business = 'business',
  Motability = 'motability'
}

export enum ERoutePageSlugs {
  FAQ = 'faq',
  MBC = 'mercedes-benz-cars-uk-ltd',
  MSA = 'modern-slavery-act',
  PrivacyPolicy = 'privacy-policy',
  LegalNotice = 'legal-notice'
}

interface HomepageRouteParams extends RouteParamsRaw {
  condition: ERouteConditions
  type: ERouteCustomerTypes | undefined | null
}

interface ResultsRouteParams extends RouteParamsRaw {
  condition: ERouteConditions
  type: ERouteCustomerTypes | undefined | null
}

interface PageRouteParams extends RouteParamsRaw {
  slug: string
}

interface VehicleRouteParams extends RouteParamsRaw {
  condition: ERouteConditions
  type: ERouteCustomerTypes | undefined | null
  id: string | number
  model: string
  retailerId: string
}

interface IVehicleQuery extends IKioskQuery {
  RetailerGroup?: string
  Retailer?: number
}

export type HomepageRoute = RouteLocationNamedRaw & {
  name: ERoutes.Homepage
  params: HomepageRouteParams
}

export type ResultsRoute = RouteLocationNamedRaw & {
  name: ERoutes.Results
  params: ResultsRouteParams
  query?: IBaseCriteria
}

export type PageRoute = RouteLocationNamedRaw & {
  name: ERoutes.Page
  params: PageRouteParams
}

export type VehicleRoute = RouteLocationNamedRaw & {
  name: ERoutes.Vehicle
  params: VehicleRouteParams
  query?: IVehicleQuery
}
