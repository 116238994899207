import {
  EVehicleType,
  IVehicle,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'

/**
 * Check if the response for mandatory fields
 */
function isValidResponse(response: any): [boolean, string?] {
  if (typeof response.Vin !== 'string') return [false, 'Vin should be a string']
  if (typeof response.Description !== 'string')
    return [false, 'Description should be a string']
  if (!['Electric', 'Hybrid', 'Petrol', 'Diesel'].includes(response.FuelType))
    return [false, 'FuelType should be a valid fuel type']
  if (typeof response.Retailer !== 'object')
    return [false, 'Retailer should be an object']
  if (typeof response.Media !== 'object')
    return [false, 'Media should be an object']
  if (typeof response.VehicleType !== 'string')
    return [false, 'VehicleType should NEW or USED']

  const isUsed = response.VehicleType === EVehicleType.Used
  const isNew = response.VehicleType === EVehicleType.New

  if (isUsed) {
    if (
      typeof response.RetailPrice !== 'number' &&
      typeof response.RetailPrice !== 'string'
    )
      return [false, 'RetailPrice should be a number or string']
  } else if (isNew) {
    if (typeof response.OTR !== 'number')
      return [false, 'OTR should be a number']
    if (typeof response.DeliveryTime !== 'number')
      return [false, 'DeliveryTime should be a number']
  }

  return [true]
}

export function isNewVehicle(
  vehicle?: NewVehicle | UsedVehicle | IVehicle | null
): vehicle is NewVehicle {
  return !!vehicle && vehicle.VehicleType === EVehicleType.New
}

export function isUsedVehicle(
  vehicle: NewVehicle | UsedVehicle | IVehicle
): vehicle is UsedVehicle {
  return vehicle.VehicleType === EVehicleType.Used
}

export default isValidResponse
