<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.219 5.969L8.329 3.14l1.28-1.25 1.61 1.562L14.734 0l1.297 1.266-4.343 4.25-.47.453zm4.297 2.937L16 10.891V16h-2.031v-1H2.03v1H0v-5.11l.516-1.906L3.53 5H8.75l1.016 1H4.109L3 8h10.047l-.828-1.64.156-.235.64-.625 2.5 3.406zM3.984 11.875c.094-.083.102-.195.024-.336a2.074 2.074 0 0 0-.352-.445 6.997 6.997 0 0 0-.5-.453c-.177-.146-.317-.26-.422-.344a1.058 1.058 0 0 0-.687-.266 1 1 0 0 0-.367.07c-.12.047-.232.113-.336.196a.79.79 0 0 0-.21.938.833.833 0 0 0 .21.28c.104.084.281.167.531.25.25.084.516.146.797.188.281.042.547.063.797.063s.422-.047.515-.141zm6.954.656c.229-.364.382-.672.46-.922.079-.25.12-.453.125-.609.006-.156-.007-.268-.039-.336l-.046-.101H4.53l-.047.101c-.03.068-.041.18-.03.336.01.156.054.36.132.61.078.25.226.557.445.921h5.907zm3.828-1.015a.79.79 0 0 0 .21-.938.833.833 0 0 0-.21-.281 1.286 1.286 0 0 0-.336-.195 1 1 0 0 0-.735 0c-.12.046-.231.112-.336.195-.093.083-.229.198-.406.344a6.997 6.997 0 0 0-.5.453 2.075 2.075 0 0 0-.351.445c-.079.14-.07.253.023.336.094.094.266.14.516.14s.515-.02.796-.062c.282-.042.547-.104.797-.187.25-.084.427-.167.532-.25zM0 7h1v.969H0V7zm15 0h1v.969h-1V7z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Condition'
}
</script>
