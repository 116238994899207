import { ITitle, IDataProtection } from '~/types/forms'

export const errorMessages = {
  reloadFormError:
    'There appears to be something wrong with our system, we are looking into it. Please try again.'
}

export const defaultTitle: ITitle = { text: 'Title', id: 0 }

export const titles: ITitle[] = [
  { text: 'Mr', id: 1 },
  { text: 'Mrs', id: 2 },
  { text: 'Miss', id: 3 },
  { text: 'Ms', id: 4 },
  { text: 'Dr', id: 5 },
  { text: 'Lady', id: 6 },
  { text: 'Lord', id: 7 },
  { text: 'Professor', id: 8 },
  { text: 'Sir', id: 9 }
]

export const dataProtection: IDataProtection = {
  personalData:
    "You have the right to request a copy of the personal data we hold on you and to request that we rectify or erase (where lawfully permitted) this data at any time. You may also exercise your right of data portability (should you wish to transfer your data to a third party), as well as the right to submit a complaint to us or to the Information Commissioner's Office at any time.",
  personalInformation:
    'Mercedes-Benz UK Ltd take your privacy seriously. We will only use your personal information to provide the products and services you have requested from us or to administer your account. We may need to share your details with third parties in order to provide our services to you. Our full Privacy Policy is published on our UK website http://www.mercedes-benz.co.uk/Privacy and is also available on request.',
  keepYouInformed:
    'We would like to keep you updated on products and services from the Mercedes-Benz brand. Our brand is supported by Mercedes-Benz AG group of companies in the UK, consisting of the respective national sales companies, financial services and insurance companies, as well as our network of authorised dealerships with which your details may be shared. Further details are available in our Privacy Policy (as detailed above).',
  marketingPreferences:
    'If you change your mind about your marketing preferences or would like us to update/amend any of your personal data, please contact us via one of the following:'
}

export const liveChatMessages = {
  mercedesBenz:
    "This car is owned by Mercedes-Benz UK and sold by a Retailer of your choice. Enter your details and we'll get in touch, or click below to chat to an advisor.",
  smart:
    "This car is owned by smart UK and sold by a Showroom of your choice. Enter your details and we'll get in touch, or click below to chat to an advisor.",
  mercedesBenzOffline:
    "This car is owned by Mercedes-Benz UK and sold by a Retailer of your choice. Enter your details and we'll get in touch.",
  smartOffline:
    "This car is owned by smart UK and sold by a Showroom of your choice. Enter your details and we'll get in touch."
}

export const whatHappensNextMotability: string[] = [
  'When you visit the Showroom for your new Mercedes-Benz, you will need to bring the following documents:' +
    '\n\nBoth parts of your driving licence if you are going to drive' +
    '\n\nCertificate of entitlement from either the Department for Work and Pensions (DWP) or the Service Personnel and Veterans Agency (SPVA)' +
    '\n\nProof of address such as a recent utility bill or bank statement' +
    "\n\nNominated drivers' driving licences and consent for their details to be checked by the DVLA" +
    '\n\nDetails of any previous accidents and driving convictions that you, or your nominated driver holds'
]
