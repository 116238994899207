<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5.595 12.055L0 2.367v-.131c0-.417.154-1.162 1.295-1.82C1.755.153 2.216 0 2.655 0c.548 0 .834.241.856.241l5.617 9.666c-.615.066-1.229.307-1.887.701-.68.439-1.229.92-1.646 1.447zm4.937 3.55l-.68.395-3.38-2.433c.067-.372 1.47-2.301 3.116-2.214.088 0 .22.022.395.044l.044.066.505 4.142z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Pen'
}
</script>
