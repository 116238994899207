/**
 * Normalises a value to a string with 2 decimal places.
 */
function normaliseValue(value: string | number): string {
  const string = String(value).replace(/,/g, '')
  const float = parseFloat(string)

  return isNaN(float) ? '0.00' : float.toFixed(2)
}

export const formatGBP = (value) => `£${formatOTRPrice(value)}`

export function formatOTRPrice(value?: number): string {
  if (value === 0) {
    return '0.00'
  }

  if (!value) {
    return '-'
  }

  return formatWithCommas(Number(value).toFixed(2))
}

export function formatRetailPrice(value: number | string): string {
  if (!value) return ''

  if (isNaN(value as number)) {
    return value as string
  }

  return formatGBP(value)
}

export function removePrefix(value: string | number): string {
  if (!value) {
    return ''
  }

  return formatWithCommas(value).replace('£', '')
}

/**
 * Formats a given value with thousand separators.
 */
export function formatWithCommas(value: number | string): string {
  if (!value) {
    return '0'
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Formats a given value to 2 decimal places.
 */
export function formatWithDecimals(value: string | number | null): string {
  if (!value) return '0.00'

  return normaliseValue(value)
}

/**
 * Formats a given value with thousand separators & decimals.
 */
export function formatWithCommasAndDecimals(value: number | string): string {
  if (value === '') return ''

  const normal = normaliseValue(value)

  return formatWithCommas(normal)
}
