<template>
  <svg viewBox="0 0 16 24">
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <g transform="translate(-852.000000, -895.000000)" fill="currentColor">
        <g transform="translate(848.000000, 895.000000)">
          <path
            d="M15.1789474,6.31578947 C15.1789474,7.45263158 14.8947368,8.49473684 14.3263158,9.47368421 L20.3263158,19.8 C20.6736842,20.4 20.8631579,21 20.8631579,21.6 C20.8631579,22.7684211 19.9789474,23.7789474 18.6210526,23.7789474 C17.4526316,23.7789474 16.8526316,23.3368421 16.2526316,22.2 L10.3157895,11.8736842 C8.23157895,11.8736842 5.32631579,10.5789474 4.69473684,7.35789474 L4,3.88421053 L4.91578947,3.34736842 L6.74736842,6.47368421 C7.28421053,7.42105263 8.2,7.89473684 9.17894737,7.89473684 C10.8210526,7.89473684 12.0210526,6.53684211 12.0210526,5.02105263 C12.0210526,4.48421053 11.9263158,4.07368421 11.6736842,3.69473684 L9.84210526,0.568421053 L10.8210526,0 L13.4105263,2.33684211 C14.6105263,3.41052632 15.1789474,4.73684211 15.1789474,6.31578947 L15.1789474,6.31578947 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Spanner'
}
</script>
