<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.636 16L.759 4.03H0V2.027h4.575L4.883 0h4.315l.308 2.027h4.575V4.03h-.758L12.445 16H1.635zm8.96-1.98l.735-9.966H2.75l.735 9.967h7.111zM6.282 5.509v7.058H4.954V5.508h1.328zm2.94 0v7.058H7.893V5.508h1.327z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Delete'
}
</script>
