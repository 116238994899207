export enum ECustomerTypes {
  Private = 'Private',
  Business = 'Business',
  Motability = 'Motability'
}

export interface ICustomerTypeStore {
  isMotability: boolean
  isBusiness: boolean
}
