<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5.185 0v13.715l3.673-2.72v1.316l-4.442 3.746L0 12.337V11.02l3.666 2.71V0h1.52zm5.375 6.419v1.515H8.282V6.42h2.278zm3.037-3.21v1.516H8.282V3.209h5.315zM16 0v1.515H8.282V0H16z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Sort'
}
</script>
