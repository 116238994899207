<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M10.8659046,12.957537 L9.48050602,13.5279378 L9.04593812,16 L6.95430575,16 L6.51973784,13.5279378 L5.13433928,12.957537 L3.06977595,14.4243865 L1.60292638,12.9302241 L3.0427069,10.8656607 L2.47230605,9.48026216 L0,9.07300716 L0,6.95406188 L2.47206219,6.51949398 L3.04246304,5.13409541 L1.60268252,3.09684499 L3.06953208,1.60268252 L5.13409541,3.04246304 L6.51949398,2.47206219 L6.95406188,0 L9.04569425,0 L9.48026216,2.47206219 L10.8656607,3.04246304 L12.9029111,1.60268252 L14.3970736,3.09684499 L12.9572931,5.13409541 L13.5276939,6.51949398 L15.9997561,6.95406188 L15.9997561,9.04569425 L13.5276939,9.48026216 L12.9572931,10.8656607 L14.3970736,12.9302241 L12.9029111,14.3970736 L10.8659046,12.957537 L10.8659046,12.957537 Z M11.1919524,8.09510745 C11.1919524,7.2530407 10.8932175,6.54680689 10.2684347,5.94909313 C9.67072093,5.32431032 8.96448712,5.02557537 8.12242036,5.02557537 C7.2803536,5.02557537 6.5741198,5.32431032 5.94933699,5.94909313 C5.35162323,6.54680689 5.05288828,7.2530407 5.05288828,8.09510745 C5.05288828,8.93717421 5.35162323,9.64340802 5.94933699,10.2681908 C6.5741198,10.8659046 7.2803536,11.1646395 8.12242036,11.1646395 C8.96448712,11.1646395 9.67072093,10.8659046 10.2684347,10.2681908 C10.8929736,9.64340802 11.1919524,8.93717421 11.1919524,8.09510745 L11.1919524,8.09510745 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Settings'
}
</script>
