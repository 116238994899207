import { defineStore } from 'pinia'
import { useFilterDataStore } from '../filterDataStore'
import {
  ICustomerCriteria,
  ICustomerCriteriaFilters
} from '~/types/forms/stockNotification'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { IStep } from '~/types/steps'
import { IStockNotificationSelectionDataLayer } from '~/types/googleDataLayer'
import { NStockNotifications } from '~/types/api'
import { ECondition } from '~/types/vehicle'
import { IFilterCollection } from '~/types/filters'

interface IEquipmentItem {
  Id: number
  Description: string
  image: string
}

export const equipmentData: IEquipmentItem[] = [
  {
    Id: 1,
    Description: '360 Camera',
    image: '360-camera.jpg'
  },
  {
    Id: 2,
    Description: 'Burmester speakers',
    image: 'Burmaster-speakers.jpg'
  },
  {
    Id: 3,
    Description: 'Electric or memory seats',
    image: 'Electric-memory-seats.jpg'
  },
  {
    Id: 4,
    Description: 'Heated seats',
    image: 'heated-seats.jpg'
  },
  {
    Id: 5,
    Description: 'Reversing camera',
    image: 'Reserving-camera.jpg'
  },
  {
    Id: 6,
    Description: 'Sunroof',
    image: 'sunroof.jpg'
  }
]

export enum EStockNotificationSteps {
  'Login' = 'Login',
  'Models' = 'Models',
  'FuelType' = 'Fuel type',
  'Colour' = 'Colour',
  'Equipment' = 'Equipment',
  'Retailer' = 'Showroom',
  'AboutYou' = 'About you',
  'Summary' = 'Summary',
  'ThankYou' = 'Thank you'
}

export const steps: IStep<EStockNotificationSteps>[] = [
  {
    label: EStockNotificationSteps.Login,
    title: 'Requesting a stock notification',
    intro: ''
  },
  {
    label: EStockNotificationSteps.Models,
    title: 'Models',
    intro: ''
  },
  {
    label: EStockNotificationSteps.FuelType,
    title: 'My preferred fuel types are...',
    intro: ''
  },
  {
    label: EStockNotificationSteps.Colour,
    title: 'My preferred colours are...',
    intro: ''
  },
  {
    label: EStockNotificationSteps.Equipment,
    title: "I'd like my car to have...",
    intro: ''
  },
  {
    label: EStockNotificationSteps.Retailer,
    title: 'Preferred showroom',
    intro: ''
  },
  {
    label: EStockNotificationSteps.AboutYou,
    title: 'Tell us about you',
    intro: ''
  },
  {
    label: EStockNotificationSteps.Summary,
    title: 'Summary',
    intro: ''
  },
  {
    label: EStockNotificationSteps.ThankYou,
    title: 'Thank you',
    intro: ''
  }
]

type TSelections = {
  models: number[]
  fuels: number[]
  colours: number[]
  equipment: number[]
}

interface IStockNotificationStoreState {
  preSelection: TSelections
  interests: TSelections
}

export const useStockNotificationStore = defineStore('stockNotification', {
  state: (): IStockNotificationStoreState => ({
    preSelection: {
      models: [],
      fuels: [],
      colours: [],
      equipment: []
    },
    interests: {
      models: [],
      fuels: [],
      colours: [],
      equipment: []
    }
  }),
  getters: {
    getSteps(state: IStockNotificationStoreState): IStep[] {
      const isPreSelected = {
        [EStockNotificationSteps.Models]: state.preSelection.models.length > 0,
        [EStockNotificationSteps.FuelType]: state.preSelection.fuels.length > 0,
        [EStockNotificationSteps.Colour]: state.preSelection.colours.length > 0
      }

      return steps.filter(({ label }) => {
        if (Object.keys(isPreSelected).includes(label))
          return !isPreSelected[label]

        return true
      })
    },
    /**
     * If the first model selected is a smart model we will return true
     * otherwise it will be false and deefault to MB
     */
    isSmart(state: IStockNotificationStoreState): boolean {
      const selectedModels = state.preSelection.models.concat(
        state.interests.models
      )
      const filterDataStore = useFilterDataStore()
      const smartModelIds = filterDataStore.getSmartModelIds

      return smartModelIds?.includes(selectedModels[0])
    },
    /**
     * Returns the Customer Criteria object
     */
    getCriteria(state: IStockNotificationStoreState): ICustomerCriteria {
      const filterDataStore = useFilterDataStore()
      const data = filterDataStore.getFilterDataNew
      const filtersStore = useFiltersStore()
      const selected = useCloneDeep(filtersStore.active)
      const filters = {
        ...selected,
        models: state.preSelection.models.concat(state.interests.models),
        fuels: state.preSelection.fuels.concat(state.interests.fuels),
        colours: state.preSelection.colours.concat(state.interests.colours)
      }

      const criteria: ICustomerCriteriaFilters = getCustomerCritera(
        filters,
        data
      )

      return {
        ...criteria,
        Equipment: this.equipmentDescriptions.concat(filters.keywords),
        UrlReferrer: this.getUrl,
        PreferredDeliveryDate: '' // TODO: Ask CODE what this is
      }
    },
    /**
     * Returns an object containg selections made in this form with dataLayer structure.
     */
    // old selectionsMade
    selectionsMadeDataLayer(): IStockNotificationSelectionDataLayer {
      const { Model, ColourGroup, Fuel, Equipment } = this.getCriteria
      const modelDescriptions = Model.map(({ Description }) => Description)

      return {
        vehicle_name: modelDescriptions.join(',') || '',
        fuel_type: Fuel.join(',') || '',
        colour: ColourGroup.join(',') || '',
        equipment: Equipment?.join(',') || ''
      }
    },
    /**
     * Returns a descriptions list for selected equipment
     */
    equipmentDescriptions(state: IStockNotificationStoreState): string[] {
      return equipmentData
        .filter(({ Id }) => state.interests.equipment.includes(Id))
        .map((f) => f.Description)
    },
    /**
     * Returns the url for the given filters.
     */
    getUrl(state: IStockNotificationStoreState): string {
      const route = useRoute()
      const filtersStore = useFiltersStore()
      const selected = useCloneDeep(filtersStore.active)
      const filters = {
        ...selected,
        keywords: selected.keywords.concat(this.equipmentDescriptions),
        models: state.preSelection.models.concat(state.interests.models),
        fuels: state.preSelection.fuels.concat(state.interests.fuels),
        colours: state.preSelection.colours.concat(state.interests.colours)
      }
      const filterDataStore = useFilterDataStore()

      const query = filterCollection.getQueryParams(filters, {
        New: filterDataStore.getFilterDataNew || {},
        Used: {}
      }).asString

      const routePath = route?.path || ''
      const querystring = query.length ? `?${query}` : ''
      return routePath + querystring
    },
    /**
     * Returns descriptions list of combined
     * filters & stock notification selections
     */
    getTags(state: IStockNotificationStoreState): string[] {
      const filtersStore = useFiltersStore()
      const filterDataStore = useFilterDataStore()

      const selected = useCloneDeep(filtersStore.active)

      const filters = {
        ...selected,
        condition: ECondition.New,
        keywords: selected.keywords.concat(this.equipmentDescriptions),
        models: state.preSelection.models.concat(state.interests.models),
        fuels: state.preSelection.fuels.concat(state.interests.fuels),
        colours: state.preSelection.colours.concat(state.interests.colours)
      }

      const filterCollectionTags = tags.fromFilterCollection(filters, {
        New: filterDataStore.getFilterDataNew,
        Used: {}
      })

      return filterCollectionTags
    },

    /**
     * Returns the final submission payload.
     */
    getPayload(): NStockNotifications.create.Payload {
      const { getCustomerPayload, customerDetails } = useFormsStore()
      const { retailer } = useFiltersStore()

      return {
        Source: 'OnlineShowroom',
        TagUrl: getTagUrl() || '',
        GssnId: retailer?.GssnId || '',
        AdditionalInformation: customerDetails.additionalInfo || '',
        Customer: getCustomerPayload,
        CustomerCriteria: this.getCriteria,
        GasId: ''
      }
    }
  },

  actions: {
    updatePreselectionWithFilters(collection?: Partial<IFilterCollection>) {
      const { active } = useFiltersStore()

      const filters = collection || active

      this.setPreSelection({
        models: filters.models || [],
        fuels: filters.fuels || [],
        colours: filters.colours || []
      })
    },

    setPreSelection(payload: Partial<TSelections>) {
      this.preSelection = {
        models: [],
        fuels: [],
        colours: [],
        equipment: [],
        ...payload
      }
    },

    setInterests(data: Partial<TSelections>) {
      this.interests = { ...this.interests, ...data }
    },

    reset() {
      this.$reset()
    },

    submit() {
      return this.api.myAccount.stockNotifications.create(this.getPayload)
    }
  },
  hasNavigation: true
})
