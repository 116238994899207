export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', (..._args) => {
    if (import.meta.server) {
      // Server side
      process?.stdout?.write(`vue:error ${JSON.stringify(_args)} \n`)
      return
    }

    // Client side
    console.error('vue:error', _args)
  })

  nuxtApp.hook('app:error', (..._args) => {
    if (import.meta.server) {
      // Server side
      process?.stdout?.write(`app:error ${JSON.stringify(_args)} \n`)
      return
    }

    // Client side
    console.error('app:error', _args)
  })

  nuxtApp.vueApp.config.errorHandler = (..._args) => {
    if (import.meta.server) {
      // Server side
      process?.stdout?.write(`global error handler ${JSON.stringify(_args)} \n`)
      return
    }

    // Client side
    console.error('global error handler', _args)
  }
})
