<template>
  <span
    v-if="messengerStore.isLoaded"
    :class="[
      'chatButton',
      messengerStore.notificationsNumber > 0 && 'chatButton--hasNotifications',
      $slots.default && 'chatButton--hasText',
      !messengerStore.isOnline && 'chatButton--disabled'
    ]"
    @click="toggle"
  >
    <OsIcon
      v-if="!textOnly"
      name="Chat"
      :fill="colour || iconFill(true)"
      :badge="messengerStore.notificationsNumber"
      class="mr-2"
    />
    <slot />
  </span>
  <span v-else class="chatButton--disabled">
    <slot />
  </span>
</template>

<script lang="ts">
import { useMessengerStore } from '~/stores/messengerStore'

export default {
  props: {
    colour: {
      type: String,
      required: false,
      default: undefined
    },
    textOnly: {
      type: Boolean,
      default: false
    },
    gaCategory: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const messengerStore = useMessengerStore()

    const toggle = () => {
      $dataLayer.linkClick({
        category: props.gaCategory,
        action: 'chat'
      })
      messengerStore.isShown
        ? messengerStore.hideMessenger()
        : messengerStore.showMessenger()
    }

    return {
      messengerStore,
      toggle
    }
  }
}
</script>

<style lang="scss" scoped>
.chatButton {
  display: flex;
  align-items: center;
  font-size: rem(14);
  font-family: $mb-font-text-bold;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.chatButton--disabled {
  cursor: auto;
  color: $grey--darkest;
}

.chatButton--hasNotifications {
  &.chatButton--hasText {
    svg {
      margin-right: rem(8);
    }
  }
}
</style>
