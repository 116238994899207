<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.765 15.347c-.082-.035-.153-.054-.21-.092-.836-.552-1.67-1.106-2.505-1.661-.2-.134-.228-.332-.073-.513 1.01-1.183 1.372-2.574 1.325-4.093a6.503 6.503 0 0 0-.108-1.011A5.662 5.662 0 0 0 1.59 6.29C1.246 5.662.79 5.134.183 4.739-.029 4.6-.057 4.413.1 4.217a956.8 956.8 0 0 1 1.806-2.243c.125-.154.247-.171.455-.099 1.078.37 2.093.258 3.022-.418.376-.274.648-.775.832-1.2C6.295.073 6.42 0 6.621 0c.92.006 1.84.002 2.759.003.235 0 .301.056.405.274.417.879 1.123 1.543 2.075 1.738a3.157 3.157 0 0 0 1.811-.149c.177-.068.322-.027.433.11l1.75 2.184c.22.273.195.42-.098.62-.84.576-1.393 1.371-1.705 2.32a7.725 7.725 0 0 0-.235.877c-.302 1.476-.117 2.921.647 4.313.15.275.355.523.548.773.16.21.143.395-.078.542l-2.47 1.64c-.167.109-.297.112-.432 0-.599-.492-1.187-1.096-1.935-1.034a2.594 2.594 0 0 0-.954.259c-.311.155-.686 1.04-.86 1.346-.133.23-.433.25-.56.028-.31-.532-.62-1.444-1.198-1.563-.596-.123-1.19-.103-1.757.128-.213.087-.57.699-.77.815-.075.043-.153.081-.23.122l-.002.001z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Upholstery'
}
</script>
