export enum EConsents {
  AWS = 'Amazon Web Services',
  CIAM = 'CIAM',
  Contentful = 'Contentful CDN',
  FBPixel = 'Facebook Pixel',
  Flashtalking = 'Flashtalking',
  GA = 'Google Analytics',
  GMP = 'Google Marketing Products',
  GTM = 'Google Tag Manager',
  MMLogin = 'Mercedes Me Login',
  ProfileCookie = 'Profile ID Cookie',
  Salesforce = 'Salesforce Audience Studio',
  TradeDesk = 'TradeDesk',
  UCMP = 'Usercentrics Consent Management Platform',
  Userlike = 'Userlike',
  Optimizely = 'Optimizely'
}

export interface IConsentHistory {
  status: boolean
  timestamp: Date
}

export interface IConsent {
  status: boolean
  history: IConsentHistory[]
}
