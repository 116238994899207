<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 9">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M20.25 6.473c.472 0 .833.357.833.824a.814.814 0 0 1-.833.824.814.814 0 0 1-.833-.824c0-.44.36-.824.833-.824zm1.722.824c0 .934-.75 1.703-1.722 1.703a1.704 1.704 0 1 1 0-3.406c.944.027 1.722.769 1.722 1.703zm-.583 0c0-.632-.5-1.126-1.139-1.126a1.12 1.12 0 0 0-1.139 1.126c0 .632.5 1.126 1.139 1.126a1.12 1.12 0 0 0 1.139-1.126zm-18.417 0c0 .467.361.824.834.824a.814.814 0 0 0 .833-.824.814.814 0 0 0-.833-.824.831.831 0 0 0-.834.824zm2.556 0C5.528 8.231 4.778 9 3.806 9a1.704 1.704 0 1 1 0-3.406c.916.027 1.722.769 1.722 1.703zm-.584 0c0-.632-.5-1.126-1.138-1.126a1.12 1.12 0 0 0-1.14 1.126c0 .632.5 1.126 1.14 1.126a1.12 1.12 0 0 0 1.138-1.126zM24 6.803l-.111.604-1.528.302c.028-.137.028-.275.028-.385 0-.521-.195-.988-.5-1.373l-.056-.055a2.091 2.091 0 0 0-1.583-.714c-.611 0-1.194.275-1.556.687a2.05 2.05 0 0 0-.555 1.428c0 .192.028.385.083.577-.75 0-10.472.192-12.416.192.083-.247.138-.494.138-.742A2.128 2.128 0 0 0 3.806 5.21c-1.167 0-2.14.934-2.14 2.115 0 .275.056.522.14.77L.11 7.736 0 6.253l.306-.357-.084-.577s.306-.961.39-1.071c.721-.44 4-1.319 4-1.319 2.61-1.895 2.61-1.812 4.166-2.527 1.36-.522 13.639-.522 14-.082 1.028 1.428.972 5.878.972 5.878l.25.605zM2.306 4.138l-1.362.55-.305.933 1.528-.934.139-.549zM22.5 2.902s-.139-1.181-1.111-1.978c-.333-.192-2-.33-3.639-.357l.25 2.17h-.5L17.25.595C14.917.567 12.278.65 12 .65l.056 2.334c-.334.028-.778.028-1.112.055L11 .65S9.722.678 8.917.953C8.11 1.309 6.389 2.188 5 3.396c-2.083.522-2.278.577-2.278.577C6.75 3.451 18.944 2.875 22.5 2.902z"
    />
  </svg>
</template>

<script>
export default {
  name: 'MPV'
}
</script>
