const _transparent = "transparent"
const _black = "#000"
const _white = "#fff"
const _stone = {"50":"#f6f6f6","100":"#e7e7e7","200":"#d1d1d1","300":"#b0b0b0","400":"#888888","500":"#6d6d6d","600":"#5d5d5d","700":"#4f4f4f","800":"#454545","900":"#3d3d3d","950":"#151515"}
const _blue = {"50":"#f0f8ff","100":"#e0effe","200":"#b9dffe","300":"#7cc6fd","400":"#36abfa","500":"#0c90eb","600":"#0078d6","700":"#0159a3","800":"#064c86","900":"#0b406f","950":"#07284a"}
const _red = {"50":"#fef2f2","100":"#ffe1e1","200":"#ffc9ca","300":"#fea3a4","400":"#fb6e70","500":"#f34042","600":"#e1292b","700":"#bc191b","800":"#9c1819","900":"#811b1c","950":"#46090a"}
const _green = {"50":"#f1fcf5","100":"#defaea","200":"#bef4d4","300":"#8beab3","400":"#52d689","500":"#2ecc71","600":"#1d9c53","700":"#1b7a43","800":"#1a6139","900":"#175031","950":"#072c18"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const config = { "transparent": _transparent, "black": _black, "white": _white, "stone": _stone, "blue": _blue, "red": _red, "green": _green, "primary": _primary, "gray": _gray,  }
export { config as default, _transparent, _black, _white, _stone, _blue, _red, _green, _primary, _gray }