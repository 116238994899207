<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.707-7.415v3.122h1.561V3.902h-1.56v3.122h-1.562V3.902h-1.56v3.122H7.023V3.902h-1.56v3.122H3.901V3.902h-1.56v4.683h3.121v3.122h1.561V8.585h1.561v3.122h1.561V8.585h1.561z"
    />
  </svg>
</template>
<script>
export default {
  name: 'Manual'
}
</script>
