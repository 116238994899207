import mitt from 'mitt'
import { Callout } from '~/components/header/Callout.vue'
import { IWindowOpenOptions } from '~/types/window'

export type BusEvents = {
  'my-account:notified': Callout
  'my-account:updated': undefined
  'data-layer:page-view': undefined
  'filters:shown': undefined
  'qrcode:refresh': undefined
  'qrcode:trap-link': IWindowOpenOptions
  'finance:intersecting': boolean
  'mega-menu:close': undefined
}

export default defineNuxtPlugin(() => {
  const bus = mitt<BusEvents>()

  return {
    provide: {
      bus
    }
  }
})
