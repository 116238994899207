export interface IFinanceProductOption<T = number> {
  Value: T
  IsDefault: boolean
}

interface IFinanceProductOptions {
  Options: Array<IFinanceProductOption>
}

export enum EFinanceProductKeys {
  PCP = 'PCP',
  PCH = 'PCH',
  CH = 'CH',
  PHP = 'PHP',
  BCP = 'BCP',
  BHP = 'BHP',
  POL = 'POL',
  BOL = 'BOL'
}

export enum EFinanceProductDescriptions {
  PCP = 'At the end of the agreement decide whether to purchase your car, hand back or buy a new Mercedes-Benz.',
  PCH = 'Lease your car and give it back at the end of your agreement. Payment includes Vehicle Excise Duty.',
  CH = 'Lease your car and give it back at the end of your agreement. Payment includes Vehicle Excise Duty.',
  PHP = 'Purchase your car at the end of the agreement.',
  BCP = 'At the end of the agreement decide whether to purchase your car, hand back or buy a new Mercedes-Benz. For business users, the car is shown as an asset on your balance sheet.',
  BHP = 'Purchase your car at the end of the agreement.',
  POL = 'Lease your car and give it back at the end of your agreement. Payment excludes Vehicle Excise Duty.',
  BOL = 'Lease your car and give it back at the end of your agreement. Payment excludes Vehicle Excise Duty.'
}

export enum EFinanceProductCustomerType {
  Personal = 'Personal',
  Business = 'Business'
}

export interface IFinanceBaseProduct {
  Key: EFinanceProductKeys
  RegularPayment?: string | null
  Deposit: {
    Default: number | string
    IsRequired?: boolean
    Minimum?: string
    Maximum?: string
    DefaultIsPercentage?: boolean
    Locale?: {
      DisplayValue: string
    }
    OrderNo?: string
    MaximumAsPercentageOfOtr?: string
    MinimumAsPercentageOfOtr?: string
    DefaultAsPercentageOfOtr?: string
  } | null
  AdvanceRentals?: IFinanceProductOptions | null
  Term: IFinanceProductOptions | null
  Mileage?: IFinanceProductOptions | null
  PartExchange?: string | null
  Settlement?: string | null
}

/* exported IFinanceProduct */
export interface IFinanceProduct extends IFinanceBaseProduct {
  Name: string
  Type: string
  IsDefault: boolean
  IsPersonalised: boolean
  VehicleType: string
  CustomerType: EFinanceProductCustomerType
  MonthlyPrice: {
    Min: number | null
    Max: number | null
  }
}
