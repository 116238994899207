import { defineStore } from 'pinia'
import { useFilterDataStore } from './filterDataStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { ISearchCriteria } from '~/types/profile/savedSearch'
import { IFilterCollection } from '~/types/filters'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { NSavedSearch } from '~/types/api'

interface ISavedSearchState {
  searches: ISearchCriteria[]
}

export const useSavedSearchStore = defineStore('savedSearch', {
  state: (): ISavedSearchState => {
    return {
      searches: []
    }
  },

  actions: {
    async fetch() {
      const { data, error } = await this.api.profile.savedSearch.get()

      if (error) return

      this.update(data.SavedSearches)
    },

    async add(filters: IFilterCollection) {
      const customerTypeStore = useCustomerTypeStore()
      const filterDataStore = useFilterDataStore()
      const { isRetailerMode } = useFiltersStore()
      const New = filterDataStore.getFilterDataNew
      const Used = filterDataStore.getFilterDataUsed
      const searchData: NSavedSearch.add.Payload = {
        Criteria: filterCollection.getSavedSearchPayload(
          filters,
          {
            New,
            Used
          },
          customerTypeStore.getCustomerTypeName,
          isRetailerMode
        )
      }

      const { data, error } = await this.api.profile.savedSearch.add(searchData)

      if (!error) {
        this.searches.unshift(data.Criteria)
      }
    },

    update(searches: ISearchCriteria[]) {
      this.searches = searches
    },

    async remove(searchId: string) {
      const { error } = await this.api.profile.savedSearch.delete(searchId)

      if (!error) {
        this.searches = this.searches.filter((item) => item.Id !== searchId)
      }
    },

    resetAll() {
      this.searches = []
    },

    async removeAll() {
      const { error } = await this.api.profile.savedSearch.deleteAll()

      if (!error) {
        this.searches = []
      }
    }
  },

  getters: {
    getSearches: (state) => state.searches,
    getSearchesCount: (state) => state.searches.length,
    getSearchCollections: (state) => {
      return state.searches.map((search) => {
        return {
          collection: filterCollection.fromSearchCriteria(search),
          customerType: search.CustomerMode
        }
      })
    }
  }
})
