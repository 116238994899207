import {
  required,
  confirmed,
  length,
  email,
  digits,
  numeric,
  alpha,
  max,
  regex,
  alpha_dash as alphaDash,
  min_value as minValue,
  max_value as maxValue,
  alpha_spaces as alphaSpaces
} from '@vee-validate/rules'
import { defineRule } from 'vee-validate'
import { ITitle } from '~/types/forms'
import { titles } from '~/constants/forms'

const TEST_BLACKLIST = ['test', 'testing']

const makeNumeric = (value: unknown): number => {
  const string = String(value).replace(/,/g, '')
  return parseFloat(string)
}

export default defineNuxtPlugin(() => {
  defineRule('regex', (value: unknown, [regex, message]: any[]) => {
    return regex(value, { regex: regex.params[0].cast(regex) }) ? true : message
  })

  defineRule('name_field_regex', (value: unknown, [message]: [string]) => {
    return regex(value, { regex: /^([a-zA-Z]*-?)(\s?)([a-zA-Z]*)$/ })
      ? true
      : (message ??
          'This field may contain alphabetic characters and dashes only and is limited to two words')
  })

  defineRule('alpha_dash', (value: unknown, params: [string]) => {
    return alphaDash(value, params)
      ? true
      : 'This field may contain alphabetic characters and dashes only'
  })

  defineRule('alpha_spaces', (value: unknown, params: [string]) => {
    return alphaSpaces(value, params)
      ? true
      : 'This field may contain alphabetic characters and spaces only'
  })

  defineRule(
    'min_value_monthly',
    (value: unknown, params: [string | number]) => {
      const numeric = value ? makeNumeric(value) : value

      return minValue(numeric, params)
        ? true
        : 'The minimum monthly budget is £50'
    }
  )

  defineRule(
    'max_value_monthly',
    (value: unknown, params: [string | number]) => {
      const numeric = value ? makeNumeric(value) : value

      return maxValue(numeric, params)
        ? true
        : 'The maximum monthly budget is £4,000'
    }
  )

  defineRule('min_value', (value: unknown, params: [string | number]) => {
    const numeric = value ? makeNumeric(value) : value

    return minValue(numeric, params)
      ? true
      : 'Unfortunately the minimum value of £0'
  })

  defineRule('max_length_deposit', (value: string, params: [number]) => {
    return max(value.replace(/,|\./g, ''), params)
      ? true
      : 'Please limit to 8 characters'
  })

  defineRule('min_value_deposit', (value: unknown, params: [number]) => {
    const numeric = value ? makeNumeric(value) : value

    return minValue(numeric, params)
      ? true
      : 'There is a min deposit of £0 required'
  })

  defineRule('required', (value: unknown) => {
    return required(value) ? true : 'This field is required'
  })

  defineRule('digits', (value: unknown, params: [string | number]) => {
    return digits(value, params) ? true : 'Digits field is required'
  })

  defineRule('numeric', (value: unknown) => {
    return numeric(value) ? true : 'This field must be a valid number'
  })

  defineRule('email', (value: unknown) => {
    return email(value) ? true : 'This field must be a valid email'
  })

  defineRule('confirmed', (value: unknown, params: [string]) => {
    return confirmed(value, params)
      ? true
      : 'This field confirmation does not match'
  })

  defineRule('length', (value: unknown, params: [string]) => {
    return length(value, params) ? true : 'This field must have 2 options'
  })

  defineRule('phone', (value) => {
    if (
      value &&
      !value.match(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
      )
    ) {
      return 'Please enter a valid phone number'
    }

    return true
  })
  defineRule('day', (value) => {
    if (value && !value.match(/^(0?[1-9]|[12][0-9]|3[01])$/)) {
      return 'Invalid'
    }

    return true
  })
  defineRule('month', (value) => {
    if (value && !String(value).match(/^(0?[1-9]|1[0,1,2])$/)) {
      return 'Invalid'
    }

    return true
  })
  defineRule('year', (value) => {
    if (value && !value.match(/(?:19|20)\d{2}/)) {
      return 'Invalid'
    }

    return true
  })

  defineRule('max_character', (value: string): string | boolean => {
    if (value.length > 20) {
      return `This field has a maximum character limit of 20 characters, you have entered ${value.length} characters `
    }

    return true
  })

  defineRule('min_character', (value: string) => {
    if (value.length < 2) {
      return `This field has a minimum character limit of 2, you have entered ${value.length} character `
    }

    return true
  })

  defineRule('max_words', (value: unknown, [maxWords]: [number]) => {
    if (value.split(' ').length > maxWords) {
      return `You can only enter ${maxWords} words `
    }

    return true
  })

  defineRule('alpha', (value: unknown, params: [string]) => {
    return alpha(value, params)
      ? true
      : 'This field must be alphabetic characters only'
  })

  defineRule('postcode', (value: unknown) => {
    if (value === 'Current location') {
      return true
    }

    if (!value.match(/^[a-zA-Z]{1,2}[0-9a-zA-Z]{1,2}\s*[0-9]{1}[a-zA-Z]{2}$/)) {
      return 'Please enter a valid postcode'
    }

    return true
  })

  defineRule('postcode_prefilter', (value: unknown) => {
    if (value === 'Current location') {
      return true
    }

    if (/^([A-Z]{1,2}[A-Z\d]{0,2})\s*?(\d[A-Z]{0,2})$/i.test(value)) {
      return true
    }

    return 'The postcode you have entered is not valid'
  })

  defineRule('decimal', (value: unknown) => {
    const valueFloat = isNaN(value)
      ? parseFloat(value.replace(/,/g, ''))
      : parseFloat(value)

    if (!isNaN(valueFloat) && isFinite(valueFloat)) {
      return true
    }

    return 'The number you have entered is not valid'
  })

  defineRule('uk-reg', (value: unknown) => {
    // see https://gist.github.com/danielrbradley/7567269
    if (
      !value
        .replace(' ', '')
        .toUpperCase()
        .match(
          /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/g
        )
    ) {
      return 'Please input a valid UK car registration'
    }

    return true
  })

  defineRule('registration', (value: unknown) => {
    if (value.replace(/ /g, '').length > 7) {
      return 'Registration must be a max of 7 characters'
    }

    if (!value.match(/^[a-zA-Z0-9 ]*$/)) {
      return 'Please use alphanumeric characters and spaces only'
    }

    return true
  })

  defineRule('mileage', (value: unknown) => {
    if (value < 1) {
      return 'Mileage must be greater than 0'
    }

    if (value.replaceAll(',', '').length > 7) {
      return 'Mileage must be under 7 characters'
    }

    return true
  })

  defineRule('title', (value: ITitle) => {
    if (
      titles
        .map((title) => JSON.stringify(title))
        .includes(JSON.stringify(value))
    ) {
      return true
    }

    return 'Please select a valid title'
  })

  defineRule('exclude_test', (value: string) => {
    if (TEST_BLACKLIST.includes(value.toLowerCase())) {
      return 'Please use a real name'
    }

    return true
  })
})
