<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.13133 9.04559H15.1542L16 3.17153H4.6629L3.74864 1H0V2.75988H2.55981L6.51417 12.1543H14.9714V10.3942H7.70279L7.13133 9.04559ZM6.39983 7.28571L5.32555 4.77138H14.1026L13.7369 7.28571H6.39983ZM9.3256 14.3943C9.3256 15.1028 8.77708 15.6514 8.06854 15.6514C7.36 15.6514 6.81148 15.1028 6.81148 14.3943C6.81148 13.6857 7.36 13.1372 8.06854 13.1372C8.77708 13.1372 9.3256 13.6857 9.3256 14.3943ZM13.7828 15.6513C14.4913 15.6513 15.0398 15.1028 15.0398 14.3943C15.0398 13.6857 14.4913 13.137 13.7828 13.1372C13.0742 13.1372 12.5485 13.6857 12.5485 14.3943C12.5485 15.1028 13.0742 15.6513 13.7828 15.6513Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Cart'
}
</script>
