import ApiFactory from './factory'
import { IFilterDataNew, IFilterDataUsed } from '~/types/filterData'
import { IFilterDataStoreState } from '~/stores/filterDataStore'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'

class FiltersModule extends ApiFactory {
  async get(): Promise<IFilterDataStoreState | undefined> {
    const serviceStatusStore = useServiceStatusStore(this.pinia)

    const response = await Promise.allSettled([
      $osGet(`${this.apiVersion}/filters/new`),
      $osGet(`${this.apiVersion}/filters/used`)
    ]).then((res) => {
      const fulfilledPromises = getFulfilledPromises<any>(res)

      if (fulfilledPromises.length === 2) {
        return fulfilledPromises
      }
    })

    if (!response) {
      serviceStatusStore.updateFilters(false)

      return
    }

    return {
      new: {
        Private: transformFilterDataResponse(
          response[0].data as IFilterDataNew,
          true,
          this.pinia
        ),
        Motability: {}
      },
      used: {
        Private: transformFilterDataResponse(
          response[1].data as IFilterDataUsed,
          false,
          this.pinia
        )
      }
    }
  }
}

export default FiltersModule
