import { RuntimeConfig } from 'nuxt/schema'
import { RouteLocation, Router } from '#vue-router'
import {
  CodeType,
  CommCodes,
  ECodeFormType,
  RequestSources
} from '~/types/commCodes'
import { IVehiclePayload } from '~/types/forms'
import { IRetailer } from '~/types/retailer'
import {
  EVehicleType,
  IVehicle,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'
import { ERouteConditions, ERoutes } from '~/types/routes'
import { ECustomerTypes } from '~/types/customerType'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { isNewVehicle } from '~/guards/vehicle'
import { useProfileStore } from '~/stores/profileStore'
import { useValueMyCarStore } from '~/stores/forms/valueMyCarStore'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'

export interface ICodesOptions {
  isNew: boolean
  isSmart: boolean
  isMotability?: boolean
  isBusiness: boolean
}

export const getVehicleDetailsUrl = (
  vehicle: IVehicle | NewVehicle | UsedVehicle,
  customerTypeName: ECustomerTypes,
  route: RouteLocation,
  router: Router,
  baseUrl?: string
) => {
  let url = ''
  const isNew = !!vehicle && vehicle.VehicleType === EVehicleType.New

  if (route.name === ERoutes.Results && vehicle && baseUrl) {
    const { slugify } = useUi()
    const resolvedRoute = router.resolve({
      name: ERoutes.Vehicle,
      params: {
        id: vehicle.CommissionNumber,
        condition: isNew ? ERouteConditions.New : ERouteConditions.Used,
        type: getTypeParam(customerTypeName),
        model: slugify(vehicle.Description),
        retailerId: String(vehicle.Retailer.Id)
      }
    } as any)

    url = `${baseUrl || ''}${resolvedRoute.href}`
  } else if (route.fullPath) {
    url = `${baseUrl || ''}${route.fullPath.split('?')[0]}`
  }

  url = `${url.replace(/(https:\/\/)|(http:\/\/)/, '')}`

  return url
}

const getCodes = (
  codeType: (typeof CodeType)['COMM_CODES' | 'REQUEST_SOURCE'],
  formType: ECodeFormType,
  opts: ICodesOptions
) => {
  if (opts.isNew) {
    if (opts.isSmart) {
      if (opts.isMotability) {
        return codeType[`NewSmartMotability${formType}`]
      } else if (opts.isBusiness) {
        return codeType[`NewSmartBusiness${formType}`]
      } else {
        return codeType[`NewSmart${formType}`]
      }
    } else if (opts.isMotability || opts.isBusiness) {
      return opts.isMotability
        ? codeType[`NewMotability${formType}`]
        : codeType[`NewBusiness${formType}`]
    }

    return codeType[`New${formType}`]
  } else {
    return opts.isSmart
      ? codeType[`UsedSmart${formType}`]
      : codeType[`Used${formType}`]
  }
}

export const getCommsCodes = (formType: ECodeFormType, opts: ICodesOptions) =>
  getCodes(CodeType.COMM_CODES, formType, opts)

export const getRequestSources = (
  formType: ECodeFormType,
  opts: ICodesOptions
) => getCodes(CodeType.REQUEST_SOURCE, formType, opts)

export const getCallbackCommCodes = (opts: ICodesOptions) => {
  if (opts.isSmart)
    return opts.isNew ? CommCodes.NewSmartCallback : CommCodes.UsedSmartCallback

  return opts.isBusiness ? CommCodes.NewBusinessCallback : CommCodes.Callback
}

export const getCallbackRequestResource = (opts: ICodesOptions) => {
  if (opts.isSmart)
    return opts.isNew
      ? RequestSources.NewSmartCallback
      : RequestSources.UsedSmartCallback

  if (opts.isMotability) return RequestSources.MotabilityCallback

  if (!opts.isNew) return RequestSources.UsedCallback

  return opts.isBusiness
    ? RequestSources.NewBusinessCallback
    : RequestSources.NewCallback
}

export function getRetailerSelectedData(
  isSmart: boolean,
  data: IRetailer
): any {
  const phone = isSmart
    ? data?.smartPhone
    : data?.DisplayPhoneNumberNew || data?.Phone
  return {
    Name: isSmart
      ? data?.smartDescription?.replace('&', '&amp;') ||
        data?.Description?.replace('&', '&amp;')
      : data?.Description?.replace('&', '&amp;'),
    Phone: phone?.replace('+44 ', '0'),
    Street: data?.Street,
    City: data?.City,
    Postcode: data?.Postcode,
    Website: data?.Website?.replace('https://' || 'http://', ''),
    RetailerId: data?.Id,
    GSSNID: data?.GssnId || ''
  }
}

export function getRetailerOwningData(retailer: IRetailer) {
  return {
    Name: retailer?.Description?.replace('&', '&amp;'),
    Phone: retailer?.Phone?.replace('+44 ', '0'),
    Street: retailer?.Street,
    City: retailer?.City,
    Postcode: retailer?.Postcode,
    Website: retailer?.Website?.replace('https://' || 'http://', '')
  }
}

// TODO: This is very similar to getVehiclePayload, maybe unify
export function getVehicleData(
  vehicle: IVehicle | NewVehicle | UsedVehicle,
  isVehicleNew: boolean,
  isBusiness: boolean,
  isMotability: boolean,
  VehicleDetailsUrl: string
): IVehiclePayload {
  const data: IVehicle | any = {}
  data.IsSpecialistCar = vehicle.IsSpecialistCar
  data.CommissionNumber = vehicle.CommissionNumber || ''
  data.Description = vehicle.Description || ''
  data.OTRPrice = isNewVehicle(vehicle)
    ? `£${formatOTRPrice(vehicle.OTR)}`
    : formatRetailPrice(vehicle.RetailPrice)
  data.FuelType = vehicle.FuelType || ''
  data.Transmission = vehicle.Transmission || ''
  data.Upholstery = vehicle.UpholsteryDescription || ''
  data.Colour = vehicle.Colour || ''
  data.VehicleType = vehicle.VehicleType
  data.MainImageUrl = vehicle.Media?.MainImageUrl || ''
  data.VehicleDetailsUrl = VehicleDetailsUrl
  data.Vin = vehicle.Vin || ''
  // TODO: Add vehicle emissions

  if (isNewVehicle(vehicle)) {
    if (vehicle.IsPipelineStock !== undefined) {
      data.IsPipelineStock = vehicle.IsPipelineStock
    }

    data.PortArrivalDate = vehicle.PortArrivalDate || ''
  }

  if (!isNewVehicle(vehicle)) {
    data.Emissions =
      vehicle.Emission === null || vehicle.Emission === undefined
        ? ''
        : vehicle.Emission
    data.RetailPrice = formatRetailPrice(vehicle.RetailPrice) || ''
    data.RegistrationNumber = vehicle.RegistrationNumber || ''
    data.RegistrationDate = vehicle.RegistrationDate || ''
    data.Mileage = vehicle.Mileage || ''
  }

  if (vehicle.ElectricRange) {
    data.ElectricRange = vehicle.ElectricRange
  }

  if (isMotability && vehicle.Motability) {
    if (vehicle.Motability.CivilianAdvanceRental !== null) {
      data.motabilitycivilianadvancedrental =
        vehicle.Motability.CivilianAdvanceRental?.toString()
    }

    if (vehicle.Motability.WPMSAdvancePayment !== null) {
      data.motabilitywpmsadvancedrental =
        vehicle.Motability.WPMSAdvancePayment?.toString()
    }
  }

  if (isBusiness) {
    data.P11D = vehicle.P11D
  }

  return data
}

/**************************************/
/** ******** PAYLOAD commons ******* **/
/**************************************/

export const getEnquiryInfoCommons = (
  config: RuntimeConfig,
  isSmart: boolean
) => {
  const { $pinia } = useNuxtApp()
  const { meta } = useFormsStore($pinia)
  const LeadSource = meta.leadSource

  return {
    Token: config.public.contactFormToken,
    EnquiryDateTime: new Date().toISOString(),
    ProductGroup: isSmart ? '4' : '1',
    LeadSource
  }
}

export const getTagUrl = (CommunicationCode?: string) => {
  const { $pinia } = useNuxtApp()
  const { meta } = useFormsStore($pinia)

  return (
    meta.utm ||
    meta.csref?.parsedCsref ||
    meta.gagcmid ||
    meta.gclid ||
    CommunicationCode
  )
}

export const getFormId = (isSmart: boolean, isNewVehicle: boolean) => {
  return isSmart ? (isNewVehicle ? 'NSL' : 'USL') : isNewVehicle ? 'NCL' : 'UCL'
}

export const getValuationInfo = () => {
  const { $pinia } = useNuxtApp()
  const valueMyCarStore = useValueMyCarStore($pinia)

  return {
    TradeIn: valueMyCarStore.currentValuation?.ValuationId ?? null,
    TradeInValuation: valueMyCarStore.indicativeValuation
  }
}

export const getFormPayload = (
  route: RouteLocation,
  isSmart: boolean,
  isVehiclePayload = false,
  detailsUrl?: string
): any => {
  const { $pinia } = useNuxtApp()
  const { getCustomer, getVehicle, isNewVehicle, isSubmittingAsBusiness } =
    useFormsStore($pinia)
  const { retailer } = useRetailerStore($pinia)
  const { isMotability } = useCustomerTypeStore($pinia)
  const { getSearchCriteria, meta } = useVehiclesSearchStore($pinia)
  const { getProfileId } = useProfileStore($pinia)
  const { currentQuoteDto } = useFinanceQuotesStore($pinia)
  const financeCriteriaStore = useFinanceCriteriaStore($pinia)

  return {
    CustomerDetails: getCustomerDetailsPayload(
      getCustomer,
      isSubmittingAsBusiness
    ),
    AdditionalDetails: getCustomer.additionalInfo || '',
    GssnCode: retailer?.GssnId || '',
    searchURL: route.fullPath ?? '',
    searchCriteria: {
      Criteria: {
        ...getSearchCriteria,
        VehicleType: isNewVehicle ? 1 : 2
      }
    },
    productRange: meta.data?.ProductRange ?? '',
    OfferCode: getOfferList(getVehicle || {}),
    ProfileId: getProfileId,
    RetailerSelected: getRetailerSelectedData(isSmart, retailer!),
    ...(isVehiclePayload
      ? {
          Interest: { ProductRange: getVehicle!.ProductRange },
          FinanceQuote: formatCustomerFinanceData(
            currentQuoteDto,
            financeCriteriaStore.isPersonalised,
            parseTotalOffer(getVehicle?.OTR),
            isSubmittingAsBusiness
          ),
          RetailerOwning: getRetailerOwningData(getVehicle!.Retailer),
          Vehicle: getVehicleData(
            getVehicle!,
            isNewVehicle,
            isSubmittingAsBusiness,
            isMotability,
            detailsUrl || ''
          )
        }
      : {})
  }
}

export function getConvertedDate(selectedDate: Date): string {
  const date = new Date(selectedDate)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return [day, month, date.getFullYear()].join('/')
}
