<template>
  <div>
    <NuxtPwaManifest />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { EConsents } from '~/types/usercentrics'

const { isEnabled } = useFeatureFlag('optimizely_enabled')
const { hasConsent } = useConsent(EConsents.Optimizely)

// Optimizely Script
useScript(
  { src: 'https://cdn.optimizely.com/js/29864360354.js' },
  {
    trigger: useScriptTriggerConsent({
      consent: computed(() => isEnabled.value && hasConsent.value)
    })
  }
)
</script>
