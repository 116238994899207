<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M2 .84h2v2H2v-2zm10 0h2v2h-2v-2zm4 2v14H0v-14h2v1h2v-1h8v1h2v-1h2zm-2 2H2v10h12v-10zm-9.5 2h2v6h-2v-6zm5 2h-2v-2h4v2l-1 4h-2l1-4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Age'
}
</script>
