<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.01 17.49">
    <g>
      <path
        id="chat-bubble"
        fill-rule="evenodd"
        fill="currentColor"
        d="M6.72,0C3.01,0,0,2.35,0,5.24c0,1.85,1.24,3.47,3.1,4.4-.44,1.24-1.24,2.3-2.28,3.05,0,0,3.31-.38,5.57-2.23h.33c3.71,0,6.72-2.34,6.72-5.23S10.43,0,6.72,0ZM3.28,3.77H7.86v1.03H3.28v-1.03Zm6.88,3.09H3.28v-1.03h6.88v1.03Z"
      />
      <path
        fill-rule="evenodd"
        fill="currentColor"
        d="M15.91,14.69c1.86-.85,3.1-2.34,3.1-4.05,0-2.2-2.07-4.06-4.89-4.63-.49,2.93-3.61,5.19-7.39,5.19h-.07c-.28,.22-.58,.41-.88,.59,.73,2.09,3.36,3.65,6.51,3.65h.33c2.26,1.7,5.57,2.05,5.57,2.05-1.04-.69-1.84-1.66-2.28-2.8Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChatBubble'
}
</script>
