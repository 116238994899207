import { ECondition } from '~/types/vehicle'
import {
  IFilterCollection,
  IFiltersBudget,
  IFiltersRadius,
  IFiltersRange
} from '~/types/filters'

export const filtersFactory = {
  create: (condition: ECondition = ECondition.New): IFilterCollection => {
    return {
      models: [],
      colours: [],
      transmissions: [],
      deliveryTime: [],
      fuels: [],
      bodyStyles: [],
      engines: [],
      upholsteries: [],
      lines: [],
      packages: [],
      age: { Min: null, Max: null },
      emission: { Min: null, Max: null },
      mileage: { Min: null, Max: null },
      keywords: [],
      retailers: [],
      retailerGssnId: [],
      retailerName: '',
      retailerGroups: [],
      condition,
      budget: filtersFactory.createBudget(),
      radius: filtersFactory.createRadius(),
      arrivalInDays: { Min: null, Max: null },
      ageInDays: filtersFactory.createRange(),
      latestModel: false,
      previousModel: false,
      hasOffer: false,
      excludeOutOfStock: true
    }
  },

  createBudget: (): IFiltersBudget => {
    return {
      price: {
        Min: null,
        Max: null
      },
      monthlyPrice: {
        Min: null,
        Max: null
      }
    }
  },

  createRadius: (): IFiltersRadius => {
    return {
      Distance: 25,
      Postcode: ''
    }
  },

  createRange: (): IFiltersRange => {
    return {
      Min: null,
      Max: null
    }
  }
}
