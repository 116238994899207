export const retailerIPBlackList = [
  // Arnold Clark
  '192.124.206.1',
  '192.124.207.1',
  '195.11.167.1',
  '62.254.60.122',
  '46.226.7.130',

  // City West County
  '212.24.75.224.29',
  '87.127.186.24.29',
  '176.35.166.13.32',
  '87.127.44.248.29',
  '45.89.7.24.29',
  '45.89.7.249.32',
  '62.254.154.162.29',
  '159.242.119.46.32',

  // Group 1
  '88.215.15.154',
  '51.52.220.187',
  '51.52.220.178',
  '51.52.220.154',
  '78.143.249.122',
  '51.219.185.202',
  '212.250.152.66',
  '51.52.220.98',
  '195.224.141.98',
  '45.83.39.34',
  '188.66.108.254',
  '195.224.202.162',
  '78.143.249.130',
  '5.2.102.82',

  // Inchape
  '205.217.15.0.24',
  '91.223.255.0.24',
  '213.174.203.0.26',

  // Isaac Agnew
  '81.149.113.26',
  '81.130.154.228',
  '81.137.211.187',
  '81.137.211.188',
  '213.120.101.165',

  // Jardin
  '172.25.19.254',
  '172.25.26.254',
  '172.25.5.254',
  '172.25.76.254',
  '172.25.156.254',

  // JCT600
  '10.168.170.254.24',
  '10.168.181.254.24',
  '10.168.182.254.24',
  '10.168.130.254.24',
  '10.168.129.254.24',

  // L&L
  '62.232.26.130/29',
  '172.25.9.0.24',
  '77.107.137.150.30',
  '172.25.11.0.24',
  '83.219.63.46.30',
  '77.107.137.106.30',
  '172.25.10.0.24',
  '83.219.57.114.30',
  '77.44.55.218.30',
  '172.25.16.0.24',
  '195.70.67.186.29',
  '172.25.14.0.24',
  '192.168.113.0.24',
  '217.78.159.39.27',

  // Listers
  '185.181.145.124',
  '89.197.163.170',
  '89.197.163.171',
  '89.197.163.172',
  '89.197.163.173',
  '86.53.118.243',

  // Lookers
  '159.242.122.0.24',
  '159.242.123.0.24',
  '159.242.124.0.24',
  '194.74.144.193.32',
  '194.74.144.197.32',

  // LSH
  '10.80.32.0.20',
  '10.80.104.0.22',
  '10.80.64.0.22',
  '10.80.108.0.22',
  '10.80.76.0.22',
  '10.80.68.0.22',
  '10.80.48.0.20',
  '10.200.134.0.22',

  // MBRG
  '77.86.22.1',
  '77.86.22.2',
  '77.86.22.3',
  '77.86.22.4',
  '77.86.22.5',
  '77.86.22.6',
  '77.86.22.7',
  '77.86.22.8',
  '77.86.22.9',
  '77.86.22.10',
  '77.86.22.11',
  '77.86.22.12',
  '77.86.22.13',
  '77.86.22.14',
  '87.102.106.49',
  '87.102.106.50',
  '87.102.106.51',
  '87.102.106.52',
  '87.102.106.53',
  '87.102.106.54',
  '87.102.106.55',
  '87.102.106.56',
  '87.102.106.57',
  '87.102.106.58',
  '87.102.106.59',
  '87.102.106.60',
  '87.102.106.61',
  '87.102.106.62',
  '194.207.31.161',
  '194.207.31.162',
  '194.207.31.163',
  '194.207.31.164',
  '194.207.31.165',
  '194.207.31.166',
  '194.207.31.167',
  '194.207.31.168',
  '194.207.31.169',
  '194.207.31.170',
  '194.207.31.171',
  '194.207.31.172',
  '194.207.31.173',
  '194.207.31.174',
  '194.207.31.81',
  '194.207.31.82',
  '194.207.31.83',
  '194.207.31.84',
  '194.207.31.85',
  '194.207.31.86',
  '194.207.31.87',
  '194.207.31.88',
  '194.207.31.89',
  '194.207.31.90',
  '194.207.31.91',
  '194.207.31.92',
  '194.207.31.93',
  '194.207.31.94',
  '194.207.31.65',
  '194.207.31.66',
  '194.207.31.67',
  '194.207.31.68',
  '194.207.31.69',
  '194.207.31.70',
  '194.207.31.71',
  '194.207.31.72',
  '194.207.31.73',
  '194.207.31.74',
  '194.207.31.75',
  '194.207.31.76',
  '194.207.31.77',
  '194.207.31.78',
  '194.207.31.79',

  // Sinclair
  '217.33.22.99',
  '62.172.145.18',
  '86.188.168.162',
  '81.128.224.218 ',

  // Sytner
  '62.255.168.128.25',

  // Vertu
  '62.252.237.247',
  '62.252.93.29',
  '213.104.215.148',
  '213.104.215.210',
  '213.104.215.148',
  '62.31.79.37',
  '213.104.65.254',
  '62.31.79.86',
  '195.188.206.242',
  '213.104.215.172',
  '213.104.133.194',
  '217.137.122.42',
  '213.104.217.112',
  '62.252.254.22',
  '62.31.73.100',
  '62.31.79.84',

  // Western Automobile Co
  '185.240.201.5'
]
