import { defineStore } from 'pinia'

interface ISettingsStore {
  isAgentUser: boolean
  viewportDesktop: boolean
  viewportPortrait: boolean
  mode: {
    finance: boolean
  }
  footer?: {
    image: string
    message: string
  } | null
}

export const useSettingsStore = defineStore('settings', {
  state: (): ISettingsStore => {
    return {
      isAgentUser: false,
      viewportDesktop: false,
      viewportPortrait: true,
      mode: {
        finance: false
      },
      footer: null
    }
  },

  persist: {
    paths: ['isAgentUser']
  },

  actions: {
    saveAgentUser(queryValue?: string) {
      this.isAgentUser = this.isAgentUser || queryValue === 'true'
    },
    updateViewport(isViewportDesktop: boolean) {
      this.viewportDesktop = isViewportDesktop
    },
    updateOrientation(isViewportPortrait: boolean) {
      this.viewportPortrait = isViewportPortrait
    },
    updateFinanceMode(isFinanceMode: boolean) {
      this.mode.finance = isFinanceMode
    },
    updateFooter(footerData: any) {
      if (footerData) {
        this.footer = footerData
        localStorage.setItem('footer', JSON.stringify(footerData))
      }
    }
  },

  getters: {
    modes(): { finance: boolean } {
      return this.mode
    },

    viewportIsDesktop(): boolean {
      return this.viewportDesktop
    },

    viewportIsPortrait(): boolean {
      return this.viewportPortrait
    }
  }
})
