<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M15.5969054,7.35691318 C15.3401631,5.35048232 14.2618452,3.54983923 12.6186942,2.31511254 C12.4646488,2.21221865 12.3106034,2.10932476 12.156558,2.00643087 L13.6970121,0 L7.63789275,0 L9.28104375,5.71061093 L10.7701493,3.75562701 C10.9241948,3.8585209 11.1295886,3.96141479 11.283634,4.06430868 C13.6970121,5.86495177 14.1591483,9.26045016 12.3619519,11.6270096 C11.8484672,12.2958199 11.1809371,12.8617363 10.4107101,13.221865 C9.6918315,13.5819936 8.81890753,13.7877814 7.99733203,13.7877814 L7.99733203,13.7877814 C6.81631725,13.7877814 5.68665094,13.3762058 4.71103003,12.6559486 C3.53001525,11.7813505 2.81113668,10.4951768 2.60574281,9.10610932 C2.40034893,7.66559486 2.75978821,6.27652733 3.68406065,5.09324759 C4.19754534,4.4244373 4.86507543,3.8585209 5.63530247,3.49839228 C5.89204481,3.39549839 6.14878716,3.2926045 6.45687797,3.18971061 L5.84069634,1.02893891 C5.42990859,1.1318328 5.01912084,1.28617363 4.65968156,1.49196141 C3.58136371,2.00643087 2.65709128,2.77813505 1.88686424,3.75562701 C0.654500991,5.40192926 0.141016303,7.35691318 0.397758647,9.414791 C0.654500991,11.4212219 1.73281884,13.221865 3.37596984,14.4565916 C4.71103003,15.4855305 6.35418103,16 7.99733203,16 L7.99733203,16 C9.17834682,16 10.3080131,15.6913183 11.386331,15.2282958 C12.4646488,14.7138264 13.3889213,13.9421222 14.1591483,12.9646302 C15.3401631,11.3697749 15.8536478,9.36334405 15.5969054,7.35691318 L15.5969054,7.35691318 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Reset'
}
</script>
