import { useFiltersStore } from '~/stores/filtersStore'
import { ECondition } from '~/types/vehicle'

export default defineNuxtPlugin(({ $pinia, hooks }) => {
  const filtersStore = useFiltersStore($pinia)
  const requiresGeolocation = useState('requiresGeolocation')

  if (filtersStore.active.condition === ECondition.Used) return

  hooks.hookOnce('page:finish', async () => {
    if (filtersStore.active.retailers.length) return

    const preferred = useLocalStorage<string | null>(
      'preferredRetailerId',
      null
    )

    if (preferred.value) {
      const retailer = await filtersStore.fetchRetailer(preferred.value)

      if (retailer) {
        const { saveFilters, updateRetailer } = useFilters(filtersStore.active)

        updateRetailer(retailer)
        saveFilters()

        requiresGeolocation.value = false
      }
    }
  })
})
