<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 8">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.826 5.512c-.415 0-.77.373-.77.809 0 .124.029.217.088.342.149.249.386.466.682.466a.749.749 0 0 0 .683-.466.735.735 0 0 0 .089-.342c0-.467-.327-.809-.772-.809zm15.662-.933c-.89 0-1.631.778-1.631 1.71 0 .934.741 1.711 1.631 1.711s1.632-.777 1.632-1.71c0-.933-.712-1.71-1.632-1.71zm0 2.799c-.563 0-1.038-.498-1.038-1.088 0-.591.475-1.089 1.038-1.089.564 0 1.038.498 1.038 1.089 0 .59-.445 1.088-1.038 1.088zM3.826 4.579c-.89 0-1.631.778-1.631 1.71C2.195 7.224 2.937 8 3.826 8c.89 0 1.632-.777 1.632-1.71 0-.933-.742-1.71-1.632-1.71zm0 2.799c-.563 0-1.038-.498-1.038-1.088 0-.591.475-1.089 1.038-1.089.564 0 1.039.498 1.039 1.089 0 .59-.475 1.088-1.039 1.088zm19.815-3.296l-.267-1.275.148-.498-2.165-.373C17.708.07 14.119-.396 10.975.319c0 0-.89.186-3.411 1.99 0 0-4.657.28-6.17.87l-.623.25-.623.964.06.684-.208.31s.148 1.245.119 1.245l1.75.342h.059a2.308 2.308 0 0 1-.119-.653c0-1.182.89-2.115 2.017-2.115 1.128 0 2.017.933 2.017 2.115 0 .217-.03.466-.118.653h.148l11.598-.031.06-.031a2.251 2.251 0 0 1-.09-.622c0-1.182.89-2.115 2.017-2.115 1.128 0 2.017.933 2.017 2.115 0 .155 0 .248-.03.373h.09c.385-.093 2.284-.404 2.284-.404l.178-1.835c.03 0-.149-.125-.356-.342zm-22.128.373l-.92.373s.178-.684.623-.933c.237-.155 1.068-.342 1.068-.342l-.771.902zm18.45-1.773c-.505.062-1.187.125-2.047.156-4.716.155-10.62.497-10.678.497-1.187.063-2.878.187-2.878.187s.801-.342 3.47-.498c1.336-1.181 3.026-2.021 3.026-2.021.86-.249 2.077-.373 2.136-.373.593-.062 1.216-.031 1.898.031l-.178 2.052c.386-.03.742-.03 1.068-.03l.178-1.898c1.394.218 2.73.685 3.62 1.244.088.062-.98.436-1.425.56 1.513-.093 2.58.031 2.58.031s-.296 0-.77.062zm-.475 2.8c.415 0 .771.372.771.808 0 .435-.356.808-.77.808-.416 0-.772-.373-.772-.808 0-.436.356-.809.771-.809z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Coupe'
}
</script>
