export enum EStoreStatus {
  OK = 1,
  ERROR = 9
}

export enum EFormType {
  Callback = 'callback',
  StockNotification = 'stockNotification',
  ValueMyCar = 'valueMyCar',
  TestDrive = 'testDrive',
  Enquiry = 'enquiry',
  FinanceEligibility = 'financeEligibility',
  Offer = 'offer',
  CallToActionDirectory = 'callToActionDirectory'
}

export interface IFormListItem {
  text: string
  icon: string
}

// todo: this shouldn't be in forms interfaces as is global
export interface ITitle {
  text: string
  id: number
}

export interface IDataProtection {
  personalData: string
  marketingPreferences: string
  personalInformation: string
  keepYouInformed: string
}

export interface IFormStep {
  id: number
  isActive: boolean
  isChecked: boolean
  step: string
  label: string
  heading?: string
  nextStep?: string
}

export interface IVehiclePayload {
  CommissionNumber?: string
  Description?: string
  OTRPrice?: string
  RetailPrice?: string
  P11D?: number
  RegistrationNumber?: string
  RegistrationDate?: string
  Mileage?: string
  FuelType?: string
  Transmission?: string
  Upholstery?: string
  ElectricRange?: number
  Colour?: string
  VehicleType?: string
  MainImageUrl?: string
  VehicleDetailsUrl?: string
  Emissions?: number | string
  motabilitycivilianadvancedrental?: number
  motabilitywpmsadvancedrental?: number
  IsPipelineStock: boolean
  PortArrivalDate?: string
  Vin?: number
}

export interface ICsRef {
  parsedCsref: string
}
