<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
    <path
      fill="currentColor"
      d="M2.5 0 .5 0C.22386 0 0 .22386 0 .5L0 9.5C0 9.7761.22386 10 .5 10L2.5 10C2.7761 10 3 9.7761 3 9.5L3 .5C3 .22386 2.7761 0 2.5 0ZM8.5 0 6.5 0C6.2239 0 6 .22386 6 .5L6 9.5C6 9.7761 6.2239 10 6.5 10L8.5 10C8.7761 10 9 9.7761 9 9.5L9 .5C9 .22386 8.7761 0 8.5 0Z"
      transform="translate(.5)"
    />
  </svg>
</template>

<script>
export default {
  name: 'MediaPause'
}
</script>
