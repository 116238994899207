<template>
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m5.58947368 3.15789474c0 .56842105-.14210526 1.08947368-.42631579 1.57894737l3 5.16315789c.17368422.3.26842106.6.26842106.9 0 .5842105-.44210527 1.0894737-1.12105263 1.0894737-.58421053 0-.88421053-.2210526-1.18421053-.7894737l-2.96842105-5.16315789c-1.04210527 0-2.49473684-.64736843-2.81052632-2.25789474l-.34736842-1.73684211.45789474-.26842105.91578947 1.5631579c.26842105.47368421.72631579.71052631 1.21578947.71052631.82105264 0 1.42105264-.67894737 1.42105264-1.4368421 0-.26842106-.04736843-.47368421-.17368421-.6631579l-.91578948-1.56315789.48947369-.28421053 1.29473684 1.16842105c.6.53684211.88421052 1.2.88421052 1.98947369z"
      fill="currentColor"
      transform="translate(2)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Servicing'
}
</script>
