import { NBackOrders } from '~/types/api'
import ApiFactory from '~/api/factory'

class BackOrdersModule extends ApiFactory {
  get(ciamId: string) {
    return $osGet<NBackOrders.get.Response>(
      `${this.apiVersion}/myaccount/${ciamId}/backorderenquiries`
    )
  }
}

export default BackOrdersModule
