import {
  IOcdSection,
  EOcdLabel,
  TFormFactorySection,
  IOcdField
} from '~/types/forms'

export const getUpdatedFields = (sections: IOcdSection[]): IOcdField[] => {
  const fields: IOcdField[] = []

  sections.forEach((section) => {
    ;(section.Fields || []).forEach((field) => {
      if (field.Value) {
        fields.push(field)
      }
    })

    if (section.Sections.length) {
      getUpdatedFields(section.Sections).forEach((field) => {
        if (field.Value) {
          fields.push(field)
        }
      })
    }
  })

  return fields
}

const getStepFieldsCount = (sections: IOcdSection[]): number => {
  let count = 0

  sections.forEach((section) => {
    count += section.Fields.length

    if (section.Sections.length) {
      count += getStepFieldsCount(section.Sections)
    }
  })

  return count
}

export const getSectionsWithCount = (
  sections: IOcdSection[]
): TFormFactorySection[] => {
  let formSteps = [] as TFormFactorySection[]

  sections.forEach((section) => {
    if ([EOcdLabel.APPLICANTS, EOcdLabel.APPLICANT].includes(section.Label)) {
      formSteps = formSteps.concat(getSectionsWithCount(section.Sections))
      return
    }

    formSteps.push({
      ...section,
      key: section.Label,
      fieldsCount: getStepFieldsCount([section])
    })
  })

  return formSteps
}
