import { createGtm, useGtm } from '@gtm-support/vue-gtm'
import analyticsFactory from '~/services/analytics/analyticsFactory'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const nonce = useNonce()

  nuxtApp.vueApp.use(
    createGtm({
      id: (config.public.GTM_KEY as string) || 'GTM-KNDR38M',
      enabled: true,
      nonce
    })
  )

  return {
    provide: {
      gtm: useGtm(),
      dataLayer: analyticsFactory()
    }
  }
})
