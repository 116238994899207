import {
  IFilterData,
  IFilterDataBudget,
  IFilterDataMinMax,
  IFilterDataNew,
  IOsFilterDataNew,
  IOsFilterDataUsed,
  isFilterDataNew
} from '~/types/filterData'
import { useFiltersStore } from '~/stores/filtersStore'

function isMinMaxType(
  value: IFilterData[keyof IFilterData]
): value is IFilterDataMinMax {
  return (
    !Array.isArray(value) &&
    !!value &&
    (Object.hasOwn(value, 'Min') ||
      Object.hasOwn(value, 'Max') ||
      JSON.stringify(value) === '{}')
  )
}
function getBudgetData(budget = { Min: 0, Max: 200000 }) {
  return {
    Price: { Min: Math.floor(budget.Min), Max: Math.ceil(budget.Max) },
    MonthlyPrice: { Min: 50, Max: 4000 }
  }
}

type getDataType<E extends IFilterData> = E extends IFilterDataNew
  ? IOsFilterDataNew
  : IOsFilterDataUsed

export function transformFilterDataResponse<T extends IFilterData>(
  data: T,
  isNew = true,
  $pinia
): getDataType<T> {
  const filtersStore = useFiltersStore($pinia)

  const response: T = useCloneDeep(data)

  // default MinMax to 0 if it's empty
  Object.keys(response).forEach((key) => {
    const value = response[key as keyof IFilterData]

    if (isMinMaxType(value) && JSON.stringify(value) === '{}') {
      ;(response[key as keyof IFilterData] as IFilterDataMinMax) = {
        Min: 0,
        Max: 0
      }
    }
  })

  if (!isNew && !filtersStore.isRetailerMode) {
    response.SortOptions.push({
      Description: 'Distance',
      Id: 5
    })
  }

  return {
    ...response,
    Budget: getBudgetData(
      isFilterDataNew(response) ? response.OTR : response.RetailPrice
    ) as IFilterDataBudget
  } as unknown as getDataType<T>
}
