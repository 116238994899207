import { defineStore } from 'pinia'
import { filterDataFactory } from '~/factories/filterDataFactory'
import { ECondition } from '~/types/vehicle'
import { ECustomerTypes } from '~/types/customerType'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFinanceProductsStore } from '~/stores/finance/productsStore'
import {
  IFilterDataBrandModel,
  IFilterDataBudget,
  IFilterDataCommon,
  IFilterDataFilter,
  IFilterDataMinMax,
  IFilterDataNew,
  IFilterDataUsed,
  IOsBodyStyleIds,
  IOsFilterData,
  IOsFilterDataCommon,
  IOsFilterDataNew,
  IOsFilterDataUsed,
  extendsIFilterDataFilter
} from '~/types/filterData'
import { ExcludeCommonProps } from '~/types'

type getDataType<
  E extends keyof (IOsFilterDataNew & IOsFilterDataUsed) | undefined
> = E extends keyof ExcludeCommonProps<IFilterDataNew, IFilterDataCommon>
  ? IOsFilterDataNew[E] | undefined // if T is a newFilter key but condition is Used it will be undefined
  : E extends keyof ExcludeCommonProps<IFilterDataUsed, IFilterDataCommon>
    ? IOsFilterDataUsed[E] | undefined // if T is a usedFilter key but condition is New it will be undefined
    : E extends keyof IFilterDataCommon
      ? IFilterDataCommon[E]
      : E extends keyof IOsFilterDataCommon
        ? IOsFilterDataCommon[E]
        : IOsFilterData

export interface IFilterDataStoreState {
  new: {
    Private: IOsFilterDataNew
    Motability: IOsFilterDataNew
  }
  used: {
    Private: IOsFilterDataUsed
  }
}

export const useFilterDataStore = defineStore('filterData', {
  state: (): IFilterDataStoreState => {
    return {
      new: {
        [ECustomerTypes.Private]: filterDataFactory.create(ECondition.New),
        [ECustomerTypes.Motability]: filterDataFactory.create(ECondition.New)
      },
      used: {
        [ECustomerTypes.Private]: filterDataFactory.create(ECondition.Used)
      }
    }
  },

  actions: {
    async fetchFilterData() {
      const data = await this.api.filters.get()

      if (data) {
        this.new = { ...data.new }
        this.used = { ...data.used }
      }
    }
  },

  getters: {
    getCustomerTypeName(): ECustomerTypes.Motability | ECustomerTypes.Private {
      const customerTypeStore = useCustomerTypeStore()

      return customerTypeStore.isMotability
        ? ECustomerTypes.Motability
        : ECustomerTypes.Private
    },

    getFilterData(): IOsFilterData {
      const { isNew } = useFiltersStore()

      return isNew ? this.getFilterDataNew : this.getFilterDataUsed
    },

    getFilterDataNew(state): IOsFilterDataNew {
      return state.new[this.getCustomerTypeName]
    },

    getFilterDataUsed(state): IOsFilterDataUsed {
      return state.used[ECustomerTypes.Private]
    },

    // returns filterData new/used if no "filter" sent and the "filter" data otherwise
    getData() {
      return <
        T extends keyof (IOsFilterDataNew & IOsFilterDataUsed) | undefined
      >(
        condition: ECondition,
        filter?: T
      ): getDataType<T> => {
        const data: IOsFilterData =
          condition === ECondition.New ||
          this.getCustomerTypeName === ECustomerTypes.Motability
            ? this.getFilterDataNew
            : this.getFilterDataUsed

        if (!filter) {
          return data as getDataType<T>
        }

        return data[filter as keyof IOsFilterData] as getDataType<T>
      }
    },

    getActiveData() {
      return <
        T extends keyof (IOsFilterDataNew & IOsFilterDataUsed) | undefined
      >(
        filter?: T
      ): getDataType<T> => {
        const { isNew } = useFiltersStore()

        return this.getData(isNew ? ECondition.New : ECondition.Used, filter)
      }
    },

    getBudgetData() {
      return (condition?: ECondition): IFilterDataBudget => {
        const { getDefaultMonthlyPrice } = useFinanceProductsStore()

        const budget = condition
          ? this.getData(condition, 'Budget')
          : this.getActiveData('Budget')

        return {
          Price: budget.Price,
          MonthlyPrice: getDefaultMonthlyPrice as IFilterDataMinMax
        }
      }
    },

    getAllBodystyles() {
      return (condition: ECondition) =>
        useMemoize(getAllBodystylesFromBrands)(
          this.getData(condition, 'Brands')
        )
    },

    getAllModels() {
      return (condition?: ECondition) => {
        const brands = condition
          ? this.getData(condition, 'Brands')
          : this.getActiveData('Brands')

        if (!brands) return []

        return useMemoize(getAllModelsFromBrands)(brands)
      }
    },

    getSpecialistModels(): IFilterDataBrandModel[] {
      const { models } = useFiltersStore().active

      const Models = this.getAllModels().filter(({ Id, Specialist }) => {
        return models.includes(Id) && Specialist === true
      })

      return Models
    },

    getSmartModelIds(): number[] {
      return (
        this.getActiveData('Brands')
          .find(({ Description }) => Description === 'smart')
          ?.Models.map((model) => model.Id) || []
      )
    },

    getUnavailableBodystyles() {
      return (
        condition: ECondition,
        Models: IFilterDataBrandModel[]
      ): IOsBodyStyleIds[] => {
        if (Models.length === 0) return []

        const bodyStyles = this.getAllBodystyles(condition)

        const uniqueIds = new Set(Models.map(({ BodyStyleId }) => BodyStyleId))
        const bodyStyleIds = [...uniqueIds]

        return bodyStyles.filter((bodyStyle) => {
          return !bodyStyle.Ids.some((Id) => bodyStyleIds.includes(Id))
        })
      }
    },

    getModelsByFuel() {
      return (
        condition: ECondition | null,
        FuelIds: number[]
      ): IFilterDataBrandModel[] => {
        if (FuelIds.length === 0 || !condition) return []

        const ModelFuels = this.getData(condition, 'ModelFuel')

        const ModelIds = ModelFuels.filter((pivot) =>
          FuelIds.includes(pivot.FuelId)
        ).map(({ ModelId }) => ModelId)

        const Models = this.getAllModels(condition)

        return Models.filter(({ Id }) => ModelIds.includes(Id))
      }
    },

    getFuelIdByFuelType() {
      return (fuelType: string): number | undefined => {
        const fuel = this.getActiveData('Fuels').find(
          (fuel) => fuel.Description === fuelType
        )
        return fuel && fuel.Id
      }
    },

    getModelById() {
      return (id: number): IFilterDataBrandModel | undefined =>
        this.getAllModels().find(({ Id }) => id === Id)
    },

    // get any filterData property by Id
    getPropertyById() {
      return (property: keyof IOsFilterData, id: number) => {
        const results = this.getActiveData(property)

        return extendsIFilterDataFilter(results)
          ? (results as IFilterDataFilter[]).find(({ Id }) => id === Id)
          : undefined
      }
    },

    // get any filterData property by Description
    getPropertyByDescription(): any {
      return (property: keyof IOsFilterData, desc: string) => {
        const results = this.getActiveData(property)

        return extendsIFilterDataFilter(results)
          ? (results as IFilterDataFilter[]).find(
              ({ Description }) => desc === Description
            )
          : undefined
      }
    }
  }
})
