import { useProfileStore } from '~/stores/profileStore'
import { ERoutes } from '~/types/routes'

function restoreMyAccountSession($pinia) {
  const profileStore = useProfileStore($pinia)
  const sessionProfile =
    sessionStorage.profile && JSON.parse(sessionStorage.profile)
  const sessionToken = sessionStorage.token && JSON.parse(sessionStorage.token)

  // Handling my account login persistence
  // Checking if the sessionStorage data exists
  if (sessionProfile?.CiamId && sessionToken?.expires_in) {
    const now = new Date()
    // If the token has expired, logout the profile...
    if (sessionToken.expires_in < now.getTime()) {
      profileStore.logoutProfile()
      // ...otherwise update the store with the sessionStorage data
    } else {
      delete sessionProfile.authToken
      delete sessionProfile.refreshToken

      profileStore.updateProfile(sessionProfile)
      profileStore.updateCustomerDetails(sessionToken.UserInfo)
      profileStore.token = sessionToken
    }
  }
}

export default defineNuxtPlugin(({ _route, $pinia }) => {
  if (_route.name === ERoutes.MyAccountLogout) {
    return
  }
  const profileStore = useProfileStore($pinia)

  restoreMyAccountSession($pinia)

  profileStore.updateCookie()
  // This needs to be lazy
  profileStore.fetchProfileData()
})
