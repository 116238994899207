<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 8">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M4.165 4.62c-.957 0-1.759.742-1.759 1.69 0 .92.771 1.69 1.759 1.69.956 0 1.758-.741 1.758-1.69a1.744 1.744 0 0 0-1.758-1.69zm0 2.787c-.617 0-1.142-.504-1.142-1.097 0-.592.525-1.096 1.142-1.096.616 0 1.14.504 1.14 1.096 0 .593-.493 1.097-1.14 1.097zm0-1.927c-.463 0-.864.356-.833.8 0 .445.37.801.833.801.215 0 .431-.089.616-.237a.811.811 0 0 0 .247-.593c-.03-.415-.4-.77-.863-.77zm16.41-.86c-.955 0-1.758.742-1.758 1.69 0 .92.772 1.69 1.759 1.69.956 0 1.758-.741 1.758-1.69 0-.948-.802-1.69-1.758-1.69zm0 2.758c-.616 0-1.11-.475-1.11-1.038 0-.563.494-1.067 1.08-1.067.617 0 1.08.474 1.08 1.037.092.593-.401 1.038-1.05 1.068zm3.21-3.439c-.618-2.045-1.235-2.757-1.327-2.875l.216-.119-.062-.148c-1.944-.415-5.399-.919-9.964-.77-2.499.088-5.645 2.193-5.83 2.252-.432.178-4.875.593-6.386 1.512L.03 4.828l.092.356L0 5.303l.154 1.185 1.913.386a1.842 1.842 0 0 1-.093-.593c0-1.127.957-2.045 2.129-2.045 1.172 0 2.128.918 2.128 2.045 0 .207-.03.415-.092.593h12.37a1.842 1.842 0 0 1-.093-.593c0-1.127.957-2.045 2.129-2.045 1.11 0 2.036.83 2.129 1.897v.563l.956-.297.37-2.045-.216-.415zm-22.15.682l-.987.356s.216-.653.648-.92c.277-.118 1.172-.325 1.172-.325l-.833.889zm17.522-1.838c-.031 0-5.028.178-5.183.178-.37 0-4.627.089-7.372.356-1.45.118-3.455.237-3.455.237s1.573-.356 3.455-.534C7.835 2.31 10.334.916 12.216.59c.37-.06 1.573-.09 1.573-.09l-.34 2.165c.186 0 .772-.03.834-.03l.34-2.164c1.572 0 2.714.03 3.855.178l.586 1.808h.617l-.555-1.57c1.326.266 2.684 1.037 3.486 1.837-.031-.03-2.93.089-3.455.06zm1.419 2.727c-.463.03-.833.356-.833.8 0 .415.37.8.833.8.432 0 .833-.355.833-.8 0-.444-.37-.8-.833-.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Hatchback'
}
</script>
