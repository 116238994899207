<template>
  <NuxtLayout>
    <section class="errorPage">
      <section class="errorPage__container">
        <section class="errorPage__content">
          <div class="error">
            <h1>{{ message.problem }}</h1>
            <p>
              {{ message.solution }}.<template v-if="messengerStore.isLoaded">
                Or talk to us using

                <OsChatButton
                  class="inline ml-2"
                  :text-only="true"
                  :ga-category="'error-page'"
                  colour="red"
                  >Live chat</OsChatButton
                >
              </template>
            </p>

            <p>
              If you'd like to submit an enquiry in the meantime please use our
              <NuxtLink
                class="errorLink"
                href="https://submit.mercedes-benz.co.uk/callback"
                target="_blank"
              >
                contact form </NuxtLink
              >.
            </p>
          </div>
        </section>
      </section>
    </section>
  </NuxtLayout>
</template>

<script lang="ts">
import { ERouteConditions, ERoutes, HomepageRoute } from '~/types/routes'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { useMessengerStore } from '~/stores/messengerStore'
import { EConsents } from '~/types/usercentrics'

export default {
  name: 'Error',

  setup() {
    const { $dataLayer, $bus } = useNuxtApp()
    const route = useRoute()
    const messengerStore = useMessengerStore()
    const filtersStore = useFiltersStore()
    const customerTypeStore = useCustomerTypeStore()

    const error = useError()
    const { statusCode, message } = useOsError(error)

    useHead({
      bodyAttrs: { class: 'body--errorPage' }
    })

    onBeforeUnmount(() => {
      $bus.off('data-layer:page-view')
    })

    onMounted(() => {
      $bus.on('data-layer:page-view', () => trackPage())

      trackPage()
    })

    const isTracked = ref<boolean>(false)
    const { hasConsent } = useConsent(EConsents.GA)

    const trackPage = () => {
      if (isTracked.value || !hasConsent.value) return

      $dataLayer.pageView({
        action: `${statusCode.value}:${message.value.problem}`,
        category: 'error page'
      })

      isTracked.value = true
    }

    const homepageRoute = computed<HomepageRoute>(() => {
      return {
        name: ERoutes.Homepage,
        params: {
          condition: route.params.condition as ERouteConditions,
          type: customerTypeStore.getCustomerTypeRouteParam
        }
      }
    })

    const getSearchLabel = () => {
      return `search for a ${filtersStore.routeCondition} car`
    }

    return {
      messengerStore,
      filtersStore,
      customerTypeStore,
      message,
      homepageRoute,
      getSearchLabel,
      error
    }
  }
}
</script>

<style lang="scss">
.errorLink {
  color: #fff;
  &--bold {
    color: #fff;
    font-family: $mb-font-text-bold;
    text-decoration: none;
  }
}
.chatButton {
  cursor: pointer;
  display: inline;
  color: $white;
  font-size: unset;
}
</style>
