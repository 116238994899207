import { EConsents } from '~/types/usercentrics'

declare global {
  interface Window {
    UC_UI?: {
      isInitialized: () => boolean
      getServicesBaseInfo: () => any[]
      getServicesFullInfo: () => Promise<any>
      restartCMP: () => void
    }
  }
}

type ConsentEvent = {
  detail: Record<EConsents, boolean> & {
    action?: string
    event?: string
    type?: string
  }
}

// An object to store the consent
// status of User Centrics
const services = reactive<Record<EConsents, boolean>>({
  [EConsents.AWS]: false,
  [EConsents.CIAM]: false,
  [EConsents.Contentful]: false,
  [EConsents.FBPixel]: false,
  [EConsents.Flashtalking]: false,
  [EConsents.GA]: false,
  [EConsents.GMP]: false,
  [EConsents.GTM]: false,
  [EConsents.MMLogin]: false,
  [EConsents.ProfileCookie]: false,
  [EConsents.Salesforce]: false,
  [EConsents.TradeDesk]: false,
  [EConsents.UCMP]: false,
  [EConsents.Userlike]: false,
  [EConsents.Optimizely]: false
})

export const useConsent = (service?: EConsents) => {
  const initialise = () => {
    if (!import.meta.client) return

    if (window.UC_UI?.isInitialized()) {
      // Reduce the array to create an object
      // where the key is the service name
      // and the value is the consent.
      const newServices = window.UC_UI?.getServicesBaseInfo().reduce(
        (acc, item) => {
          acc[item.name] = item.consent.status
          return acc
        },
        {}
      )

      Object.assign(services, newServices)
    }

    // Update the reactive object when
    // User entrics is loaded
    // or when it updates
    window.addEventListener('whenConsentLoaded', (event: ConsentEvent) => {
      const filteredServices = { ...event.detail }

      delete filteredServices.action
      delete filteredServices.event
      delete filteredServices.type

      Object.assign(services, filteredServices)
    })
  }

  // Has switched from false to true
  const hasConsented = (newValue: boolean, oldValue: boolean) =>
    newValue === true && oldValue === false

  // Get constent of given service
  const hasConsent = computed<boolean>(() => {
    if (!service) return false

    return services?.[service] || false
  })

  return {
    initialise,
    services,
    hasConsent,
    hasConsented
  }
}
