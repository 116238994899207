<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24">
    <path
      d="M18.3141436,21.9053305 C18.3141436,22.6831557 18.0150675,23.2818763 17.3566453,23.6412225 L17.4163468,23.6412225 L17.3566453,23.700924 C17.0575693,23.880597 16.6982232,24 16.2791756,24 C15.3813788,24 14.6035537,23.4615494 14.004833,22.4437811 L10.6530206,16.6982232 C9.87519545,16.8778962 9.1565032,16.9972992 8.49864961,16.9972992 C4.00966596,16.9972992 0,13.1667377 0,8.49864961 C0,3.83056148 3.83056148,0 8.49864961,0 C13.1667377,0 16.9972992,3.83056148 16.9972992,8.49864961 C16.9972992,10.7132907 16.159204,12.7482587 14.5432836,14.4835821 L17.8348259,20.22914 C18.1344705,20.9478323 18.3141436,21.4862829 18.3141436,21.9053305 L18.3141436,21.9053305 L18.3141436,21.9053305 Z M8.49864961,14.6035537 C11.850462,14.6035537 14.6035537,11.850462 14.6035537,8.49864961 C14.6035537,5.14683724 11.850462,2.39374556 8.49864961,2.39374556 C5.14683724,2.39374556 2.39374556,5.14683724 2.39374556,8.49864961 C2.39374556,10.1742715 2.99246624,11.6110874 4.18933902,12.8079602 C5.3862118,14.004833 6.82302772,14.6035537 8.49864961,14.6035537 L8.49864961,14.6035537 L8.49864961,14.6035537 Z M5.3862118,9.27704335 L5.3862118,7.72082445 L11.6105188,7.72082445 L11.6105188,9.27704335 L5.3862118,9.27704335 L5.3862118,9.27704335 Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchMinus'
}
</script>
