import ApiFactory from './factory'

class FormsModule extends ApiFactory {
  vehicleDetailsEnquiryCallbackSubmit(data: any) {
    return $osPost<{
      requestProcessorResponse: {
        lead: any
        oneApiResponse: any
        statusCode: number
      }
    }>('v3/durable/enquiry', {
      body: data
    })
  }

  backorderSubmit(data: any) {
    return $osPost('v3/durable/backorder', {
      body: data
    })
  }
}

export default FormsModule
