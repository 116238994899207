<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 7">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.662 4.688c.423 0 .76.338.76.76a.758.758 0 0 1-.76.762.758.758 0 0 1-.76-.761c0-.423.337-.761.76-.761zm19.972-1.213C23.127 1.614 20.648.768 20.648.768l.197-.085-.056-.17C16.338.092 13.38-.275 10.9.289c-.62.141-1.943.564-4.225 1.608 0 0-3.915.253-5.324.79l-.591.253-.592.874.056.62-.225.17S.113 5.759.113 5.73l1.662.31h.028a2.109 2.109 0 0 1-.085-.564c0-1.071.874-1.946 1.944-1.946 1.07 0 1.944.875 1.944 1.946 0 .226-.029.395-.085.592h.17l11.267-.028h.056a2.109 2.109 0 0 1-.084-.564c0-1.071.873-1.946 1.943-1.946s1.944.875 1.944 1.946c0 .113 0 .226-.028.367h.056l2.62-.339.422-.705.113-.987-.366-.338zM1.437 3.84l-.874.367s.198-.62.592-.874c.225-.141 1.014-.339 1.014-.339l-.732.846zm19.802-1.607c-.112.028-.873.028-1.549.028-.14 0-1.211.028-1.69.057-.028 0-7.634.028-11.127.394-2 .198-3.662.31-3.662.31.676-.197 2.254-.45 3.409-.535C8.563 1.614 10.479.627 12.958.627l-.17 1.494c.282 0 .508 0 .79-.028l.14-1.495c1.155.057 2.338.141 3.578.367l.676 1.128h.507l-.592-1.015c1.409.197 2.733.705 3.212.959.14.056.225.169.14.197zM3.662 3.898c-.873 0-1.55.705-1.55 1.55 0 .875.705 1.552 1.55 1.552.873 0 1.55-.705 1.55-1.551 0-.874-.705-1.551-1.55-1.551zm0 2.594A1.04 1.04 0 0 1 2.62 5.45c0-.564.45-1.043 1.042-1.043a1.04 1.04 0 0 1 1.042 1.043 1.04 1.04 0 0 1-1.042 1.043zm15.155-2.594c-.873 0-1.55.705-1.55 1.55 0 .875.705 1.552 1.55 1.552.873 0 1.55-.705 1.55-1.551 0-.874-.677-1.551-1.55-1.551zm0 2.566a.98.98 0 0 1-.986-.987c0-.564.423-.987.986-.987a.98.98 0 0 1 .986.987c0 .508-.45.987-.986.987zm0-1.748c.394 0 .732.338.732.733a.746.746 0 0 1-.732.733.746.746 0 0 1-.732-.733c0-.395.31-.733.732-.733z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShootingBrake'
}
</script>
