import { defineStore } from 'pinia'
import { NewVehicle, StockLevelIndicator, UsedVehicle } from '~/types/vehicle'
import { useFinanceProductsStore } from '~/stores/finance/productsStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'
import { isNewVehicle } from '~/guards/vehicle'

interface VehicleStore {
  vehicle: NewVehicle | UsedVehicle | null
  isLoading: boolean
}

export const useVehicleStore = defineStore('vehicle', {
  state: (): VehicleStore => {
    return {
      vehicle: null,
      isLoading: false
    }
  },

  actions: {
    async fetch(id: string, { isBackOrder = false, isDisplayStock = false }) {
      this.isLoading = true

      const serviceStatusStore = useServiceStatusStore()

      const { isEnabled } = useFeatureFlag('vin_number_enabled')

      const vinId = isEnabled.value
        ? !/^\d/.test(id)
          ? { Vins: [{ Vin: id }] }
          : { CommissionNumber: [id] }
        : { CommissionNumber: [id] }

      const response = await this.api.vehicles.details.get({
        ...vinId,
        IncludeAllocatedToBackOrder: isBackOrder,
        IncludeBackOrderAccountVehicles: isBackOrder,
        IncludeNonSellableDisplayStock: isDisplayStock
      })

      this.save(response.data?.vehicles[0])

      serviceStatusStore.updateAgency(!!response.data?.agencyEnabled)

      this.isLoading = false

      return response.data.vehicles || []
    },

    save(vehicle?: NewVehicle | UsedVehicle) {
      if (!vehicle) {
        this.vehicle = null
        return
      }

      this.vehicle = vehicle
    },

    setVehicle(vehicle: NewVehicle | UsedVehicle | null) {
      if (!vehicle) {
        this.vehicle = null
        return
      }

      this.vehicle = vehicle
    },

    async loadFinance(vehicle: NewVehicle | UsedVehicle | null = null) {
      const localVehicle = vehicle || this.vehicle

      if (!localVehicle) return

      const financeProductsStore = useFinanceProductsStore()
      const financeQuotesStore = useFinanceQuotesStore()
      const serviceStatusStore = useServiceStatusStore()

      const { Vin, VehicleType, ActualPrice } = localVehicle

      await financeProductsStore.fetchProductsForVehicle({
        vin: Vin,
        vehicleType: VehicleType,
        price: ActualPrice
      })

      if (!serviceStatusStore.finance.vinDefaults.isEnabled) {
        financeQuotesStore.reset()
      }
    },

    async fetchStockLevel() {
      const response = await this.api.vehicles.details.getStock(
        this.vehicle?.Vin || ''
      )

      if (response.data?.StockLevelIndicator && this.vehicle) {
        if (isNewVehicle(this.vehicle)) {
          this.vehicle.StockLevelIndicator = response.data
            .StockLevelIndicator as StockLevelIndicator
        }
      }
    },

    async getIsSold(vin?: string): Promise<boolean> {
      if (!vin) return false

      const { data } = await this.api.vehicles.details.getIsSold(vin)

      return !!data?.Vehicles.find((vehicle) => vehicle.Vin === vin)?.isSold
    }
  }
})
