import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { parseDeeplink } from '~/utils/finance'
import { ESortIds } from '~/types/filters'
import { parseQueryValue } from '~/utils/route'

export default defineNuxtPlugin((context) => {
  if (!context._route.query.finance) return

  const financeCriteriaStore = useFinanceCriteriaStore(context.$pinia)
  const filterStore = useFiltersStore(context.$pinia)

  const parsed = parseDeeplink(context._route.query.finance)
  const sorting = parseQueryValue(context._route.query.SortId)

  // finance
  financeCriteriaStore.deeplink = parseQueryValue(context._route.query.finance)
  financeCriteriaStore.setCriteriaFromDeepLink(parsed)

  // sorting
  filterStore.updateSorting(Number(sorting) || ESortIds.MonthlyPriceAscending)

  // we need a timeout to avoid the "navigation cancelled" error
  // if finance sorting is different than the router SortId we redirect in results page too
  setTimeout(() => {
    // We don't want to persist the Finance Deep Link in the URL
    navigateTo({ query: { ...context._route.query, finance: undefined } })
  }, 500)
})
