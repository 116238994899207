<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0,0 L18.6888,0 L18.6888,17.9076 L9.318,24 L0,17.8044 L0,0 L0,0 Z M7.08,15.9828 L15.9816,5.6748 L14.94,4.5804 L6.8712,12.6504 L3.9048,10.5156 L2.6556,11.8176 L7.08,15.9828 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Warranty'
}
</script>
