/**
 * Returns formatted postcode in upper case
 * @param postcode
 * @returns {string|*}
 */

export function formatPostcode(postcode) {
  if (postcode[postcode.length - 4] === ' ') {
    return postcode
  } else {
    const postArr = postcode.split('')
    postArr.splice(-3, 0, ' ')
    return postArr.join('').toUpperCase()
  }
}
