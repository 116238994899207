<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M15.7738667,7.87226667 C15.8328,12.1469333 12.1477333,15.8616 7.9024,15.7730667 C3.62773333,15.8616 -0.0869333333,12.1469333 0.0016,7.87226667 C-0.0869333333,3.62693333 3.62773333,-0.0581333333 7.9024,0.0008 C12.2069333,0.00106666667 15.7738667,3.568 15.7738667,7.87226667 L15.7738667,7.87226667 L15.7738667,7.87226667 Z M8.61013333,7.81333333 L8.61013333,2.94906667 L6.7824,2.94906667 L6.7824,9.46426667 L12.1773333,9.46426667 L12.1773333,7.81333333 L8.61013333,7.81333333 L8.61013333,7.81333333 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Clock'
}
</script>
