export enum ESidepanel {
  FILTERS = 'filters',
  REFINED_SEARCH = 'refinedSearch',
  FORMS = 'forms'
}

export const useSidepanels = () => {
  const sidepanels = useState<Record<ESidepanel, boolean>>(
    'sidepanels',
    () => ({
      [ESidepanel.FILTERS]: false,
      [ESidepanel.REFINED_SEARCH]: false,
      [ESidepanel.FORMS]: false
    })
  )

  const isSidepanelOpen = computed(() =>
    Object.values(sidepanels.value).some(Boolean)
  )

  const closeAllSidepanels = () => {
    for (const key in sidepanels.value) {
      sidepanels.value[key as ESidepanel] = false
    }
  }

  return { closeAllSidepanels, isSidepanelOpen, sidepanels }
}

export const useSidepanel = (sidepanelName: ESidepanel) => {
  const { sidepanels } = useSidepanels()

  const sidepanelModel = computed({
    get() {
      return sidepanels.value[sidepanelName]
    },
    set(val) {
      sidepanels.value[sidepanelName] = val
    }
  })
  const showSidepanel = () => {
    sidepanels.value[sidepanelName] = true
  }
  const hideSidepanel = () => {
    sidepanels.value[sidepanelName] = false
  }
  const toggleSidepanel = () => {
    sidepanels.value[sidepanelName] = !sidepanels.value[sidepanelName]
  }

  const sidepanelScrollableElement = ref<HTMLElement | null>(null)

  watch(sidepanelModel, async (val) => {
    if (val) {
      await nextTick()

      sidepanelScrollableElement.value = document.querySelector(
        `#sidepanel--${sidepanelName} .sidepanel__scrollable`
      )
    }
  })

  const scrollSidepanelToTop = () => {
    if (sidepanelScrollableElement.value) {
      sidepanelScrollableElement.value.scroll({
        top: 0,
        behavior: 'auto'
      })
    } else {
      document.querySelector('.sidepanel__scrollable')?.scrollTo(0, 0)
    }
  }

  const scrollSidepanelToElement = (el: HTMLElement | null) => {
    if (!el) return
    if (sidepanelScrollableElement.value) {
      const top = el.offsetTop - sidepanelScrollableElement.value.clientHeight
      sidepanelScrollableElement.value.scroll({
        top,
        behavior: 'auto'
      })
    }
  }

  return {
    sidepanels,
    sidepanelModel,
    showSidepanel,
    hideSidepanel,
    toggleSidepanel,
    scrollSidepanelToTop,
    scrollSidepanelToElement
  }
}
