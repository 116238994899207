import { defineStore } from 'pinia'
import { IRetailer } from '~/types/retailer'
import { useFiltersStore } from '~/stores/filtersStore'

interface IRetailerStore {
  retailer: IRetailer | null
}

export const useRetailerStore = defineStore('retailer', {
  state: (): IRetailerStore => {
    return {
      retailer: null
    }
  },

  actions: {
    async fetchRetailer(retailerId: string | number | null = null) {
      if (!retailerId) return

      const { data } = await this.api.retailers.get(retailerId.toString())

      if (data?.Retailer) {
        this.updateRetailer(data.Retailer)
      }

      return data.Retailer
    },

    updateRetailer(retailer: IRetailer) {
      this.retailer = retailer
    },

    resetRetailer() {
      this.retailer = null
    }
  },

  getters: {
    hasRetailer(): boolean {
      return this.retailer !== null
    },

    hasFixedRetailer(): boolean {
      const { isNew } = useFiltersStore()

      return isNew && !!this.retailer && isNotMBUK(this.retailer)
    }
  }
})
