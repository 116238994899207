import {
  EFilterMap,
  EQueryParamMap,
  IBaseCriteria,
  ICriteriaPayload,
  IFilterCollection,
  IFiltersRadius,
  IFiltersRange,
  IParams
} from '~/types/filters'
import { ECondition } from '~/types/vehicle'
import { filtersFactory } from '~/factories/filtersFactory'
import {
  ISavedSearchCriteria,
  ISearchCriteria
} from '~/types/profile/savedSearch'
import { ECustomerTypes } from '~/types/customerType'

interface IGetQueryParams {
  asString: string
  asObject: IBaseCriteria
}

export const filterCollection = {
  /**
   * Update a collection of filters with a given set of new filters.s
   */
  updateFilters: (
    currentFilters: IFilterCollection,
    newFilters: Partial<IFilterCollection>
  ): IFilterCollection => {
    return {
      ...currentFilters,
      ...usePick(newFilters, Object.keys(currentFilters))
    }
  },

  /**
   * Compare two collections of filters.
   */
  compare(a: IFilterCollection, b: IFilterCollection): boolean {
    const aCopy = { ...a }
    const bCopy = { ...b }

    if (a.condition === ECondition.New) {
      aCopy.age = filtersFactory.createRange()
      bCopy.age = filtersFactory.createRange()
      aCopy.mileage = filtersFactory.createRange()
      bCopy.mileage = filtersFactory.createRange()
    }

    return isEqual(aCopy, bCopy)
  },

  /**
   * Compare the Active filters with a given saved search.
   */
  compareSavedSearch(
    active: Partial<IFilterCollection>,
    search: Partial<IFilterCollection>,
    { New, Used }
  ): boolean {
    const data = active.condition === ECondition.New ? New : Used

    const activeCopy = this.normaliseRanges(active, data)
    const searchCopy = this.normaliseRanges(search, data)

    return isEqual(activeCopy, searchCopy)
  },

  /**
   * If the ranges are empty, set the to the default values.
   */
  normaliseRanges(filters: Partial<IFilterCollection>, data) {
    const copy = { ...filters }
    const ranges = ['emission', 'mileage', 'age']

    ranges.forEach((key) => {
      if (filterUtils.isEmpty(copy[key])) {
        const mappedKey = EQueryParamMap[key]

        copy[key] = data[mappedKey]
      }
    })

    if (copy.condition === ECondition.New) {
      delete copy.mileage
      delete copy.age
    } else {
      delete copy.deliveryTime
      delete copy.excludeOutOfStock
      delete copy.ageInDays
    }

    return copy
  },

  /**
   * Returns the filters in a format ready
   * to be sent to the API for searching.
   *
   * @param collection
   * @param { New, Used }
   */
  getCriteria(
    collection: IFilterCollection,
    { New, Used },
    isRetailerMode = false,
    isMotability = false
  ): ICriteriaPayload {
    const isNew = collection.condition === ECondition.New
    const data = isNew ? New : Used
    const criteria: ICriteriaPayload = {
      VehicleType: collection.condition,
      LimitToMotability: isMotability,
      LatestModel: collection.latestModel,
      PreviousModel: collection.previousModel
    }

    if (filterUtils.isNotEmpty(collection.models)) {
      criteria.ModelId = collection.models
    }

    if (filterUtils.isNotEmpty(collection.colours)) {
      criteria.ColourId = { Values: collection.colours }
    }

    if (filterUtils.isNotEmpty(collection.transmissions)) {
      criteria.TransmissionId = { Values: collection.transmissions }
    }

    if (filterUtils.isNotEmpty(collection.deliveryTime)) {
      criteria.DeliveryTime = collection.deliveryTime
    }

    if (filterUtils.isNotEmpty(collection.fuels)) {
      criteria.FuelId = { Values: collection.fuels }
    }

    if (filterUtils.isNotEmpty(collection.bodyStyles)) {
      criteria.BodyStyleId = { Values: collection.bodyStyles }
    }

    if (filterUtils.isNotEmpty(collection.engines)) {
      criteria.EngineId = collection.engines
    }

    if (filterUtils.isNotEmpty(collection.upholsteries)) {
      criteria.UpholsteryId = { Values: collection.upholsteries }
    }

    if (filterUtils.isNotEmpty(collection.lines)) {
      criteria.Mapped_Line = collection.lines
    }

    if (filterUtils.isNotEmpty(collection.packages)) {
      criteria.PackageId = collection.packages
    }

    if (
      collection.condition === ECondition.Used &&
      filterUtils.isNotEmpty(collection.age) &&
      !isEqual(collection.age, data.Age)
    ) {
      criteria.Age = collection.age
    }

    if (
      collection.condition === ECondition.Used &&
      filterUtils.isNotEmpty(collection.mileage) &&
      !isEqual(collection.mileage, data.Mileage)
    ) {
      criteria.Mileage = collection.mileage
    }

    if (
      filterUtils.isNotEmpty(collection.emission) &&
      !isEqual(collection.emission, data.Emission)
    ) {
      criteria.Emission = collection.emission
    }

    if (filterUtils.isNotEmpty(collection.keywords)) {
      criteria.Keywords = collection.keywords.join(' ').split(' ')
    }

    if (filterUtils.isNotEmpty(collection.retailers)) {
      criteria.RetailerId = collection.retailers
    }

    if (collection.condition === ECondition.Used && collection.retailerGroups) {
      criteria.RetailerGroupId = collection.retailerGroups
    }

    if (filterUtils.hasMonthlyPrice(collection.budget)) {
      criteria.MonthlyPrice = collection.budget.monthlyPrice
    }

    if (filterUtils.hasPrice(collection.budget)) {
      const key =
        collection.condition === ECondition.New ? 'OTR' : 'RetailPrice'

      criteria[key] = collection.budget.price
    }

    if (filterUtils.isNotEmpty(collection.radius)) {
      const radius = collection.radius.Postcode
        ? {
            Distance: collection.radius.Distance,
            Postcode: collection.radius.Postcode
          }
        : {
            Distance: collection.radius.Distance,
            Lat: collection.radius.Lat,
            Lon: collection.radius.Lon
          }

      criteria.Radius = radius
    }

    if (filterUtils.isNotEmpty(collection.arrivalInDays)) {
      criteria.ArrivalInDays = collection.arrivalInDays
    }

    if (filterUtils.isNotEmpty(collection.ageInDays) && isNew) {
      criteria.AgeInDays = collection.ageInDays
    }

    if (collection.hasOffer) {
      criteria.PromotionalOfferVehiclesOnly = true
    }

    if (isNew && collection.excludeOutOfStock) {
      criteria.ExcludeUnderOfferVehicles = true
    }

    if (isRetailerMode) {
      criteria.IsFixedRetailer = true
    }

    return criteria
  },

  /**
   * Returns the filters in a format ready
   * to be sent to the API for Saved Search,
   * as well as comparing to other Searches.
   */
  getSavedSearchPayload(
    collection: IFilterCollection,
    { New, Used },
    customerMode: ECustomerTypes = ECustomerTypes.Private,
    fixedRetailerMode = false
  ): ISavedSearchCriteria {
    const criteria: ISavedSearchCriteria = filterCollection.getCriteria(
      collection,
      { New, Used },
      fixedRetailerMode
    )

    // overwrite splitted keywords
    if (filterUtils.isNotEmpty(collection.keywords)) {
      criteria.Keywords = collection.keywords
    }

    if (filterUtils.isNotEmpty(collection.lines)) {
      criteria.LineId = collection.lines

      delete criteria.Mapped_Line
    }

    if (collection.retailerName) {
      criteria.RetailerName = collection.retailerName
    }

    if (collection.condition === ECondition.New) {
      delete criteria.Age
      delete criteria.Mileage
    }

    criteria.CustomerMode = customerMode

    delete criteria.LatestModel
    delete criteria.LimitToMotability
    delete criteria.PreviousModel
    delete criteria.AgeInDays

    return criteria
  },

  /**
   * Create a new collection of filters from a set of params.
   */
  fromQueryParams(
    params: IParams,
    condition: ECondition = ECondition.New
  ): IFilterCollection {
    const collection = filtersFactory.create(condition)

    for (const [key, value] of Object.entries(params)) {
      const mappedKey = EFilterMap[key]
      const isStringArray = [
        'Keywords',
        'RetailerGroup',
        'RetailerGroupId',
        'Retailer'
      ].includes(key)

      if (mappedKey) {
        // List Filters (Models, Lines, etc)
        if (filterUtils.isList(collection[mappedKey])) {
          collection[mappedKey] = (
            isArray(value) ? value : value.split(',')
          ).map(isStringArray ? String : Number)
        }

        // String Filter
        if (filterUtils.isString(collection[mappedKey])) {
          collection[mappedKey] = decodeURI(value)
        }

        // Range Filters (Age, Mileage, etc)
        if (filterUtils.isRange(collection[mappedKey])) {
          const [Min, Max] = (isArray(value) ? value : value.split(',')).map(
            Number
          )

          collection[mappedKey] = { Min, Max }
        }

        // Budget Filter
        if (filterUtils.isBudget(collection[mappedKey])) {
          const [Min, Max] = (isArray(value) ? value : value.split(',')).map(
            Number
          )

          let budget

          if (key === 'MonthlyPrice') {
            budget = {
              monthlyPrice: { Min, Max },
              price: { Min: null, Max: null }
            }
          } else {
            budget = {
              price: { Min, Max },
              monthlyPrice: { Min: null, Max: null }
            }
          }

          collection[mappedKey] = budget
        }
      }

      // Radius Filter
      if (key === 'Distance') {
        collection.radius.Distance = Number(value)
      }

      if (key === 'Keywords') {
        collection.keywords = decodeURI(value).split(',')
      }

      if (key === 'Postcode') {
        collection.radius.Postcode = decodeURI(value)
      }

      if (key === 'Lat') {
        collection.radius.Lat = Number(value)
      }

      if (key === 'Lon') {
        collection.radius.Lon = Number(value)
      }

      // Booleans
      if (
        [
          'LatestModel',
          'PreviousModel',
          'PromotionalOfferVehiclesOnly',
          'ExcludeUnderOfferVehicles'
        ].includes(key)
      ) {
        collection[mappedKey] = value === 'true'
      }
    }

    return collection
  },

  /**
   * Returns the filters as a URLSearchParams string, to be used in a URL.
   */
  getQueryParams(
    collection: IFilterCollection,
    { New, Used }
  ): IGetQueryParams {
    const data = collection.condition === ECondition.New ? New : Used
    const isNew = collection.condition === ECondition.New
    const isUsed = !isNew
    const params: URLSearchParams = new URLSearchParams()

    for (const [key, value] of Object.entries(collection)) {
      // excludeOutOfStock is true by default, so we need to add the false value too
      // todo: do this dynamic checking what's the initial value of the filter
      if (key === 'ageInDays' && isUsed) continue

      if (key === 'excludeOutOfStock') {
        if (isUsed) continue

        const mappedKey = EQueryParamMap[key]

        if (mappedKey) {
          params.set(mappedKey, value.toString())
        }

        continue
      }

      if (typeof value === 'boolean') {
        const mappedKey = EQueryParamMap[key]

        if (mappedKey && value) {
          params.set(mappedKey, value.toString())
        }

        continue
      }

      if (filterUtils.isEmpty(value)) continue

      if (key === 'radius') {
        const { Distance, Postcode = '', Lat = 0, Lon = 0 } = value

        params.set('Distance', Distance)

        if (Postcode) {
          params.set('Postcode', Postcode)
        } else {
          params.set('Lat', Lat)
          params.set('Lon', Lon)
        }

        continue
      }

      if (key === 'budget') {
        const isMonthly = filterUtils.hasMonthlyPrice(value)
        const mappedKey = isMonthly ? 'MonthlyPrice' : 'Price'
        const { Min, Max } = isMonthly ? value.monthlyPrice : value.price

        params.set(mappedKey, `${Min},${Max}`)

        continue
      }

      if (key === 'deliveryTime') {
        if (isUsed) continue

        params.set(EQueryParamMap[key], value.join(','))

        continue
      }

      if (filterUtils.isRange(value)) {
        if (['age', 'mileage'].includes(key) && isNew) continue

        const mappedKey = EQueryParamMap[key]

        if (isEqual(value, data[mappedKey])) continue

        params.set(mappedKey, `${value.Min},${value.Max}`)

        continue
      }

      if (key === 'retailerGroups' && isNew) continue

      if (
        collection.retailerGssnId?.length ||
        collection.retailerGroups?.length
      ) {
        if (key === 'retailers' || key === 'retailerName') continue
      }

      if (filterUtils.isList(value)) {
        const mappedKey = EQueryParamMap[key]

        params.set(mappedKey, value.join(','))
      }

      if (filterUtils.isString(value)) {
        const mappedKey = EQueryParamMap[key]

        params.set(mappedKey, value)
      }
    }

    return {
      asString: params.toString(),
      asObject: Object.fromEntries(params)
    }
  },

  /**
   * Create a new collection of filters from a set of search criteria.
   */
  fromSearchCriteria(criteria: ISearchCriteria): IFilterCollection {
    const collection = filtersFactory.create()

    for (const [key, value] of Object.entries(criteria)) {
      const mappedKey = EFilterMap[key]

      if (mappedKey) {
        if (key === 'RetailerGssnId' && !criteria.IsFixedRetailer) continue

        // List Filters (Models, Lines, etc)
        if (filterUtils.isList(collection[mappedKey])) {
          if (Array.isArray(value)) {
            collection[mappedKey] = value
          } else {
            collection[mappedKey] = value.Values
          }
        }

        // Range Filters (Age, Mileage, etc)
        if (filterUtils.isRange(collection[mappedKey])) {
          collection[mappedKey] = value
        }

        // Budget Filter
        if (filterUtils.isBudget(collection[mappedKey])) {
          const { Min, Max } = value as IFiltersRange

          let budget

          if (key === 'MonthlyPrice') {
            budget = {
              monthlyPrice: { Min, Max },
              price: { Min: null, Max: null }
            }
          } else {
            budget = {
              price: { Min, Max },
              monthlyPrice: { Min: null, Max: null }
            }
          }

          collection[mappedKey] = budget
        }

        // String Filter
        if (filterUtils.isString(collection[mappedKey])) {
          collection[mappedKey] = value
        }
      }

      // Radius Filter
      if (filterUtils.isRadius(collection[mappedKey])) {
        const radius = value as IFiltersRadius

        if (radius.Lat) {
          collection.radius = {
            Distance: radius.Distance,
            Lat: radius.Lat,
            Lon: radius.Lon
          }
        } else {
          collection.radius = {
            Distance: radius.Distance,
            Postcode: radius.Postcode
          }
        }
      }

      // Booleans
      if (
        [
          'LatestModel',
          'PreviousModel',
          'PromotionalOfferVehiclesOnly',
          'ExcludeUnderOfferVehicles'
        ].includes(key)
      ) {
        collection[mappedKey] = value
      }

      // Vehicle Type
      if (key === 'VehicleType') {
        collection.condition =
          value === 'NEW' ? ECondition.New : ECondition.Used
      }

      if (key === 'RetailerNames') {
        collection.retailerName = value[0] || ''
      }
    }

    return collection
  }
}
