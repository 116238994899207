<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M14.667 10h-2l-6 6-6-6 3.5-3.5V2A2.008 2.008 0 0 1 5.394.156C5.637.052 5.895 0 6.166 0A2.008 2.008 0 0 1 8.01 1.227c.104.244.156.502.156.773v1.5l6.5 6.5zm0 0l.156.39c.104.261.219.56.344.9.125.338.24.674.343 1.007.105.333.157.568.157.703a1.006 1.006 0 0 1-.29.71 1.006 1.006 0 0 1-1.102.21.98.98 0 0 1-.32-.21 1.006 1.006 0 0 1-.29-.71c.002-.135.054-.37.158-.703.104-.333.219-.67.344-1.008.125-.338.24-.638.343-.898l.157-.391zm-9.5-8v3.5l2-2V2a1.006 1.006 0 0 0-.29-.71 1.006 1.006 0 0 0-1.102-.21.98.98 0 0 0-.32.21 1.006 1.006 0 0 0-.29.71zm4.672 8l1-1-2.672-2.672v1.313a.792.792 0 0 1 .289.156c.067.062.117.13.148.203.031.073.05.151.055.234.005.084.008.172.008.266a.989.989 0 0 1-.29.71 1.006 1.006 0 0 1-1.102.21.98.98 0 0 1-.53-.522.989.989 0 0 1-.078-.398c0-.094.002-.182.007-.266A.701.701 0 0 1 6.73 8a.599.599 0 0 1 .149-.203.792.792 0 0 1 .289-.156V6.328L3.495 10h6.344z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Paint'
}
</script>
