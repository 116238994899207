import { useProfileStore } from '~/stores/profileStore'

export default defineNuxtRouteMiddleware(() => {
  const { $pinia } = useNuxtApp()
  const profileStore = useProfileStore($pinia)
  const now = new Date()

  const hasExpired =
    profileStore.token?.expires_in &&
    profileStore.token.expires_in < now.getTime()

  if (hasExpired) {
    profileStore.logoutProfile()

    // if (process.client) {
    //   profileStore.updateCookie()
    //   delete sessionStorage.profile
    //   delete sessionStorage.token
    // }
  }
})
