import { NSavedSearch } from '~/types/api'
import ApiFactory from '~/api/factory'
import { useProfileStore } from '~/stores/profileStore'

class SavedSearchModule extends ApiFactory {
  profileId() {
    const { getProfileId } = useProfileStore(this.pinia)

    return getProfileId
  }

  get() {
    const getUrl = `${
      this.apiVersion
    }/myaccount/profile/${this.profileId()}/savedsearches`

    return $osGet<NSavedSearch.get.Response>(getUrl)
  }

  add(payload: NSavedSearch.add.Payload) {
    const getUrl = `${
      this.apiVersion
    }/myaccount/profile/${this.profileId()}/savedsearches`

    return $osPost(getUrl, { body: payload })
  }

  delete(searchId: string) {
    const getUrl = `${
      this.apiVersion
    }/myaccount/profile/${this.profileId()}/savedsearches/${searchId}`

    return $osDelete(getUrl)
  }

  deleteAll() {
    const getUrl = `${
      this.apiVersion
    }/myaccount/profile/${this.profileId()}/savedsearches`

    return $osDelete(getUrl)
  }
}

export default SavedSearchModule
