import ApiFactory from './factory'
import {
  IValuationCustomerDetails,
  IValuationCustomerDetailsResponse,
  IValuationRequest,
  IValuationResponse
} from '~/types/valuation'

class ValuationModule extends ApiFactory {
  async createValuation(valuationPayload: IValuationRequest) {
    return await $osPost<IValuationCustomerDetailsResponse>('v3/valuation', {
      body: valuationPayload
    })
  }

  async addCustomerDetailsToValuation(
    customerDetails: IValuationCustomerDetails
  ) {
    return await $osPut<IValuationCustomerDetailsResponse>(
      `v3/valuation/customer`,
      {
        body: customerDetails
      }
    )
  }

  async getValuation(
    valuationId: IValuationResponse['ValuationId'],
    profileId?: string
  ) {
    const url =
      profileId !== undefined
        ? `v3/valuation/${valuationId}/profile/${profileId}`
        : `v3/valuation/${valuationId}`

    // TODO: Remove any type
    return await $osGet<IValuationResponse>(url)
  }
}

export default ValuationModule
