<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M12,0 C5.37230769,0 0,5.37230769 0,12 C0,18.6276923 5.37230769,24 12,24 C18.6276923,24 24,18.6276923 24,12 C24,5.37230769 18.6276923,0 12,0 Z M16.6153846,12.3415385 L9.65538462,16.3569231 C9.6,16.3938462 9.52615385,16.4123077 9.46153846,16.4123077 C9.24923077,16.4123077 9.07384615,16.2369231 9.07384615,16.0246154 L9.07384615,7.99384615 C9.07384615,7.92923077 9.09230769,7.85538462 9.12923077,7.8 C9.24,7.61538462 9.48,7.55076923 9.66461538,7.65230769 L16.6246154,11.6676923 C16.68,11.7046154 16.7353846,11.7507692 16.7723077,11.8153846 C16.8830769,12 16.8184615,12.24 16.6246154,12.3507692 L16.6153846,12.3415385 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Play'
}
</script>
