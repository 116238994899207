<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 23">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5.1,21.7c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7c2,0,3.7,1.7,3.7,3.7S7.1,21.7,5.1,21.7L5.1,21.7z M10,18.6
	c0-0.2,0-0.4,0-0.6c1.8,0,8.4-0.1,12.1-0.3c0.9,0,1.5-0.4,1.8-0.5c2.6-1.6,5.4-6.3,5.7-7.5c0.6-2.2-1.1-6.2-2.2-8.2
	c1,0.1,1.8,0.1,2.5,0.2l0.2,0c0.4,1,1.7,4,1.9,4.5l0,0c0.3,0.7,0.7,1.7,2,1.7h0.2c0.2,0.6,0.4,1.6,0.6,2.4c-0.6,0.3-1.4,0.7-2.3,1.5
	c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6,0c0.4,0,0.8,0,1.2,0.1c0.3-0.2,0.6-0.4,0.9-0.5c1.1,0.8,1.6,2.1,1.8,3.5
	c-0.9-1.1-2.3-1.9-3.9-1.9c-2.7,0-5,2.2-5,5c0,0.2,0,0.4,0,0.6H10z M33.3,21.7c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7
	c2,0,3.7,1.7,3.7,3.7S35.3,21.7,33.3,21.7L33.3,21.7z M2.2,10.9c-0.9,0.5-2.6,2-2.1,6.7c0,0.3,0,0.7,0.1,0.8
	c0.2,2.5,2.4,4.5,4.9,4.5c2.1,0,3.9-1.3,4.6-3.1h19c0.7,1.8,2.5,3.1,4.6,3.1c2.3,0,4.2-1.5,4.8-3.6l0,0c0.7-2,0.9-6.7-1.8-8.9
	C36,9.2,35.4,7,35.4,7c-0.1-0.3-0.3-0.5-0.6-0.5h-0.7c-0.4,0-0.5-0.2-0.8-0.9l0,0c0-0.1-0.1-0.1-0.1-0.2L35,5.3
	c-0.1-0.5-0.2-0.9-0.3-1.3l-2,0.1c-0.6-1.4-1.4-3.3-1.5-3.4c-0.1-0.2-0.3-0.4-0.5-0.4L30,0.3C29,0.2,27.9,0.1,26.2,0
	c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.1,0.5,0,0.7c0.7,1,1.8,3.2,2.4,5.2l-3.4,0.2V0l-1.3,0v6.5L14.2,7l-0.1-1.3l-1.3,0.1L13,9
	l1.3-0.1l0-0.6l14.1-0.7c0.1,0.7,0.1,1.3,0,1.7c-0.2,0.8-2.8,5.3-5.2,6.8c-0.2,0.1-0.6,0.3-1.2,0.4c-3.8,0.2-10.7,0.3-12.2,0.3
	C9.3,14.6,7.4,13,5.1,13c-1.5,0-2.8,0.6-3.7,1.7C1.8,12.4,3,12,3,12c0.3-0.1,0.5-0.3,0.5-0.6c0-1.2,0.3-2.6,3.7-2.7l4.6-0.2
	l-0.1-1.3L9.7,7.2c1.6-1.6,4.9-4.6,7.8-5.8V0c-4.2,1.5-9,6.6-9.7,7.3L7,7.4C3.9,7.5,2.4,8.7,2.2,10.9L2.2,10.9z M5.1,19.2
	c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S5.7,19.2,5.1,19.2L5.1,19.2z M5.1,15.5c-1.4,0-2.5,1.1-2.5,2.5
	s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5S6.5,15.5,5.1,15.5L5.1,15.5z M33.3,19.2c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2
	s1.2,0.5,1.2,1.2S34,19.2,33.3,19.2L33.3,19.2z M33.3,15.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5
	S34.7,15.5,33.3,15.5L33.3,15.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SmartCabrio'
}
</script>
