<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5 11v-1h6v1H5zm0 2v-1h6v1H5zm11-8v6h-2v5H2v-5H0V5h2V0h12v5h2zM4 2v3h8V2H4zm0 12h8V9H4v5zm11.25-7.188v-1h-1v1h1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Print'
}
</script>
