<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8 0c1.104 0 2.143.208 3.117.625a8.028 8.028 0 0 1 2.547 1.71 8.028 8.028 0 0 1 1.711 2.548C15.792 5.857 16 6.896 16 8a7.841 7.841 0 0 1-.625 3.117 8.028 8.028 0 0 1-1.71 2.547 8.028 8.028 0 0 1-2.548 1.711A7.841 7.841 0 0 1 8 16a7.841 7.841 0 0 1-3.117-.625 8.028 8.028 0 0 1-2.547-1.71 8.028 8.028 0 0 1-1.711-2.548A7.841 7.841 0 0 1 0 8c0-1.104.208-2.143.625-3.117a8.028 8.028 0 0 1 1.71-2.547A8.028 8.028 0 0 1 4.884.625 7.841 7.841 0 0 1 8 0zm1 13.484a5.409 5.409 0 0 0 1.602-.554 5.675 5.675 0 0 0 2.328-2.328c.265-.495.45-1.03.554-1.602H11.5V7h1.984a5.508 5.508 0 0 0-1.546-2.945 5.79 5.79 0 0 0-1.336-.985A5.409 5.409 0 0 0 9 2.516V4.5H7V2.516a5.409 5.409 0 0 0-1.602.554A5.675 5.675 0 0 0 3.07 5.398 5.408 5.408 0 0 0 2.516 7H4.5v2H2.516c.104.573.289 1.107.554 1.602a5.675 5.675 0 0 0 2.328 2.328c.495.265 1.03.45 1.602.554V11.5h2v1.984z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Location'
}
</script>
