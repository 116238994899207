<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 4">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.97 3.94a1.97 1.97 0 1 0 0-3.94 1.97 1.97 0 0 0 0 3.94Zm6.03 0A1.97 1.97 0 1 0 8 0a1.97 1.97 0 0 0 0 3.94Zm6.03 0a1.97 1.97 0 1 0 0-3.94 1.97 1.97 0 0 0 0 3.94Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'More'
}
</script>
