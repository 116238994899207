<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M9.216,3.312 C6.576,3.312 4.416,5.472 4.416,8.112 C4.416,10.752 6.576,12.912 9.216,12.912 C11.856,12.912 14.016,10.752 14.016,8.112 C14.016,5.472 11.856,3.312 9.216,3.312 L9.216,3.312 Z M13.296,8.112 C13.296,8.784 13.152,9.408 12.864,9.984 L9.984,7.728 L9.456,4.032 C11.568,4.176 13.296,5.952 13.296,8.112 L13.296,8.112 Z M9.024,4.08 L8.544,7.776 L5.616,10.08 C5.328,9.504 5.136,8.88 5.136,8.16 C5.136,5.952 6.864,4.176 9.024,4.08 L9.024,4.08 Z M9.216,12.192 C7.824,12.192 6.576,11.472 5.808,10.368 L9.216,8.976 L12.624,10.32 C11.904,11.472 10.656,12.192 9.216,12.192 L9.216,12.192 Z M18.816,16.32 L11.616,16.32 L10.224,24 L8.544,24 L7.2,16.32 L0,16.32 L0,0 L18.816,0 L18.816,16.32 L18.816,16.32 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'MapPin'
}
</script>
