import { ECondition, EVehicleType } from '~/types/vehicle'
import { ERouteConditions } from '~/types/routes'
import { EPageNames } from '~/api/pages'

// todo: remove comment - previous getCondition
export default {
  fromVehicleType(value: EVehicleType): ECondition {
    if (!value) return ECondition.New
    return value === EVehicleType.New ? ECondition.New : ECondition.Used
  },

  fromConditonRouteParam(value: ERouteConditions): ECondition {
    if (!value) return ECondition.New
    return value === ERouteConditions.New ? ECondition.New : ECondition.Used
  },

  toVehicleType(value: ECondition): EVehicleType {
    if (!value) return EVehicleType.New
    return value === ECondition.New ? EVehicleType.New : EVehicleType.Used
  },

  toPageName(value: ECondition): EPageNames {
    if (!value) return EPageNames.New
    return value === ECondition.New ? EPageNames.New : EPageNames.Used
  }
}
