const TIMEOUT = 60 * 1000 * 2

const hasTimePassed = ref(false)
const isSurveyComplete = ref(false)

export const useSurvey = (immediate: boolean = false) => {
  // This will start in survey plugin
  const startSession = () =>
    setTimeout(() => {
      hasTimePassed.value = true
    }, TIMEOUT)

  const isKioskMode = useState('kioskmode')

  const showSurvey = computed(() => {
    if (isKioskMode.value) return false

    if (isSurveyComplete.value) return false

    if (immediate) return true

    return hasTimePassed.value
  })

  const setSurveyComplete = () => {
    setTimeout(() => {
      isSurveyComplete.value = true
    }, 3000)
  }

  return {
    startSession,
    hasTimePassed,
    showSurvey,
    setSurveyComplete
  }
}
