<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M13.687 13.685c-1.383 0-2.408-1.123-2.408-2.728 0-.504.19-1.26.405-1.88.19-.596.382-1.192.382-1.604 0-.94-.525-1.421-1.598-1.421l-.334.023v8.504h.93V16H0v-1.421h.93V0h9.156v4.928c2.337 0 3.124 1.032 3.124 2.59-.024.299-.167.872-.405 1.697-.263.848-.406 1.421-.406 1.788 0 .94.525 1.604 1.145 1.604.763 0 1.264-.985 1.264-2.498V5.34l-.835-.871V2.613L12.4.963l.954-.848L16 2.935v7.174c0 1.444-.262 2.407-.787 2.888-.5.459-1.025.688-1.526.688zm-5.58-8.092V1.948H2.981v3.645h5.126zM6.724 9.948c0-.48-1.025-2.223-1.168-2.223l-.596.894c-.406.642-.596 1.1-.596 1.33 0 .641.5 1.146 1.168 1.146.668 0 1.192-.505 1.192-1.147z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Diesel'
}
</script>
