import { useFormsStore } from '~/stores/forms/formsStore'

export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp()
  const formsStore = useFormsStore($pinia)

  if (to.query.utm_content) {
    const utm = to.fullPath.split('?').pop()
    formsStore.updateMeta({ utm })

    return
  }

  if (to.query.csref) {
    const csref = String(to.query.csref)

    const parsedCsref = filterUtils.isString(to.query.csref)
      ? to.query.csref.split('ai-')[1] || to.query.csref.split('_')[0]
      : ''

    formsStore.updateMeta({ csref: { parsedCsref, csref } })
  }

  if (to.query.gagcmid) {
    const gagcmid = to.fullPath.split('?').pop()
    formsStore.updateMeta({ gagcmid })
  }

  if (to.query.gclid) {
    const gclid = to.fullPath.split('?').pop()
    formsStore.updateMeta({ gclid })
  }
})
