import { defineStore } from 'pinia'
import { useFilterDataStore } from '../filterDataStore'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { ICriteriaPayload } from '~/types/filters'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { NVehiclesSearch } from '~/types/api'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'

// TODO: Rename to just searchStore
interface IVehiclesSearchState {
  vehicles: Array<NewVehicle | UsedVehicle>
  bestMatchVehicles: Array<NewVehicle | UsedVehicle>
  promoOffer: any | null
  meta: {
    count: {
      all: number
      new: number
      used: number
    }
    pages: {
      current: number
      total: number
    }
    data: {
      Age: {
        Min: number
        Max: number
      }
      Mileage: {
        Min: number
        Max: number
      }
      OTR: {
        Min: number
        Max: number
      }
      ProductRange: Array<string>
      RetailPrice: {
        Min: number
        Max: number
      }
      Vins: Array<string>
      VinsLocatedByKeyword: Array<string>
    } | null
  }
  scrollPosition: number
  isNightMode: boolean
  hasSearchedNew: boolean
  isLoading: boolean
}

let abortController: AbortController | null = null

export const useVehiclesSearchStore = defineStore('vehicles/search', {
  state: (): IVehiclesSearchState => {
    return {
      vehicles: [],
      bestMatchVehicles: [],
      promoOffer: null,
      meta: {
        count: {
          all: 0,
          new: 0,
          used: 0
        },
        pages: {
          current: 0,
          total: 0
        },
        data: null
      },
      scrollPosition: 0,
      isNightMode: false,
      hasSearchedNew: false,
      isLoading: false
    }
  },

  actions: {
    async fetch() {
      const serviceStatusStore = useServiceStatusStore()
      const { isNew, active } = useFiltersStore()

      // Do not search new vehicles without retailer
      if (isNew && isEmpty(active.retailers)) return

      // Stop duplicate request
      if (abortController) {
        abortController.abort('Abort Search')
      }

      abortController = new AbortController()
      const signal = abortController?.signal

      this.isLoading = true

      const response = await this.api.vehicles.search.get({ signal })

      if (response.error) {
        throw createError({
          statusCode: response.error.code
        })
      }

      if (isNew) {
        this.hasSearchedNew = true
      }

      serviceStatusStore.updateAgency(!!response.data.agencyEnabled)

      this.updateVehicleData(response.data.searchResults)

      this.isLoading = false
      return response.data.searchResults
    },

    async fetchPromoOffer() {
      if (!useFiltersStore().isNew) return
      const response = await this.api.vehicles.search.getPromoOffers()
      const promoOffer = response?.data

      if (promoOffer) {
        this.promoOffer = Array.isArray(promoOffer) ? promoOffer[0] : null
      }
    },

    fetchNextPage() {
      this.meta.pages.current += 1
    },

    fetchPreviousPage() {
      this.meta.pages.current -= 1
    },

    resetVehicleData() {
      this.vehicles = []
      this.bestMatchVehicles = []
      this.meta.count.new = 0
      this.meta.count.used = 0
      this.meta.pages.total = 0
      this.meta.pages.current = 0
      this.scrollPosition = 0
    },

    resetPromoData() {
      this.promoOffer = null
    },

    updateVehicleData(data: NVehiclesSearch.Results) {
      this.vehicles = data.Vehicles
      this.bestMatchVehicles = data.BestMatchVehicles
      this.meta.count.all = data.TotalResults
      this.meta.count.new = data.TotalResultsNew
      this.meta.count.used = data.TotalResultsUsed
      this.meta.pages.total = data.TotalPages
      this.meta.data = data.Metadata
      this.scrollPosition = 0
    },

    updateScrollPosition(pos: number) {
      this.scrollPosition = pos
    }
  },

  getters: {
    getSearchCriteria(): ICriteriaPayload {
      const filterDataStore = useFilterDataStore()
      const { active, isRetailerMode } = useFiltersStore()
      const { isMotability } = useCustomerTypeStore()
      const New = filterDataStore.getFilterDataNew
      const Used = filterDataStore.getFilterDataUsed

      return filterCollection.getCriteria(
        active,
        { New, Used },
        isRetailerMode,
        isMotability
      )
    }
  }
})
