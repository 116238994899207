<template>
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.654 0c.207.089.403.196.59.32.427.263.78.627 1.033 1.06.368.664.564 1.23.564 1.673a.507.507 0 0 1-.147.418l-1.45.86c.074.419.173.862.295 1.329.27 1.017.676 1.994 1.205 2.903a11.19 11.19 0 0 0 1.917 2.462c.32.313.656.608 1.007.886l1.475-.863c.024-.025.074-.025.147-.025.664.002 2.04 2.068 2.04 3.151a.51.51 0 0 1-.147.419l-2.212 1.304a.445.445 0 0 1-.344.099c-.836 0-1.942-.837-3.342-2.535a30.851 30.851 0 0 1-3.686-5.808C.54 5.463 0 3.74 0 2.46c0-.615.147-1.01.443-1.182L2.653 0h.001Zm3.7 3.925c.313 0 .583.069.812.208.185.113.323.252.413.416.09.164.142.372.155.624h-.738l-.011-.086a.592.592 0 0 0-.118-.274c-.117-.151-.294-.226-.527-.226a.712.712 0 0 0-.396.108.375.375 0 0 0-.187.326c0 .124.068.225.204.301.077.041.296.135.657.281.36.143.578.237.656.282.229.126.386.281.472.462.068.147.102.314.102.499 0 .404-.15.72-.45.949-.245.188-.57.281-.977.281-.492 0-.87-.122-1.135-.367-.265-.243-.407-.6-.426-1.068h.744l.013.1c.076.448.346.673.81.673.188 0 .342-.04.463-.12a.352.352 0 0 0 .173-.308c0-.152-.092-.279-.275-.38-.073-.04-.303-.14-.691-.302-.418-.176-.695-.335-.83-.478a1.037 1.037 0 0 1-.278-.73c0-.19.042-.367.128-.53.084-.162.204-.293.358-.395.25-.164.543-.246.879-.246Zm8.055 0c.313 0 .583.069.812.208.186.113.323.252.413.416.09.164.141.372.155.624h-.738l-.011-.086a.592.592 0 0 0-.118-.274c-.117-.151-.293-.226-.527-.226a.711.711 0 0 0-.396.108.374.374 0 0 0-.188.326c0 .124.069.225.206.301.076.041.294.135.656.281.36.143.578.237.656.282.229.126.386.281.472.462.068.147.102.314.102.499 0 .404-.15.72-.451.949-.244.188-.57.281-.975.281-.492 0-.871-.122-1.136-.367-.264-.243-.406-.6-.426-1.068h.744l.014.1c.074.448.345.673.81.673.187 0 .342-.04.462-.12a.353.353 0 0 0 .174-.308c0-.152-.092-.279-.276-.38a11.24 11.24 0 0 0-.692-.302c-.418-.176-.694-.335-.828-.478a1.037 1.037 0 0 1-.279-.73c0-.19.043-.367.127-.53.086-.162.205-.293.36-.395.25-.164.542-.246.878-.246ZM9.523 4l.864 3.176L11.263 4h1.064V8h-.694V4.883L10.777 8H9.99l-.844-3.117V8h-.694V4h1.072Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SMS'
}
</script>
