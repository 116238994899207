import { IVehiclePayload } from '~/types/forms'
import { IVehicle, NewVehicle, UsedVehicle } from '~/types/vehicle'
import { isNewVehicle } from '~/guards/vehicle'

export const getVehiclePayload = (
  vehicle: IVehicle | NewVehicle | UsedVehicle,
  isBusiness = false,
  isMotability = false
): IVehiclePayload => {
  const payload: any = {}

  payload.CommissionNumber = vehicle.CommissionNumber || ''
  payload.Description = vehicle.Description || ''
  payload.OTRPrice = isNewVehicle(vehicle)
    ? (vehicle.OTR && formatGBP(vehicle.OTR)) || ''
    : (vehicle.RetailPrice && formatRetailPrice(vehicle.RetailPrice)) || ''
  payload.FuelType = vehicle.FuelType || ''
  payload.Transmission = vehicle.Transmission || ''
  payload.Upholstery = vehicle.UpholsteryDescription || ''
  payload.Colour = vehicle.Colour || ''
  payload.VehicleType = vehicle.VehicleType
  payload.MainImageUrl = vehicle.Media?.MainImageUrl || ''
  payload.VehicleDetailsUrl = '' // TODO: Use Kosta's solution when available
  payload.PortArrivalDate = isNewVehicle(vehicle) ? vehicle.PortArrivalDate : ''

  payload.IsPipelineStock = isNewVehicle(vehicle)
    ? !!vehicle.IsPipelineStock
    : false

  if (!isNewVehicle(vehicle)) {
    payload.Emissions =
      vehicle.Emission === null || vehicle.Emission === undefined
        ? ''
        : vehicle.Emission
    payload.RetailPrice = formatRetailPrice(vehicle.RetailPrice || 0)
    payload.RegistrationNumber = vehicle.RegistrationNumber || ''
    payload.RegistrationDate = vehicle.RegistrationDate || ''
    payload.Mileage = vehicle.Mileage || ''
  }

  if (vehicle.ElectricRange) {
    payload.ElectricRange = vehicle.ElectricRange
  }

  if (
    isMotability &&
    vehicle.Motability &&
    vehicle.Retailer.IsMotabilityRetailer
  ) {
    const { CivilianAdvanceRental, WPMSAdvancePayment } = vehicle.Motability

    if (CivilianAdvanceRental !== null) {
      payload.motabilitycivilianadvancedrental = CivilianAdvanceRental
    }

    if (WPMSAdvancePayment !== null) {
      payload.motabilitywpmsadvancedrental = WPMSAdvancePayment
    }
  }

  if (isBusiness) {
    payload.P11D = vehicle.P11D
  }

  return payload
}
