<template>
  <Icon v-bind="$props" :name="iconName ?? ''" width="1em" height="1em" />
</template>

<script lang="ts">
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: '1em'
    },
    class: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup(props) {
    const iconName = computed(() => {
      const normalisedName = normaliseString(props.name).replace(/\s/g, '')
      const firstLetter = normalisedName.slice(0, 1)

      if (!firstLetter) return

      return `Icons${normalisedName.replace(
        firstLetter,
        firstLetter.toUpperCase()
      )}`
    })

    return {
      iconName
    }
  }
}
</script>
