<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 9">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M4.355 5.146c-1.08 0-1.952.86-1.952 1.927C2.403 8.14 3.274 9 4.355 9c1.082 0 1.953-.86 1.953-1.927a1.936 1.936 0 0 0-1.953-1.927zm0 3.172a1.274 1.274 0 1 1 0-2.55c.721 0 1.292.564 1.292 1.275-.03.742-.6 1.275-1.292 1.275zm0-2.223a.994.994 0 0 0-.99.978c0 .534.45.978.99.978.541 0 .992-.444.992-.978a.994.994 0 0 0-.992-.978zM23.67 4.524l-.03-1.097-1.142-2.55.21-.237-.06-.326C20.576.047 10.693-.22 10.033.284 8.71 1.322 7.479 2.508 7.479 2.508l-6.217.8-.811.652L0 4.88l.24.444-.21.148.3 1.512 1.713.237a2.885 2.885 0 0 1-.06-.504c0-1.274 1.05-2.312 2.342-2.312s2.343 1.038 2.343 2.312c0 .237-.06.504-.12.741h10.814c0-.03 0-.088-.03-.118a1.888 1.888 0 0 1-.09-.593c0-1.304 1.05-2.342 2.373-2.342 1.321 0 2.372 1.038 2.372 2.342v.296l1.863-.533.15-1.423-.33-.563zm-22.318.355L.6 5.087l.45-.92.781-.296.571.356-1.051.652zm2.042-1.037s.871-.267 4.205-.445c1.442-1.334 2.404-2.253 2.854-2.58.871-.266 3.514-.147 3.514-.147l-.18 2.49c.36-.03.751-.03 1.082-.06l.15-2.46h3.724l.601 2.312h.51l-.6-2.282 1.983.118.87 1.63-.15.623s-.39.06-.93.119c-.571.06-1.352.118-2.314.148 0 0-8.56.267-10.723.445-2.403.089-4.596.089-4.596.089zm16.28 1.304c-1.08 0-1.952.86-1.952 1.927 0 1.067.871 1.927 1.953 1.927 1.08 0 1.952-.86 1.952-1.927a1.936 1.936 0 0 0-1.952-1.927zm0 3.172a1.274 1.274 0 1 1 0-2.55c.721 0 1.292.564 1.292 1.275-.03.742-.6 1.275-1.291 1.275zm0-2.223a.994.994 0 0 0-.99.978c0 .534.45.978.99.978.541 0 .992-.444.992-.978a.994.994 0 0 0-.991-.978z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SUV'
}
</script>
