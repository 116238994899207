import { FeatureFlags } from '~/plugins/featureFlags.server'

type FeatureFlag = keyof FeatureFlags

export const useFeatureFlag = (featureFlag: FeatureFlag) => {
  const defaultFlags: FeatureFlags = {
    geolocation_enabled: { enabled: false },
    cross_sell_enabled: { enabled: false },
    commission_number_enabled: { enabled: true },
    stock_level_enabled: { enabled: false },
    vin_number_enabled: { enabled: true },
    optimizely_enabled: { enabled: false },
    onedoc_enabled: { enabled: true },
    value_my_car_enabled: { enabled: false },
    opening_hours_enabled: { enabled: true },
    ocd_enabled: { enabled: false }
  }

  const { data } = useNuxtData<FeatureFlags | null>('featureFlags')

  const isEnabled = computed<boolean>(() => {
    return (
      data.value?.[featureFlag]?.enabled ?? defaultFlags[featureFlag].enabled
    )
  })

  return {
    isEnabled
  }
}
