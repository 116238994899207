import dayjs from 'dayjs'
import { ISearchCriteria } from '~/types/profile/savedSearch'
import {
  ECondition,
  EVehicleType,
  NewVehicle,
  PreOrderVehicle,
  UsedVehicle
} from '~/types/vehicle'
import {
  ECriteriaMap,
  EFilterDataMap,
  IFilterCollection,
  TCriteriaMapKey
} from '~/types/filters'
import { IRetailer } from '~/types/retailer'
import { IValuationCustomerDetailsSpecification } from '~/types/valuation'
import { isUsedVehicle } from '~/guards/vehicle'
import { IFilterDataNew, IFilterDataUsed } from '~/types/filterData'

const keys = {
  ModelNames: '',
  BodyStyleNames: '',
  FuelNames: '',
  TransmissionNames: '',
  DeliveryTimeNames: '',
  MappedLineNames: '',
  MonthlyPrice: '',
  Price: '',
  EngineNames: 'Engine: ',
  PackageNames: 'Package: ',
  ColourNames: '',
  Radius: '',
  UpholsteryNames: '',
  VehicleType: '',
  Emission: '',
  Keywords: '',
  Age: 'Age: ',
  Mileage: '',
  PromotionalOfferVehiclesOnly: '',
  RetailerNames: '',
  ExcludeUnderOfferVehicles: '',
  OTR: '',
  RetailPrice: ''
}

export const offerTag = 'Cars with offers'

export const inStockOnlyTag = 'In stock only'

const fromSearchCriteria = (criteria: ISearchCriteria): string[] => {
  const tags: string[] = []

  for (const [key, value] of Object.entries(keys)) {
    if (!criteria[key]) continue

    if (key === 'VehicleType') {
      tags.push(criteria[key] === EVehicleType.New ? 'New car' : 'Used car')
      continue
    }

    if (key === 'Radius') {
      tags.push(filterUtils.getRadiusAsString(criteria[key]))
      continue
    }

    if (['MonthlyPrice', 'Price', 'OTR', 'RetailPrice'].includes(key)) {
      if (criteria[key]?.Min == null || criteria[key]?.Max == null) continue

      const prefix = key === 'MonthlyPrice' ? 'Monthly:' : 'Price:'
      tags.push(`${prefix} ${getFormattedBudget(criteria[key])}`)
      continue
    }

    if (key === 'Emission') {
      const { Min, Max } = criteria[key]
      tags.push(`${Min}—${Max} g/km`)
      continue
    }

    if (key === 'Mileage') {
      const { Min, Max } = criteria[key]
      tags.push(`${Min}—${Max} miles`)
      continue
    }

    if (key === 'Age') {
      const { Min, Max } = criteria[key]
      tags.push(`${value}${Min}—${Max}`)
      continue
    }

    if (key === 'PromotionalOfferVehiclesOnly') {
      tags.push(offerTag)
      continue
    }

    if (key === 'ExcludeUnderOfferVehicles') {
      tags.push(inStockOnlyTag)
      continue
    }

    if (criteria[key]?.length > 0) {
      const values = criteria[key]
        .filter((item) => !!item)
        .map((item) => value + item)

      if (values.length === 0) continue

      tags.push(...values)
    }
  }

  return tags
}

const isCriteriaMap = (
  key: keyof ECriteriaMap | string
): key is TCriteriaMapKey => {
  return (<any>Object).keys(ECriteriaMap).includes(key)
}

const fromFilterCollection = (
  collection: Partial<IFilterCollection>,
  { New, Used }: { New: IFilterDataNew; Used: IFilterDataUsed },
  tagsSelected: Partial<Record<TCriteriaMapKey, string>> = keys
): string[] => {
  const isNew = collection.condition === ECondition.New
  const data = isNew ? New : Used
  const tags: string[] = []

  for (const [key, value] of Object.entries(tagsSelected)) {
    if (!isCriteriaMap(key)) continue

    const mappedKey = ECriteriaMap[key]

    if (!collection[mappedKey]) continue

    if (key === 'VehicleType') {
      tags.push(
        collection.condition === ECondition.New ? 'New car' : 'Used car'
      )

      continue
    }

    if (key === 'Radius') {
      if (filterUtils.isEmpty(collection.radius)) continue

      tags.push(filterUtils.getRadiusAsString(collection.radius))

      continue
    }

    if (key === 'MonthlyPrice') {
      if (!collection.budget || !filterUtils.hasMonthlyPrice(collection.budget))
        continue

      tags.push(
        `Monthly: ${getFormattedBudget(collection.budget.monthlyPrice)}`
      )

      continue
    }

    if (key === 'Price') {
      if (!collection.budget || !filterUtils.hasPrice(collection.budget))
        continue

      tags.push(`Price: ${getFormattedBudget(collection.budget.price)}`)

      continue
    }

    if (key === 'Emission') {
      const { emission } = collection
      if (filterUtils.isEmpty(emission) || isEqual(emission, data.Emission))
        continue

      const { Min, Max } = emission
      tags.push(`${Min}—${Max} g/km`)

      continue
    }

    if (key === 'Mileage') {
      if (isNew) continue

      const { mileage } = collection

      if (filterUtils.isEmpty(mileage) || isEqual(mileage, data.Mileage))
        continue

      const { Min, Max } = mileage
      tags.push(`${Min}—${Max} miles`)

      continue
    }

    if (key === 'Age') {
      if (isNew) continue

      const { age } = collection

      if (filterUtils.isEmpty(age) || isEqual(age, data.Age)) continue

      const { Min, Max } = age
      tags.push(`${value}${Min}—${Max}`)

      continue
    }

    if (key === 'ModelNames') {
      const values = collection.models

      if (!values || values.length === 0) continue

      const allModels = getAllModelsFromBrands(data.Brands)

      const mappedValues = allModels
        .filter(({ Id }) => values.includes(Id))
        .map(({ Description }) => value + Description)

      if (mappedValues.length === 0) continue

      tags.push(...mappedValues)

      continue
    }

    if (key === 'BodyStyleNames') {
      const values = collection.bodyStyles

      if (!values || values.length === 0) continue

      const allStyles = getAllBodystylesFromBrands(data.Brands)

      const mappedValues = allStyles
        .filter(({ Ids }) => Ids.some((id) => values.includes(id)))
        .map(({ Description }) => value + Description)

      if (mappedValues.length === 0) continue

      tags.push(...mappedValues)

      continue
    }

    if (key === 'Keywords') {
      const values = collection.keywords

      if (!values || values.length === 0) continue

      const mappedValues = values.map((keyword) => value + keyword)

      tags.push(...mappedValues)

      continue
    }

    if (key === 'RetailerNames') {
      const values = collection.retailerName

      if (!values || values.length === 0) continue

      tags.push(values)
      continue
    }

    if (key === 'PromotionalOfferVehiclesOnly') {
      if (collection.hasOffer) {
        tags.push(offerTag)
      }

      continue
    }

    if (key === 'ExcludeUnderOfferVehicles') {
      if (isNew && collection.excludeOutOfStock) {
        tags.push(inStockOnlyTag)
      }

      continue
    }

    if (collection[mappedKey]?.length > 0) {
      const dataKey = EFilterDataMap[mappedKey]

      if (!data[dataKey]) continue

      const values = collection[mappedKey]

      const mappedValues = data[dataKey]
        .filter(({ Id }) => values.includes(Id))
        .map(({ Description }) => value + Description)

      if (mappedValues.length === 0) continue

      tags.push(...mappedValues)
    }
  }

  return tags
}

const minimumVehicleTags = (
  vehicle: NewVehicle | UsedVehicle,
  options?: Partial<{
    includeRetailer: boolean
    includeStockLevel: boolean
    retailer: string
  }>
): string[] => {
  options = {
    includeRetailer: false,
    includeStockLevel: true,
    retailer: '',
    ...options
  }
  const { Retailer, VehicleType, DisplayRetailer, Brand, StockLevelIndicator } =
    vehicle
  const tags: string[] = []
  const isUsed = isUsedVehicle(vehicle)

  if (options.includeRetailer) {
    const fallback =
      Brand?.Description === 'SMART' && Retailer?.smart
        ? Retailer?.smartDescription || Retailer?.Description
        : Retailer?.Description || ''

    const owningRetailer = vehicle.OwningRetailer?.Description

    const localRetailer = isUsed
      ? owningRetailer
      : DisplayRetailer?.Description || options.retailer || fallback

    if (localRetailer) tags.push(localRetailer)
  }

  if (VehicleType) {
    tags.push(isUsed ? 'Used' : 'New')
  }

  if (options.includeStockLevel && StockLevelIndicator) {
    const stockLevel = getStockLevelFormatted(StockLevelIndicator)
    stockLevel && tags.push(stockLevel.text)
  }

  return tags
}

const fromVehicle = (
  vehicle: NewVehicle | UsedVehicle,
  includeRetailer = false,
  retailer = '',
  includeStockLevel = true
): string[] => {
  const {
    FuelType,
    TransmissionType,
    RegistrationDate,
    VehicleSource,
    Colour
  } = vehicle

  const isUsed = isUsedVehicle(vehicle)
  const tags = minimumVehicleTags(vehicle, {
    includeRetailer,
    includeStockLevel,
    retailer
  })

  if (FuelType) {
    tags.push(FuelType)
  }

  if (TransmissionType) {
    tags.push(TransmissionType)
  }

  if (isUsed && vehicle.Mileage) {
    const miles = Number(vehicle.Mileage).toLocaleString()

    tags.push(`${miles} Miles`)
  }

  if (isUsed && vehicle.Age !== undefined) {
    tags.push(dayjs(RegistrationDate).format('MM/YYYY'))
  }

  if (VehicleSource?.Description) {
    tags.push(VehicleSource.Description)
  }

  if (Colour) {
    tags.push(Colour)
  }

  return tags
}

const fromPreOrderVehicle = (
  vehicle: PreOrderVehicle,
  retailer: IRetailer
): string[] => {
  const { Fuel, Transmission, Colour } = vehicle
  const tags: string[] = []

  if (retailer?.Description) {
    tags.push(retailer.Description)
  }

  if (Fuel) {
    tags.push(Fuel)
  }

  if (Transmission) {
    tags.push(Transmission)
  }

  if (Colour) {
    tags.push(Colour)
  }

  return tags
}

const fromCustomerDetails = (
  spec: IValuationCustomerDetailsSpecification
): string[] => {
  const { getCleanDate } = useDate()
  const {
    RegistrationNumber,
    Mileage,
    FuelType,
    Colour,
    TransmissionType,
    RegistrationDate
  } = spec

  const keys = [RegistrationNumber, FuelType, Colour, TransmissionType]

  if (Mileage !== undefined) keys.push(`${Mileage} miles`)
  if (RegistrationDate !== undefined)
    keys.push(
      `${dayjs(new Date(getCleanDate(RegistrationDate))).format('DD/MM/YYYY')}`
    )

  return keys.filter(Boolean)
}

export default {
  fromCustomerDetails,
  fromSearchCriteria,
  fromFilterCollection,
  minimumVehicleTags,
  fromVehicle,
  fromPreOrderVehicle
}
