import dayjs from 'dayjs'

// STRINGS
// todo: remove - old normalise
export function normaliseString(name: string) {
  const normalizeString = name.normalize('NFD')
  return normalizeString.replace(/[\u0300-\u036f]/g, '')
}

/**
 * Capitalise the first letter of every word in a string
 * @param {string} t Text
 */
export function capitalise(t: string) {
  return t.replace(/(^\w{1})|(\s{1}\w{1})/g, (m) => m.toUpperCase())
}

// SORTING
export function sortByOrderDate<T extends { OrderDate: string }>(data: T[]) {
  return data.sort((a, b) => {
    const format = 'DD/MM/YYYY HH:mm:ss'
    const aOrderDate = dayjs(a.OrderDate, format)
    const bOrderDate = dayjs(b.OrderDate, format)

    if (aOrderDate.isSame(bOrderDate)) return 0

    if (aOrderDate.isBefore(bOrderDate)) {
      return 1
    }

    return -1
  })
}

// TODO: Move this somewhere gud. Maybe rename is too
export function iconFill(active: boolean) {
  const { osColours } = useUi()

  return active ? osColours.blue : osColours.greyDark
}
