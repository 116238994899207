<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0 2)">
      <transition name="fade">
        <g v-if="badge > 0" key="1">
          <path
            d="m12.2 10.1c-1.1 0-2.9 0-5.6 0l-4.4 2.8v-2.8h-1.8c-.3 0-.4-.1-.4-.4v-7.8c0-.3.1-.4.4-.4h13.8.2c-.6.7-3.2 3.9-2.2 8.6zm-9.7-6.1v1.1h9.2v-1.1zm0 3.6h6.1v-1h-6.1zm11.4 6.3c-1.1 0-2.8 0-5 0-.3 0-.4-.1-.4-.4v-1.9h4.1c.3.5.6 1.3 1.3 2.3z"
            fill="currentColor"
          />
          <circle
            clip-rule="evenodd"
            cx="23"
            cy="8"
            fill="#e1292b"
            fill-rule="evenodd"
            r="8"
          />
          <text
            text-anchor="middle"
            x="23"
            y="12"
            class="badge__label"
            :class="{ 'badge__label--small': badge > 9 }"
          >
            {{ badge }}
          </text>
        </g>
        <g v-else key="2">
          <path
            d="m14.2 10.1h-7.5l-4.4 2.8v-2.8h-1.9c-.3 0-.4-.1-.4-.4v-7.8c0-.3.1-.4.4-.4h13.8c.3 0 .4.1.4.4v7.8c0 .3-.1.4-.4.4zm-11.7-6.1v1.1h9.2v-1.1zm0 3.6h6.1v-1h-6.1zm17.5-.1v6c0 .3-.1.4-.4.4h-1.4v2.2l-3.4-2.2h-5.9c-.3 0-.4-.1-.4-.4v-1.9h7.2c.3 0 .4-.1.4-.4v-4.1h3.5c.3 0 .4.1.4.4z"
            fill="currentColor"
          />
        </g>
      </transition>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Chat',
  props: {
    badge: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style lang="scss">
.badge__label {
  font-size: rem(14);
  font-family: $mb-font-text;
  fill: #ffffff;
  font-weight: bold;
  text-anchor: middle;
}
.badge__label--small {
  font-size: rem(12);
}
</style>
