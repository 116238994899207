import {
  EFinanceProductCustomerType,
  EFinanceProductKeys,
  IFinanceProduct
} from '~/types/finance/product'
import { IQuoteDto, IQuoteError } from '~/types/finance/quote'

export const isQuoteError = (
  res: IQuoteError | IQuoteDto
): res is IQuoteError => !!(res as IQuoteError).Error

/**
 * Accepts an array of finance products and returns
 * filtered results
 */
export const getProducts = (products: IFinanceProduct[]) => ({
  get personal(): IFinanceProduct[] {
    return products.filter(
      (p: IFinanceProduct) =>
        p.CustomerType === EFinanceProductCustomerType.Personal
    )
  },
  get business(): IFinanceProduct[] {
    return products.filter(
      (p: IFinanceProduct) =>
        p.CustomerType === EFinanceProductCustomerType.Business
    )
  },
  get default(): IFinanceProduct {
    return products.find((p: IFinanceProduct) => p.IsDefault)!
  }
})

// TODO: Refactor to getFinancePayload()
export function formatCustomerFinanceData(
  data: IQuoteDto | IQuoteError | undefined,
  isPersonalised: boolean,
  vehicleOTR: number,
  isBusiness = false
): any {
  if (!data || isQuoteError(data)) {
    return 'Finance unavailable'
  }
  const monthlyPayments = `${data.DurationOfAgreement} Monthly Payments of*`
  const {
    MonthlyPayment,
    OTR,
    CustomerDeposit,
    TotalDepositContribution,
    TotalDeposit,
    AmountOfCredit,
    OptionalPurchasePayment,
    PurchaseActivationFee,
    PartExchange,
    Settlement,
    TotalAmountPayable,
    DurationOfAgreement,
    AnnualMileage,
    ExcessMileageCharge,
    FixedInterestRate,
    APR,
    PaymentProfile,
    TermsAndConditions,
    IsPCP,
    IsCH,
    IsBHP,
    IsBCP,
    IsPHP,
    IsBOL
  } = data

  const isPurchase = IsPHP || IsPCP || IsBHP || IsBCP
  const financeContribution = isPurchase
    ? 'Finance Deposit Contribution'
    : 'Finance Contribution'

  let quoteType = 'Your finance quote'
  if (!isPersonalised) {
    quoteType = isBusiness ? 'Finance example' : 'Representative example'
  }

  const financeQuoteData = {
    'Quote Type': quoteType,
    'Finance Product': data.Product,
    ...(isPurchase && {
      [monthlyPayments]: `£${formatOTRPrice(MonthlyPayment)}`
    }),
    ...(!isPurchase && {
      [`Monthly Rental${IsCH || IsBOL || IsBHP ? ' (excl. VAT) ' : ''}*`]: `£${formatOTRPrice(MonthlyPayment)}`
    }),
    ...(!isPurchase && {
      [`Advance Rental${IsCH || IsBOL || IsBHP ? ' (excl. VAT)' : ''}`]: `£${formatOTRPrice(CustomerDeposit)}`
    }),
    ...(isPurchase &&
      (!!vehicleOTR && vehicleOTR > 0
        ? { 'On The Road Price': `£${formatOTRPrice(OTR)}` }
        : { 'Retail Price': `£${formatOTRPrice(OTR)}` })),

    ...(isPurchase && {
      'Customer Deposit': `£${formatOTRPrice(CustomerDeposit)}`
    }),
    ...(TotalDepositContribution && {
      [financeContribution]: `£${formatOTRPrice(TotalDepositContribution)}`
    }),
    'Indicative car valuation': `£${formatOTRPrice(PartExchange)}`,
    'Settlement Figure': `£${formatOTRPrice(Settlement)}`,
    ...(isPurchase && { 'Total Deposit': `£${formatOTRPrice(TotalDeposit)}` }),

    ...(isPurchase && {
      'Purchase Activation Fee': `£${formatOTRPrice(PurchaseActivationFee)}`
    }),
    ...(isPurchase && {
      'Amount of Credit': `£${formatOTRPrice(AmountOfCredit)}`
    }),
    ...((IsPCP || IsBCP) && {
      'Optional Purchase Payment': `£${formatOTRPrice(OptionalPurchasePayment)}`
    }),
    ...(isPurchase && {
      'Total Amount Payable': `£${formatOTRPrice(TotalAmountPayable)}`
    }),
    'Duration of Agreement': `${DurationOfAgreement} months`,
    ...(!IsBHP &&
      !IsPHP && {
        'Annual Mileage': `${AnnualMileage} miles`,
        [`Excess Mileage Charge${IsCH || IsBOL ? ' (excl. VAT)' : ''}`]: `${ExcessMileageCharge} pence per mile`
      }),
    ...(!isPurchase && { 'Payment Profile': PaymentProfile }),
    ...(isPurchase && { 'Fixed Interest Rate': `${FixedInterestRate}%` }),
    ...(isPurchase && { 'Representative APR': `${APR}% APR` }),
    'Terms and Conditions': TermsAndConditions
  }

  return financeQuoteData
}

/**
 * Returns an object with all the possible sentences
 * available on a compliance statement
 *
 * @param   {Object} data FinanceQuote object
 * @returns {Object}      Returns the formatted object
 */
export function formattedComplianceText(data: IQuoteDto): any {
  const advancedRentalAmount = data.TotalDepositContribution
    ? formatOTRPrice(data.TotalDeposit - data.TotalDepositContribution)
    : formatOTRPrice(data.TotalDeposit)

  return {
    durationOfAgreement: `Based on ${data.DurationOfAgreement} month ${data.Product}.`,
    advanceRental: `£${advancedRentalAmount} advance rental.`,
    annualMileage: `${data.AnnualMileage} miles per year.`,
    mileageAndCharges: 'Mileage & damage charges may apply.',
    noOwnershipOption: 'No ownership option.',
    customerDeposit: `£${formatOTRPrice(
      data.CustomerDeposit
    )} customer deposit.`,
    optionalPurchasePayment: `£${formatOTRPrice(
      data.OptionalPurchasePayment
    )} Optional Purchase Payment.`,
    representativeAPR: `Representative ${data.APR}% APR.`,
    excludeVAT: 'Rentals exclude VAT.'
  }
}

/**
 * Cleanly merge the Global finance products
 * with the VIN-specific finance products
 *
 * @param {Array} allProducts Global Finance Products
 * @param {Array} vehicleProducts Vehicle-specific Finance Products
 * @returns {Array} Processed finance products
 */
export function getUniqueFinanceProducts(
  allProducts: IFinanceProduct[],
  vehicleProducts: IFinanceProduct[]
): IFinanceProduct[] {
  const vehicleProductKeys = vehicleProducts.map((product) => product.Key)

  return allProducts.map((globalProduct) => {
    if (vehicleProductKeys.includes(globalProduct.Key)) {
      const vinProduct = vehicleProducts.find(
        (vinProduct) => vinProduct.Key === globalProduct.Key
      )

      if (vinProduct) {
        vinProduct.IsDefault = globalProduct.IsDefault && vinProduct.IsDefault

        return vinProduct
      }
    }

    return globalProduct
  })
}

export function isQuote(
  value: IQuoteDto | IQuoteError | undefined
): value is IQuoteDto {
  if (!value) return false

  return !('Error' in value)
}

/**
 * Returns true when the given key is a purchase product.
 * @param key
 */
export const isPurchase = (key: EFinanceProductKeys): boolean => {
  return [
    EFinanceProductKeys.PCP,
    EFinanceProductKeys.PHP,
    EFinanceProductKeys.BCP,
    EFinanceProductKeys.BHP
  ].includes(key)
}

export const isBusiness = (key: EFinanceProductKeys): boolean => {
  return [
    EFinanceProductKeys.BCP,
    EFinanceProductKeys.BHP,
    EFinanceProductKeys.BOL,
    EFinanceProductKeys.CH
  ].includes(key)
}

export const isPersonal = (key: EFinanceProductKeys): boolean => {
  return [
    EFinanceProductKeys.PCP,
    EFinanceProductKeys.PCH,
    EFinanceProductKeys.PHP,
    EFinanceProductKeys.POL
  ].includes(key)
}

export const excludesVAT = (key: EFinanceProductKeys): boolean => {
  return [EFinanceProductKeys.CH, EFinanceProductKeys.BOL].includes(key)
}
