import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'

export function getPromoOffersPayload(page: string) {
  const { $pinia } = useNuxtApp()
  const filterStore = useFiltersStore($pinia)
  const customerTypeStore = useCustomerTypeStore($pinia)
  const { condition, models, fuels, engines } = filterStore.active

  const body: {
    Page: string[]
    VehicleType: string[]
    Mode: string[]
    Models?: number[]
    Fuel?: number[]
    Engines?: number[]
  } = {
    Page: [page],
    VehicleType: [convertCondition.toVehicleType(condition)],
    Mode: []
  }

  const bodyMode = customerTypeStore.getCustomerTypeRouteParam
  body.Mode = bodyMode ? [bodyMode] : []

  if (models.length > 0) body.Models = [...models]
  if (fuels.length > 0) body.Fuel = [...fuels]
  if (engines.length > 0) body.Engines = [...engines]

  return body
}
