import { defineStore } from 'pinia'
import { useFilterDataStore } from './filterDataStore'
import { ECondition } from '~/types/vehicle'
import {
  ESortIds,
  IFilterCollection,
  IBaseCriteria,
  ICriteriaPayload
} from '~/types/filters'
import { ERouteConditions } from '~/types/routes'
import {
  IOsFilterDataNew,
  IOsFilterDataUsed,
  extendsIFilterDataFilter
} from '~/types/filterData'
import { filtersFactory } from '~/factories/filtersFactory'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { IRetailer } from '~/types/retailer'

interface FiltersStore {
  active: IFilterCollection
  sorting: ESortIds | null
  openFilters: (keyof IFilterCollection)[]
  popularModels: string[]
  retailer: IRetailer | null
}

export const useFiltersStore = defineStore('filters', {
  state: (): FiltersStore => {
    return {
      active: filtersFactory.create(),
      sorting: null,
      openFilters: [],
      popularModels: [],
      retailer: null
    }
  },

  actions: {
    addOpenFilters(filterName: keyof IFilterCollection) {
      if (!this.openFilters.includes(filterName)) {
        this.openFilters.push(filterName)
      }
    },

    clearOpenFilters() {
      this.openFilters = []
    },

    async saveFilters(newFilters?: Partial<IFilterCollection>) {
      const { resetVehicleData, resetPromoData } = useVehiclesSearchStore()

      this.active = filterCollection.updateFilters(
        this.active,
        newFilters || {}
      )

      if (
        filterUtils.isNotEmpty(this.active.retailers) &&
        this.active.retailers[0] !== this.retailer?.Id
      ) {
        await this.fetchRetailer(this.active.retailers[0])
      }

      resetVehicleData()
      resetPromoData()
    },

    removeRetailerMode() {
      this.active.retailers = []
      this.active.retailerGroups = []
      this.active.retailerGssnId = []
      this.resetRetailer()
    },

    updateSorting(sorting?: ESortIds | number, defaultToRoute = true) {
      const filterDataStore = useFilterDataStore()
      const searchStore = useVehiclesSearchStore()

      let sortId = sorting || this.sorting

      // fix - Calling useRoute within middleware may lead to misleading results
      if (!sortId && defaultToRoute) {
        const route = useRoute()
        sortId = parseInt(route.query.SortId as string)
      }

      const defaultSortOptions = filterDataStore.getActiveData('SortOptions')

      // Check if selected sort is valid, otherwise clear the value to use the default in getSortId
      this.sorting =
        defaultSortOptions?.find((option) => option.Id === sortId)?.Id ||
        this.sorting

      // Always reset to first page
      searchStore.meta.pages.current = 0
    },

    async fetchRetailer(retailerId: string | number | null = null) {
      if (!retailerId) return

      const { data } = await this.api.retailers.get(retailerId.toString())

      if (data?.Retailer) {
        this.updateRetailer(data.Retailer)
      }

      return data?.Retailer
    },

    updateRetailer(retailer: IRetailer) {
      this.retailer = retailer
    },

    resetRetailer() {
      this.active.retailers = []
      this.active.retailerGroups = []
      this.active.retailerGssnId = []
      this.retailer = null
    }
  },

  getters: {
    isNew: (state): boolean => {
      return state.active.condition === ECondition.New
    },

    getSortId(state): ESortIds {
      const filterDataStore = useFilterDataStore()
      const defaultSortOptions = filterDataStore.getActiveData('SortOptions')
      const defaultRadius = filtersFactory.createRadius()
      let sortId = defaultSortOptions[0].Id

      if (!isEqual(this.active.radius, defaultRadius)) {
        sortId = getSortOptionFromDescription(
          defaultSortOptions,
          'Distance'
        )?.Id
      }
      // If a user has set a MonthlyPrice, set sorting by Montly Price
      else if (filterUtils.hasMonthlyPrice(this.active.budget)) {
        sortId = getSortOptionFromDescription(
          defaultSortOptions,
          'Monthly Price'
        )?.Id
      }
      // If a user has set a Price, set sorting by Price
      else if (filterUtils.hasPrice(this.active.budget)) {
        sortId = getSortOptionFromDescription(defaultSortOptions, 'Price')?.Id
      }

      // Check if selected sort is valid, otherwise use the default one
      const defaultSorting =
        defaultSortOptions.find((option) => option.Id === sortId)?.Id ||
        defaultSortOptions[0].Id
      return state.sorting || defaultSorting
    },

    isSortedByMonthlyPrice(state) {
      return [
        ESortIds.MonthlyPriceAscending,
        ESortIds.MonthlyPriceDescending
      ].includes(state.sorting as ESortIds)
    },

    routeCondition(): ERouteConditions {
      return this.isNew ? ERouteConditions.New : ERouteConditions.Used
    },

    isRetailerMode(state): boolean {
      if (state.active.condition === ECondition.New)
        return !!state.active.retailerGssnId.length

      return (
        !!state.active.retailerGroups.length ||
        !!state.active.retailerGssnId.length
      )
    },

    getDescriptions(state) {
      return (
        key: keyof IOsFilterDataNew | keyof IOsFilterDataUsed | 'Models',
        conditionAttr?: ECondition,
        collectionAttr?: IFilterCollection
      ) => {
        const condition = conditionAttr || state.active.condition
        const collection = collectionAttr || state.active
        const isUsed = condition === ECondition.Used

        if (isUsed && key === 'DeliveryTime') return ''

        const filterDataStore = useFilterDataStore()

        const values =
          key === 'Models'
            ? filterDataStore.getAllModels()
            : filterDataStore.getData(condition, key)

        if (!values || !extendsIFilterDataFilter(values)) return ''

        const filterKey = (
          key === 'DeliveryTime' ? 'deliveryTime' : key.toLowerCase()
        ) as keyof IFilterCollection

        const active = values
          .filter(({ Id }) => collection[filterKey].includes(Id))
          .map(({ Description }) => Description)

        return active && active?.length > 0 ? active.join() : ''
      }
    },

    getActiveQueryParams(state): IBaseCriteria {
      const filterDataStore = useFilterDataStore()

      return {
        ...filterCollection.getQueryParams(state.active, {
          New: filterDataStore.getFilterDataNew,
          Used: filterDataStore.getFilterDataUsed
        }).asObject,
        ...(this.sorting ? { SortId: this.sorting.toString() } : {})
      }
    },

    hasRangeChanged(state): (filter: keyof IFilterCollection) => boolean {
      return (filter: keyof IFilterCollection) => {
        const range = state.active[filter]
        const filterDataStore = useFilterDataStore()
        const filterData = this.isNew
          ? filterDataStore.getFilterDataNew
          : filterDataStore.getFilterDataUsed

        return (
          filterUtils.isNotEmpty(range) &&
          !isEqual(range, filterData[capitalise(filter)])
        )
      }
    },

    // TODO: Move to majority collection and test
    getDataForAnalytics() {
      return (collection?: IFilterCollection) => {
        collection = collection || this.active
        const { age, mileage, emission, budget, retailers, radius, hasOffer } =
          collection
        const location =
          retailers.length > 0
            ? undefined
            : filterUtils.getRadiusAsString(radius)
        const PriceRange = filterUtils.hasPrice(budget)
          ? `${budget.price.Min}-${budget.price.Max}`
          : undefined
        const MonthlyPriceRange = filterUtils.hasMonthlyPrice(budget)
          ? `${budget.monthlyPrice.Min}-${budget.monthlyPrice.Max}`
          : undefined

        const data: any = {
          model: this.getDescriptions('Models'),
          fuel: this.getDescriptions('Fuels'),
          colour: this.getDescriptions('Colours'),
          line: this.getDescriptions('Lines'),
          package: this.getDescriptions('Packages'),
          transmission: this.getDescriptions('Transmissions'),
          engine: this.getDescriptions('Engines'),
          finance: {
            PriceRange,
            MonthlyPriceRange
          },
          upholstery: this.getDescriptions('Upholsteries'),
          emission: this.hasRangeChanged('emission')
            ? `${emission.Min}-${emission.Max}`
            : '',
          age: this.hasRangeChanged('age') ? `${age.Min}-${age.Max}` : '',
          mileage: this.hasRangeChanged('mileage')
            ? `${mileage.Min}-${mileage.Max}`
            : '',
          location,
          offers: hasOffer ? 'cars with offers' : ''
        }

        return data
      }
    },

    getCriteria(state): ICriteriaPayload {
      const { isMotability } = useCustomerTypeStore()
      const filterDataStore = useFilterDataStore()

      return filterCollection.getCriteria(
        state.active,
        {
          New: filterDataStore.getFilterDataNew,
          Used: filterDataStore.getFilterDataUsed
        },
        this.isRetailerMode,
        isMotability
      )
    }
  }
})
