<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <polygon
      fill="currentColor"
      points="5,15 5,23 5,25 7,25 15,25 15,23 8.4,23 14,17 13,16 7,21.6 7,15 "
    />
    <polygon
      fill="currentColor"
      points="24.5,15.5 24.5,7.5 24.5,5.5 22.5,5.5 14.5,5.5 14.5,7.5 21.1,7.5 15.5,13.5 16.5,14.5 22.5,8.9 22.5,15.5 "
    />
  </svg>
</template>

<script>
export default {
  name: 'MediaFullscreen'
}
</script>
