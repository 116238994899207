import errorMessages from '~/constants/errorMessages'
import ApiFactory from '~/api/factory'
import { NProducts } from '~/types/api/finance/products'
import { IFinanceProduct } from '~/types/finance/product'
import {
  EFinanceServices,
  useServiceStatusStore
} from '~/stores/serviceStatusStore'

function transformProductsResponseData(
  response: NProducts.getProducts.Response
): IFinanceProduct[] {
  let products: IFinanceProduct[] = []

  for (const p of response.CustomerTypes || []) {
    products = products.concat(p.Products)
  }

  return products
}

class ProductsModule extends ApiFactory {
  async getProducts() {
    const serviceStatusStore = useServiceStatusStore(this.pinia)

    return await Promise.allSettled([
      $osGet<NProducts.getProducts.Response, IFinanceProduct[]>(
        `${this.apiVersion}/finance/default/new`,
        {
          transform: transformProductsResponseData
        }
      ),
      $osGet<NProducts.getProducts.Response, IFinanceProduct[]>(
        `${this.apiVersion}/finance/default/used`,
        {
          transform: transformProductsResponseData
        }
      )
    ]).then((response) => {
      const data = transformSettlePromises<IFinanceProduct[]>(response)

      serviceStatusStore.updateFinanceService(
        EFinanceServices.Defaults,
        !data.some((res) => res.error)
      )

      return data
    })
  }

  async getProductsForVehicle(
    vehicleType: string,
    Vin: string,
    OfferPrice: number
  ) {
    const serviceStatusStore = useServiceStatusStore(this.pinia)

    // Reset Finance Calculate Status
    if (!serviceStatusStore.finance.calculate.isEnabled) {
      serviceStatusStore.updateFinanceService(EFinanceServices.Calculate, true)
    }

    const response = await $osPost<
      NProducts.getProducts.Response,
      IFinanceProduct[]
    >(`${this.apiVersion}/finance/default/${vehicleType}`, {
      body: {
        Vin,
        OfferPrice
      },
      transform: transformProductsResponseData
    })

    // Update API Health status for endpoint.
    // TODO: Move this to the store.
    const hasError = response.error || !response.data.length

    if (hasError) {
      const errorMessage = Array.isArray(response.data)
        ? errorMessages.finance.defaultEmpty
        : errorMessages.finance.notFound

      serviceStatusStore.updateFinanceService(
        EFinanceServices.VinDefaults,
        false,
        errorMessage
      )
    } else {
      serviceStatusStore.updateFinanceService(
        EFinanceServices.VinDefaults,
        true
      )
    }

    return response
  }
}

export default ProductsModule
