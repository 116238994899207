import { EFormType } from '~/types/forms'

enum EFormContainerState {
  currentForm = 'currentForm',
  isBackDisabled = 'isBackDisabled',
  isPreviousStepLogin = 'isPreviousStepLogin',
  backFn = 'backFn'
}

export const useCreateFormContainer = (formName: EFormType) => {
  const currentForm = useState<EFormType | null>(
    EFormContainerState.currentForm,
    () => null
  )
  // manually disabling back for special cases
  const isBackDisabled = useState<boolean>(
    EFormContainerState.isBackDisabled,
    () => false
  )
  const isPreviousStepLogin = useState<boolean>(
    EFormContainerState.isPreviousStepLogin,
    () => false
  )
  const backFn = useState<(() => void) | undefined>(
    EFormContainerState.backFn,
    () => undefined
  )

  currentForm.value = formName
  isBackDisabled.value = false
  isPreviousStepLogin.value = false
  backFn.value = undefined

  return {
    isBackDisabled,
    isPreviousStepLogin
  }
}

export const useFormContainer = () => {
  const currentForm = useState<EFormType | null>(
    EFormContainerState.currentForm
  )
  // manually disabling back for special cases
  const isBackDisabled = useState<boolean>(EFormContainerState.isBackDisabled)
  const isPreviousStepLogin = useState<boolean>(
    EFormContainerState.isPreviousStepLogin
  )
  const backFn = useState<(() => void) | null>(EFormContainerState.backFn)

  provide('currentForm', currentForm)

  return {
    currentForm,
    isBackDisabled,
    isPreviousStepLogin,
    backFn
  }
}
