<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 18 18"
    :style="style"
  >
    <defs>
      <polygon
        id="ic_oneweb_arrow-up-a"
        points="18 7.864 18 10.136 4.364 10.136 10.614 16.386 9 18 0 9 9 0 10.614 1.614 4.364 7.864"
      />
    </defs>
    <use
      fill="currentColor"
      transform="rotate(90 9 9)"
      xlink:href="#ic_oneweb_arrow-up-a"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowUpIcon',

  props: {
    rotate: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },

  setup(props) {
    const style = computed(() =>
      props.rotate ? { transform: `rotate(${props.rotate}deg)` } : {}
    )

    return {
      style
    }
  }
}
</script>
