import { defineStore } from 'pinia'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

interface ICompareStore {
  vehicles: (NewVehicle | UsedVehicle)[]
}

export const useCompareStore = defineStore('compare', {
  state: (): ICompareStore => {
    return {
      vehicles: []
    }
  },

  actions: {
    addVehicle(index: number, vehicle: NewVehicle | UsedVehicle) {
      this.vehicles[index] = vehicle
    },

    removeVehicle(vin: string) {
      const index = this.vehicles.findIndex((vehicle) => vehicle.Vin === vin)
      this.vehicles.splice(index, 1)
    },

    removeAllVehicles() {
      this.vehicles = []
    }
  }
})
