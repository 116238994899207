import { isNewVehicle } from '~/guards/vehicle'
import {
  EVehicleType,
  IVehicle,
  IVehicleAttribute,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'

// todo: remove FE check when code fixes the TotalOfferValue
export function parseTotalOffer(val) {
  if (!val) return 0

  return parseFloat(val)
}

/**
 * Normalise the vehicle services data and only keep the current condition
 * @todo Update the vehicle data and the components to get rid
 *       of the condition checks in the components
 * @param {object} vehicle
 */

export const carDisclaimerMessages = {
  bestMatch: 'This car does not match on the following: '
}

// TODO: what do we do with forEach loops e.g. spotlight cars
export const isAdvertiseOnlyHelper = (
  vehicle?: NewVehicle | UsedVehicle | IVehicle
): boolean => {
  if (!isNewVehicle(vehicle!)) return false

  return vehicle?.VehicleUsageCode === '18'
}

export const getStockLevelFormatted = (
  stockLevel: IVehicle['StockLevelIndicator'] = undefined
): IVehicleAttribute | undefined => {
  switch (stockLevel) {
    case 'G':
      return {
        text: '5+ in stock',
        color: 'green'
      }
    case 'Y':
      return {
        text: 'Hurry. Last few remaining',
        color: 'yellow',
        tooltipTitle: 'Last few remaining',
        tooltipText: 'Based on current stock levels.'
      }
    case 'A':
      return {
        text: 'Hurry. Last 1 in stock',
        color: 'amber'
      }
    case 'R':
      return {
        text: 'Out of stock',
        color: 'red'
      }
    default:
      return undefined
  }
}

export const isNew = (
  vehicle: NewVehicle | UsedVehicle | IVehicle
): boolean => {
  return vehicle.VehicleType === EVehicleType.New
}

export const isUsed = (
  vehicle: NewVehicle | UsedVehicle | IVehicle
): boolean => {
  return vehicle.VehicleType === EVehicleType.Used
}
