<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 23">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M33.3,19.2c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S34,19.2,33.3,19.2L33.3,19.2z M33.3,15.5
	c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C35.8,16.6,34.7,15.5,33.3,15.5L33.3,15.5z M5.1,21.7
	c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7c2,0,3.7,1.7,3.7,3.7S7.1,21.7,5.1,21.7L5.1,21.7z M9.9,16.7C9.3,14.6,7.4,13,5.1,13
	c-1.5,0-2.8,0.6-3.7,1.7C1.8,12.4,3,12,3,12c0.3-0.1,0.5-0.3,0.5-0.6c0-1.2,0.3-2.6,3.7-2.7l4.6-0.2l-0.1-1.3L9.9,7.2
	c2.2-1.9,7-5.6,10.1-5.8c1.1-0.1,2-0.1,3-0.1c0.1,0,0.2,0,0.4,0v5.2L14.2,7l-0.1-1.3l-1.3,0.1L13,9l1.3-0.1l0-0.6l14.2-0.7
	c0.1,0.6,0.1,1.2,0,1.7c-0.2,0.8-2.8,5.3-5.2,6.8c-0.2,0.1-0.6,0.3-1.2,0.3C18.2,16.6,11.4,16.7,9.9,16.7L9.9,16.7z M10,18.6
	c0-0.2,0-0.4,0-0.6c1.8,0,8.4-0.1,12.1-0.3c0.9,0,1.5-0.4,1.8-0.5c2.6-1.6,5.4-6.3,5.7-7.5c0.5-1.9-0.2-4.4-2.3-7.3
	c-0.2-0.2-0.4-0.5-0.7-0.9c1.4,0.1,3,0.2,4.6,0.3l1,4.6c0.1,0.6,0.8,1.8,2.1,1.8h0.3c0.2,0.7,0.3,1.4,0.4,2
	c-0.6,0.3-1.4,0.7-2.2,1.5c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c0.4,0,0.8,0,1.2,0.1c0.3-0.2,0.6-0.4,0.9-0.5
	c1.1,0.8,1.6,2.1,1.8,3.5c-0.9-1.1-2.3-1.9-3.9-1.9c-2.7,0-5,2.2-5,5c0,0.2,0,0.4,0,0.6H10z M33.3,21.7c-2,0-3.7-1.7-3.7-3.7
	s1.7-3.7,3.7-3.7S37,15.9,37,18S35.3,21.7,33.3,21.7L33.3,21.7z M33.1,1.9c0.2,0.9,0.7,2.9,1.1,5c-0.6,0-0.8-0.8-0.8-0.8l-0.9-4.2
	C32.7,1.9,32.9,1.9,33.1,1.9L33.1,1.9z M24.7,1.6C25.5,2,26,2.6,26.3,3c0.8,1.2,1.4,2.3,1.8,3.2l-3.4,0.2V1.6L24.7,1.6z M2.2,10.9
	c-0.9,0.5-2.6,2-2.1,6.7c0,0,0,0,0,0c0,0.8,0.2,1.5,0.2,1.5c0.5,2.2,2.5,3.8,4.8,3.8c2.1,0,3.9-1.3,4.6-3.1h19
	c0.7,1.8,2.5,3.1,4.6,3.1c2.3,0,4.2-1.5,4.8-3.6l0,0c0.7-2,0.9-6.7-1.8-8.9c-0.6-2.8-2-9.2-2-9.2C34.2,1,34,0.8,33.7,0.7l-0.1,0
	c-3.1-0.4-6.5-0.5-8.8-0.7c-0.7,0-1.2,0-1.8-0.1C23,0,23,0,23,0v0c-1.1,0-2,0-3.1,0.1c-4.4,0.3-11.4,6.6-12,7.2L7,7.4
	C3.9,7.5,2.4,8.7,2.2,10.9L2.2,10.9z M5.1,19.2c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S5.7,19.2,5.1,19.2L5.1,19.2
	z M5.1,15.5c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C7.6,16.6,6.5,15.5,5.1,15.5L5.1,15.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SmartCoupe'
}
</script>
