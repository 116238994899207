import { defineStore } from 'pinia'
import {
  BudgetOption,
  useFinanceCriteriaStore
} from '~/stores/finance/criteriaStore'
import { EFinanceProductKeys } from '~/types/finance/product'
import {
  IQuoteDto,
  IQuoteError,
  IQuoteFinanceProduct
} from '~/types/finance/quote'
import { EVehicleType } from '~/types/vehicle'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'

type Quotes = {
  [key in EFinanceProductKeys]: IQuoteFinanceProduct | null
}

type State = {
  pending: boolean
  vin: string
  gssnId: string
  ocdProposalReference: string
  abortController: AbortController | null
  quotes: Quotes
}

export const useFinanceQuotesStore = defineStore('finance/quotes', {
  state: (): State => {
    return {
      pending: false,
      vin: '',
      gssnId: '',
      ocdProposalReference: '',
      abortController: null,
      quotes: {
        [EFinanceProductKeys.PCP]: null,
        [EFinanceProductKeys.PCH]: null,
        [EFinanceProductKeys.PHP]: null,
        [EFinanceProductKeys.POL]: null,
        [EFinanceProductKeys.BCP]: null,
        [EFinanceProductKeys.BHP]: null,
        [EFinanceProductKeys.BOL]: null,
        [EFinanceProductKeys.CH]: null
      }
    }
  },

  getters: {
    currentQuote(state): IQuoteFinanceProduct | null {
      const financeCriteriaStore = useFinanceCriteriaStore()
      const key = financeCriteriaStore.currentKey

      return state.quotes[key]
    },

    currentQuoteDto(): IQuoteDto | IQuoteError | undefined {
      return this.currentQuote?.QuoteDto
    },

    hasError(): boolean {
      if (!this.currentQuote) return true

      return this.currentQuote?.HasError || false
    },

    getQuoteByKey(): (key: EFinanceProductKeys) => IQuoteFinanceProduct | null {
      return (key: EFinanceProductKeys) => {
        return this.quotes[key]!
      }
    }
  },

  actions: {
    async fetchQuote(
      key: EFinanceProductKeys,
      vehicleType: EVehicleType = EVehicleType.New,
      signal?: AbortSignal
    ) {
      this.pending = true

      const response = await useFetchQuote(
        this.vin,
        this.gssnId,
        vehicleType,
        signal,
        key
      )

      if (response?.data) {
        this.quotes[key] = response.data
      }

      if (response?.error) {
        this.quotes[key] = null
      }

      this.pending = false

      return response
    },

    async fetchQuotes(
      newVin: string,
      newGssnId: string,
      vehicleType: EVehicleType = EVehicleType.New,
      includeBusiness: boolean = false
    ) {
      if (this.abortController) {
        this.abortController.abort()
      }

      const financeCriteriaStore = useFinanceCriteriaStore()
      const ocdStore = useOnlineCreditDecisionStore()

      ocdStore.reset()

      this.abortController = new AbortController()
      const { signal } = this.abortController

      this.vin = newVin
      this.gssnId = newGssnId

      // Fetch all products (w/wo business)
      const products = Object.keys(EFinanceProductKeys).filter((key) =>
        includeBusiness
          ? isBusiness(key as EFinanceProductKeys)
          : isPersonal(key as EFinanceProductKeys)
      )

      // Filter out the selected product
      const index = products.findIndex(
        (key) => key === financeCriteriaStore.currentKey
      )
      const [selected] = products.splice(index, 1)

      // Fetch selected quote
      const firstQuote = await this.fetchQuote(
        selected as EFinanceProductKeys,
        vehicleType,
        signal
      )

      if (financeCriteriaStore.isPersonalised) {
        if (
          financeCriteriaStore.budgetOption === BudgetOption.PAYMENT_MULTIPLE &&
          isQuote(firstQuote.data?.QuoteDto)
        ) {
          financeCriteriaStore.deposit = String(
            firstQuote.data?.QuoteDto?.CustomerDeposit
          )
        }
      }

      // Loop over remaining products and fetch quotes
      await Promise.all(
        products.map((product) =>
          this.fetchQuote(product as EFinanceProductKeys, vehicleType, signal)
        )
      )

      // We need to reset ocdProposalReference if its a different vehicle
      if (this.vin !== newVin) {
        this.ocdProposalReference = ''
      }

      this.vin = newVin
    },

    reset() {
      this.quotes.PCP = null
      this.quotes.PCH = null
      this.quotes.PHP = null
      this.quotes.POL = null
      this.quotes.BCP = null
      this.quotes.BHP = null
      this.quotes.BOL = null
      this.quotes.CH = null

      this.vin = ''
    }
  }
})
