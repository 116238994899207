import { defineStore, storeToRefs } from 'pinia'
import { useFinanceProductsStore } from './productsStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { EFinanceProductKeys, IFinanceProduct } from '~/types/finance/product'
import {
  DeepLinkOption,
  DeepLinkOptions,
  FinanceCriteria
} from '~/utils/finance/criteria'
import { EFinanceProductsType } from '~/types/finance/products'
import { ERoutes } from '~/types/routes'

// Budget options for products
export enum BudgetOption {
  DEPOSIT = 0,
  MONTHLY_BUDGET = 1,
  PAYMENT_MULTIPLE = 2
}

type State = {
  key: EFinanceProductKeys | null
  defaultKey: EFinanceProductKeys
  businessKey: EFinanceProductKeys | null
  defaultBusinessKey: EFinanceProductKeys
  deposit: string
  monthlyBudget: string
  paymentMultiple: number
  term: number
  mileage: number
  budgetOption: BudgetOption
  useBusiness: boolean
  hasAccepted: boolean
  deeplink: string
  settlement: string
  partExchange: number
}

export const useFinanceCriteriaStore = defineStore('finance/criteria', {
  state: (): State => {
    return {
      key: null,
      defaultKey: EFinanceProductKeys.PCP,
      businessKey: null,
      defaultBusinessKey: EFinanceProductKeys.CH,
      deposit: '',
      monthlyBudget: '',
      paymentMultiple: 0,
      term: 0,
      mileage: 0,
      budgetOption: BudgetOption.DEPOSIT,
      useBusiness: false,
      hasAccepted: false,
      deeplink: '',
      settlement: '',
      partExchange: 0
    }
  },

  getters: {
    currentKey: (state) => {
      if (state.useBusiness) {
        return state.businessKey ?? state.defaultBusinessKey
      }

      return state.key ?? state.defaultKey
    },

    isPurchaseProduct(): boolean {
      return isPurchase(this.currentKey)
    },

    isPersonalised: (state) =>
      state.key !== null ||
      state.businessKey !== null ||
      state.useBusiness ||
      state.deposit !== '' ||
      state.monthlyBudget !== '' ||
      state.paymentMultiple !== 0 ||
      state.mileage !== 0 ||
      state.term !== 0 ||
      state.hasAccepted,

    hasInteractedWithBudget: () => {
      const filtersStore = useFiltersStore()
      const { active } = storeToRefs(filtersStore)

      return (
        filterUtils.hasMonthlyPrice(active.value.budget) ||
        filtersStore.isSortedByMonthlyPrice
      )
    },

    isUsingTargetMonthlyBudget(state): boolean {
      return state.budgetOption === BudgetOption.MONTHLY_BUDGET
    },

    quoteCriteria(state) {
      return (key: EFinanceProductKeys): IFinanceProduct => {
        const route = useRoute()
        const productsType =
          route.name === ERoutes.Vehicle || route.name === ERoutes.Offer
            ? EFinanceProductsType.vehicle
            : EFinanceProductsType.all

        const criteria: FinanceCriteria = {
          key,
          budgetOption: state.budgetOption,
          deposit: state.deposit,
          monthlyBudget: state.monthlyBudget,
          paymentMultiple: state.paymentMultiple,
          mileage: state.mileage,
          term: state.term,
          isPersonalised: this.isPersonalised,
          partExchange: state.partExchange,
          settlement: state.settlement
        }

        const financeProductsStore = useFinanceProductsStore()
        const filtersStore = useFiltersStore()
        const budgetFilter = filtersStore.active.budget

        const defaultProduct =
          financeProductsStore.getProductByKey(key)[productsType]!

        return getQuoteCriteria(criteria, defaultProduct, budgetFilter)
      }
    },

    toDeeplink(state) {
      const FinanceCriteria: Partial<Record<DeepLinkOptions, string>> = {
        [DeepLinkOption.Key]: this.currentKey,
        [DeepLinkOption.Term]: state.term,
        [DeepLinkOption.Mileage]: state.mileage
      }

      if (state.budgetOption === BudgetOption.PAYMENT_MULTIPLE) {
        FinanceCriteria.AdvanceRentals = String(state.paymentMultiple)
      } else if (state.budgetOption === BudgetOption.MONTHLY_BUDGET) {
        FinanceCriteria.RegularPayment = state.monthlyBudget
      } else if (this.isPurchaseProduct) {
        FinanceCriteria.Deposit = state.deposit
      } else {
        FinanceCriteria.AdvanceRentalSum = state.deposit
      }

      let financeQueryString = ''
      for (const [key, value] of Object.entries(FinanceCriteria)) {
        financeQueryString += `${financeQueryString.length ? '|' : ''}${key}:${value}`
      }

      return financeQueryString
    }
  },

  actions: {
    setCriteriaFromDeepLink(criteria: Map<DeepLinkOptions, string>) {
      for (const [option, value] of criteria) {
        switch (option) {
          case 'Deposit':
            if (value === '17.5%') {
              this.deposit = ''
              break
            } else if (value.includes('%')) break
            this.deposit = value
            this.budgetOption = BudgetOption.DEPOSIT
            break
          case 'AdvanceRentalSum':
            this.deposit = value
            this.budgetOption = BudgetOption.DEPOSIT
            break
          case 'AdvanceRentals':
            this.paymentMultiple = parseInt(value)
            this.budgetOption = BudgetOption.PAYMENT_MULTIPLE
            break
          case 'RegularPayment':
            this.monthlyBudget = value
            this.budgetOption = BudgetOption.MONTHLY_BUDGET
            break
          case 'Term':
            this.term = parseInt(value)
            break
          case 'Mileage':
            this.mileage = parseInt(value)
            break
          case 'Key':
            if (isPersonal(value as EFinanceProductKeys)) {
              this.key = value as EFinanceProductKeys
              this.useBusiness = false
            }

            if (isBusiness(value as EFinanceProductKeys)) {
              this.businessKey = value as EFinanceProductKeys
              this.useBusiness = true
            }
            break
        }
      }
    },

    resetKey() {
      this.key = this.defaultKey
      this.businessKey = this.defaultBusinessKey
    }
  },

  persist: {
    paths: [
      'key',
      'businessKey',
      'deposit',
      'monthlyBudget',
      'paymentMultiple',
      'term',
      'mileage',
      'budgetOption',
      'useBusiness',
      'hasAccepted',
      'deeplink',
      'settlement',
      'partExchange'
    ]
  }
})
