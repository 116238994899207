import {
  IVehicleImage,
  IVehicleOffers,
  IVehicleRetailer,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'
import { ECustomerTypes } from '~/types/customerType'

export type SavedVehicle = {
  vin: string
  customerMode: ECustomerTypes
  retailerId: number
  IsNew: boolean
}

export type SavedVehicleCard = SavedVehicle & {
  vehicle?: NewVehicle | UsedVehicle
}

export enum EProfileVehicleCardType {
  Shortlist = 'shortlist',
  RecentlyViewed = 'recently-viewed'
}

export enum EProfileVehicleGroup {
  Favourite = 'Favourite',
  RecentlyViewed = 'recentlyviewed'
}

export interface IProfileVehicle {
  AdditionalDetails: []
  BodyStyle: string
  ClientApplicationId: string
  ClientApplicationUserName: string
  ColourGroup: string
  CustomerMode: ECustomerTypes
  Description: string
  ExternalModelId: string
  ExternalRetailerId: string
  ExternalVehicleId: string
  FuelType: string
  GssnId: string
  ImageURL: string
  IsAvailable: boolean
  IsAvailableOnline: boolean
  IsNew: boolean
  Model: string
  ModifiedDate: string
  ProfileId: string
  RegistrationNumber: string
  RetailerDescription: string
  Secured: boolean
  Transmission: string
  TransmissionType: string
  VIN: string
  VehicleClass: string
  VehicleGroup: EProfileVehicleGroup
  ImageUrl?: string
  shopLink?: string
  Media?: IVehicleImage
  UnderOffer?: boolean
  P11D?: number | null
  Offers?: IVehicleOffers
  Retailer?: IVehicleRetailer
}

export interface IProfileVehicleResponse extends IProfileVehicle {
  CreationDate: string
  ModifiedDate: string
}
