import { IQuoteDto } from '~/types/finance/quote'
import { IRetailer } from '~/types/retailer'
import { EEmissionType, IVehicleEmissionsItem } from '~/types/emissions'
import { ECustomerTypes } from '~/types/customerType'

export enum ECondition {
  New = 1,
  Used = 2
}

export enum EConditionSlug {
  New = 'new',
  Used = 'used'
}

export enum EVehicleType {
  New = 'NEW',
  Used = 'USED'
}

export enum EVehicleCompareType {
  New = 'NEW',
  Used = 'USED',
  Mixed = 'MIXED'
}

export type Engine = {
  Id: number
  Badge: string
  CubicCapacity: number
  Cyclinders: number
  Kw: string
  HorsePower: string
  Selected: boolean
}

export type Price = {
  Id: null
  Description: string
  Net: number
  VAT: number
  Gross: number
}

// TODO: convert to type
export interface IVehicleImage {
  ImageCount?: number
  MainImageUrl: string
  MainImageUrlMobile: string
  VehicleImageUrls: string[]
  VehicleMobileImageUrls: string[]
  NoImage: boolean
}

export interface IVehicleAttribute {
  text: string
  tooltipTitle?: string
  tooltipText?: string
  color?: 'green' | 'yellow' | 'amber' | 'red'
}

export interface IAncillaryItem {
  Value?: string
  Description?: string
  Tooltip?: string
  TooltipIndex?: string
  Color?: 'green' | 'yellow' | 'amber' | 'red'
}

// TODO: convert to type
interface IService {
  Id?: number
  Description?: string
}

// TODO: convert to type
export interface IServices {
  New: IService[]
  Used: IService[]
}

type Combined = {
  Value: string
  Current: boolean
}

export type CampaignMessage = {
  Id: number
  Vin: string
  CampaignId: number
  Value: string
  From: string
  To: string
  CampaignDescription: string
}

export type FinanceCampaignMessage = CampaignMessage & {
  Label: string
  Enabled: boolean
  Pending: boolean
  OfferType: 'Finance'
  TermsAndConditions: string
}

export interface ICampaignOffer {
  Id: number
  AccessoryId: number
  Age: number
  AgeCriteriaId: number
  CampaignId: number
  CampaignName: string
  CampaignOLEVGrant: boolean
  Category: string
  Creation: any
  CustomerCode: string
  CustomId: number
  Description: string
  DiscountId: number
  Enabled: boolean
  From: any
  Modified: any
  Net: number
  OfferDescription: string
  OfferType:
    | 'Accessory Offer'
    | 'Custom'
    | 'Other'
    | 'Service'
    | 'Warranty'
    | 'Finance'
    | 'Discount'
  OfferTypeEnabled: boolean
  OfferTypeId: number
  OfferValueGross?: number
  OfferValueNet?: number
  OfferValueVat?: number
  OfferVehicle: any
  OtherId: number
  Pending: boolean
  Personalised: boolean
  ReactivationStatus: number
  Reference: any
  ResolvedByUser: boolean
  ServiceId: number
  To: any
  Used: boolean
  Value: string
  VAT: number
  Vin: any
  WarrantyId: number
  Gssn: any
  IsReadOnly: boolean
  PromoCode?: string
}

export interface IOfferTypes {
  ActiveOffers: any[]
  CampaignOffers: ICampaignOffer[]
  RetailerOffers: ICampaignOffer[]
  Combined: Combined[]
  CampaignMessages: CampaignMessage[]
  CampaignCustomOffers: any[]
  CampaignServiceOffers: any[]
  CampaignOtherOffers: any[]
  CampaignDiscountOffers: any[]
  CampaignOLEVGrantOffers: any[]
  CampaignPersonalisedOffers: any[]
  CampaignWarrantyOffers: any[]
  NonCampaignOffers: any[]
  RetailerCustomOffers: any[]
  RetailerDiscountOffers: any[]
  RetailerServiceOffers: any[]
  RetailerWarrantyOffers: any[]
  FinanceOfferMessages: FinanceCampaignMessage[]
}

// TODO: Pick from Retailer interface
export interface IVehicleRetailer {
  Id: number
  Services: IServices
  Description: string
  smart?: boolean
  IsCentralRetailer: boolean
  IsMotabilityRetailer: boolean
}

export interface IVehicleOffers {
  Combined: IOfferTypes
}

// TODO: convert to type
export interface IOffer {
  Id: number
  Value: string
  To: string
  Enabled: boolean
  Pending: boolean
  OfferType: string
}

// TODO: convert to type
interface IEquipment {
  Id: number
  Importance: number
  IsAdditional: boolean
  Code: string
  Description: string
}

// TODO: convert to type
export interface IEquipmentCategories {
  Order: number
  Equipment: IEquipment[]
  Code: string
  Description: string
}

// TODO: convert to type
export interface IConsumptionUrban {
  Lkm: string
  Mpg: string
}

export interface IEmissions {
  key: string
  type: EEmissionType
  note?: string
  value?: any
  showInfo?: boolean
}

// TODO: remove for Brand type
export interface IBrand {
  Id: number
  Description: 'Passenger' | 'AMG' | 'EQ' | 'SMART'
  Selected: boolean // Not sure this is used
}

export interface IVehicleData {
  Description?: string
  Value?: string
  Footnote?: string
  sortOrder?: number
  section?: number
}

export interface IMotability {
  CivilianAdvanceRental?: number | null
  WPMSAdvancePayment?: number | null
}

export interface IMotabilityPayload {
  motabilitycivilianadvancedrental?: number
  motabilitywpmsadvancedrental?: number
}

// TODO: Phase out
/* exported IVehicle */
export interface IVehicle {
  fetching: any
  DisplayRetailer?: IRetailer
  IsDisplayStock: boolean
  ActiveOffers: any[]
  OfferExpiryDate: any
  PromoCode: string
  CampaignPromoOfferContribution: number
  ActualPrice: number
  Age?: number
  IsSpecialistCar: boolean
  AvailablePackages: string[]
  BestMatchDroppedValues: string[]
  BodyStyle: string
  Brand: IBrand
  Colour: string
  ColourGroup: string
  Description: string
  EquipmentCategories: IEquipmentCategories[]
  ExternalRetailerId: string
  FuelType: string
  ImageURL?: string
  isDisplayStock?: boolean
  IsSellable?: boolean
  IsAvailableOnline: boolean
  Media: IVehicleImage
  Model: string
  ModelId: number
  OwningRetailer?: IRetailer
  Offers: IVehicleOffers
  OTR: number
  P11D: number
  QuoteDto?: IQuoteDto
  Retailer: IRetailer
  RetailerDescription?: string
  RetailPrice: number
  TermsLink: string
  TotalOfferValue: number
  Transmission: string
  TransmissionType: string
  VehicleClass: string
  VehicleData: IVehicleData[]
  VehicleType: EVehicleType
  VehicleSource?: any
  Vin: string
  VIN: string
  CampaignOfferContribution?: number
  RetailerOfferContribution?: number
  CampaignOLEVOfferContribution?: number
  UnderOffer: boolean
  Emissions: IVehicleEmissionsItem[]
  EnergyEfficiencyClass: {
    Rating: string
    Colour: string
  }
  ConsumptionExtraUrban: IConsumptionUrban
  ConsumptionUrban: IConsumptionUrban
  ConsumptionCombined: IConsumptionUrban
  WltpFuelConsumptionLow: IConsumptionUrban
  WltpFuelConsumptionMedium: IConsumptionUrban
  WltpFuelConsumptionHigh: IConsumptionUrban
  WltpFuelConsumptionExtraHigh: IConsumptionUrban
  EmissionCombined: string
  WltpEmissionCombined: string
  WltpFuelConsumptionCombined: IConsumptionUrban
  RegistrationDate: string
  ProductRange: string
  CommissionNumber: string
  RegistrationNumber?: string
  Mileage?: string
  UpholsteryDescription?: string
  ElectricRange?: number
  ElectricConsumption?: number
  Motability?: IMotability
  IsNew?: boolean
  Emission?: number
  IsPipelineStock?: boolean
  PortArrivalDate?: string
  VehicleUsageCode: string
  StockLevelIndicator?: 'G' | 'Y' | 'A' | 'R'
  Secured?: boolean
}

export interface IVehicleBenefit {
  title: string
  summary: string
  link: string
  key: string
}
export interface IVehicleBenefits {
  title: string
  summary?: string
  benefits: IVehicleBenefit[]
  benefitsURL: string
}

export interface IVehicleBenefitsMap {
  title: string
  summary?: string
  benefits: string[]
  benefitsURL: string
}

export interface IVehicleRangeBenefits {
  [key: string]: IVehicleBenefitsMap
}

export interface IVehicleConditionBenefits {
  [key: string]: IVehicleRangeBenefits
}

export type StockLevelIndicator = 'G' | 'Y' | 'A' | 'R'

/**
 * Comprehensive interface for a vehicle returned from
 * search, details or customer management.
 */
export interface Vehicle {
  Acceleration?: number
  Account?: string
  AccountType?: string
  ActualPrice?: number
  Age?: number
  AvailablePackages?: string[]
  Baumuster?: string
  BestMatchDroppedParameters?: string[]
  BestMatchDroppedValues?: string[]
  Bm7NST?: string
  BodyStyle?: string
  BootCapacity?: number
  Brand?: IBrand
  CampaignOfferContribution?: number
  CampaignOLEVOfferContribution?: number
  CampaignPromoOfferContribution?: number
  CesarIdShipToParty?: string
  ChassisNumber?: string
  CivilianAdvanceRental?: number
  Colour?: string
  ColourDescription?: string
  ColourGroup?: string
  CombinedPowerHP?: number
  CombinedPowerKw?: number
  CombustionPowerHp?: number
  CommissionNumber: string
  Condition?: string
  ConsumptionCombined?: IConsumptionUrban
  ConsumptionExtraUrban?: IConsumptionUrban
  ConsumptionUrban?: IConsumptionUrban
  CreationDate?: string
  CustomerMode?: ECustomerTypes
  DeliveryTime?: number
  Description: string
  DisplayRetailer?: IRetailer
  Distance?: number
  DriveConcept?: string
  ElectricConsumption?: number
  ElectricPowerHp?: number
  ElectricPowerKw?: number
  ElectricRange?: number
  EligibleForIntegratedServiceCare?: boolean
  Emission?: number
  EmissionCombined?: string
  EmissionDirective?: string
  EmissionExtraUrban?: string
  EmissionUrban?: string
  EnergyEfficiencyClass?: {
    Rating: string
    Colour: string
  }
  Engine?: Engine
  Equipment?: IEquipment[]
  EquipmentAdditional?: IEquipment[]
  EquipmentCategories?: IEquipmentCategories[]
  Error?: Error
  FinanceQuote?: IQuoteDto
  FuelType: string
  HasError?: boolean
  HasQuoteError?: boolean
  Id?: number
  ImageUrl?: string
  IsAMG?: boolean
  IsAvailableOnline?: boolean
  IsDisplayStock?: boolean
  IsExcluded?: boolean
  IsOfferAvailable?: boolean
  IsPipelineStock?: boolean
  IsSellable?: boolean
  IsSpecialistCar?: boolean
  IspVehicleCustomerDescriptions?: string[]
  Line?: string
  LocationTypeName?: string
  MaintenanceDate?: string
  MappedLine?: number
  MatchMessage?: string
  MBUKReserve?: string
  Media: IVehicleImage
  Metadata?: any // Added manually
  Mileage?: string
  Model?: string
  ModelId?: number
  ModelYear?: string
  ModelYearCode?: string
  MonthlyPrice?: number
  Motability?: {
    CivilianAdvanceRental: number
    WPMSAdvancePayment: any
  }
  NormalisedEmissionDirective?: string
  OfferExpiryDate?: string
  Offers?: IVehicleOffers
  OnHold?: boolean
  OTR: number
  OwningRetailer: IRetailer
  P11D?: number
  PortArrivalDate?: string
  Prices?: Price[]
  ProductRange?: string
  PromoCode?: string
  QuoteError?: Error
  RegistrationDate?: string
  RegistrationNumber?: string
  Retailer: IRetailer
  RetailerOfferContribution?: number
  RetailPrice: number | string
  RetailPriceExVAT?: number
  RetailPriceIncVAT?: number
  SalesDescription?: string
  Secured?: boolean
  SeoFriendlyDescription?: string
  shopLink?: string
  ShortDescription?: string
  Source?: string
  SpecialInfo?: boolean
  StockLevelIndicator?: StockLevelIndicator
  TopSpeed?: number
  Torque?: number
  TotalOfferValue?: number
  Transmission?: string
  TransmissionType?: string
  UnderOffer?: boolean
  UpholsteryDescription?: string
  UpholsteryType?: string
  VATQualifying?: boolean
  VehicleClass?: string
  VehicleData?: IVehicleData[]
  VehicleSource?: {
    Description?: string
    Explanation?: string
  }
  VehicleType: string
  VehicleUsageCode?: string
  Vid?: string
  Vin: string
  WltpEmissionCombined?: string
  WltpFuelConsumptionCombined?: IConsumptionUrban
  WltpFuelConsumptionExtraHigh?: IConsumptionUrban
  WltpFuelConsumptionHigh?: IConsumptionUrban
  WltpFuelConsumptionLow?: IConsumptionUrban
  WltpFuelConsumptionMedium?: IConsumptionUrban
  FullModelYearCode?: string
}

/**
 * New Vehicle omits used attributes
 */
export type NewVehicle = {
  VehicleType: EVehicleType.New
} & Omit<Vehicle, 'RetailPrice | Age | Mileage | Emission'>

/**
 * Used Vehicle omits new attributes
 */
export type UsedVehicle = {
  VehicleType: EVehicleType.Used
} & Omit<
  Vehicle,
  'OTR | IsPipelineStock | PortArrivalDate | VehicleUsageCode | StockLevelIndicator'
>

/**
 * Type for a vehicle returned from the customer Back Orders API.
 * This is considerably different from the comprehensive vehicle,
 * so we are creating it's own interface.
 */
export type PreOrderVehicle = Pick<
  Vehicle,
  | 'ActualPrice'
  | 'Bm7NST'
  | 'BodyStyle'
  | 'Colour'
  | 'CommissionNumber'
  | 'Description'
  | 'Engine'
  | 'ImageUrl'
  | 'IsDisplayStock'
  | 'IsSellable'
  | 'Line'
  | 'Motability'
  | 'OTR'
  | 'P11D'
  | 'PortArrivalDate'
  | 'TotalOfferValue'
  | 'Transmission'
  | 'VehicleType'
  | 'Vin'
> & {
  Fuel: string
  Packages: any[]

  RetailPrice_ExVAT: string
  Specialist: boolean
  Upholstery: string
}

/**
 * Type for a vehicle returned from the Orders API.
 * This is a subset from the comprehensive vehicle,
 * so we are creating it's own type.
 */
export type OrderVehicle = Pick<
  Vehicle,
  | 'VehicleClass'
  | 'ActualPrice'
  | 'BodyStyle'
  | 'Colour'
  | 'Description'
  | 'IsDisplayStock'
  | 'DisplayRetailer'
  | 'FuelType'
  | 'ImageUrl'
  | 'OTR'
  | 'Retailer'
  | 'TransmissionType'
  | 'UpholsteryType'
  | 'Vin'
>
