import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { useProfileStore } from '~/stores/profileStore'

const interval = 90000

interface state {
  authCode: string
  expiryDate: dayjs.Dayjs | null
  isExpired: boolean
  sessionActive: boolean
  sessionStarted: boolean
  wasClaimed: boolean
  poll: any
  isBusy: boolean
  timeRemaining: number
}

export const useRetailerSessionStore = defineStore('retailerSession', {
  state: (): state => {
    return {
      authCode: '',
      expiryDate: null,
      isExpired: false,
      sessionActive: false,
      sessionStarted: false,
      wasClaimed: false,
      poll: null,
      isBusy: false,
      timeRemaining: 0
    }
  },

  actions: {
    startSession() {
      this.wasClaimed = JSON.parse(
        localStorage.getItem('sessionWasClaimed') || 'false'
      )
      this.sessionStarted = true
    },

    setExpiryDate(date) {
      const format = 'DD/MM/YYYY hh:mm:ss'

      this.expiryDate = date ? dayjs(date, format) : null
    },

    setSessionStatus({ SessionActive, IsExpired }) {
      if (SessionActive) {
        this.wasClaimed = true
        localStorage.setItem('sessionWasClaimed', 'true')
      }

      this.sessionActive = SessionActive
      this.isExpired = IsExpired
    },

    stopPoll() {
      clearTimeout(this.poll)
    },

    async generateAuthCode() {
      const profileStore = useProfileStore()

      this.isBusy = true

      const CiamId = profileStore.persona?.CiamId

      const { data } = await this.api.retailerSession.generate(CiamId)

      if (!data?.statusCode) {
        this.authCode = data.AuthCode
        this.setExpiryDate(data.ExpiryDate)
        this.startSession()

        this.pollStatus()

        localStorage.setItem(
          'retailerSession',
          JSON.stringify({
            AuthCode: data.AuthCode,
            ExpiryDate: data.ExpiryDate
          })
        )
      }

      return data
    },

    resumeSession({ AuthCode, ExpiryDate }) {
      this.authCode = AuthCode
      this.setExpiryDate(ExpiryDate)
      this.startSession()
      this.pollStatus()
    },

    async fetchStatus() {
      this.isBusy = true

      const { data } = await this.api.retailerSession.status(this.authCode)

      if (!data?.statusCode) {
        if (data.IsExpired) {
          this.stopPoll()
          this.authCode = ''
          localStorage.removeItem('retailerSession')
        }

        this.setSessionStatus(data)
        this.setExpiryDate(data.ExpiryDate || null)
      }

      this.isBusy = false
    },

    async pollStatus() {
      await this.fetchStatus()

      if (!this.authCode) return

      if (this.poll) clearTimeout(this.poll)
      this.poll = setTimeout(() => this.pollStatus, interval)
    },

    async extendSession() {
      this.stopPoll()

      const { data } = await this.api.retailerSession.extend(this.authCode)

      if (!data?.statusCode) {
        this.setExpiryDate(data.NewEndDateTime)
        this.pollStatus()

        localStorage.setItem(
          'retailerSession',
          JSON.stringify({
            AuthCode: this.authCode,
            ExpiryDate: data.NewEndDateTime
          })
        )
      }

      return data
    },

    async terminateSession() {
      this.stopPoll()

      const response = await this.api.retailerSession.terminate(this.authCode)

      if (!response?.data?.statusCode) {
        this.fetchStatus()
        this.authCode = ''
        this.setExpiryDate(null)

        localStorage.removeItem('retailerSession')
      }

      return response
    },

    updateTime(time) {
      this.timeRemaining = time
    }
  },

  getters: {
    analyticsStatus(state) {
      if (state.sessionStarted) {
        return state.isExpired
          ? 'ended'
          : state.sessionActive
            ? 'active'
            : 'awaiting'
      }

      return 'not started'
    }
  }
})
