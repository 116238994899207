import ApiFactory from './factory'
import { IVehicle } from '~/types/vehicle'
import { IQuoteFinanceProduct } from '~/types/finance/quote'

interface PDFPayload {
  Vehicle: IVehicle
  Finance?: Partial<IQuoteFinanceProduct>
  Url: string
}

class PDFModule extends ApiFactory {
  async get(pdfConfig: PDFPayload) {
    return await $osPost<Blob>(`${this.apiVersion}/pdf`, {
      body: pdfConfig,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf'
      },
      responseType: 'arrayBuffer',
      transform: (res) => new Blob([res])
    })
  }
}

export default PDFModule
