import { filtersFactory } from '~/factories/filtersFactory'
import { ECondition } from '~/types/vehicle'
import {
  IFilterCollection,
  IFiltersBudget,
  IFiltersRadius,
  IFiltersRange
} from '~/types/filters'
import { IFilterDataMinMax, isFilterDataNew } from '~/types/filterData'
import { IRetailer } from '~/types/retailer'
import { NonNullableInterface } from '~/types'

import { useFilterDataStore } from '~/stores/filterDataStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { useFinanceProductsStore } from '~/stores/finance/productsStore'

type RangeFilter =
  | 'age'
  | 'emission'
  | 'mileage'
  | 'arrivalInDays'
  | 'budgetPrice'
  | 'budgetMonthlyPrice'

type NonMonetaryRangeFilter = Exclude<
  RangeFilter,
  'budgetPrice' | 'budgetMonthlyPrice'
>

type ToggleIdFilter =
  | 'deliveryTime'
  | 'transmissions'
  | 'colours'
  | 'engines'
  | 'packages'
  | 'upholsteries'
  | 'lines'
  | 'fuels'

type BooleanFilter =
  | 'latestModel'
  | 'previousModel'
  | 'hasOffer'
  | 'excludeOutOfStock'

interface UseFilters {
  filters: Ref<IFilterCollection>
  retailer: Ref<IRetailer | null>
  isPostcodeValid: Ref<boolean>

  isNew: Ref<boolean>
  availableLinesByModels: Ref<number[]>
  availableFuelsByModels: Ref<number[]>
  availableEnginesByModels: Ref<number[]>

  selectedBudget: Ref<NonNullableInterface<IFiltersBudget>>

  getDefaultRange: (filter: RangeFilter) => IFilterDataMinMax

  resetFilters: (conditionAttr: ECondition) => void

  updateFilters: (newFilters: Partial<IFilterCollection>) => void

  updateRangeFilters: (
    newFilters: Partial<Record<NonMonetaryRangeFilter, IFiltersRange>>
  ) => void

  toggleFilter: (filterName: BooleanFilter) => void
  toggleFilterId: (filterName: ToggleIdFilter, filterValue: number) => void
  updateModels: (models: number[], updateColateral?: boolean) => void
  updateFuelsByModels: () => void
  updateCondition: (condition: ECondition, updateColateral?: boolean) => void
  saveFilters: (updateColateral?: boolean) => Promise<void>
  updateRetailer: (retailerAttr?: IRetailer | null) => void
  updateRadius: (
    radius?: IFilterCollection['radius'],
    updateColateral?: boolean
  ) => void
  toggleModel: (model: number, updateColateral?: boolean) => void
  updateBudget: (budget: Partial<IFiltersBudget>) => void

  addKeyword: (keyword: string) => void
  removeKeyword: (keyword: string) => void

  restoreFilter: (filterName: keyof IFilterCollection) => void
  restoreAllFilters: () => void
}

type UseFiltersOptions = {
  updateAllColaterals?: boolean
}

export const FiltersInjectionKey: InjectionKey<UseFilters> = Symbol.for(
  'FiltersInjectionKey'
)

/*
 * This composable is used to manage the filters locally inside a component.
 * It has replaced `selected` filters in the filters store.
 *
 * There are various helper methods to update the filters, such as toggleFilter or updateModels.
 * Once you are happy with the filters, you can save them to the store with saveFilters method.
 *
 * @param collection - The initial filters to use. Can be a ref or a plain object.
 * If it is a ref, it will be watched and update the filters when it changes.
 *
 * @param options - The options to use with the filters.
 */
export const useFilters = (
  collection: MaybeRef<Partial<IFilterCollection>>,
  options: UseFiltersOptions = {
    updateAllColaterals: false
  }
): UseFilters => {
  const { runWithContext } = useNuxtApp()

  // Merge all filters with new ones passed in.
  const updateFilters = (newFilters: Partial<IFilterCollection>) => {
    filters.value = {
      ...filters.value,
      ...newFilters
    }
  }

  // Update retailer & relevant booleans. If no retailer is passed, it will be set to null.
  const updateRetailer = (retailerAttr?: IRetailer | null) => {
    retailer.value = retailerAttr || null

    // Reset preferred retailer only when switching condition
    if (!!retailerAttr && !isValidRetailer(retailerAttr, isNew.value)) {
      const preferred = useLocalStorage<Number | null>(
        'preferredRetailerId',
        null
      )

      preferred.value = null
    }

    // Update filters
    if (!retailer.value || !isValidRetailer(retailer.value, isNew.value)) {
      retailer.value = null

      updateFilters({
        retailers: [],
        retailerName: ''
      })
    } else {
      updateFilters({
        retailers: [retailer.value.Id],
        retailerName: retailer.value.Description || ''
      })

      updateFilters({ radius: filtersFactory.createRadius() })
    }
  }

  // Update range filters, such as age, mileage, emission, etc.
  const updateRangeFilters = (
    newFilters: Partial<Record<NonMonetaryRangeFilter, IFiltersRange>>
  ) => {
    updateFilters(newFilters)
  }

  // Toggle boolean filters, such as latestModel, previousModel, hasOffer, etc.
  const toggleFilter = (filterName: BooleanFilter) => {
    updateFilters({ [filterName]: !filters.value[filterName] })
  }

  // Toggle filters that are arrays of IDs, such as transmissions, colours, engines, etc.
  const toggleFilterId = (filterName: ToggleIdFilter, filterValue: number) => {
    const newFilters = filterUtils.toggle<number>(
      filters.value[filterName],
      filterValue
    )

    updateFilters({ [filterName]: newFilters })
  }

  // Toggle models, and update colateral filters if needed.
  const toggleModel = (model: number, updateColateral = false) => {
    const models = filterUtils.toggle<number>(filters.value.models, model)

    updateModels(models, updateColateral)
  }

  // Update models, and update colateral filters if needed.
  const updateModels = (models: number[], updateColateral = false) => {
    updateFilters({ models })

    if (!updateColateral && !options.updateAllColaterals) return

    updateLinesByModels()
    updateEnginesByModels()
    updateFuelsByModels()
  }

  // Update lines based on models.
  const updateLinesByModels = () => {
    updateFilters({
      lines: filters.value.lines.filter((lineId) =>
        availableLinesByModels.value.includes(lineId)
      )
    })
  }

  // Update fuels based on models.
  const updateFuelsByModels = () => {
    updateFilters({
      fuels: filters.value.fuels.filter((fuelId) =>
        availableFuelsByModels.value.includes(fuelId)
      )
    })
  }

  // Update engines based on models.
  const updateEnginesByModels = () => {
    updateFilters({
      engines: filters.value.engines.filter((engineId) =>
        availableEnginesByModels.value.includes(engineId)
      )
    })
  }

  // Update models based on condition.
  const updateModelsByCondition = async () => {
    if (!filters.value.models.length) return

    const { modelsById } = await runWithContext(() =>
      useBrands(filters.value.condition)
    )
    const modelIds = Object.keys(modelsById)

    const newModels = filters.value.models.filter((modelId) =>
      modelIds.includes(modelId.toString())
    )

    updateFilters({ models: newModels })
  }

  // Update ranges based on active filter data.
  const updateRanges = () => {
    let ranges: Partial<
      Pick<IFilterCollection, 'emission' | 'age' | 'mileage'>
    > = {
      // Always set the emissions
      ...(filterUtils.isEmpty(filters.value.emission)
        ? { emission: activeFilterData.value.Emission }
        : {})
    }

    // Only set the age and mileage if used
    if (!isFilterDataNew(activeFilterData.value)) {
      ranges = {
        ...ranges,
        ...(filterUtils.isEmpty(filters.value.age)
          ? { age: activeFilterData.value.Age }
          : {}),
        ...(filterUtils.isEmpty(filters.value.mileage)
          ? { mileage: activeFilterData.value.Mileage }
          : {})
      }
    }

    updateFilters(ranges)
  }

  // Update condition, and update colateral filters if needed.
  const updateCondition = (condition: ECondition, updateColateral = false) => {
    if (condition === filters.value.condition) return

    updateFilters({ condition })

    if (!updateColateral && !options.updateAllColaterals) return

    updateRetailer(retailer.value)
    updateRanges()

    const currentModels = useCloneDeep(filters.value.models)

    updateModelsByCondition()

    if (currentModels === filters.value.models) return

    updateLinesByModels()
    updateEnginesByModels()
    updateFuelsByModels()
  }

  // Update radius, and update colateral filters if needed.
  const updateRadius = (radius?: IFiltersRadius, updateColateral = false) => {
    updateFilters({
      radius: filterUtils.updateRadius(filters.value.radius, radius)
    })

    if (!radius || (!updateColateral && !options.updateAllColaterals)) return

    updateRetailer()
  }

  // Restore a single filter to its previous value.
  const restoreFilter = (filterName: keyof IFilterCollection) => {
    updateFilters({
      [filterName]: activeFilters.value[filterName]
    })
  }

  // Restore all filters to their previous values.
  const restoreAllFilters = () => {
    updateFilters({
      ...activeFilters.value
    })
    updateRetailer(filtersStore.retailer)
  }

  // Update the montly and/or total budget.
  const updateBudget = (budget: Partial<IFiltersBudget>) => {
    const defaultBudget = filtersFactory.createBudget()

    // Make sure we maintain the null values on Min/Max, when the
    // value matches the default budget values, for consistency
    if (isEqual(budget.price, getDefaultRange('budgetPrice'))) {
      budget.price = defaultBudget.price
    }

    if (isEqual(budget.monthlyPrice, getDefaultRange('budgetMonthlyPrice'))) {
      budget.monthlyPrice = defaultBudget.monthlyPrice
    }

    updateFilters({
      budget: {
        ...defaultBudget,
        ...budget
      }
    })
  }

  // Update the selected keywords
  const addKeyword = (keyword: string) => {
    const newKeywords = [...filters.value.keywords, keyword]

    updateFilters({ keywords: newKeywords })
  }

  // Remove a given keyword
  const removeKeyword = (keyword: string) => {
    const current = [...filters.value.keywords]
    const newKeywords = current.filter((kw) => kw !== keyword)

    updateFilters({ keywords: newKeywords })
  }

  // Save filters (to the store), and update colateral filters if needed.
  const saveFilters = async (updateColateral = true) => {
    const hasConditionChanged =
      filters.value.condition !== activeFilters.value.condition

    if (updateColateral && !options.updateAllColaterals) {
      if (hasConditionChanged) {
        updateRetailer(retailer.value)
        updateRanges()
        updateModelsByCondition()
      }

      updateLinesByModels()
      updateEnginesByModels()
      updateFuelsByModels() // ??
    }

    await runWithContext(
      async () => await filtersStore.saveFilters({ ...filters.value })
    )

    if (hasConditionChanged) {
      runWithContext(() => filtersStore.updateSorting())
    }
  }

  const filterDataStore = useFilterDataStore()
  const filtersStore = useFiltersStore()

  const isPostcodeValid = ref(true)
  const retailer = ref(filtersStore.retailer)

  const activeFilters = computed<IFilterCollection>(() => filtersStore.active)
  const activeFilterData = computed(() =>
    filterDataStore.getData<undefined>(filters.value.condition)
  )

  // Initial filters with the default values from the stores.
  const initialFilters = (conditionAttr: ECondition): IFilterCollection => {
    const initialFilterData = filterDataStore.getData<undefined>(conditionAttr)

    return {
      ...filtersFactory.create(conditionAttr),
      emission: initialFilterData.Emission,
      ...(!isFilterDataNew(initialFilterData)
        ? {
            age: initialFilterData.Age,
            mileage: initialFilterData.Mileage
          }
        : {})
    }
  }

  // Is the condition new or used?
  const isNew = computed(() => filters.value.condition === ECondition.New)

  // Available lines based on models.
  const availableLinesByModels = computed(() =>
    activeFilterData.value.ModelLines.reduce<number[]>((prev, current) => {
      if (
        !filters.value.models.length ||
        filters.value.models.includes(current.ModelId)
      ) {
        return [...prev, current.LineId]
      }
      return prev
    }, [])
  )

  // Available fuels based on models.
  const availableFuelsByModels = computed(() =>
    activeFilterData.value.ModelFuel.reduce<number[]>((prev, current) => {
      if (
        !filters.value.models.length ||
        filters.value.models.includes(current.ModelId)
      ) {
        return [...prev, current.FuelId]
      }
      return prev
    }, [])
  )

  // Available engines based on models.
  const availableEnginesByModels = computed(() =>
    activeFilterData.value.Engines.reduce<number[]>((prev, current) => {
      if (
        !filters.value.models.length ||
        filters.value.models.some((modelId) =>
          current.ModelIds.includes(modelId)
        )
      ) {
        return [...prev, current.Id]
      }
      return prev
    }, [])
  )

  // Selected budget with optional defaults.
  const selectedBudget = computed(() => {
    const selectedBudget = filters.value.budget
    const res: NonNullableInterface<IFiltersBudget> = {
      price: getDefaultRange('budgetPrice'),
      monthlyPrice: getDefaultRange('budgetMonthlyPrice')
    }

    if (filterUtils.hasPrice(selectedBudget)) {
      res.price = selectedBudget.price as NonNullableInterface<IFiltersRange>
    }

    if (filterUtils.hasMonthlyPrice(selectedBudget)) {
      res.monthlyPrice =
        selectedBudget.monthlyPrice as NonNullableInterface<IFiltersRange>
    }

    return res
  })

  // Get the default range for a given filter.
  const getDefaultRange = (
    filter:
      | 'emission'
      | 'age'
      | 'mileage'
      | 'arrivalInDays'
      | 'budgetPrice'
      | 'budgetMonthlyPrice'
  ) => {
    switch (filter) {
      case 'emission':
        return activeFilterData.value.Emission || { Min: 0, Max: 369 }
      case 'age':
        return isFilterDataNew(activeFilterData.value)
          ? { Min: 2016, Max: 2024 }
          : activeFilterData.value.Age
      case 'mileage':
        return isFilterDataNew(activeFilterData.value)
          ? { Min: 5, Max: 99132 }
          : activeFilterData.value.Mileage
      case 'budgetPrice':
        return (
          activeFilterData.value.Budget.Price || { Min: 22225, Max: 222700 }
        )
      case 'budgetMonthlyPrice':
        const { getDefaultMonthlyPrice } = useFinanceProductsStore()
        return getDefaultMonthlyPrice || { Min: 50, Max: 4000 }
      // todo: is arrivalInDays used?
      default:
        return { Min: 0, Max: 4000 }
    }
  }

  // Reset the filters
  const resetFilters = (conditionAttr: ECondition) => {
    const newFilters: Partial<IFilterCollection> = {
      retailerGssnId: filters.value.retailerGssnId,
      retailerGroups: filters.value.retailerGroups,
      retailers: filters.value.retailers,
      retailerName: filters.value.retailerName
    }

    filters.value = {
      ...initialFilters(conditionAttr),
      ...newFilters
    }
  }

  // Ensure the collection is up to date
  if (isRef(collection)) {
    watch(collection, (newCollection) => updateFilters(newCollection), {
      deep: true
    })
  }

  // Ensure the retailer is up to date
  filtersStore.$subscribe((mutation, state) => updateRetailer(state.retailer))

  const condition = unref(collection).condition

  const filters = ref<IFilterCollection>({
    ...initialFilters(condition || ECondition.New),
    ...unref(collection)
  })

  // Update the filters with the initial values.
  updateRetailer(retailer.value)
  updateLinesByModels()
  updateEnginesByModels()
  updateFuelsByModels()

  return {
    filters,
    retailer,
    isPostcodeValid,

    isNew,
    availableLinesByModels,
    availableFuelsByModels,
    availableEnginesByModels,
    selectedBudget,

    getDefaultRange,

    resetFilters,
    updateFilters,
    updateRangeFilters,
    updateModels,
    updateFuelsByModels,
    updateCondition,
    saveFilters,
    updateRetailer,
    updateRadius,
    addKeyword,
    removeKeyword,
    toggleModel,
    updateBudget,
    toggleFilter,
    toggleFilterId,
    restoreFilter,
    restoreAllFilters
  }
}
