import { retailerIPBlackList } from '~/constants/retailerIPs'
import { ETrafficType } from '~/types/googleDataLayer'

export const useTrafficType = (): ETrafficType => {
  const userIP = useState('userIP').value as string

  if (!userIP) return ETrafficType.User

  if (retailerIPBlackList.includes(userIP)) return ETrafficType.Showroom

  if (userIP.startsWith('74.217.93')) return ETrafficType.Showroom

  if (userIP.startsWith('103.219.78')) return ETrafficType.Showroom

  if (userIP.startsWith('8.36.116')) return ETrafficType.Showroom

  if (userIP.startsWith('31.186.239')) return ETrafficType.Showroom

  if (userIP.startsWith('103.219.79')) return ETrafficType.Showroom

  if (userIP.startsWith('45.250.160')) return ETrafficType.Showroom

  if (userIP.startsWith('164.207.31')) {
    return ETrafficType.Internal
  }

  if (userIP.startsWith('193.201.132.244')) {
    return ETrafficType.Publicis
  }

  return ETrafficType.User
}
