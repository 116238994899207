import { ExcludeCommonProps } from '~/types'
import {
  IFilterDataCommon,
  IFilterDataNew,
  IFilterDataUsed,
  IOsFilterDataCommon,
  IOsFilterDataNew,
  IOsFilterDataUsed
} from '~/types/filterData'
import { ECondition } from '~/types/vehicle'

const commonFilters = {
  Account: [],
  AllLines: [],
  Baumuster: [],
  Brands: [],
  Colours: [],
  Emission: { Min: 0, Max: 0 },
  Engines: [],
  Fuels: [],
  Lines: [],
  ModelFuel: [],
  ModelLines: [],
  NST: [],
  Packages: [],
  RetailerServices: [],
  SortOptions: [],
  Upholsteries: [],
  UsageCode: [],
  Budget: {
    MonthlyPrice: { Min: 0, Max: 0 },
    Price: { Min: 0, Max: 0 }
  }
} as IOsFilterDataCommon

export const filterDataFactory = {
  create: <T extends ECondition>(
    condition: T
  ): T extends ECondition.New ? IOsFilterDataNew : IOsFilterDataUsed => {
    const filterDataCondition =
      condition === ECondition.New
        ? ({
            OTR: { Min: 0, Max: 0 },
            AdvancedRental: { Min: 0, Max: 0 },
            DeliveryTime: []
          } as ExcludeCommonProps<IFilterDataNew, IFilterDataCommon>)
        : ({
            RetailPrice: { Min: 0, Max: 0 },
            Age: { Min: 0, Max: 0 },
            Mileage: { Min: 0, Max: 0 },
            Transmissions: []
          } as ExcludeCommonProps<IFilterDataUsed, IFilterDataCommon>)

    return {
      ...useCloneDeep(commonFilters),
      ...filterDataCondition
    } as T extends ECondition.New ? IOsFilterDataNew : IOsFilterDataUsed
  },
  make: () => {
    return {
      New: {
        Brands: [
          {
            Models: [
              { Id: 1, Description: 'A-Class' },
              { Id: 2, Description: 'B-Class' },
              { Id: 3, Description: 'C-Class' },
              { Id: 4, Description: 'E-Class' },
              { Id: 5, Description: 'G-Class' },
              { Id: 6, Description: 'AMG GT' }
            ]
          }
        ],
        Transmissions: [{ Id: 1, Description: 'Manual' }],
        Fuels: [{ Id: 1, Description: 'Petrol' }],
        Upholsteries: [{ Id: 1, Description: 'Leather' }],
        Lines: [{ Id: 1, Description: 'AMG Line' }],
        Colours: [
          { Id: 1, Description: 'Red' },
          { Id: 2, Description: 'Blue' },
          { Id: 3, Description: 'Green' }
        ],
        Packages: [{ Id: 1, Description: 'Night Edition' }],
        Engines: [{ Id: 1, Description: '250' }]
      },
      Used: {
        Brands: [
          {
            Models: [
              { Id: 1, Description: 'A-Class' },
              { Id: 2, Description: 'B-Class' },
              { Id: 3, Description: 'C-Class' },
              { Id: 4, Description: 'E-Class' },
              { Id: 5, Description: 'G-Class' },
              { Id: 6, Description: 'AMG GT' }
            ]
          }
        ]
      }
    }
  }
}

export default filterDataFactory
