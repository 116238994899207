import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { IQuoteFinanceProduct } from '~/types/finance/quote'

export const useFinanceAnalytics = (
  quote?: IQuoteFinanceProduct | null,
  error: NuxtError | null = null
) => {
  const filtersStore = useFiltersStore()
  const { currentQuote } = useFinanceQuotesStore()

  const financeAnalytics = computed<FinanceAnalyticsProduct>(() => {
    return quoteAnalytics(
      quote ?? currentQuote,
      filtersStore.active.budget.monthlyPrice,
      filtersStore.isNew,
      true,
      error
    )
  })

  return {
    financeAnalytics
  }
}
