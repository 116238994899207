<template>
  <svg
    id="loader"
    viewBox="0 0 57 56"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-10" fill-rule="nonzero">
        <path
          d="M5.62051188,44.8159018 C2.0918059,40.1310743 -3.63264974e-13,34.3027735 -3.63264974e-13,27.9859053 C-3.63264974e-13,21.688839 2.07871185,15.8772818 5.58736936,11.2 C7.2835986,12.4726275 8.88354753,13.6728918 10.3872161,14.8007929 C7.63214411,18.4751452 6,23.0399214 6,27.9859053 C6,32.9491589 7.64356178,37.5285381 10.4161165,41.209474 C8.9138415,42.3392265 7.31530663,43.5413691 5.62051188,44.8159018 Z"
          fill="currentColor"
          fill-opacity="0.5"
        />
        <path
          d="M11.3650086,50.4480905 C12.6326146,48.7481005 13.828224,47.1446655 14.9518367,45.6377854 C18.6204333,48.3777273 23.1725354,50 28.1035397,50 C40.2538042,50 50.1035397,40.1502645 50.1035397,28 C50.1035397,15.8497355 40.2538042,6 28.1035397,6 C23.1496248,6 18.5781412,7.63738262 14.9007585,10.4004781 C13.772782,8.89686711 12.5725291,7.29691057 11.3,5.60060855 C15.9803053,2.08394152 21.7985572,0 28.1035397,0 C43.5675127,0 56.1035397,12.536027 56.1035397,28 C56.1035397,43.463973 43.5675127,56 28.1035397,56 C21.8277161,56 16.0341315,53.9352893 11.3650086,50.4480905 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" type="text/scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

#loader {
  animation: spin 2s linear infinite;
}
</style>
