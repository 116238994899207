<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 29">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M24.09 12.294c4.361 0 7.91 3.546 7.91 7.906 0 4.359-3.549 7.905-7.91 7.905-2.318 0-4.406-1-5.854-2.592a7.875 7.875 0 0 1-2.038-4.805c-.011-.11-.02-.34-.02-.518 0-4.359 3.55-7.896 7.911-7.896zm-.016 12.965c2.793 0 5.08-2.271 5.08-5.062a5.07 5.07 0 0 0-5.065-5.063 5.064 5.064 0 0 0-5.036 5.574c.13 1.31.67 2.369 1.544 3.18.983.85 2.139 1.371 3.477 1.371zm-1.053-4.31l3.368-3.294 1.225 1.198-4.147 4.054-.46.436-2.41-2.34-.252-.295-.08-.069 1.225-1.188 1.53 1.497zm-13.57-.241h5.956c.121 1.856.679 3.522 1.846 4.805H0l1.589-4.805h3.164v-4.805H0l1.589-4.695h3.164V7.864C4.753 3.472 8.316 0 12.703 0c4.386 0 7.949 3.236 7.949 8.04h-4.766c0-2.402-1.463-3.269-3.217-3.269-1.755 0-3.217 1.336-3.217 3.093v3.344h4.807v4.695H9.452v4.805z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PriceTick'
}
</script>
