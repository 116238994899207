import { defineStore } from 'pinia'
import { useProfileStore } from '~/stores/profileStore'

interface TAuthProfile {
  id: string
  refreshToken: string
  authToken: string
}

const parseProfileCookie = (
  profileCookie: string | null | undefined
): TAuthProfile | null | undefined => {
  try {
    return typeof profileCookie === 'string'
      ? JSON.parse(profileCookie)
      : profileCookie
  } catch (e) {
    return null
  }
}

export const useAuthStore = defineStore('auth', {
  actions: {
    async authenticate(profileCookie?: string | null) {
      const profileStore = useProfileStore()
      const nuxtApp = useNuxtApp()
      const parsedProfileCookie = parseProfileCookie(profileCookie)

      if (!parsedProfileCookie) {
        await profileStore.createProfile()

        return
      }

      await this.refreshToken(parsedProfileCookie).catch(
        async () =>
          await nuxtApp.runWithContext(
            async () => await profileStore.createProfile()
          )
      )
    },

    async refreshToken(profile?: TAuthProfile | null) {
      const profileStore = useProfileStore()
      const persona = profileStore.persona

      const profileId = profile?.id || persona?.Id
      const refreshToken = profile?.refreshToken || persona?.refreshToken
      const authToken = profile?.authToken || persona?.authToken

      if (!profileId || !refreshToken || !authToken) {
        throw new Error('No profileId, refreshToken or authToken')
      }

      const response = await this.api.auth.refreshToken(
        profileId,
        refreshToken,
        authToken
      )

      if (!response.data || response.error) {
        throw new Error('RefreshToken API failed')
      }

      profileStore.updateProfile({
        Id: profileId,
        authToken: response.data.authToken,
        refreshToken: response.data.refreshToken
      })

      if (process.client) {
        profileStore.updateCookie()
      }
    }
  },

  getters: {}
})
