<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 16">
    <g>
      <polygon
        fill="currentColor"
        fill-rule="evenodd"
        points="0 0 0 16 25.659 16 34.972 8 25.659 0"
      />
      <text x="2" y="13" class="label">{{ rating }}</text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EfficiencyArrow',
  props: {
    rating: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.label {
  fill: #fff;
  font-family: $mb-font-text-bold;
  font-size: 14px;
}
</style>
