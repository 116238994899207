<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M5.02511111,13.1675556 C6.28755556,13.1675556 7.62422222,12.8704444 8.98577778,12.2764444 C10.372,11.6824444 11.6097778,10.9644444 12.674,10.098 C13.7384444,9.23155556 14.4811111,8.43955556 14.8771111,7.72155556 C14.9017778,8.09288889 14.9266667,8.39 14.9266667,8.61266667 C14.9513333,11.4346667 12.9464444,15.5188889 6.80733333,15.4695556 C4.95088889,15.3704444 3.29222222,15.024 1.78222222,14.4051111 C1.11377778,14.1328889 0.519777778,13.8357778 0,13.514 C0.668444444,12.3011111 1.38622222,11.1871111 2.15355556,10.2217778 C3.96066667,7.87022222 5.84177778,6.36022222 7.77266667,5.64222222 C8.936,5.22133333 10.0253333,4.99866667 11.0402222,4.99866667 C11.8571111,4.99866667 12.5997778,5.12244444 13.268,5.39466667 C14.0353333,4.37977778 14.6046667,2.79555556 15.0006667,0.666666667 L15.9166667,0.889555556 C15.8424444,1.78066667 15.7433333,2.548 15.5702222,3.16688889 C15.4217778,3.78577778 15.1246667,4.70155556 14.6542222,5.93933333 C14.2828889,6.95422222 13.466,7.96911111 12.2531111,9.03355556 C11.0402222,10.098 9.654,10.9644444 8.11911111,11.6575556 C6.60911111,12.3506667 5.24755556,12.6971111 4.03466667,12.6971111 L4.30688889,13.118 C4.60422222,13.1428889 4.85177778,13.1675556 5.02511111,13.1675556 L5.02511111,13.1675556 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Emission'
}
</script>
