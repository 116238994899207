import { ICountResponse } from '~/types/myaccount'
import ApiFactory from '~/api/factory'
import { useProfileStore } from '~/stores/profileStore'

class CountModule extends ApiFactory {
  async get() {
    const profileStore = useProfileStore(this.pinia)

    const id = profileStore.isLoggedIn
      ? profileStore.getCiamId
      : profileStore.getProfileId

    const type = profileStore.isLoggedIn ? 'ciam' : 'profile'
    return await $osGet<ICountResponse>(
      `${this.apiVersion}/myaccount/${type}/${id}/count`
    )
  }
}

export default CountModule
