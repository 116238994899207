import ApiFactory from './factory'
import { ERouteConditions } from '~/types/routes'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'
import {
  CmsDynamicHomepage,
  CmsPromotionalOffer,
  CmsStaticPage
} from '~/types/cms/contentModel'

export enum EPageNames {
  New = 'New',
  Used = 'Used'
}

export function flattenPageResponseData<T = any>(data: T[]): T {
  return Array.isArray(data) ? data[0] : data
}

class PagesModule extends ApiFactory {
  async getPage(slug: string) {
    const { getCustomerTypeName } = useCustomerTypeStore(this.pinia)
    const { isNew } = useFiltersStore(this.pinia)

    const condition = isNew ? EPageNames.New : EPageNames.Used

    const params = {
      slug,
      condition,
      customerType: getCustomerTypeName
    }

    return await $osPost<CmsDynamicHomepage>(
      `${this.apiVersion}/cms/homepages`,
      { body: params }
    )
  }

  async getHome(slug: string, _condition: ERouteConditions) {
    const isKioskMode = useState('kioskmode')
    const { getCustomerTypeName } = useCustomerTypeStore(this.pinia)
    const condition = convertCondition.toPageName(
      convertCondition.fromConditonRouteParam(_condition)
    )
    const params = isKioskMode.value
      ? {
          slug: `homepage-${_condition}-kiosk`,
          condition,
          customerType: 'Private'
        }
      : {
          slug: `homepage-${_condition}${slug ? '-' + slug : ''}`,
          condition,
          customerType: getCustomerTypeName
        }

    return await $osPost<CmsDynamicHomepage>(
      `${this.apiVersion}/cms/homepages`,
      { body: params }
    )
  }

  async getPromotions() {
    return await $osPost<CmsPromotionalOffer[], CmsPromotionalOffer>(
      `${this.apiVersion}/offers/promotion`,
      {
        body: getPromoOffersPayload('Home'),
        transform: flattenPageResponseData
      }
    )
  }

  async getStatic(pageTitle: string) {
    return await $osGet<CmsStaticPage>(
      `${this.apiVersion}/cms/pages/static/${pageTitle}`
    )
  }
}

export default PagesModule
