import { NProfileVehicle } from '~/types/api'

export function transformProfileVehicles(data: NProfileVehicle.get.Response) {
  return {
    ...data,
    Vehicles: data.Vehicles.map((profileVehicle) => ({
      vin: profileVehicle.VIN,
      IsNew: profileVehicle.IsNew,
      customerMode: profileVehicle.CustomerMode,
      retailerId: Number(profileVehicle.ExternalRetailerId)
    }))
  }
}
