<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6.737 6.737V0h2.526v6.737H16v2.526H9.263V16H6.737V9.263H0V6.737h6.737z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Plus'
}
</script>
