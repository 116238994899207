import { defineStore } from 'pinia'
import { useFinanceCriteriaStore } from '../finance/criteriaStore'
import { IStep } from '~/types/steps'
import { useFormsStore } from '~/stores/forms/formsStore'
import {
  IValuation,
  IValuationCustomerDetails,
  IValuationCustomerDetailsResponse,
  IValuationResponse
} from '~/types/valuation'
import { EStoreStatus } from '~/types/forms'
import { EConditionSlug } from '~/types/vehicle'
import { useProfileStore } from '~/stores/profileStore'
import { IRetailer } from '~/types/retailer'

export enum EValueMyCarSteps {
  RegistrationSearch = 'Value my car',
  Condition = 'Condition',
  SettlementFigure = 'Settlement figure',
  Retailer = 'Showroom',
  AboutYou = 'About you',
  Valuation = 'Your valuation'
}
export enum EValueMyCarErrors {
  'Error: Appraisal Not Found' = 'Unfortunately we cannot find this vehicle, please ensure the registration is correct. Contact your preferred Showroom if this persists.',
  'Valuation Not Possible' = 'Unfortunately we cannot provide a valuation at this time. Please contact your preferred Showroom.'
}

export const valuationNextSteps: string[] = [
  'You will receive an email with details of your indicative valuation.',
  'Your selected Showroom will be in touch to confirm next steps.',
  'Search for your next car on the Online Showroom.',
  'If you are happy with your indicative valuation, click ‘Add to finance’ to create your customised finance quote.',
  'The final price for your car will be agreed with your Showroom.'
]
export const steps: IStep[] = [
  {
    label: EValueMyCarSteps.RegistrationSearch,
    title: 'Value my car'
  },
  {
    label: EValueMyCarSteps.Condition,
    title: 'Car condition'
  },
  {
    label: EValueMyCarSteps.SettlementFigure,
    title: 'Include a settlement figure'
  },
  {
    label: EValueMyCarSteps.Retailer,
    title: 'Select your showroom'
  },
  {
    label: EValueMyCarSteps.AboutYou,
    title: 'Tell us about you'
  },
  {
    label: EValueMyCarSteps.Valuation,
    title: 'Your indicative car valuation',
    trackingLabel: 'valuation'
  }
]

export interface IValuationCondition {
  condition: string
  conditionAnswer: keyof IValuation
  isChecked: boolean
  grade: number
  description?: string
}

export const carConditions: IValuationCondition[] = [
  {
    condition: 'Excellent condition',
    conditionAnswer: 'Excellent',
    isChecked: false,
    grade: 1,
    description:
      'Your car has been very well looked after, and is almost immaculate. The exterior, interior and wheels are in excellent condition with no damage or marks and there are no missing items. Your car has a full service history.'
  },
  {
    condition: 'Good condition',
    conditionAnswer: 'Good',
    isChecked: false,
    grade: 2,
    description:
      'Your car is well looked after, with a few small scratches or stone chips, maybe a car park dent, but overall it’s looking very good. The interior is very well kept, there are no missing items and no chips or cracks to the glass. Your car has a partial service history.'
  },
  {
    condition: 'Poor condition',
    conditionAnswer: 'Poor',
    isChecked: false,
    grade: 5,
    description:
      'Your car still drives but it looks tired. Multiple panels are damaged, including dents and scratches, and it may have suffered accident damage. The interior has significant signs of wear and tear and may be missing trim items. Your car has little/no service history.'
  }
]

interface IValueMyCarModel {
  heading: string
  tags: string[]
}
interface IValueMyCarStoreState {
  error: string | null
  status: EStoreStatus
  currentValuation: IValuationCustomerDetailsResponse
  chosenCondition: IValuationCondition | null
  settlementFigure: string | null
  finalisedValuation: IValuationResponse | null
  initialRegistration: string | null
  retailer: IRetailer | null
}

export const useValueMyCarStore = defineStore('valueMyCar', {
  state: (): IValueMyCarStoreState => {
    return {
      status: EStoreStatus.OK,
      error: null,
      currentValuation: {} as IValuationCustomerDetailsResponse,
      chosenCondition: null,
      settlementFigure: null,
      finalisedValuation: null,
      initialRegistration: null,
      retailer: null
    }
  },
  getters: {
    getSteps(): IStep[] {
      return steps
    },
    getErrorMessage(): string | null {
      if (!this.hasError || !this.error) return null

      return EValueMyCarErrors[this.error]
    },
    valuationRegistration(state: IValueMyCarStoreState): string {
      return state.currentValuation?.Specification?.RegistrationNumber ?? ''
    },
    isSettlementPresent(state: IValueMyCarStoreState): boolean {
      return (
        state.settlementFigure !== '0.00' && state.settlementFigure !== null
      )
    },
    currentModel(state: IValueMyCarStoreState): IValueMyCarModel | null {
      if (state.currentValuation?.Specification === undefined) return null

      const { Model } = state.currentValuation.Specification
      return {
        heading: Model ?? {},
        tags: tags.fromCustomerDetails(state.currentValuation.Specification)
      }
    },
    valuationPayload(state: IValueMyCarStoreState): IValuationCustomerDetails {
      const { customerDetails } = useFormsStore()
      const { getProfileId } = useProfileStore()

      return {
        ValuationId: state.currentValuation?.ValuationId,
        TitleId: 1,
        FirstName: customerDetails.firstName ?? '',
        LastName: customerDetails.lastName ?? '',
        Email: customerDetails.email ?? '',
        Phone: customerDetails.phone ?? '',
        PostCode: customerDetails.postcode ?? '',
        RetailerId: state.retailer?.Id ?? 0,
        Telephone: customerDetails.OptInTelephone ?? false,
        Electronic: customerDetails.OptInEmail ?? false,
        SMS: customerDetails.OptInSMS ?? false,
        Post: customerDetails.OptInMail ?? false,
        CsRef: getTagUrl() || '',
        ValuationOriginUrl: window.location.href,
        Settlement: parseFloat(
          state.settlementFigure?.replace(',', '') ?? ''
        ).toFixed(2),
        ...(getProfileId ? { ProfileId: getProfileId } : {}),
        Condition: state.chosenCondition?.conditionAnswer
      }
    },
    indicativeValuation(state: IValueMyCarStoreState): number | undefined {
      if (
        !state.chosenCondition?.conditionAnswer ||
        !state.finalisedValuation?.Valuation
      )
        return undefined

      return state.finalisedValuation.Valuation[
        state.chosenCondition.conditionAnswer
      ] as number
    },
    hasError(state: IValueMyCarStoreState) {
      return state.status === EStoreStatus.ERROR
    }
  },
  actions: {
    async createValuation(registration, mileage): Promise<void> {
      const route = useRoute()
      const SourceVehicleType = (route.params.condition ??
        EConditionSlug.New) as string

      const res = await this.api.valuation.createValuation({
        RegistrationNumber: registration,
        Mileage: mileage,
        SourceVehicleType
      })

      if (res.error) {
        this.status = EStoreStatus.ERROR
        this.error = res.error.data.message || 'Sorry, something went wrong'
        return
      }

      this.currentValuation = res.data
    },
    async addCustomerToCurrentValuation(): Promise<void> {
      const detailResponse =
        await this.api.valuation.addCustomerDetailsToValuation(
          this.valuationPayload
        )

      if (detailResponse.error) {
        this.status = EStoreStatus.ERROR
        return
      }

      this.currentValuation = {
        ...this.currentValuation,
        ...detailResponse.data
      }
    },
    async fetchFinalizedValuation(): Promise<void> {
      const { getProfileId } = useProfileStore()

      const res = await this.api.valuation.getValuation(
        this.currentValuation.ValuationId,
        getProfileId
      )

      if (res.error) {
        this.status = EStoreStatus.ERROR
        this.error = res.error.data.message || 'Sorry, something went wrong'
      } else {
        this.finalisedValuation = {
          ...this.currentValuation,
          ...res.data
        }
      }
    },
    /**
     * Resets chosenCondition to null if no param is passed
     * @param condition
     */
    updateCondition(condition?): void {
      this.chosenCondition = condition ?? null
    },

    updateSettlementFigure(figure?: string | number): void {
      const financeCriteriaStore = useFinanceCriteriaStore()

      this.settlementFigure = String(figure ?? '0.00')

      financeCriteriaStore.settlement = String(figure ?? '0.00')
    },
    reset(): void {
      this.$reset()
    }
  },
  hasNavigation: true
})
