import { NProfileVehicle } from '~/types/api'
import { useProfileStore } from '~/stores/profileStore'
import ApiFactory from '~/api/factory'

class RecentlyViewedModule extends ApiFactory {
  profileId() {
    const { getProfileId } = useProfileStore(this.pinia)
    return getProfileId
  }

  get() {
    const getUrl = `${
      this.apiVersion
    }/profiles/${this.profileId()}/vehicles/recentlyviewed`

    return $osGet<
      NProfileVehicle.get.Response,
      NProfileVehicle.get.TransformedResponse
    >(getUrl, {
      transform: transformProfileVehicles
    })
  }

  async add(data: NProfileVehicle.add.Payload) {
    const getUrl = `${this.apiVersion}/profiles/${this.profileId()}/vehicles`

    // TODO: Type this and return appropriate response
    return await $osPost<NProfileVehicle.add.Response>(getUrl, {
      body: data
    })
  }
}

export default RecentlyViewedModule
