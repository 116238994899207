<template>
  <svg viewBox="0 0 24 21">
    <path
      id="icon"
      fill="currentColor"
      fill-rule="evenodd"
      d="M22.3817143,15.478628 L19.9405714,13.109657 L19.9405714,22.64 L16.9782857,22.64 L16.9782857,15.1791029 L9.90171429,15.1791029 L9.90171429,22.64 L4.032,22.64 L4.032,13.109657 L1.61828571,15.478628 L0,13.8720844 L12.0137143,2 L24,13.899314 L22.3817143,15.478628 L22.3817143,15.478628 L22.3817143,15.478628 Z M14.9211429,17.1940897 L14.9211429,22.6127704 L11.9588571,22.6127704 L11.9588571,17.1940897 L14.9211429,17.1940897 L14.9211429,17.1940897 L14.9211429,17.1940897 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeDelivery'
}
</script>
