import { IFinanceProduct } from '~/types/finance/product'

export enum EFinanceProductsType {
  all = 'all',
  vehicle = 'vehicle'
}

export interface IFinanceProductsSingle {
  [EFinanceProductsType.all]?: IFinanceProduct
  [EFinanceProductsType.vehicle]?: IFinanceProduct
}
export interface IFinanceProducts {
  [EFinanceProductsType.all]: IFinanceProduct[]
  [EFinanceProductsType.vehicle]: IFinanceProduct[]
}
