<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
    <g fill="currentColor" fill-rule="evenodd">
      <g stroke="currentColor" transform="translate(12)">
        <path d="M4.8,13.5 C8.4,9.9 8.4,4.1 4.8,0.5 C4.8,0.5 4.8,0.5 4.8,0.5" />
        <path
          d="M2.8 11.5C5.3 9 5.3 5 2.8 2.5 2.8 2.5 2.8 2.5 2.8 2.5M.8 9.5C2.2 8.1 2.2 5.9.8 4.5"
        />
      </g>
      <path
        fill="currentColor"
        fill-rule="nonzero"
        d="M9.2,0.5 L3.6,4 L0.5,4 C0.2,4 0,4.2 0,4.5 L0,9.5 C0,9.8 0.2,10 0.5,10 L3.6,10 L9.2,13.5 C9.4,13.6 9.7,13.6 9.9,13.3 C9.9,13.2 10,13.1 10,13 L10,0.9 C10,0.6 9.8,0.4 9.5,0.4 C9.4,0.4 9.3,0.4 9.2,0.5 Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MediaVolumeMute'
}
</script>
