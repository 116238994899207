<template>
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="R1"
      stroke="none"
      stroke-width="1"
      fill="currentColor"
      fill-rule="evenodd"
    >
      <g
        id="bds/Actions/grid-view-4/16"
        transform="translate(1, 1)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <g id="GL_galleryview-1/16">
          <rect id="Rectangle" x="0" y="0" width="6" height="6" rx="0.5"></rect>
          <rect id="Rectangle" x="8" y="0" width="6" height="6" rx="0.5"></rect>
          <rect id="Rectangle" x="0" y="8" width="6" height="6" rx="0.5"></rect>
          <rect id="Rectangle" x="8" y="8" width="6" height="6" rx="0.5"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Grid'
}
</script>
