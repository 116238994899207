<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" :style="style">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0 .38v2.45l7.976 6.922L16 2.782V.333L7.976 6.455z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Arrow',

  props: {
    rotate: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },

  setup(props) {
    const style = computed(() =>
      props.rotate ? { transform: `rotate(${props.rotate}deg)` } : {}
    )

    return {
      style
    }
  }
}
</script>
