<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 7">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5.495 5.355c0 .418-.348.777-.821.777-.442 0-.821-.329-.821-.777 0-.42.347-.778.82-.778.411.03.822.359.822.778zm.947 0C6.442 6.252 5.684 7 4.705 7c-.947 0-1.737-.718-1.737-1.645 0-.898.758-1.646 1.737-1.646.948.03 1.737.748 1.737 1.646zm-.663 0c0-.569-.505-1.047-1.105-1.047s-1.106.478-1.106 1.047c0 .568.506 1.047 1.106 1.047.6.06 1.105-.449 1.105-1.047zm11.4-3.5v-.688l-.6-.3-.505 1.018 1.105-.03zm4.326 3.5c0 .897-.758 1.645-1.737 1.645-.947 0-1.736-.718-1.736-1.645 0-.898.757-1.646 1.736-1.646.98.03 1.737.748 1.737 1.646zm-.631 0c0-.569-.506-1.047-1.106-1.047-.6 0-1.105.478-1.105 1.047 0 .568.505 1.047 1.105 1.047.632.06 1.106-.449 1.106-1.047zM19.8 4.577c-.442 0-.821.329-.821.778 0 .418.347.777.821.777.442 0 .821-.329.821-.777-.032-.42-.41-.778-.821-.778zm4.2-.778l-.221 1.675-1.958.778a3.43 3.43 0 0 0 .095-.748c0-1.107-.948-2.094-2.148-2.094-1.2 0-2.084.898-2.084 2.005 0 .209.032.538.095.747l-.095.12H6.726c.063-.27.095-.509.095-.718A2.119 2.119 0 0 0 4.674 3.41c-1.2 0-2.116 1.077-2.116 2.184 0 .21.031.419.095.688h-.127l-1.137-.27-1.326-.597L0 4.577l.221-.27-.063-.687.726-.658.695-.27c1.674-.598 6.379-.987 6.379-.987S11.148.33 12.189.06c.032-.03.38-.06.38-.06v.509C11.462.808 9.6 1.615 8.462 2.244c-3.19.06-5.305.538-5.305.538s1.705.03 3.505-.15c4.042-.299 11.21-.418 13.074-.658.316-.06.852-.12.852-.12l3.222.39-.127.508.127.778.189.27zM2.4 2.991s-.916.21-1.2.33c-.474.269-.726.957-.726.957l1.073-.36.853-.927z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Cabriolet'
}
</script>
