import { UseFetchOptions } from 'nuxt/app'
import ApiFactory from './factory'
import { EOcdFormType } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { EFormActions } from '~/types/onlineCreditDecision'

class OnlineCreditDecisionModule extends ApiFactory {
  getJourneyState() {
    return $osGet(`${this.apiVersion}/finance/ocd/state`)
  }

  generateProposal(payload: any) {
    return $osPost(`${this.apiVersion}/finance/ocd/session`, {
      body: payload
    })
  }

  getConsent(id: string) {
    return $osGet(
      `${this.apiVersion}/finance/ocd/${id}/consent?POLICYTYPE=financeApply`
    )
  }

  approveConsent(id: string) {
    return $osPost(`${this.apiVersion}/finance/ocd/${id}/consent`, {
      body: { PolicyType: 'financeApply' }
    })
  }

  getCustomerCreditCheck(
    id: string,
    form: string,
    minusForm: EOcdFormType | string
  ) {
    const url = `${this.apiVersion}/finance/ocd/${id}/form?FORMTYPE=${form}${
      minusForm ? '&MINUSFORMTYPES=' + minusForm : ''
    }&includeErrors=true
  `

    return $osGet(url)
  }

  updatePersonalInformation(id: string, personalInfo: any) {
    return $osPut(`${this.apiVersion}/finance/ocd/${id}/personalinformation`, {
      body: personalInfo
    })
  }

  updateForm(id: string, payload: any, options: UseFetchOptions<any, any>) {
    return $osPut(`${this.apiVersion}/finance/ocd/${id}/form-fields`, {
      body: payload,
      ...options
    })
  }

  updateFormAddress(id: string, payload: any) {
    return $osPut(`${this.apiVersion}/finance/ocd/${id}/form-field-data`, {
      body: payload
    })
  }

  extendSession(sessionToken: string) {
    return $osPost(
      `${this.apiVersion}/finance/ocd/session/extend/${sessionToken}`
    )
  }

  getAddressList(payload: any, options: UseFetchOptions<any, any>) {
    return $osPost(`${this.apiVersion}/finance/ocd/addresses/postcode`, {
      body: payload,
      ...options
    })
  }

  submitForm(id: string, payload: any) {
    return $osPost(`${this.apiVersion}/finance/ocd/${id}/submit-form`, {
      body: {
        FormType: payload,
        Lead: ''
      }
    })
  }

  apply(id: string, payload: any) {
    return $osPost(`${this.apiVersion}/finance/ocd/${id}/apply`, {
      body: {
        FormType: payload,
        Lead: ''
      }
    })
  }

  getCreditRating(proposalReference: string, sessionToken: string) {
    return $osGet(
      `${this.apiVersion}/finance/ocd/${proposalReference}/creditrating`,
      {
        query: { SessionToken: sessionToken }
      }
    )
  }

  getFinanceRating(proposalReference: string, sessionToken: string) {
    return $osGet(
      `${this.apiVersion}/finance/ocd/${proposalReference}/financeapplications`,
      {
        query: { SessionToken: sessionToken }
      }
    )
  }

  getPreCreditText() {
    return $osGet(
      `${this.apiVersion}/finance/ocd/content/text/PRE-CREDIT-ELIGIBILITY-SUBMIT-TEXT`
    )
  }

  getCreditAgencyNotice() {
    return $osGet(
      `${this.apiVersion}/finance/ocd/content/text/CREDIT-AGENCY-NOTICE`
    )
  }

  sendEnquiry(id: string) {
    return $osGet(`${this.apiVersion}/finance/ocd/${id}/enquiry`)
  }

  removeSection(id: string, actiondId: string) {
    return $osPost(`${this.apiVersion}/finance/ocd/${id}/form-action`, {
      body: {
        ActionType: EFormActions.RemoveSection,
        Value: actiondId,
        MinusFormTypes: [],
        ReturnAllFieldErrors: false
      }
    })
  }
}

export default OnlineCreditDecisionModule
