import ApiFactory from './factory'

const DEFAULT_EXTENSION = 60

class RetailerSessionModule extends ApiFactory {
  generate(ciamId: string) {
    return $osGet(`${this.apiVersion}/omnichannel/auth/generate/${ciamId}`)
  }

  status(authCode: string) {
    return $osGet(`${this.apiVersion}/omnichannel/auth/status/${authCode}`)
  }

  extend(authCode: string) {
    return $osGet(
      `${this.apiVersion}/omnichannel/auth/extend/${authCode}/${DEFAULT_EXTENSION}`
    )
  }

  terminate(authCode: string) {
    return $osDelete(
      `${this.apiVersion}/omnichannel/auth/terminate/${authCode}`
    )
  }
}

export default RetailerSessionModule
