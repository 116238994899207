/**
 * Outdated browser middleware. Redirect to /outdated page for old browsers.
 */
export default defineNuxtPlugin(() => {
  function getIsBrowserOutdated(headers: string | undefined) {
    return (
      headers && (headers.includes('MSIE ') || headers.includes('Trident/'))
    )
  }

  const headers = useRequestHeaders(['user-agent'])
  const config = useRuntimeConfig()

  const baseUrl = !config.public.baseUrl.includes('mercedes-benz.co.uk')
    ? 'https://shop.dev.os.mercedes-benz.co.uk'
    : config.public.baseUrl
  if (getIsBrowserOutdated(headers['user-agent'])) {
    navigateTo(`${baseUrl}/outdated/`, {
      external: true
    })
  }
})
