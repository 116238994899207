import {
  EFilterTypes,
  IFiltersBudget,
  IFiltersRadius,
  IFiltersRange,
  IFiltersService
} from '~/types/filters'
import { filtersFactory } from '~/factories/filtersFactory'

export const filtersService: IFiltersService = {
  budget: {
    /**
     * Check if budget is empty
     */
    isEmpty: (value: IFiltersBudget): boolean => {
      return (
        filtersService.range.isEmpty(value.monthlyPrice) &&
        filtersService.range.isEmpty(value.price)
      )
    },

    /**
     * Check if budget is not empty
     */
    isNotEmpty: (value: IFiltersBudget): boolean => {
      return !filtersService.budget.isEmpty(value)
    }
  },

  radius: {
    /**
     * Check if radius is empty
     */
    isEmpty: (value: IFiltersRadius): boolean => {
      return !value.Postcode && (!value.Lat || !value.Lon)
    },

    /**
     * Check if radius is not empty
     */
    isNotEmpty: (value: IFiltersRadius): boolean => {
      return !filtersService.radius.isEmpty(value)
    }
  },

  range: {
    /**
     * Check if range is empty
     */
    isEmpty: (value: IFiltersRange) => {
      if (!value) return true

      return value.Min === null || value.Max === null
    },

    /**
     * Check if the range is not empty
     */
    isNotEmpty: (value: IFiltersRange) => {
      if (!value) return false

      return !filtersService.range.isEmpty(value)
    }
  },

  list: {
    /**
     * Check if list is empty
     */
    isEmpty: (value: any[]): boolean => {
      return !value || value.length === 0
    },

    /**
     * Check if list is not empty
     */
    isNotEmpty: (value: any[]): boolean => {
      return !filtersService.list.isEmpty(value)
    }
  },

  string: {
    /**
     * Check if string is empty
     */
    isEmpty: (value: string): boolean => {
      return !value || value.length === 0
    },

    /**
     * Check if string is not empty
     */
    isNotEmpty: (value: string): boolean => {
      return !filtersService.string.isEmpty(value)
    }
  }
}

/**
 * Returns the type of the filter, given it's value
 */
const getType = (value: any): EFilterTypes => {
  // TODO: Drop the else if and just return early
  if (typeof value === 'string') {
    return EFilterTypes.String
  } else if (
    !value ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return EFilterTypes.Unknown
  } else if ('monthlyPrice' in value || 'price' in value) {
    return EFilterTypes.Budget
  } else if ('Distance' in value) {
    return EFilterTypes.Radius
  } else if ('Min' in value && 'Max' in value) {
    return EFilterTypes.Range
  } else if (Array.isArray(value)) {
    return EFilterTypes.List
  }

  return EFilterTypes.Unknown
}

/**
 * Is the given filter a range.
 */
const isRange = (value: any): boolean => {
  return getType(value) === EFilterTypes.Range
}

/**
 * Is the given filter a budget.
 */
const isBudget = (value: any): boolean => {
  return getType(value) === EFilterTypes.Budget
}

/**
 * Is the given filter a radius.
 */
const isRadius = (value: any): boolean => {
  return getType(value) === EFilterTypes.Radius
}

/**
 * Is the given filter a list.
 */
const isList = (value: any): boolean => {
  return getType(value) === EFilterTypes.List
}

/**
 * Is the given filter a string.
 */
const isString = (value: any): boolean => {
  return getType(value) === EFilterTypes.String
}

/**
 * Is the given filter empty
 */
const isEmpty = (value: any): boolean => {
  const type = getType(value)

  if (type === EFilterTypes.Unknown) {
    return true
  }

  return filtersService[type].isEmpty(value)
}

/**
 * Is the given filter not empty
 */
const isNotEmpty = (value: any): boolean => {
  const type = getType(value)

  if (type === EFilterTypes.Unknown) {
    return false
  }

  return filtersService[type].isNotEmpty(value)
}

/*
 * Does the given budget have a monthly price.
 */
const hasMonthlyPrice = (budget: IFiltersBudget): boolean => {
  if (!budget.monthlyPrice) return false

  return budget.monthlyPrice.Min !== null || budget.monthlyPrice.Max !== null
}

/*
 * Does the given budget have a price.
 */
const hasPrice = (budget: IFiltersBudget): boolean => {
  if (!budget.price) return false

  return budget.price.Min !== null || budget.price.Max !== null
}

/*
 * Return the given radius' location.
 */
const getLocation = (radius): string => {
  if (radius.Lat) return 'Current location'

  return (radius.Postcode && formatPostcode(radius.Postcode)) || ''
}

/*
 * Return the given radius' distance.
 */
const getDistance = (radius): string => {
  if (radius.Distance === 5000) return 'National'

  return radius.Distance ? `${radius.Distance} Miles` : ''
}

/*
 * Return the given radius as string.
 */
const getRadiusAsString = (radius): string => {
  const location = getLocation(radius)
  const distance = getDistance(radius)

  if (location && distance) {
    return `${location}, ${distance}`
  }

  return ''
}

/**
 * Toggle a given filter from it's collection.
 */
const toggle = <Type>(collection: Type[], value: Type): Type[] => {
  if (collection.includes(value)) {
    return collection.filter((v) => v !== value)
  }

  return [...collection, value]
}

/**
 * Update the radius filter with a given value.
 */
const updateRadius = (
  oldRadius: IFiltersRadius,
  newRadius?: IFiltersRadius
): IFiltersRadius => {
  if (!newRadius) {
    return filtersFactory.createRadius()
  }

  const copy = { ...oldRadius, ...newRadius }
  const isUpdatingLatLon = newRadius.Lat || newRadius.Lon
  const isUpdatingPostcode = newRadius.Postcode != null

  if (isUpdatingLatLon || (!isUpdatingPostcode && (copy.Lat || copy.Lon))) {
    return {
      Distance: copy.Distance,
      Lat: copy.Lat,
      Lon: copy.Lon
    }
  }

  return {
    Distance: copy.Distance,
    Postcode: copy.Postcode
  }
}

export const filterUtils = {
  getType,
  isRange,
  isBudget,
  isRadius,
  isList,
  isString,
  isEmpty,
  isNotEmpty,
  hasMonthlyPrice,
  hasPrice,
  getLocation,
  getDistance,
  getRadiusAsString,
  toggle,
  updateRadius
}
