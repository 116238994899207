import { ECondition } from '~/types/vehicle'

/**
 * All possible filter types.
 */
export enum EFilterTypes {
  'Budget' = 'budget',
  'Radius' = 'radius',
  'Range' = 'range',
  'List' = 'list',
  'String' = 'string',
  'Unknown' = 'unknown'
}

export enum ESortIds {
  PriceAscendingNew = 1,
  PriceDescendingNew = 2,
  MonthlyPriceAscending = 3,
  MonthlyPriceDescending = 4,
  Distance = 5,
  PriceAscendingUsed = 6,
  PriceDescendingUsed = 7,
  MileageAscending = 8,
  MileageDescending = 9,
  AgeAscending = 10,
  AgeDescending = 11,
  Co2Ascending = 15,
  Co2Descending = 16
}

/**
 * An individual filter service,
 * housing the the helper functions for a filter type.
 */
interface IFilterService {
  isEmpty: (value: any) => boolean
  isNotEmpty: (value: any) => boolean
}

/**
 * Filters service object that houses the helper
 * functions for each of the filter types.
 */
export interface IFiltersService {
  budget: IFilterService
  radius: IFilterService
  range: IFilterService
  list: IFilterService
  string: IFilterService
}

/**
 * Maps properties from the filter payload to the
 * corresponding filter collection property.
 */
export enum EFilterMap {
  'ModelId' = 'models',
  'EngineId' = 'engines',
  'PackageId' = 'packages',
  'LineId' = 'lines',
  'Mapped_Line' = 'lines',
  'ColourId' = 'colours',
  'TransmissionId' = 'transmissions',
  'DeliveryTime' = 'deliveryTime',
  'FuelId' = 'fuels',
  'BodyStyleId' = 'bodyStyles',
  'UpholsteryId' = 'upholsteries',
  'RetailerId' = 'retailers',
  'Retailer' = 'retailerGssnId',
  'RetailerGroup' = 'retailerGroups',
  'RetailerGroupId' = 'retailerGroups',
  'RetailerName' = 'retailerName',
  'RetailerGssnId' = 'retailerGssnId',
  'Keywords' = 'keywords',
  'Age' = 'age',
  'AgeInDays' = 'ageInDays',
  'Mileage' = 'mileage',
  'Emission' = 'emission',
  'Price' = 'budget',
  'OTR' = 'budget',
  'RetailPrice' = 'budget',
  'MonthlyPrice' = 'budget',
  'LatestModel' = 'latestModel',
  'PreviousModel' = 'previousModel',
  'Radius' = 'radius',
  'PromotionalOfferVehiclesOnly' = 'hasOffer',
  'ExcludeUnderOfferVehicles' = 'excludeOutOfStock'
}

/**
 * Maps strings from the query params to the
 * corresponding filter payload property names.
 */
export enum EQueryParamMap {
  'models' = 'ModelId',
  'engines' = 'EngineId',
  'packages' = 'PackageId',
  'lines' = 'LineId',
  'colours' = 'ColourId',
  'transmissions' = 'TransmissionId',
  'deliveryTime' = 'DeliveryTime',
  'fuels' = 'FuelId',
  'bodyStyles' = 'BodyStyleId',
  'upholsteries' = 'UpholsteryId',
  'retailers' = 'RetailerId',
  'retailerGroups' = 'RetailerGroup',
  'retailerGssnId' = 'Retailer',
  'retailerName' = 'RetailerName',
  'keywords' = 'Keywords',
  'age' = 'Age',
  'mileage' = 'Mileage',
  'emission' = 'Emission',
  'arrivalInDays' = 'AID',
  'ageInDays' = 'AgeInDays',
  'latestModel' = 'LatestModel',
  'previousModel' = 'PreviousModel',
  'hasOffer' = 'PromotionalOfferVehiclesOnly',
  'excludeOutOfStock' = 'ExcludeUnderOfferVehicles'
}

/**
 * Maps strings from the search criteria to the
 * corresponding filter collection property names.
 */
export enum ECriteriaMap {
  ModelNames = 'models',
  BodyStyleNames = 'bodyStyles',
  FuelNames = 'fuels',
  TransmissionNames = 'transmissions',
  DeliveryTimeNames = 'deliveryTime',
  MappedLineNames = 'lines',
  MonthlyPrice = 'budget',
  Price = 'budget',
  EngineNames = 'engines',
  PackageNames = 'packages',
  ColourNames = 'colours',
  Radius = 'radius',
  UpholsteryNames = 'upholsteries',
  VehicleType = 'condition',
  Emission = 'emission',
  Keywords = 'keywords',
  Age = 'age',
  Mileage = 'mileage',
  PromotionalOfferVehiclesOnly = 'hasOffer',
  RetailerNames = 'retailerName',
  ExcludeUnderOfferVehicles = 'excludeOutOfStock'
}

export type TCriteriaMapKey = keyof typeof ECriteriaMap

/**
 * Maps strings from the search criteria to the
 * corresponding filter collection property names.
 */
export enum EFilterDataMap {
  models = 'Models',
  bodyStyles = 'BodyStyles',
  fuels = 'Fuels',
  transmissions = 'Transmissions',
  deliveryTime = 'DeliveryTime',
  lines = 'Lines',
  engines = 'Engines',
  packages = 'Packages',
  colours = 'Colours',
  upholsteries = 'Upholsteries',
  keywords = 'Keywords'
}

export enum EFilterCriteriaMap {
  models = 'Model',
  transmissions = 'Transmission',
  fuels = 'Fuel',
  upholsteries = 'Upholstery',
  equipment = 'Equipment',
  lines = 'Line',
  bodyStyles = 'BodyStyle',
  colours = 'ColourGroup',
  packages = 'Packages',
  engines = 'Engine'
}

/**
 * Interface for a radius filter.
 */
export interface IFiltersRadius {
  Postcode?: string
  Distance?: number
  Lat?: number
  Lon?: number
}

/**
 * Interface for a range filter.
 */
export interface IFiltersRange {
  Min: number | null
  Max: number | null
}

/**
 * Interface for a budget filter.
 */
export interface IFiltersBudget {
  price: IFiltersRange
  monthlyPrice: IFiltersRange
}

/**
 * Interface for all the required filter types.
 * Used when dealing with the filter collection helpers.
 */
export interface IFilterCollection {
  models: number[]
  colours: number[]
  transmissions: number[]
  deliveryTime: number[]
  fuels: number[]
  bodyStyles: number[]
  engines: number[]
  upholsteries: number[]
  lines: number[]
  packages: number[]
  age: IFiltersRange
  ageInDays: IFiltersRange
  emission: IFiltersRange
  mileage: IFiltersRange
  keywords: string[]
  retailers: number[]
  retailerGssnId: string[]
  retailerName: string
  retailerGroups: string[]
  condition: ECondition
  budget: IFiltersBudget
  radius: IFiltersRadius
  arrivalInDays: IFiltersRange
  latestModel: boolean
  previousModel: boolean
  hasOffer: boolean
  excludeOutOfStock: boolean
}

export interface ICriteriaObject {
  Values: (string | number)[]
}

/**
 * Base interface for the payload of filters that get sent to the API.
 * Such as Saved Searches and Vehicle Search.
 */
export interface IBaseCriteria {
  VehicleType?: ECondition
  ModelId?: (string | number)[] // todo: this is a string
  ColourId?: ICriteriaObject
  TransmissionId?: ICriteriaObject
  DeliveryTime?: (string | number)[]
  FuelId?: ICriteriaObject
  BodyStyleId?: ICriteriaObject
  EngineId?: (string | number)[]
  UpholsteryId?: ICriteriaObject
  LineId?: (string | number)[]

  Mapped_Line?: (string | number)[]
  PackageId?: (string | number)[]
  EquipmentId?: ICriteriaObject
  Age?: IFiltersRange
  Mileage?: IFiltersRange
  Emission?: IFiltersRange
  Keywords?: (string | number)[]
  RetailerId?: (string | number)[]
  RetailerGroupId?: (string | number)[]
  RetailerName?: string
  MonthlyPrice?: IFiltersRange
  RetailPrice?: IFiltersRange
  OTR?: IFiltersRange
  Radius?: any
  IsFixedRetailer?: boolean
  ArrivalInDays?: IFiltersRange
  AgeInDays?: IFiltersRange
  PromotionalOfferVehiclesOnly?: boolean
  ExcludeUnderOfferVehicles?: boolean
}

/**
 * Interface for the critera payload
 * sent to the Vehicle Search API.
 */
export interface ICriteriaPayload extends IBaseCriteria {
  LimitToMotability: boolean
  LatestModel: boolean
  PreviousModel: boolean
}

/**
 * Interface for all the possible query params.
 */
export interface IParams {
  ModelId: string
  EngineId: string
  PackageId: string
  LineId: string
  ColourId: string
  TransmissionId: string
  DeliveryTime: string
  FuelId: string
  BodyStyleId: string
  UpholsteryId: string
  RetailerId: string
  Retailer: string
  RetailerGroup: string
  RetailerName: string
  Keywords: string
  Age: string
  AgeInDays: string
  Mileage: string
  Emission: string
  Price: string
  OTR: string
  RetailPrice: string | number
  MonthlyPrice: string
  Distance: string
  Postcode: string
  Lat: string
  Lon: string
  LatestModel: boolean
  PreviousModel: boolean
  PromotionalOfferVehiclesOnly: boolean
  ExcludeUnderOfferVehicles: boolean
}
