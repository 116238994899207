<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0,0 L18,0 L18,24 L0,24 L0,0 L0,0 Z M14.4390602,5.73593428 L14.4390602,4.22640657 L3.56128733,4.22640657 L3.56128733,5.73593428 L14.4390602,5.73593428 L14.4390602,5.73593428 Z M14.4390602,8.64150411 L14.4390602,7.1319764 L3.56128733,7.1319764 L3.56128733,8.64150411 L14.4390602,8.64150411 L14.4390602,8.64150411 Z M14.4390602,11.5850154 L14.4390602,10.0754877 L3.56128733,10.0754877 L3.56128733,11.5850154 L14.4390602,11.5850154 L14.4390602,11.5850154 Z M14.4390602,14.5281879 L14.4390602,13.0186602 L3.56128733,13.0186602 L3.56128733,14.5281879 L14.4390602,14.5281879 L14.4390602,14.5281879 Z M8.82607101,17.4340965 L8.82607101,15.9245688 L3.56128733,15.9245688 L3.56128733,17.4340965 L8.82607101,17.4340965 L8.82607101,17.4340965 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Paper'
}
</script>
