import { useSettingsStore } from '~/stores/settingsStore'
import osColours from '~/assets/styles/variables/_ui.module.scss'

export const useUi = (): {
  slugify: (string: string) => string
  isViewportDesktop: Ref<boolean>
  isViewportPortrait: Ref<boolean>
  asMinutes: (seconds: number) => string
  osColours: Record<string, string>
  addOverlayScrollBlocked: () => void
  removeOverlayScrollBlocked: () => void
} => {
  const settingsStore = useSettingsStore()

  const slugify = (string: string) => {
    if (!string) {
      return ''
    }

    return string
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
  }

  const isViewportDesktop = computed(() => settingsStore.viewportIsDesktop)
  const isViewportPortrait = computed(() => settingsStore.viewportIsPortrait)

  function asMinutes(seconds: number) {
    const minutes = Math.ceil(seconds / 60)

    return `${minutes} minutes`
  }

  const scrollY = useState<number>('htmlScrollY', () => 0)

  const addOverlayScrollBlocked = () => {
    if (!window || !document?.documentElement) return

    scrollY.value = window.scrollY || 0
    document.documentElement.style.setProperty(
      '--v-body-scroll-y',
      `-${scrollY.value}px`
    )
    document.documentElement.className += ' overlay-scroll-blocked '
  }

  const removeOverlayScrollBlocked = () => {
    if (!document?.documentElement) return

    document.documentElement.className =
      document.documentElement.className.replace(/overlay-scroll-blocked/g, '')
    document.documentElement.scroll({
      behavior: 'instant',
      top: scrollY.value
    })
  }

  return {
    slugify,
    isViewportDesktop,
    isViewportPortrait,
    asMinutes,
    osColours,
    addOverlayScrollBlocked,
    removeOverlayScrollBlocked
  }
}
