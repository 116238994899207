<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 6">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M4.69 0l4.856.002c.159 0 .21.104.115.231L5.479 5.767a.658.658 0 0 1-.464.231H.163c-.159 0-.21-.104-.115-.231L4.226.231a.657.657 0 0 1 .464-.23zm8.695.231L9.207 5.767a.658.658 0 0 1-.464.231H6.46c-.16 0-.211-.104-.115-.231l4.18-5.536A.659.659 0 0 1 10.99 0h2.28c.159 0 .21.104.115.231zM14.755 0h1.74c.159 0 .21.104.114.231L12.43 5.767a.658.658 0 0 1-.464.231h-1.739c-.16 0-.211-.104-.115-.231l4.18-5.536A.658.658 0 0 1 14.755 0zM13.59 5.769L17.768.232A.656.656 0 0 1 18.232 0l1.198.002c.159 0 .21.104.115.231l-4.178 5.534a.66.66 0 0 1-.464.232h-1.198c-.16.001-.21-.103-.115-.23zm3.116-.002l4.18-5.514a.66.66 0 0 1 .465-.231h.866c.16 0 .21.104.115.231l-4.177 5.514a.66.66 0 0 1-.465.231h-.87c-.16 0-.21-.104-.114-.23zM24.1.022l8.985.002c.16 0 .29.13.29.29V5.71c0 .16-.13.29-.29.29l-1.593-.002a.29.29 0 0 1-.29-.29v-.764a.29.29 0 0 0-.29-.29l-7.714.002a.66.66 0 0 0-.465.231l-.666.88a.66.66 0 0 1-.465.231L19.574 6c-.16 0-.211-.104-.115-.23L23.636.252a.659.659 0 0 1 .464-.23zm.31 3.03l6.505-.002c.16 0 .29-.13.29-.29l-.002-.908c0-.16-.13-.29-.29-.29h-5.381a.657.657 0 0 0-.464.232l-.774 1.027c-.095.127-.044.231.116.231zm10.04 2.656V.314c0-.16.13-.29.29-.29l1.594-.002c.16 0 .4.07.533.157l3.898 2.518a.489.489 0 0 0 .487 0L45.146.179c.134-.086.374-.157.533-.157l1.593.002c.16 0 .29.13.29.29v5.394c0 .16-.13.29-.29.29h-1.595a.29.29 0 0 1-.29-.29l.002-3.045c0-.16-.112-.224-.25-.143l-3.014 1.775c-.137.081-.38.147-.54.148h-1.157c-.16.001-.402-.065-.54-.146l-3.015-1.779c-.137-.08-.25-.017-.25.143l-.001 3.047c0 .16-.13.29-.29.29h-1.593a.29.29 0 0 1-.29-.29zm14.198 0V.314c0-.16.13-.29.29-.29L60.71.022c.16 0 .29.13.29.29v.94c0 .16-.13.29-.29.29l-9.598.002a.29.29 0 0 0-.29.29v2.354c0 .16.131.29.29.29l7.426.002c.16 0 .29-.13.29-.29v-.307c0-.16-.13-.29-.29-.29l-3.622-.003c-.16 0-.29-.131-.29-.29l-.001-.58c0-.159.13-.29.29-.289l5.795.002c.16 0 .29.13.29.29v2.985c0 .16-.13.29-.29.29H48.938a.29.29 0 0 1-.29-.29z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Amg'
}
</script>
