import {
  IFinanceBaseProduct,
  IFinanceProduct,
  IFinanceProductOption
} from '~/types/finance/product'

/**
 * If a finance product's value contains an array of Options,
 * it only keeps the default option in that array
 * @param product Finance Product
 * @returns Finance product with flat Options
 */
export function getFlatProduct(
  product: IFinanceProduct | IFinanceBaseProduct
): IFinanceProduct | null {
  if (!product) return null

  const p = { ...product }
  const keys = Object.keys(p)

  for (const k of keys) {
    const value = p[k]
    // Only keep the default one because that's what we
    // should be sending on a calculate request
    if (value && value.Options && value.Options.length > 0) {
      p[k] = {
        Options: [
          value.Options.find((o: IFinanceProductOption) => o.IsDefault === true)
        ]
      }
    }
  }

  return p
}

export default {
  getFlatProduct
}
