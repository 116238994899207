import { EFormNames, IDataLayerService } from '~/types/dataLayer'
import {
  EDisplayMode,
  EPageName,
  EUserType,
  IGoogleDataLayer,
  IGoogleDataLayerOptions
} from '~/types/googleDataLayer'
import { ECondition } from '~/types/vehicle'
import { EConsents } from '~/types/usercentrics'
import { useFiltersStore } from '~/stores/filtersStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { IRetailer } from '~/types/retailer'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { ERoutePageSlugs, ERoutes } from '~/types/routes'
import { useRetailerSessionStore } from '~/stores/retailerSessionStore'
import { useProfileStore } from '~/stores/profileStore'
import { useSettingsStore } from '~/stores/settingsStore'
import { IKioskModeCookie } from '~/types/cookies'

export const defaultPageName = 'generic'

const pages = [
  { slug: ERoutes.Vehicle, name: EPageName.VehicleDetails },
  { slug: ERoutes.Results, name: EPageName.Results },
  { slug: ERoutes.Homepage, name: EPageName.Homepage },
  { slug: ERoutes.ModelHomepage, name: EPageName.Homepage },
  { slug: ERoutes.Models, name: EPageName.Models },
  { slug: ERoutes.MyAccount, name: EPageName.MyAccount },
  { slug: ERoutes.Compare, name: EPageName.Compare },
  { slug: ERoutes.CookiePolicy, name: EPageName.CookiePolicy },
  { slug: ERoutePageSlugs.PrivacyPolicy, name: EPageName.PrivacyPolicy },
  { slug: ERoutePageSlugs.LegalNotice, name: EPageName.LegalNotice },
  { slug: ERoutePageSlugs.FAQ, name: EPageName.FAQ },
  { slug: ERoutePageSlugs.MSA, name: EPageName.MSA },
  { slug: 'mercedes-benz-cars-uk-ltd', name: EPageName.MBUK },
  { slug: ERoutes.Offer, name: EPageName.Offer }
]

export const googleDataLayer: IDataLayerService = {
  push(payload: Partial<IGoogleDataLayerOptions>) {
    const { hasConsent } = useConsent(EConsents.GA)

    if (!hasConsent.value) {
      return
    }

    const {
      route,
      slug,
      type,
      pageCategory,
      category,
      action,
      user,
      vehicle,
      forms,
      filters,
      ecommerce,
      results,
      selectionsMade,
      marketingPreference,
      retailer,
      order,
      formSubmitted,
      finance,
      label,
      eligibilityResults,
      ocdApplicationResults,
      userFeedback
    } = payload

    const { $pinia } = useNuxtApp()
    const formsStore = useFormsStore($pinia)
    const filtersStore = useFiltersStore($pinia)
    const customerTypeStore = useCustomerTypeStore($pinia)
    const retailerStore = useRetailerStore($pinia)
    const retailerToUse: IRetailer | null = retailer || retailerStore.retailer
    const vehiclesSearchStore = useVehiclesSearchStore($pinia)
    const retailerSessionStore = useRetailerSessionStore($pinia)
    const settingsStore = useSettingsStore($pinia)
    const { isLoggedIn, getCiamId, getProfileId } = useProfileStore($pinia)
    const kioskModeCookie = useCookie<IKioskModeCookie | null>('kioskmode')

    const geolocationPreference = useState<string>(
      'geolocationPreference',
      () => 'not chosen'
    )

    function getUserType(): EUserType {
      if (customerTypeStore.isMotability) return EUserType.Motability
      else if (customerTypeStore.isBusiness) return EUserType.Business

      return EUserType.Private
    }

    function pageNumber(): number {
      if (route !== ERoutes.Results) return 0

      return (vehiclesSearchStore?.meta?.pages?.current ?? 0) + 1
    }

    function homepageType(): string {
      if (route === ERoutes.ModelHomepage && slug) {
        return slug
      } else if (route === ERoutes.Homepage) {
        return EPageName.Default
      }
      return ''
    }

    function getDisplayMode(): EDisplayMode {
      const _navigator: any = window.navigator
      const isStandalone =
        window.matchMedia &&
        window.matchMedia('(display-mode: standalone)').matches
      if (document.referrer.startsWith('android-app://')) {
        return EDisplayMode.TWA
      } else if (_navigator.standalone || isStandalone) {
        return EDisplayMode.Standalone
      }

      return EDisplayMode.Browser
    }

    const dataLayer: IGoogleDataLayer = {
      page: {
        name: this.pageName(route),
        category: pageCategory,
        language: 'en',
        market: 'UK',
        application: 'vs',
        displayMode: getDisplayMode(),
        page_number: pageNumber(),
        homepage_type: homepageType()
      },
      event: {
        type,
        timestamp: new Date(),
        category,
        action
      },
      user: {
        type: getUserType(),
        omnichannel_status: retailerSessionStore.analyticsStatus,
        logged_in: isLoggedIn ? 'logged in' : 'logged out',
        id: getProfileId,
        ciam: getCiamId,
        agent_user: settingsStore.isAgentUser,
        traffic_type: useTrafficType(),
        geolocation_location_status: geolocationPreference.value
      },
      vehicle: {
        name: '',
        condition:
          filtersStore.active.condition === ECondition.New ? 'new' : 'used'
      },
      retailer: {
        name: retailerToUse?.Description,
        id: retailerToUse?.Id,
        gssnid: retailerToUse?.GssnId,
        group_name: retailerToUse?.RetailerGroupName
      },
      form_submitted: Object.keys(EFormNames).reduce(
        (prev, current) => ({
          ...prev,
          [current]: formsStore.completedForms.includes(EFormNames[current])
            ? 'yes'
            : ''
        }),
        {}
      )
    }

    if (results) {
      dataLayer.results = results
    }

    if (user) {
      Object.assign(dataLayer.user!, user)
    }

    if (vehicle) {
      Object.assign(dataLayer.vehicle!, vehicle)
    }

    if (eligibilityResults) {
      dataLayer.eligibility_results = eligibilityResults
    }

    if (ocdApplicationResults) {
      dataLayer.ocd_application_results = ocdApplicationResults
    }

    if (userFeedback) {
      dataLayer.user_feedback = userFeedback
    }

    if (order) {
      const currentStatus = order.Status.find((status) => status.Current)

      dataLayer.order = {
        number: order.Guid,
        date: order.OrderDate,
        stage: currentStatus?.Slug.replace(/_/g, ' ')
      }
    }

    if (forms) {
      dataLayer.forms = forms
    }

    if (formSubmitted) {
      Object.assign(dataLayer.form_submitted!, formSubmitted)
    }

    if (filters) {
      dataLayer.filters = filters
    }

    if (ecommerce) {
      dataLayer.ecommerce = ecommerce
    }

    if (selectionsMade) {
      dataLayer.selections_made = selectionsMade
    }

    const { financeAnalytics } = useFinanceAnalytics()

    if (finance) {
      dataLayer.finance = finance
    } else {
      dataLayer.finance = financeAnalytics.value
    }

    if ('marketingPreference' in payload) {
      dataLayer.marketing_preference = marketingPreference
    }

    if (label) {
      dataLayer.label = label
    }

    if (kioskModeCookie.value?.isKiosk) {
      dataLayer.kiosk = {
        mode: kioskModeCookie.value?.isKiosk === true,
        type: kioskModeCookie.value?.KioskType || ''
      }
    }

    window.dataLayer?.push(dataLayer)

    return dataLayer
  },

  pageName(route: ERoutes): EPageName {
    if (!route) return EPageName.Default

    const page = pages.find(({ slug }) => route.includes(slug))

    return page ? page.name : EPageName.Default
  }
}
