<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <polygon
      fill="currentColor"
      points="5.1,19 9.6,19 5.1,23.8 5.1,25 6.1,25 11.1,20.4 11.1,25 13.1,25 13,19 13,17 11,17 5.1,17 "
    />
    <polygon
      fill="currentColor"
      points="24.5,11.6 20,11.6 24.5,6.7 24.5,5.5 23.5,5.5 18.5,10.1 18.5,5.5 16.5,5.5 16.6,11.6 16.6,13.5 18.6,13.6 
	24.5,13.6 "
    />
  </svg>
</template>

<script>
export default {
  name: 'MediaFullscreenColapse'
}
</script>
