<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm.738 4H7.245l-2.578 7.667H6.17l.476-1.595h2.7l.47 1.595h1.516L8.738 4zm-.747 1.667L9 9H7l.991-3.333z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Automatic'
}
</script>
