<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <polygon
      fill="currentColor"
      points="15.945 14.312 14.296 16 7.999 10.02 1.732 16 .055 14.312 6.021 8.028 0 1.716 1.677 0 7.999 6.035 14.323 0 16 1.716 9.979 8.028"
    />
  </svg>
</template>

<script>
export default {
  name: 'Close'
}
</script>
