import ApiFactory from '~/api/factory'
import { NBanner } from '~/types/api'

class BannerModule extends ApiFactory {
  getFilterBanner(payload: NBanner.getFilterBanner.Payload) {
    return $osPost<NBanner.getFilterBanner.Response>(
      `${this.apiVersion}/cms/banner`,
      {
        body: payload
      }
    )
  }
}

export default BannerModule
