import ApiFactory from './factory'
import { IRetailer } from '~/types/retailer'
class RetailerModule extends ApiFactory {
  search(query: string) {
    return $osGet<{ Retailers: IRetailer[] }>(
      `${this.apiVersion}/retailers/search/${query}`
    )
  }

  searchNearest(payload: NearestRetailerPayload) {
    return $osPost<{ Retailers: IRetailer[] }>(
      `${this.apiVersion}/retailers/search/nearest`,
      {
        body: {
          ...payload
        }
      }
    )
  }

  get(retailerId: string) {
    return $osGet<{ Retailer: IRetailer }>(
      `${this.apiVersion}/retailers/${retailerId}`
    )
  }

  getGroup(retailerId: number) {
    return $osGet(
      `${this.apiVersion}/retailers/group?RetailerId=${retailerId}&IsOnline=true`
    )
  }
}

export default RetailerModule
