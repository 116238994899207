import ApiFactory from '~/api/factory'
import { NStockNotifications, IErrorResponse, OsResponse } from '~/types/api'

const isErrorButSuccessResponse = (
  response: OsResponse<NStockNotifications.create.Response>
): response is {
  data: {
    data: any | IErrorResponse
    status: number | string
    statusText: string
  }
  error: null
} => {
  return (
    !!response.data &&
    Object.prototype.hasOwnProperty.call(response.data, 'status')
  )
}

class StockNotificationsModule extends ApiFactory {
  async create(payload: NStockNotifications.create.Payload) {
    const response = await $osPost<NStockNotifications.create.Response>(
      `${this.apiVersion}/myaccount/backorder`,
      {
        body: payload
      }
    )

    // TODO: check what's the exact response when the endpoint returns 200 but failed response
    return new Promise((resolve, reject) => {
      if (
        isErrorButSuccessResponse(response) &&
        (response.data.status !== 'success' ||
          response.data.data.ResponseStatus?.ErrorCode)
      ) {
        reject(response)
      }

      resolve(response)
    })
  }

  get(ciamId: string) {
    return $osGet<NStockNotifications.get.Response>(
      `${this.apiVersion}/myaccount/backorders/customer/${ciamId}`
    )
  }
}

export default StockNotificationsModule
