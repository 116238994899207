/*
 * A file to store all error messages, to avoid typos and duplicates.
 */

export default {
  retailerSearch: {
    empty: 'We could not find any retailers, please try again.'
  },

  showroomSearch: {
    empty: 'We could not find any showrooms, please try again.'
  },
  myAccountError: {
    fetchDetailError:
      'We could not fetch your data at this moment. Please try again later.'
  },
  finance: {
    defaultEmpty:
      'Finance calculations aren’t currently available. Please contact your Showroom for a finance quote.',
    notFound: 'Please contact your preferred Showroom for a finance quote.'
  }
}
