<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M15.998 8.226a7.82 7.82 0 0 1-.625 3.125H.626a7.898 7.898 0 0 1-.624-3.125C-.088 3.886 3.705.085 8.01.175c4.305-.09 8.098 3.71 7.987 8.05zm-6.336-.18c0-.18-.023-.338-.067-.518l3.168-3.395-.87-.855-3.391 3.17c-.157-.067-.313-.089-.536-.089-.892 0-1.65.787-1.65 1.71 0 .899.78 1.663 1.672 1.663.514 0 .915-.18 1.183-.495.312-.337.49-.72.49-1.191z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Mileage'
}
</script>
