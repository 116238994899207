import { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior(to) {
    const width = window?.innerWidth || 0
    let top = width > 768 ? 0 : 56

    if (to.hash) {
      const isOffers = to.hash === '#vehicle-offers'
      if (to.name === 'vehicle') {
        top += 120 // Accomodate the sticky header.
      }
      return new Promise((resolve) => {
        setTimeout(
          () => {
            resolve({
              el: to.hash,
              left: 0,
              top,
              behavior: 'instant'
            })
          },
          isOffers ? 2000 : 1000
        )
      })
    }

    return {
      left: 0,
      top: 0,
      behavior: 'instant'
    }
  }
}
