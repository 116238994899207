<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M14.167 13.111A2.932 2.932 0 0 1 11.228 16a2.932 2.932 0 0 1-2.889-2.939c0-.251.05-.502.1-.753l-3.49-2.16c-.528.502-1.231.753-1.96.753-1.632 0-2.964-1.28-2.989-2.888-.025-1.608 1.231-2.94 2.839-2.964h.125a2.611 2.611 0 0 1 1.934.753l3.492-2.16a3.41 3.41 0 0 1-.076-.728A2.921 2.921 0 0 1 11.254 0a2.9 2.9 0 0 1 2.913 2.914c.025 1.582-1.256 2.888-2.839 2.913h-.075a2.75 2.75 0 0 1-2.06-.854l-3.44 2.135c.075.277.125.578.1.854 0 .277-.025.553-.1.83l3.516 2.16a2.708 2.708 0 0 1 1.959-.754c1.582-.025 2.889 1.23 2.914 2.788.025.05.025.1.025.125Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Share'
}
</script>
