import { Pinia } from 'pinia'
import AuthModule from '~/api/auth'
import FiltersModule from '~/api/filters'
import PagesModule from '~/api/pages'
import RetailerModule from '~/api/retailers'
import FormsModule from '~/api/forms'
import MapModule from '~/api/map'
import RetailerSessionModule from '~/api/retailerSession'
import OnlineCreditDecisionModule from '~/api/onlineCreditDecision'
import PDFModule from '~/api/pdf'
import ValuationModule from '~/api/valuation'
import QuoteModule from '~/api/finance/quote'
import ProductsModule from '~/api/finance/products'
import ProfileAuthModule from '~/api/profile/auth'
import ShortlistModule from '~/api/profile/shortlist'
import SavedSearchModule from '~/api/profile/savedSearch'
import RecentlyViewedModule from '~/api/profile/recentlyViewed'
import VehicleSearchModule from '~/api/vehicles/search'
import VehicleDetailsModule from '~/api/vehicles/details'
import CountModule from '~/api/myaccount/count'
import OrdersModule from '~/api/myaccount/orders'
import BackOrdersModule from '~/api/myaccount/backOrders'
import EnquiriesModule from '~/api/myaccount/enquiries'
import TestDrivesModule from '~/api/myaccount/testDrives'
import ValuationsModule from '~/api/myaccount/valuations'
import StockNotificationsModule from '~/api/myaccount/stockNotifications'
import OrderStatusModule from '~/api/cms/orderStatus'
import ContentBlockModule from '~/api/cms/contentBlock'
import BannerModule from '~/api/cms/banner'
import CrossSaleModule from '~/api/cms/crossSale'
import AddressModule from '~/api/myaccount/address'

export interface Api {
  auth: AuthModule
  filters: FiltersModule
  profile: {
    auth: ProfileAuthModule
    shortlist: ShortlistModule
    savedSearch: SavedSearchModule
    recentlyViewed: RecentlyViewedModule
  }
  finance: {
    quote: QuoteModule
    products: ProductsModule
  }
  pages: PagesModule
  vehicles: {
    details: VehicleDetailsModule
    search: VehicleSearchModule
  }
  retailers: RetailerModule
  forms: FormsModule
  valuation: ValuationModule
  map: MapModule
  retailerSession: RetailerSessionModule
  myAccount: {
    address: AddressModule
    count: CountModule
    orders: OrdersModule
    backOrders: BackOrdersModule
    enquiries: EnquiriesModule
    testDrives: TestDrivesModule
    valuations: ValuationsModule
    stockNotifications: StockNotificationsModule
  }
  onlineCreditDecision: OnlineCreditDecisionModule
  pdf: PDFModule
  cms: {
    orderStatus: OrderStatusModule
    contentBlock: ContentBlockModule
    banner: BannerModule
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  const config = useRuntimeConfig()
  const _pinia = $pinia as Pinia

  return {
    provide: {
      api: {
        auth: new AuthModule(_pinia),
        filters: new FiltersModule(_pinia),
        profile: {
          auth: new ProfileAuthModule(_pinia, {
            apiVersion: config.public.apiVersions.profile
          }),
          shortlist: new ShortlistModule(_pinia, {
            apiVersion: config.public.apiVersions.profile
          }),
          savedSearch: new SavedSearchModule(_pinia, {
            apiVersion: config.public.apiVersions.profile
          }),
          recentlyViewed: new RecentlyViewedModule(_pinia, {
            apiVersion: config.public.apiVersions.profile
          })
        },
        finance: {
          quote: new QuoteModule(_pinia, {
            apiVersion: config.public.apiVersions.financeDefaults,
            quoteApiVersion: config.public.apiVersions.financeQuote
          }),
          products: new ProductsModule(_pinia, {
            apiVersion: config.public.apiVersions.financeDefaults
          })
        },
        pages: new PagesModule(_pinia),
        vehicles: {
          details: new VehicleDetailsModule(_pinia),
          search: new VehicleSearchModule(_pinia, {
            apiVersion: config.public.apiVersions.search
          })
        },
        retailers: new RetailerModule(_pinia, {
          apiVersion: config.public.apiVersions.retailer
        }),
        forms: new FormsModule(_pinia),
        valuation: new ValuationModule(_pinia),
        map: new MapModule(_pinia, {
          apiVersion: config.public.apiVersions.maps
        }),
        retailerSession: new RetailerSessionModule(_pinia, {
          apiVersion: config.public.apiVersions.omnichannel
        }),
        myAccount: {
          count: new CountModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          orders: new OrdersModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          backOrders: new BackOrdersModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          enquiries: new EnquiriesModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          address: new AddressModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          testDrives: new TestDrivesModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          valuations: new ValuationsModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          }),
          stockNotifications: new StockNotificationsModule(_pinia, {
            apiVersion: config.public.apiVersions.myAccount
          })
        },
        onlineCreditDecision: new OnlineCreditDecisionModule(_pinia, {
          apiVersion: config.public.apiVersions.ocd
        }),
        pdf: new PDFModule(_pinia, {
          apiVersion: config.public.apiVersions.pdf
        }),
        cms: {
          orderStatus: new OrderStatusModule(_pinia),
          contentBlock: new ContentBlockModule(_pinia),
          banner: new BannerModule(_pinia),
          crossSell: new CrossSaleModule(_pinia)
        }
      }
    }
  }
})
