import { Pinia } from 'pinia'
import { RouteLocation } from '#vue-router'
import { EFilterMap, ESortIds } from '~/types/filters'
import { ECondition } from '~/types/vehicle'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { IFilterDataBrandModel } from '~/types/filterData'

// todo: remove - old assets
export const filterAssets = '/images/filters'

/**
 * Formats the budget text for display.
 *
 * @param {object} option Budget that contains the Min and Max values
 */
export function getFormattedBudget(option: { Min: number; Max: number }) {
  const min = formatWithCommasAndDecimals(option.Min)
  const max = formatWithCommasAndDecimals(option.Max)

  return `£${min}—£${max}`
}

/**
 * Function that returns an array of ModelIds after a VehicleType change.
 * It does a lookup of the previously selected ModelIds and returns the
 * equivalent ones of the updated VehicleType
 */
export function getUpdatedModelIds(
  {
    oldVehicleType,
    updatedVehicleType,
    modelIds
  }: {
    oldVehicleType: ECondition
    updatedVehicleType: ECondition
    modelIds: number[]
  },
  models: {
    [ECondition.New]: IFilterDataBrandModel[]
    [ECondition.Used]: IFilterDataBrandModel[]
  }
) {
  const oldModels: IFilterDataBrandModel[] = []
  const newModelIds: number[] = []

  modelIds.forEach((modelId) => {
    const model = models[oldVehicleType].find((model) => model.Id === modelId)
    model && oldModels.push(model)
  })

  oldModels.forEach((oldModel) => {
    const model = models[updatedVehicleType].find(
      (model) => model.Id === oldModel.Id
    )

    if (model) {
      newModelIds.push(model.Id)
    }
  })

  return newModelIds
}

export function getSortOptionFromDescription(sortOptions, description) {
  return sortOptions.find((o) => o.Description.includes(description))
}

export async function setFiltersFromRouteQuery(
  route: RouteLocation,
  vehicleType: ECondition,
  pinia: Pinia
) {
  const { runWithContext } = useNuxtApp()
  const filterDataStore = useFilterDataStore(pinia)
  const filtersStore = useFiltersStore(pinia)
  const customerTypeStore = useCustomerTypeStore(pinia)

  if (route.query.RetailerGroup || route.query.Retailer) {
    // If we're getting in retailer mode, make sure
    // that the location filter query parameters
    // are deleted
    delete route.query.Postcode
    delete route.query.Distance
    delete route.query.Lat
    delete route.query.Lon
    delete route.query.RetailerId
    delete route.query.RetailerName

    // If a Retailer GSSNID is present, make a new retailer
    // request to get the actual RetailerId
    if (route.query.Retailer) {
      const retailer = await filtersStore.fetchRetailer(
        route.query.Retailer as string
      )

      if (retailer?.Id) {
        route.query.RetailerId = String(retailer.Id)

        if (retailer.IsMotabilityRetailer) {
          customerTypeStore.enableMotability()
        }
      } else {
        delete route.query.Retailer
      }
    }
  }

  const filters = filterCollection.fromQueryParams(route.query, vehicleType)

  // Set the ranges to default if empty
  const { Age, Mileage, Emission, AgeInDays } =
    vehicleType === ECondition.New
      ? filterDataStore.getFilterDataNew
      : filterDataStore.getFilterDataUsed

  if (isEmpty(filters.age) && Age) {
    filters.age = Age
  }

  if (isEmpty(filters.ageInDays) && AgeInDays) {
    filters.ageInDays = AgeInDays
  }

  if (isEmpty(filters.mileage) && Mileage) {
    filters.mileage = Mileage
  }

  if (isEmpty(filters.emission) && Emission) {
    filters.emission = Emission
  }

  // set retailer in vehicle details and offer pages
  if (route.params.retailerId) {
    if (Number.isNaN(Number(route.params.retailerId))) return

    filters[EFilterMap.RetailerId] = [Number(route.params.retailerId)]
  }

  const { saveFilters, updateCondition, updateRetailer } = await runWithContext(
    () => useFilters(filters)
  )

  if (vehicleType === ECondition.Used) {
    delete route.query.AgeInDays
  }

  // Fetch retailer obejct if present
  if (filters.retailers.length) {
    if (filters.retailers[0] !== filtersStore.retailer?.Id) {
      await runWithContext(() =>
        filtersStore.fetchRetailer(filters.retailers[0])
      )
    }

    filtersStore.retailer && updateRetailer(filtersStore.retailer)
  }

  const sortId = Array.isArray(route.query.SortId)
    ? route.query.SortId[0]
    : route.query.SortId

  runWithContext(() =>
    filtersStore.updateSorting(parseInt(sortId as string) as ESortIds, false)
  )

  runWithContext(() => saveFilters())

  updateCondition(vehicleType, true)
}
