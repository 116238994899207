<template>
  <svg viewBox="0 0 17.6 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M3.2,3.2v17.6h11.2V3.2H3.2z M4.8,4.8h8v4.8h-8V4.8z M6.4,19.2H4.8v-1.6h1.6V19.2z M6.4,16H4.8v-1.6h1.6V16z
	 M6.4,12.8H4.8v-1.6h1.6V12.8z M9.6,19.2H8v-1.6h1.6V19.2z M9.6,16H8v-1.6h1.6V16z M9.6,12.8H8v-1.6h1.6V12.8z M12.8,19.2h-1.6v-4.8
	h1.6V19.2z M12.8,12.8h-1.6v-1.6h1.6V12.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Calculator'
}
</script>
