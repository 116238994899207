import type { RouteLocation } from '#vue-router'
import { ERoutes } from '~/types/routes'

export enum ERouteState {
  FROM_ROUTE = 'fromRoute'
}

export const useOsRoute = () => {
  const fromRoute = useState<RouteLocation | undefined>(ERouteState.FROM_ROUTE)

  // back button
  const resultsBackRoute = computed(() => {
    switch (fromRoute.value?.name) {
      case ERoutes.Vehicle:
        const modelSlug =
          (Array.isArray(fromRoute.value.params.model)
            ? fromRoute.value.params.model[0]
            : fromRoute.value.params.model) || ''
        return {
          text: `Back to ${modelSlug.replace(/-/g, ' ')}`,
          route: fromRoute.value
        }
      case ERoutes.MyAccountBackOrders:
      case ERoutes.MyAccountCallback:
      case ERoutes.MyAccountEnquiries:
      case ERoutes.MyAccountOrder:
      case ERoutes.MyAccountOrders:
      case ERoutes.MyAccountRecentlyViewed:
      case ERoutes.MyAccountSavedSearch:
      case ERoutes.MyAccountRetailerSession:
      case ERoutes.MyAccountSettings:
      case ERoutes.MyAccountShortlist:
      case ERoutes.MyAccountTestDrives:
      case ERoutes.MyAccountValuations:
        return {
          text: 'Back to My Account',
          route: fromRoute.value
        }
      case ERoutes.Homepage:
      case ERoutes.ModelHomepage:
        return {
          text: 'Back to Homepage',
          route: fromRoute.value
        }
      default:
        return {
          text: 'Back to Homepage',
          route: { name: ERoutes.Homepage }
        }
    }
  })

  return {
    resultsBackRoute,
    fromRoute
  }
}
