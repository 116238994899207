// Determine if we are in Kiosk mode.
import { IKioskModeCookie } from '~/types/cookies'

export default defineNuxtPlugin(async (nuxtApp) => {
  const route = nuxtApp._route

  const isKioskMode = useState('kioskmode', () => false)
  const kioskModeCookie = await useCookie<IKioskModeCookie | null>('kioskmode')

  if (route.query.kioskmode && route.query.kioskmode !== 'true') {
    kioskModeCookie.value = null
    isKioskMode.value = false
    return
  }
  // Do we already have a kioskmode cookie
  const iskiosKModeCookkie =
    kioskModeCookie.value?.isKiosk &&
    (kioskModeCookie.value?.Retailer || kioskModeCookie.value?.RetailerGroup)

  // Is kiosk mode set via URL
  const kioskModeSetInURL =
    (route.query.RetailerGroup || route.query.Retailer) &&
    route.query.kioskmode === 'true'

  // Update curent cookie with URL set values
  if (kioskModeSetInURL) {
    kioskModeCookie.value = null
    kioskModeCookie.value = {
      isKiosk: route.query.kioskmode === 'true',
      RetailerGroup: route.query.RetailerGroup || null,
      Retailer: route.query.Retailer || null,
      KioskType: route.query.kiosktype || 'KioskType Not Set'
    }
    isKioskMode.value = true

    // if we have data in cookies we use that
  } else if (iskiosKModeCookkie) {
    if (kioskModeCookie.value?.Retailer) {
      route.query.Retailer = kioskModeCookie.value?.Retailer
    }
    if (kioskModeCookie.value?.RetailerGroup) {
      route.query.RetailerGroup = kioskModeCookie.value?.RetailerGroup
    }
    isKioskMode.value = true
  }
})
