import { useCustomerTypeStore } from '~/stores/customerTypeStore'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $api, $pinia } = useNuxtApp()
  const customerTypeStore = useCustomerTypeStore($pinia)

  // Activate Retailer Mode if the criteria is met
  if (to.query.RetailerGroup || to.query.Retailer) {
    // If we're getting in retailer mode, make sure
    // that the location filter query parameters
    // are deleted
    delete to.query.Postcode
    delete to.query.Distance
    delete to.query.Lat
    delete to.query.Lon
    delete to.query.RetailerId
    delete to.query.RetailerName

    // If a Retailer GSSNID is present, make a new retailer
    // request to get the actual RetailerId
    if (to.query.Retailer) {
      const response = await $api.retailers.get(String(to.query.Retailer))

      if (response.data?.Retailer?.Id) {
        to.query.RetailerId = String(response.data.Retailer.Id)

        if (response.data.Retailer.IsMotabilityRetailer)
          customerTypeStore.enableMotability()

        return
      }

      delete to.query.Retailer
    }
  }
})
