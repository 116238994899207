<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M14.628 5.072v4.384c0 1.332-.24 2.256-.718 2.707-.48.452-.936.667-1.415.667-1.263 0-2.199-1.054-2.199-2.58 0-.45.174-1.138.37-1.74.174-.559.349-1.117.349-1.504 0-.881-.458-1.333-1.415-1.333l-.37.022v7.973h.849V15H0v-1.332h.849V0h8.359v4.62c2.198 0 2.851.903 2.851 2.429-.021.258-.152.773-.37 1.504-.174.645-.37 1.354-.37 1.74 0 .925.544 1.483 1.045 1.483.762 0 1.154-1.01 1.154-2.32V5.072a1.909 1.909 0 0 1-1.459-1.827v-1.31h.566V.536h1.067v1.397h.697V.537h1.066v1.397H16v1.29c.022.902-.522 1.633-1.371 1.848zm-7.205.172V1.827h-4.68v3.417h4.68zm-1.35 1.719l-2.35 2.9h1l-.979 2.472h.37l2.351-2.9H5.464l.98-2.472h-.37z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Hybrid'
}
</script>
