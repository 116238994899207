import { IQuoteFinanceProduct } from '~/types/finance/quote'
import {
  EFinanceProductKeys,
  IFinanceBaseProduct
} from '~/types/finance/product'
import { EFinanceProductsType } from '~/types/finance/products'
import { ECondition } from '~/types/vehicle'
import { IFinanceProductsState } from '~/stores/finance/productsStore'

export const financeFactory = {
  products: {
    create: (): IFinanceProductsState => ({
      [EFinanceProductsType.all]: {
        [ECondition.New]: [],
        [ECondition.Used]: []
      },
      [EFinanceProductsType.vehicle]: []
    })
  },
  product: {
    create: (): IFinanceBaseProduct => ({
      Key: EFinanceProductKeys.PCP,
      AdvanceRentals: null,
      Term: null,
      Mileage: null,
      Deposit: null,
      RegularPayment: null,
      PartExchange: null,
      Settlement: null
    })
  },

  quote: {
    create: (
      key: EFinanceProductKeys = EFinanceProductKeys.PCP
    ): IQuoteFinanceProduct => ({
      Key: key,
      HasError: false,
      Product: {
        Name: ''
      },
      QuoteDto: {
        Product: '',
        MonthlyPayment: 0,
        AnnualMileage: '',
        ExcessMileageCharge: 0,
        DurationOfAgreement: 0,
        FixedInterestRate: '',
        APR: '',
        TotalAmountPayable: 0,
        AmountOfCredit: 0,
        OptionalPurchasePayment: 0,
        PurchaseActivationFee: 0,
        CustomerDeposit: 0,
        TotalDepositContribution: 0,
        TotalDeposit: 0,
        CustomerType: '',
        PartExchange: 0,
        IsBOL: false,
        IsCH: false,
        IsPOL: false,
        IsPCH: false,
        IsPCP: false,
        IsPHP: false,
        IsBHP: false,
        IsBCP: false,
        AdvanceRentals: 0,
        Settlement: 0,
        IsPersonalised: false,
        Apply: '',
        RetailerApply: ''
        // HasError: false,
      }
    })
  }
}
