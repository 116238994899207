import { ECondition } from '~/types/vehicle'
import { useFilterDataStore } from '~/stores/filterDataStore'
import {
  IFilterDataBrand,
  IFilterDataBrandModel,
  IFilterDataFilter
} from '~/types/filterData'

type FilterDataBrandsData = {
  brandsById: Record<number, IFilterDataBrand>
  bodyStylesById: Record<number, IFilterDataFilter>
  modelsById: Record<number, IFilterDataBrandModel>
  brandByModelId: Record<number, IFilterDataBrand>
  modelsByBodyStyle: Record<number, IFilterDataBrandModel[]>
}

export const useBrands = (condition: ECondition) => {
  const filterDataBrands = useState<FilterDataBrandsData>(
    `filterDataBrands_${condition}`,
    () => {
      const filterDataStore = useFilterDataStore()
      const filterBrands = filterDataStore.getData(condition, 'Brands')

      const brandsById: Record<number, IFilterDataBrand> = {}
      const bodyStylesById: Record<number, IFilterDataFilter> = {}
      const modelsById: Record<number, IFilterDataBrandModel> = {}
      const brandByModelId: Record<number, IFilterDataBrand> = {}
      let modelsByBodyStyle: Record<number, IFilterDataBrandModel[]> = {}

      filterBrands.forEach((brand) => {
        brandsById[brand.Id] = brand

        brand.BodyStyles.forEach((bodyStyle) => {
          bodyStylesById[bodyStyle.Id] = bodyStyle
        })

        brand.Models.forEach((model) => {
          modelsById[model.Id] = model
          brandByModelId[model.Id] = brand
        })
      })

      modelsByBodyStyle = useGroupBy<IFilterDataBrandModel>(
        Object.values(modelsById),
        'BodyStyleId'
      )

      return {
        brandsById,
        bodyStylesById,
        modelsById,
        brandByModelId,
        modelsByBodyStyle
      }
    }
  )

  return {
    ...filterDataBrands.value
  }
}
