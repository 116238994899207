<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6.62 10v3h6.969v3H.667l1-3H3.65v-3H.667l1-3H3.65V4.937c0-.687.13-1.33.39-1.93A4.973 4.973 0 0 1 8.636 0c.678 0 1.318.122 1.922.367a4.916 4.916 0 0 1 1.586 1.024c.453.437.81.966 1.07 1.586.261.62.391 1.304.391 2.054H10.62c0-.375-.052-.692-.156-.953a1.643 1.643 0 0 0-.43-.633 1.673 1.673 0 0 0-.64-.351 2.695 2.695 0 0 0-.774-.11 2.044 2.044 0 0 0-1.414.563 1.94 1.94 0 0 0-.586 1.39V7h2.969v3h-2.97z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Price'
}
</script>
