<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m4.39808696 11.4153043c.53721739.6725218 1.32173913 1.0886957 2.3093913 1.2869566.89997189.201548 1.835355.1822747 2.72626087-.0561739 2.06521737-.5869566 3.80104347-2.5753044 3.87617387-4.9474783l2.1885218-.03356522c-.0850435 3.12921742-2.2511305 6.27321742-5.4133913 7.08573912-.34623593.0982578-.69900514.1718337-1.0556522.2201739-2.14747264.2857648-4.3137213-.3699173-5.94226087-1.7986087l-1.48365217 1.9928696-1.60347826-5.64052175 5.82869565-.00626087zm2.57252174-11.22086952c2.14744124-.28574216 4.3136583.36986691 5.9422609 1.79843479l1.4836521-1.99286957 1.6034783 5.6413913-5.8286957.00626087 1.4306087-1.89686956c-.5372173-.67269565-1.3217391-1.08886957-2.30921735-1.28695652-.90001633-.20160576-1.83545585-.18239216-2.72643478.056-2.06382609.58573913-3.79843478 2.57165217-3.87617391 4.94104348l-2.188.03356521c.08695652-3.12765217 2.25234782-6.26817391 5.41286956-7.08.34621951-.09828334.6989947-.17180238 1.05565218-.22z"
      fill="currentColor"
      fill-rule="evenodd"
      transform="translate(0 .667)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Update'
}
</script>
