export interface IOcdValidationRules {
  ErrorMessage: string
  Type: 'Regex' | 'ServerSideRegex'
  Value: string
}

export enum EOcdFieldType {
  Input = 'Input',
  Date = 'Date',
  TelephoneNumber = 'TelephoneNumber',
  Select = 'Select',
  Postcode = 'Postcode',
  Number = 'Number',
  BankSortCode = 'BankSortCode',
  DisplayText = 'DisplayText',
  CheckBox = 'CheckBox'
}

export interface IOcdField {
  Error: string
  Identifier: string
  IsReadOnly: boolean
  IsRequired: boolean
  Key: string
  Label: string
  Options: { Text: string; Value: string }[]
  ToolTip: string
  ToolTipPrompt: string
  Type: EOcdFieldType
  ValidationRules: IOcdValidationRules[]
  Value: string
}

export enum EOcdLabel {
  APPLICANT = 'Applicant',
  APPLICANTS = 'Applicants',
  PERSONAL_DETAILS = 'Personal details',
  ADDRESS_HISTORY = 'Address History',
  CURRENT_ADDRESS = 'Current address',
  EMPLOYMENT_DETAILS = 'Your employment details',
  CURRENT_EMPLOYMENT = 'Current employment',
  BANKING_DETAILS = 'Your banking details',
  SUBMIT_APPLICATION = 'Submit your application'
}

export enum EOcdActionType {
  RemoveSection = 'DeleteSection',
  AddSection = 'AddSection',
  Text = 'Text'
}

export interface IOcdAction {
  Description: string
  Error: any
  Type: EOcdActionType
  Value: string // identifier
}

export interface IOcdSection {
  Actions: { Header: IOcdAction[]; Footer: IOcdAction[] }
  Fields: IOcdField[]
  Identifier: string
  IsValidToSubmit: boolean
  Label: EOcdLabel
  Sections: IOcdSection[]
}
