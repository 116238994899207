import { LocationQueryValue } from '#vue-router'
import { getFlatProduct } from '~/services/financeService'
import { BudgetOption } from '~/stores/finance/criteriaStore'
import { IFiltersBudget } from '~/types/filters'
import { EFinanceProductKeys, IFinanceProduct } from '~/types/finance/product'

export interface FinanceCriteria {
  key: EFinanceProductKeys
  budgetOption: BudgetOption
  deposit?: string
  monthlyBudget?: string
  paymentMultiple?: number
  mileage?: number
  term?: number
  settlement?: string
  partExchange?: number
  isPersonalised: boolean
}

const DEFAULT_DEPOSIT = '17.5%'

export const getQuoteCriteria = (
  criteria: FinanceCriteria,
  defaultProduct: IFinanceProduct,
  budget: IFiltersBudget
): IFinanceProduct => {
  const product = { ...defaultProduct }

  product.IsPersonalised = criteria.isPersonalised

  if (criteria.mileage) {
    if (['PHP', 'BHP'].includes(criteria.key)) {
      delete product.Mileage
    } else {
      product.Mileage = {
        Options: [{ IsDefault: true, Value: criteria.mileage }]
      }
    }
  }

  if (criteria.term) {
    product.Term = {
      Options: [{ IsDefault: true, Value: criteria.term }]
    }
  }

  if (criteria.settlement) {
    product.Settlement = criteria.settlement
  }

  if (criteria.partExchange) {
    product.PartExchange = String(criteria.partExchange)
  }

  // Only set the monthly price from filters
  // if we do not have a target monthly budget.
  const hasTargetMonthlyBudget =
    criteria.monthlyBudget &&
    criteria.budgetOption === BudgetOption.MONTHLY_BUDGET

  if (filterUtils.hasMonthlyPrice(budget) && !hasTargetMonthlyBudget) {
    product.MonthlyPrice = budget.monthlyPrice
  }

  /**
   * Purchase
   */
  if (isPurchase(criteria.key)) {
    product.AdvanceRentals = null

    // Target Monthly Budget
    if (criteria.budgetOption === BudgetOption.MONTHLY_BUDGET) {
      product.RegularPayment = criteria.monthlyBudget
      product.Deposit = { Default: DEFAULT_DEPOSIT }

      return getFlatProduct(product)!
    }

    // Deposit
    product.Deposit = {
      Default:
        criteria.deposit === ''
          ? DEFAULT_DEPOSIT
          : (criteria.deposit ?? DEFAULT_DEPOSIT)
    }

    product.RegularPayment = null

    return getFlatProduct(product)!
  }

  /**
   * Lease
   */

  // Advance Rental
  if (
    criteria.budgetOption === BudgetOption.DEPOSIT &&
    criteria.isPersonalised
  ) {
    product.Deposit = {
      Default:
        criteria.deposit === ''
          ? DEFAULT_DEPOSIT
          : (criteria.deposit ?? DEFAULT_DEPOSIT)
    }

    product.AdvanceRentals = null
    product.RegularPayment = null

    return getFlatProduct(product)!
  }

  // Target Monthly Budget
  if (criteria.budgetOption === BudgetOption.MONTHLY_BUDGET) {
    product.AdvanceRentals = null
    product.RegularPayment = criteria.monthlyBudget
    product.Deposit = { Default: DEFAULT_DEPOSIT }

    return getFlatProduct(product)!
  }

  // Always set deposit to null if we are not using it
  product.Deposit = null
  product.RegularPayment = null

  // Payment Multiple
  if (criteria.paymentMultiple) {
    product.AdvanceRentals = {
      Options: [{ IsDefault: true, Value: criteria.paymentMultiple }]
    }
  }

  return getFlatProduct(product)!
}

export const DeepLinkOption = {
  Term: 'Term',
  Deposit: 'Deposit',
  RegularPayment: 'RegularPayment',
  AdvanceRentals: 'AdvanceRentals',
  Mileage: 'Mileage',
  AdvanceRentalSum: 'AdvanceRentalSum',
  Key: 'Key'
}

export type DeepLinkOptions = keyof typeof DeepLinkOption

const isValidOption = (option: string): option is DeepLinkOptions => {
  return Object.keys(DeepLinkOption).includes(option)
}

export const parseDeeplink = (
  query: string | LocationQueryValue[]
): Map<DeepLinkOptions, string> => {
  const criteria = parseQueryValue(query).split('|')
  const map = new Map()

  criteria.forEach((c) => {
    const [key, value] = c.split(':')

    if (isValidOption(key)) {
      map.set(key as DeepLinkOptions, value)
    }
  })

  return map
}
