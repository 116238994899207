import { ESortIds } from '~/types/filters'

export interface IFilterDataFilter {
  Id: number
  Description: string
}

export interface IFilterDataMinMax {
  Min: number
  Max: number
}

export interface IFilterDataBrandModel extends IFilterDataFilter {
  Priority: number
  BodyStyleId: number
  Motability: boolean
  Specialist: boolean
  ImageURL3D?: string
  BbdUrl?: string
}

export interface IFilterDataModelFuel {
  FuelId: number
  ModelId: number
}
export interface IFilterDataModelLine {
  LineId: number
  ModelId: number
}

export interface IFilterDataColour extends IFilterDataFilter {
  ImageUrl: string
}

export interface IFilterDataBrand extends IFilterDataFilter {
  BodyStyles: IFilterDataFilter[]
  Models: IFilterDataBrandModel[]
}

export interface IFiltersLine extends IFilterDataFilter {
  Alias: string
}

export interface IFilterDataEngine extends IFilterDataFilter {
  Brand: string
  ModelIds: number[]
}

export interface IFilterDataSortOption extends IFilterDataFilter {
  Id: ESortIds
}

export interface IFilterDataCommon {
  Account: (string | null)[]
  AllLines: IFiltersLine[]
  Baumuster: string[]
  Brands: IFilterDataBrand[]
  Colours: IFilterDataColour[]
  Emission: IFilterDataMinMax
  Engines: IFilterDataEngine[]
  Fuels: IFilterDataFilter[]
  Lines: IFiltersLine[]
  ModelFuel: IFilterDataModelFuel[]
  ModelLines: IFilterDataModelLine[]
  NST: (string | null)[]
  Packages: IFilterDataFilter[]
  RetailerServices: IFilterDataFilter[]
  SortOptions: IFilterDataSortOption[]
  Upholsteries: IFilterDataFilter[]
  UsageCode: string[]
}

export interface IFilterDataNew extends IFilterDataCommon {
  OTR: IFilterDataMinMax
  AdvancedRental: IFilterDataMinMax
  DeliveryTime: IFilterDataFilter[]
  AgeInDays: IFilterDataMinMax
}

export interface IFilterDataUsed extends IFilterDataCommon {
  RetailPrice: IFilterDataMinMax
  Age: IFilterDataMinMax
  Mileage: IFilterDataMinMax
  Transmissions: IFilterDataFilter[]
}

export type IFilterData = IFilterDataNew | IFilterDataUsed

// Os Filters
export interface IFilterDataBudget {
  MonthlyPrice: IFilterDataMinMax
  Price: IFilterDataMinMax
}

export interface IOsFilterDataCommon {
  Budget: IFilterDataBudget
}
export interface IOsFilterDataNew extends IOsFilterDataCommon, IFilterDataNew {}
export interface IOsFilterDataUsed
  extends IOsFilterDataCommon,
    IFilterDataUsed {}

export type IOsFilterData = IOsFilterDataNew | IOsFilterDataUsed

export type IOsBodyStyleIds = {
  Description: string
  Ids: number[]
}

export function isFilterDataNew(
  response: IFilterData | IOsFilterData
): response is IFilterDataNew | IOsFilterDataNew {
  return 'OTR' in response
}
export function isOsFilterDataNew(
  response: IOsFilterData
): response is IOsFilterDataNew {
  return 'OTR' in response
}

export function extendsIFilterDataFilter(
  response: IFilterData[keyof IFilterData] | IOsFilterData[keyof IOsFilterData]
): response is IFilterDataFilter[] {
  return (
    !!Array.isArray(response) &&
    !!response[0] &&
    typeof response[0] === 'object' &&
    Object.prototype.hasOwnProperty.call(response[0], 'Id') &&
    Object.prototype.hasOwnProperty.call(response[0], 'Description')
  )
}
