<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16">
    <path
      fill="currentColor"
      d="M6.54967502,1.71587744 C6.95078923,0.690807799 7.93129062,0 9.11234912,0 C9.84772516,0 10.4939647,0.267409471 11.0510678,0.824512535 C11.6081708,1.33704735 11.8755803,1.98328691 11.8755803,2.74094708 C11.8755803,3.92200557 11.1624884,4.94707521 10.0705664,5.32590529 C10.0705664,4.43454039 9.64716806,3.49860724 8.97864438,2.80779944 C8.31012071,2.16155989 7.50789229,1.78272981 6.54967502,1.71587744 L6.54967502,1.71587744 L6.54967502,1.71587744 Z M3.5636026,5.45961003 C3.5636026,4.72423398 3.83101207,4.07799443 4.36583101,3.52089136 C4.92293408,2.9637883 5.54688951,2.69637883 6.30454968,2.69637883 C7.08449396,2.69637883 7.7084494,2.9637883 8.24326834,3.52089136 C8.8003714,4.07799443 9.06778087,4.70194986 9.06778087,5.45961003 C9.06778087,6.21727019 8.8003714,6.84122563 8.24326834,7.39832869 C7.73073352,7.95543175 7.08449396,8.22284123 6.30454968,8.22284123 C5.56917363,8.22284123 4.92293408,7.95543175 4.36583101,7.39832869 C3.83101207,6.84122563 3.5636026,6.19498607 3.5636026,5.45961003 L3.5636026,5.45961003 L3.5636026,5.45961003 Z M14.7056639,10.2506964 L14.7056639,13.3036212 L12.9229341,13.3036212 L12.9229341,12.9916435 C12.9229341,11.9665738 12.4772516,11.0529248 11.6081708,10.2952646 C10.7390901,9.53760446 9.64716806,8.98050139 8.28783658,8.64623955 C9.090065,8.17827298 9.35747447,7.73259053 9.82544104,6.75208914 C12.5663881,6.95264624 14.7056639,8.4902507 14.7056639,10.2506964 L14.7056639,10.2506964 L14.7056639,10.2506964 Z M11.9201486,12.9693593 L11.9201486,16 L0.666666667,16 L0.666666667,12.9693593 C0.666666667,11.0083565 3.20705664,9.42618384 6.28226555,9.42618384 C9.35747447,9.42618384 11.9201486,11.0083565 11.9201486,12.9693593 L11.9201486,12.9693593 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'People'
}
</script>
