import { defineStore } from 'pinia'
import {
  CREDIT_ATTEMPTS,
  EOcdFormType,
  OcdTitles,
  useOnlineCreditDecisionStore
} from './onlineCreditDecisionStore'

import { useFormFactoryStore } from './formFactoryStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { EStoreStatus } from '~/types/forms'
import { IStep } from '~/types/steps'
import { ICreditRatingTier } from '~/types/onlineCreditDecision'

export enum ECreditCheckSteps {
  FinanceQuote = 'Your finance quote',
  FinanceEligibility = 'Check finance eligibility',
  FormFactory = 'Form factory',
  FinanceEligibilityResult = 'Finance eligibility result'
}

export const steps: IStep[] = [
  {
    label: ECreditCheckSteps.FinanceQuote,
    title: 'Your finance quote'
  },
  {
    label: ECreditCheckSteps.FinanceEligibility,
    title: 'Check finance eligibility'
  },
  {
    label: ECreditCheckSteps.FormFactory,
    title: ''
  },
  {
    label: ECreditCheckSteps.FinanceEligibilityResult,
    title: 'Finance eligibility result'
  }
]

interface ICreditCheckState {
  status: EStoreStatus
  isLoading: boolean
  steps: IStep[]
  creditRatingTier: ICreditRatingTier | null
}

export const useCreditCheckStore = defineStore('creditCheck', {
  hasNavigation: true,

  state: (): ICreditCheckState => {
    return {
      status: EStoreStatus.OK,
      isLoading: false,
      steps: steps.slice(0, 2),
      creditRatingTier: null
    }
  },
  getters: {
    getSteps(): IStep[] {
      return steps
    },

    isCreditCheckLoading(): boolean {
      const ocdStore = useOnlineCreditDecisionStore()
      return this.isLoading || ocdStore.isLoading
    },

    hasError(): boolean {
      return this.status !== EStoreStatus.OK
    },

    isOCDEnabled(): boolean {
      const { isEnabled } = useFeatureFlag('ocd_enabled')
      return isEnabled.value
    }
  },
  actions: {
    reset() {
      this.$reset()
    },
    async getForm() {
      if (this.status !== EStoreStatus.OK) return

      this.steps = useCloneDeep(steps)

      const ocdStore = useOnlineCreditDecisionStore()
      await ocdStore.fetchContent('preCreditText')
      await ocdStore.checkConsent()
      await this.submitPersonalInformation()

      await ocdStore.getForm(EOcdFormType.CREDIT_CHECK)

      // if personal information has been submitted we want to update the formFactory updatedFieldsById
      // because we only show errors for edited fields, so fields existing in updatedFieldsById
      const ocdFormFactoryStore = useFormFactoryStore()
      ocdFormFactoryStore.updateFormValuesFromSections()
    },

    async submitForm() {
      const ocdStore = useOnlineCreditDecisionStore()

      this.isLoading = true

      const response = await this.api.onlineCreditDecision.submitForm(
        ocdStore.proposalReference,
        EOcdFormType.CREDIT_CHECK
      )

      this.isLoading = false

      if (response.error || response.data?.Error) {
        this.status = EStoreStatus.ERROR
      }
    },

    async submitPersonalInformation() {
      const formsStore = useFormsStore()
      const {
        title,
        firstName,
        lastName,
        email,
        phone,
        postcode,
        property,
        street,
        streetTwo,
        county,
        town
      } = formsStore.getCustomer

      if (!firstName || !lastName || !email) return

      const ocdStore = useOnlineCreditDecisionStore()
      const addressPresent =
        property || street || streetTwo || county || town || postcode

      const AddressHistory = addressPresent
        ? [
            {
              Address: {
                ...(property && { HouseName: property }),
                ...(street && { AddressLine1: street }),
                ...(streetTwo && { AddressLine2: streetTwo }),
                ...(county && { County: county }),
                ...(town && { Town: town }),
                ...(postcode && { Postcode: postcode })
              }
            }
          ]
        : []

      const personalInfo = {
        Applicants: [
          {
            ...(title?.id ? { Title: OcdTitles[title?.id] } : {}),
            FirstName: firstName.trim(),
            LastName: lastName.trim(),
            EmailAddress: email.trim(),
            ...(phone ? { PrimaryPhoneNumber: phone?.trim() } : {}),
            ...(addressPresent && { AddressHistory })
          }
        ]
      }

      this.isLoading = true

      const response =
        await this.api.onlineCreditDecision.updatePersonalInformation(
          ocdStore.proposalReference,
          personalInfo
        )

      this.isLoading = false

      if (response.error || response.data?.Error) {
        this.status = EStoreStatus.ERROR
      }
    },

    async fetchCreditRating() {
      const ocdStore = useOnlineCreditDecisionStore()
      const { sidepanelModel } = useSidepanel(ESidepanel.FORMS)

      const getCreditRating = async (retry = 0) => {
        const response = await this.api.onlineCreditDecision.getCreditRating(
          ocdStore.proposalReference,
          ocdStore.sessionToken
        )

        if (response.error && retry < CREDIT_ATTEMPTS && sidepanelModel.value) {
          await new Promise((resolve) => setTimeout(resolve, 3000))

          return getCreditRating(retry + 1)
        } else {
          return response.data
        }
      }

      this.isLoading = true

      const creditRating = await getCreditRating()

      this.isLoading = false

      this.creditRatingTier = getCreditRatingTier(creditRating?.Score || 0)

      const finalDecision = getCreditResponse(
        this.creditRatingTier,
        this.isOCDEnabled
      )

      ocdStore.setCreditRating(finalDecision)
    }
  }
})
