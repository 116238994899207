import { defineStore } from 'pinia'
import { useFormsStore } from './forms/formsStore'
import { useShortlistStore } from '~/stores/shortlistStore'
import { useRecentlyViewedStore } from '~/stores/recentlyViewedStore'
import { useSavedSearchStore } from '~/stores/savedSearchStore'
import { IUserInfo, NProfileAuth } from '~/types/api'
import { INuxtOptions } from '~/composables/useOsFetch'

// todo: remove
// updateLoggedInStatus has been removed because it can be done with a computed property

const getTimestamp = (seconds: number) => {
  const now = new Date()
  now.setTime(now.getTime() + 1 * seconds * 1000)

  return now.getTime()
}

type ProfilePersona = Partial<NProfileAuth.create.Response> &
  Pick<NProfileAuth.create.Response, 'Id' | 'authToken' | 'refreshToken'>
type ProfileToken = NProfileAuth.getAuthToken.Response

interface State {
  persona: ProfilePersona | null
  token: ProfileToken | null
  isTokenVerified: boolean
  hasFailedLogin: boolean
}

export const useProfileStore = defineStore('profile', {
  state: (): State => {
    return {
      persona: null,
      token: null,
      isTokenVerified: false,
      hasFailedLogin: false
    }
  },

  actions: {
    async createProfile(options?: INuxtOptions) {
      const profileData = await this.api.profile.auth.create(options)

      if (profileData.error) {
        this.persona = null
      } else {
        this.updateProfile(profileData.data)
      }

      if (process.client) {
        this.updateCookie()
      }
    },

    updateCookie() {
      const dt = new Date()
      dt.setDate(dt.getDate() + 90)

      const profileCookie = useCookie('profile', {
        expires: dt,
        secure: true
      })

      if (!this.persona) return this.deleteCookie()

      profileCookie.value = JSON.stringify({
        id: this.persona.Id,
        refreshToken: this.persona.refreshToken,
        authToken: this.persona.authToken
      })
    },

    deleteCookie() {
      const profileCookie = useCookie('profile')

      profileCookie.value = null
    },

    updateProfile(profileData: ProfilePersona) {
      this.persona = {
        ...this.persona,
        ...profileData
      }
    },

    async loginProfile(code: string) {
      this.hasFailedLogin = false

      const authToken = await this.api.profile.auth.getAuthToken(code)
      if (authToken.data?.token_verified) {
        const tokenData = {
          ...authToken.data,
          expires_in: getTimestamp(authToken.data.expires_in)
        }

        this.storeToken(tokenData)
        if (authToken.data?.UserInfo) {
          this.updateCustomerDetails(authToken.data?.UserInfo)
        }
        await this.mergeProfile(tokenData)
        if (!this.hasFailedLogin) {
          const { url } = localStorage?.myAccountLoginRedirect || {}

          this.fetchProfileData()

          if (url) {
            const router = useRouter()
            router.replace(url)
          }
        }
      } else {
        this.hasFailedLogin = true
      }

      delete localStorage?.myAccountLoginRedirect
    },

    // Updates customer details in the Form store with UserInfo from Login session token
    updateCustomerDetails(userinfo: IUserInfo) {
      const formsStore = useFormsStore()
      userinfo.given_name &&
        formsStore.updateCustomer({ firstName: userinfo.given_name })
      userinfo.family_name &&
        formsStore.updateCustomer({ lastName: userinfo.family_name })

      userinfo.phone_number &&
        formsStore.updateCustomer({ phone: userinfo.phone_number })
      userinfo.email && formsStore.updateCustomer({ email: userinfo.email })
    },

    storeToken(tokenData: NProfileAuth.getAuthToken.Response) {
      this.isTokenVerified = true
      this.token = tokenData
      sessionStorage.token = JSON.stringify(tokenData)
    },

    mergeProfile({
      profile,
      authToken,
      refreshToken
    }: NProfileAuth.getAuthToken.Response) {
      if (!profile?.Id) {
        this.hasFailedLogin = true
        return
      }

      const newProfile = {
        ...profile,
        authToken,
        refreshToken
      }

      this.updateProfile(newProfile)

      this.updateCookie()
      sessionStorage.profile = JSON.stringify(newProfile)
    },

    resetProfile() {
      const shortlistStore = useShortlistStore()
      shortlistStore.resetAll()

      const recentlyViewedStore = useRecentlyViewedStore()
      recentlyViewedStore.resetAll()

      const savedSearchStore = useSavedSearchStore()
      savedSearchStore.fetch()
    },
    async logoutProfile() {
      this.persona = null
      this.token = null
      this.isTokenVerified = false

      if (process.client) {
        this.deleteCookie()
        delete sessionStorage.profile
        delete sessionStorage.token
      }
      this.resetProfile()
      await this.createProfile()
      await this.fetchProfileData()
      // Reset the customer details in the form store.
      this.updateCustomerDetails({
        given_name: '',
        family_name: '',
        phone: '',
        email: ''
      })
    },

    fetchProfileData() {
      // todo: this.persona?.Id should always be defined at this point?
      if (!this.persona?.Id) return

      const shortlistStore = useShortlistStore()
      shortlistStore.fetch()

      // We done need to fetch savedSearchStore or recentlyViewed in kiosk mode
      const isKioskMode = useState('kioskmode')
      if (isKioskMode.value) return

      const recentlyViewedStore = useRecentlyViewedStore()
      const savedSearchStore = useSavedSearchStore()

      recentlyViewedStore.fetch()
      savedSearchStore.fetch()
    }
  },

  getters: {
    isLoggedIn(state) {
      return !!state.persona?.CiamId
    },

    getCiamId(state) {
      return state.persona?.CiamId || ''
    },

    getProfileId(state) {
      return state.persona?.Id || ''
    },

    getAuthToken(state) {
      return state.persona?.authToken || ''
    }
  }
})
