import { defineStore } from 'pinia'
import { createMessenger, v2, Unsubscribable } from '@userlike/messenger'

interface MessengerState {
  current: v2.State | null
  isLoaded: boolean
  subscription: Unsubscribable | null
  messenger: v2.Messenger | null
}

const widgetKey =
  'ed02910748944a69a365a54097651c5126925261ff0f4d1b9965313726f8f27b'

export const useMessengerStore = defineStore('messenger', {
  state: (): MessengerState => ({
    current: null,
    isLoaded: false,
    subscription: null,
    messenger: null
  }),
  actions: {
    async loadMessenger() {
      const isKioskMode = useState('kioskmode')

      if (isKioskMode.value) return

      this.disableMessenger()

      const result = await createMessenger({
        version: 2,
        widgetKey
      })

      if (result.kind === 'error') {
        console.error('Failed to create messenger:', result.error)
        return
      }

      const { api } = result.value

      if (api) {
        window.$messenger = api
        this.subscription = api.mount().subscribe({
          next: (mountResult) => {
            if (mountResult.kind === 'error') {
              console.error('Mount error:', mountResult.error)
              return
            }
            if (mountResult.value === null) {
              console.log('messenger is not mounted:', mountResult.reason)
              return
            }

            this.messenger = mountResult.value
            this.subscription = this.messenger.state$.subscribe({
              next: (state) => {
                this.current = state
                this.messenger?.setVisibility({
                  main: true,
                  button: false,
                  notifications: true
                })
              },
              error: (error) => {
                console.error('Messenger state error:', error)
                this.disableMessenger()
              }
            })
            this.isLoaded = true
          },
          error: (error) => {
            console.error('Mount subscription error:', error)
            this.disableMessenger()
          }
        })
      }
    },

    enableNotification() {
      this.messenger?.setVisibility({
        main: true,
        button: false,
        notifications: true
      })
    },

    disableNotification() {
      this.messenger?.setVisibility({
        main: true,
        button: false,
        notifications: false
      })
    },

    disableMessenger() {
      if (this.subscription) {
        this.subscription.unsubscribe()
        this.subscription = null
      }
      this.isLoaded = false
      this.messenger = null
      this.current = null
    },

    async enableMessenger() {
      if (!window.$messenger) {
        await this.loadMessenger()
        this.showMessenger()
      } else {
        this.isLoaded = true
      }
    },

    showMessenger() {
      this.messenger?.maximize()
    },

    hideMessenger() {
      if (!this.isShown) return

      this.messenger?.minimize()
    }
  },
  getters: {
    isShown: (state) => {
      return state.current?.state === 'maximized'
    },

    isOnline: (state) => {
      return state.isLoaded && state.current?.state !== 'hidden'
    },

    notificationsNumber(state) {
      if (!state.current) return 0
      let sum = 0
      state.current?.conversations?.forEach((conversation) => {
        if (conversation.unread) {
          sum += conversation.unread.length
        }
      })

      return sum
    }
  }
})
