<template>
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M13.3333333,13.6685714 C11.9953864,13.6685714 11.0034602,12.5485714 11.0034602,10.9485714 C11.0034602,10.4457143 11.1880046,9.69142857 11.3956171,9.07428571 C11.5801615,8.48 11.7647059,7.88571429 11.7647059,7.47428571 C11.7647059,6.53714286 11.2572088,6.05714286 10.2191465,6.05714286 L9.89619377,6.08 L9.89619377,14.56 L10.7958478,14.56 L10.7958478,15.9771429 L0.092272203,15.9771429 L0.092272203,14.56 L0.991926182,14.56 L0.991926182,0.0228571429 L9.85005767,0.0228571429 L9.85005767,4.93714286 C12.1107266,4.93714286 12.8719723,5.96571429 12.8719723,7.52 C12.8489043,7.81714286 12.710496,8.38857143 12.4798155,9.21142857 C12.2260669,10.0571429 12.0876586,10.6285714 12.0876586,10.9942857 C12.0876586,11.9314286 12.5951557,12.5942857 13.194925,12.5942857 C13.9331027,12.5942857 14.4175317,11.6114286 14.4175317,10.1028571 L14.4175317,5.34857143 L13.6101499,4.48 L13.6101499,2.62857143 L12.0876586,0.982857143 L13.0103806,0.137142857 L15.5709343,2.94857143 L15.5709343,10.1028571 C15.5709343,11.5428571 15.3171857,12.5028571 14.8096886,12.9828571 C14.3252595,13.44 13.8177624,13.6685714 13.3333333,13.6685714 L13.3333333,13.6685714 L13.3333333,13.6685714 L13.3333333,13.6685714 Z M7.93540946,5.6 L7.93540946,1.96571429 L2.97577855,1.96571429 L2.97577855,5.6 L7.93540946,5.6 L7.93540946,5.6 L7.93540946,5.6 Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Petrol'
}
</script>
